@charset "UTF-8";
@import '~animate.css/animate.min.css';
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import '~ngx-toastr/toastr.css';
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

@font-face {
  font-family: "themify";
  src: url("./../fonts/themify.eot?-fvbane");
  src: url("./../fonts/themify.eot?#iefix-fvbane") format("embedded-opentype"), url("./../fonts/themify.woff?-fvbane") format("woff"), url("./../fonts/themify.ttf?-fvbane") format("truetype"), url("./../fonts/themify.svg?-fvbane#themify") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=ti-], [class*=" ti-"] {
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
  content: "\e600";
}

.ti-volume:before {
  content: "\e601";
}

.ti-user:before {
  content: "\e602";
}

.ti-unlock:before {
  content: "\e603";
}

.ti-unlink:before {
  content: "\e604";
}

.ti-trash:before {
  content: "\e605";
}

.ti-thought:before {
  content: "\e606";
}

.ti-target:before {
  content: "\e607";
}

.ti-tag:before {
  content: "\e608";
}

.ti-tablet:before {
  content: "\e609";
}

.ti-star:before {
  content: "\e60a";
}

.ti-spray:before {
  content: "\e60b";
}

.ti-signal:before {
  content: "\e60c";
}

.ti-shopping-cart:before {
  content: "\e60d";
}

.ti-shopping-cart-full:before {
  content: "\e60e";
}

.ti-settings:before {
  content: "\e60f";
}

.ti-search:before {
  content: "\e610";
}

.ti-zoom-in:before {
  content: "\e611";
}

.ti-zoom-out:before {
  content: "\e612";
}

.ti-cut:before {
  content: "\e613";
}

.ti-ruler:before {
  content: "\e614";
}

.ti-ruler-pencil:before {
  content: "\e615";
}

.ti-ruler-alt:before {
  content: "\e616";
}

.ti-bookmark:before {
  content: "\e617";
}

.ti-bookmark-alt:before {
  content: "\e618";
}

.ti-reload:before {
  content: "\e619";
}

.ti-plus:before {
  content: "\e61a";
}

.ti-pin:before {
  content: "\e61b";
}

.ti-pencil:before {
  content: "\e61c";
}

.ti-pencil-alt:before {
  content: "\e61d";
}

.ti-paint-roller:before {
  content: "\e61e";
}

.ti-paint-bucket:before {
  content: "\e61f";
}

.ti-na:before {
  content: "\e620";
}

.ti-mobile:before {
  content: "\e621";
}

.ti-minus:before {
  content: "\e622";
}

.ti-medall:before {
  content: "\e623";
}

.ti-medall-alt:before {
  content: "\e624";
}

.ti-marker:before {
  content: "\e625";
}

.ti-marker-alt:before {
  content: "\e626";
}

.ti-arrow-up:before {
  content: "\e627";
}

.ti-arrow-right:before {
  content: "\e628";
}

.ti-arrow-left:before {
  content: "\e629";
}

.ti-arrow-down:before {
  content: "\e62a";
}

.ti-lock:before {
  content: "\e62b";
}

.ti-location-arrow:before {
  content: "\e62c";
}

.ti-link:before {
  content: "\e62d";
}

.ti-layout:before {
  content: "\e62e";
}

.ti-layers:before {
  content: "\e62f";
}

.ti-layers-alt:before {
  content: "\e630";
}

.ti-key:before {
  content: "\e631";
}

.ti-import:before {
  content: "\e632";
}

.ti-image:before {
  content: "\e633";
}

.ti-heart:before {
  content: "\e634";
}

.ti-heart-broken:before {
  content: "\e635";
}

.ti-hand-stop:before {
  content: "\e636";
}

.ti-hand-open:before {
  content: "\e637";
}

.ti-hand-drag:before {
  content: "\e638";
}

.ti-folder:before {
  content: "\e639";
}

.ti-flag:before {
  content: "\e63a";
}

.ti-flag-alt:before {
  content: "\e63b";
}

.ti-flag-alt-2:before {
  content: "\e63c";
}

.ti-eye:before {
  content: "\e63d";
}

.ti-export:before {
  content: "\e63e";
}

.ti-exchange-vertical:before {
  content: "\e63f";
}

.ti-desktop:before {
  content: "\e640";
}

.ti-cup:before {
  content: "\e641";
}

.ti-crown:before {
  content: "\e642";
}

.ti-comments:before {
  content: "\e643";
}

.ti-comment:before {
  content: "\e644";
}

.ti-comment-alt:before {
  content: "\e645";
}

.ti-close:before {
  content: "\e646";
}

.ti-clip:before {
  content: "\e647";
}

.ti-angle-up:before {
  content: "\e648";
}

.ti-angle-right:before {
  content: "\e649";
}

.ti-angle-left:before {
  content: "\e64a";
}

.ti-angle-down:before {
  content: "\e64b";
}

.ti-check:before {
  content: "\e64c";
}

.ti-check-box:before {
  content: "\e64d";
}

.ti-camera:before {
  content: "\e64e";
}

.ti-announcement:before {
  content: "\e64f";
}

.ti-brush:before {
  content: "\e650";
}

.ti-briefcase:before {
  content: "\e651";
}

.ti-bolt:before {
  content: "\e652";
}

.ti-bolt-alt:before {
  content: "\e653";
}

.ti-blackboard:before {
  content: "\e654";
}

.ti-bag:before {
  content: "\e655";
}

.ti-move:before {
  content: "\e656";
}

.ti-arrows-vertical:before {
  content: "\e657";
}

.ti-arrows-horizontal:before {
  content: "\e658";
}

.ti-fullscreen:before {
  content: "\e659";
}

.ti-arrow-top-right:before {
  content: "\e65a";
}

.ti-arrow-top-left:before {
  content: "\e65b";
}

.ti-arrow-circle-up:before {
  content: "\e65c";
}

.ti-arrow-circle-right:before {
  content: "\e65d";
}

.ti-arrow-circle-left:before {
  content: "\e65e";
}

.ti-arrow-circle-down:before {
  content: "\e65f";
}

.ti-angle-double-up:before {
  content: "\e660";
}

.ti-angle-double-right:before {
  content: "\e661";
}

.ti-angle-double-left:before {
  content: "\e662";
}

.ti-angle-double-down:before {
  content: "\e663";
}

.ti-zip:before {
  content: "\e664";
}

.ti-world:before {
  content: "\e665";
}

.ti-wheelchair:before {
  content: "\e666";
}

.ti-view-list:before {
  content: "\e667";
}

.ti-view-list-alt:before {
  content: "\e668";
}

.ti-view-grid:before {
  content: "\e669";
}

.ti-uppercase:before {
  content: "\e66a";
}

.ti-upload:before {
  content: "\e66b";
}

.ti-underline:before {
  content: "\e66c";
}

.ti-truck:before {
  content: "\e66d";
}

.ti-timer:before {
  content: "\e66e";
}

.ti-ticket:before {
  content: "\e66f";
}

.ti-thumb-up:before {
  content: "\e670";
}

.ti-thumb-down:before {
  content: "\e671";
}

.ti-text:before {
  content: "\e672";
}

.ti-stats-up:before {
  content: "\e673";
}

.ti-stats-down:before {
  content: "\e674";
}

.ti-split-v:before {
  content: "\e675";
}

.ti-split-h:before {
  content: "\e676";
}

.ti-smallcap:before {
  content: "\e677";
}

.ti-shine:before {
  content: "\e678";
}

.ti-shift-right:before {
  content: "\e679";
}

.ti-shift-left:before {
  content: "\e67a";
}

.ti-shield:before {
  content: "\e67b";
}

.ti-notepad:before {
  content: "\e67c";
}

.ti-server:before {
  content: "\e67d";
}

.ti-quote-right:before {
  content: "\e67e";
}

.ti-quote-left:before {
  content: "\e67f";
}

.ti-pulse:before {
  content: "\e680";
}

.ti-printer:before {
  content: "\e681";
}

.ti-power-off:before {
  content: "\e682";
}

.ti-plug:before {
  content: "\e683";
}

.ti-pie-chart:before {
  content: "\e684";
}

.ti-paragraph:before {
  content: "\e685";
}

.ti-panel:before {
  content: "\e686";
}

.ti-package:before {
  content: "\e687";
}

.ti-music:before {
  content: "\e688";
}

.ti-music-alt:before {
  content: "\e689";
}

.ti-mouse:before {
  content: "\e68a";
}

.ti-mouse-alt:before {
  content: "\e68b";
}

.ti-money:before {
  content: "\e68c";
}

.ti-microphone:before {
  content: "\e68d";
}

.ti-menu:before {
  content: "\e68e";
}

.ti-menu-alt:before {
  content: "\e68f";
}

.ti-map:before {
  content: "\e690";
}

.ti-map-alt:before {
  content: "\e691";
}

.ti-loop:before {
  content: "\e692";
}

.ti-location-pin:before {
  content: "\e693";
}

.ti-list:before {
  content: "\e694";
}

.ti-light-bulb:before {
  content: "\e695";
}

.ti-Italic:before {
  content: "\e696";
}

.ti-info:before {
  content: "\e697";
}

.ti-infinite:before {
  content: "\e698";
}

.ti-id-badge:before {
  content: "\e699";
}

.ti-hummer:before {
  content: "\e69a";
}

.ti-home:before {
  content: "\e69b";
}

.ti-help:before {
  content: "\e69c";
}

.ti-headphone:before {
  content: "\e69d";
}

.ti-harddrives:before {
  content: "\e69e";
}

.ti-harddrive:before {
  content: "\e69f";
}

.ti-gift:before {
  content: "\e6a0";
}

.ti-game:before {
  content: "\e6a1";
}

.ti-filter:before {
  content: "\e6a2";
}

.ti-files:before {
  content: "\e6a3";
}

.ti-file:before {
  content: "\e6a4";
}

.ti-eraser:before {
  content: "\e6a5";
}

.ti-envelope:before {
  content: "\e6a6";
}

.ti-download:before {
  content: "\e6a7";
}

.ti-direction:before {
  content: "\e6a8";
}

.ti-direction-alt:before {
  content: "\e6a9";
}

.ti-dashboard:before {
  content: "\e6aa";
}

.ti-control-stop:before {
  content: "\e6ab";
}

.ti-control-shuffle:before {
  content: "\e6ac";
}

.ti-control-play:before {
  content: "\e6ad";
}

.ti-control-pause:before {
  content: "\e6ae";
}

.ti-control-forward:before {
  content: "\e6af";
}

.ti-control-backward:before {
  content: "\e6b0";
}

.ti-cloud:before {
  content: "\e6b1";
}

.ti-cloud-up:before {
  content: "\e6b2";
}

.ti-cloud-down:before {
  content: "\e6b3";
}

.ti-clipboard:before {
  content: "\e6b4";
}

.ti-car:before {
  content: "\e6b5";
}

.ti-calendar:before {
  content: "\e6b6";
}

.ti-book:before {
  content: "\e6b7";
}

.ti-bell:before {
  content: "\e6b8";
}

.ti-basketball:before {
  content: "\e6b9";
}

.ti-bar-chart:before {
  content: "\e6ba";
}

.ti-bar-chart-alt:before {
  content: "\e6bb";
}

.ti-back-right:before {
  content: "\e6bc";
}

.ti-back-left:before {
  content: "\e6bd";
}

.ti-arrows-corner:before {
  content: "\e6be";
}

.ti-archive:before {
  content: "\e6bf";
}

.ti-anchor:before {
  content: "\e6c0";
}

.ti-align-right:before {
  content: "\e6c1";
}

.ti-align-left:before {
  content: "\e6c2";
}

.ti-align-justify:before {
  content: "\e6c3";
}

.ti-align-center:before {
  content: "\e6c4";
}

.ti-alert:before {
  content: "\e6c5";
}

.ti-alarm-clock:before {
  content: "\e6c6";
}

.ti-agenda:before {
  content: "\e6c7";
}

.ti-write:before {
  content: "\e6c8";
}

.ti-window:before {
  content: "\e6c9";
}

.ti-widgetized:before {
  content: "\e6ca";
}

.ti-widget:before {
  content: "\e6cb";
}

.ti-widget-alt:before {
  content: "\e6cc";
}

.ti-wallet:before {
  content: "\e6cd";
}

.ti-video-clapper:before {
  content: "\e6ce";
}

.ti-video-camera:before {
  content: "\e6cf";
}

.ti-vector:before {
  content: "\e6d0";
}

.ti-themify-logo:before {
  content: "\e6d1";
}

.ti-themify-favicon:before {
  content: "\e6d2";
}

.ti-themify-favicon-alt:before {
  content: "\e6d3";
}

.ti-support:before {
  content: "\e6d4";
}

.ti-stamp:before {
  content: "\e6d5";
}

.ti-split-v-alt:before {
  content: "\e6d6";
}

.ti-slice:before {
  content: "\e6d7";
}

.ti-shortcode:before {
  content: "\e6d8";
}

.ti-shift-right-alt:before {
  content: "\e6d9";
}

.ti-shift-left-alt:before {
  content: "\e6da";
}

.ti-ruler-alt-2:before {
  content: "\e6db";
}

.ti-receipt:before {
  content: "\e6dc";
}

.ti-pin2:before {
  content: "\e6dd";
}

.ti-pin-alt:before {
  content: "\e6de";
}

.ti-pencil-alt2:before {
  content: "\e6df";
}

.ti-palette:before {
  content: "\e6e0";
}

.ti-more:before {
  content: "\e6e1";
}

.ti-more-alt:before {
  content: "\e6e2";
}

.ti-microphone-alt:before {
  content: "\e6e3";
}

.ti-magnet:before {
  content: "\e6e4";
}

.ti-line-double:before {
  content: "\e6e5";
}

.ti-line-dotted:before {
  content: "\e6e6";
}

.ti-line-dashed:before {
  content: "\e6e7";
}

.ti-layout-width-full:before {
  content: "\e6e8";
}

.ti-layout-width-default:before {
  content: "\e6e9";
}

.ti-layout-width-default-alt:before {
  content: "\e6ea";
}

.ti-layout-tab:before {
  content: "\e6eb";
}

.ti-layout-tab-window:before {
  content: "\e6ec";
}

.ti-layout-tab-v:before {
  content: "\e6ed";
}

.ti-layout-tab-min:before {
  content: "\e6ee";
}

.ti-layout-slider:before {
  content: "\e6ef";
}

.ti-layout-slider-alt:before {
  content: "\e6f0";
}

.ti-layout-sidebar-right:before {
  content: "\e6f1";
}

.ti-layout-sidebar-none:before {
  content: "\e6f2";
}

.ti-layout-sidebar-left:before {
  content: "\e6f3";
}

.ti-layout-placeholder:before {
  content: "\e6f4";
}

.ti-layout-menu:before {
  content: "\e6f5";
}

.ti-layout-menu-v:before {
  content: "\e6f6";
}

.ti-layout-menu-separated:before {
  content: "\e6f7";
}

.ti-layout-menu-full:before {
  content: "\e6f8";
}

.ti-layout-media-right-alt:before {
  content: "\e6f9";
}

.ti-layout-media-right:before {
  content: "\e6fa";
}

.ti-layout-media-overlay:before {
  content: "\e6fb";
}

.ti-layout-media-overlay-alt:before {
  content: "\e6fc";
}

.ti-layout-media-overlay-alt-2:before {
  content: "\e6fd";
}

.ti-layout-media-left-alt:before {
  content: "\e6fe";
}

.ti-layout-media-left:before {
  content: "\e6ff";
}

.ti-layout-media-center-alt:before {
  content: "\e700";
}

.ti-layout-media-center:before {
  content: "\e701";
}

.ti-layout-list-thumb:before {
  content: "\e702";
}

.ti-layout-list-thumb-alt:before {
  content: "\e703";
}

.ti-layout-list-post:before {
  content: "\e704";
}

.ti-layout-list-large-image:before {
  content: "\e705";
}

.ti-layout-line-solid:before {
  content: "\e706";
}

.ti-layout-grid4:before {
  content: "\e707";
}

.ti-layout-grid3:before {
  content: "\e708";
}

.ti-layout-grid2:before {
  content: "\e709";
}

.ti-layout-grid2-thumb:before {
  content: "\e70a";
}

.ti-layout-cta-right:before {
  content: "\e70b";
}

.ti-layout-cta-left:before {
  content: "\e70c";
}

.ti-layout-cta-center:before {
  content: "\e70d";
}

.ti-layout-cta-btn-right:before {
  content: "\e70e";
}

.ti-layout-cta-btn-left:before {
  content: "\e70f";
}

.ti-layout-column4:before {
  content: "\e710";
}

.ti-layout-column3:before {
  content: "\e711";
}

.ti-layout-column2:before {
  content: "\e712";
}

.ti-layout-accordion-separated:before {
  content: "\e713";
}

.ti-layout-accordion-merged:before {
  content: "\e714";
}

.ti-layout-accordion-list:before {
  content: "\e715";
}

.ti-ink-pen:before {
  content: "\e716";
}

.ti-info-alt:before {
  content: "\e717";
}

.ti-help-alt:before {
  content: "\e718";
}

.ti-headphone-alt:before {
  content: "\e719";
}

.ti-hand-point-up:before {
  content: "\e71a";
}

.ti-hand-point-right:before {
  content: "\e71b";
}

.ti-hand-point-left:before {
  content: "\e71c";
}

.ti-hand-point-down:before {
  content: "\e71d";
}

.ti-gallery:before {
  content: "\e71e";
}

.ti-face-smile:before {
  content: "\e71f";
}

.ti-face-sad:before {
  content: "\e720";
}

.ti-credit-card:before {
  content: "\e721";
}

.ti-control-skip-forward:before {
  content: "\e722";
}

.ti-control-skip-backward:before {
  content: "\e723";
}

.ti-control-record:before {
  content: "\e724";
}

.ti-control-eject:before {
  content: "\e725";
}

.ti-comments-smiley:before {
  content: "\e726";
}

.ti-brush-alt:before {
  content: "\e727";
}

.ti-youtube:before {
  content: "\e728";
}

.ti-vimeo:before {
  content: "\e729";
}

.ti-twitter:before {
  content: "\e72a";
}

.ti-time:before {
  content: "\e72b";
}

.ti-tumblr:before {
  content: "\e72c";
}

.ti-skype:before {
  content: "\e72d";
}

.ti-share:before {
  content: "\e72e";
}

.ti-share-alt:before {
  content: "\e72f";
}

.ti-rocket:before {
  content: "\e730";
}

.ti-pinterest:before {
  content: "\e731";
}

.ti-new-window:before {
  content: "\e732";
}

.ti-microsoft:before {
  content: "\e733";
}

.ti-list-ol:before {
  content: "\e734";
}

.ti-linkedin:before {
  content: "\e735";
}

.ti-layout-sidebar-2:before {
  content: "\e736";
}

.ti-layout-grid4-alt:before {
  content: "\e737";
}

.ti-layout-grid3-alt:before {
  content: "\e738";
}

.ti-layout-grid2-alt:before {
  content: "\e739";
}

.ti-layout-column4-alt:before {
  content: "\e73a";
}

.ti-layout-column3-alt:before {
  content: "\e73b";
}

.ti-layout-column2-alt:before {
  content: "\e73c";
}

.ti-instagram:before {
  content: "\e73d";
}

.ti-google:before {
  content: "\e73e";
}

.ti-github:before {
  content: "\e73f";
}

.ti-flickr:before {
  content: "\e740";
}

.ti-facebook:before {
  content: "\e741";
}

.ti-dropbox:before {
  content: "\e742";
}

.ti-dribbble:before {
  content: "\e743";
}

.ti-apple:before {
  content: "\e744";
}

.ti-android:before {
  content: "\e745";
}

.ti-save:before {
  content: "\e746";
}

.ti-save-alt:before {
  content: "\e747";
}

.ti-yahoo:before {
  content: "\e748";
}

.ti-wordpress:before {
  content: "\e749";
}

.ti-vimeo-alt:before {
  content: "\e74a";
}

.ti-twitter-alt:before {
  content: "\e74b";
}

.ti-tumblr-alt:before {
  content: "\e74c";
}

.ti-trello:before {
  content: "\e74d";
}

.ti-stack-overflow:before {
  content: "\e74e";
}

.ti-soundcloud:before {
  content: "\e74f";
}

.ti-sharethis:before {
  content: "\e750";
}

.ti-sharethis-alt:before {
  content: "\e751";
}

.ti-reddit:before {
  content: "\e752";
}

.ti-pinterest-alt:before {
  content: "\e753";
}

.ti-microsoft-alt:before {
  content: "\e754";
}

.ti-linux:before {
  content: "\e755";
}

.ti-jsfiddle:before {
  content: "\e756";
}

.ti-joomla:before {
  content: "\e757";
}

.ti-html5:before {
  content: "\e758";
}

.ti-flickr-alt:before {
  content: "\e759";
}

.ti-email:before {
  content: "\e75a";
}

.ti-drupal:before {
  content: "\e75b";
}

.ti-dropbox-alt:before {
  content: "\e75c";
}

.ti-css3:before {
  content: "\e75d";
}

.ti-rss:before {
  content: "\e75e";
}

.ti-rss-alt:before {
  content: "\e75f";
}

:root {
  --theme-deafult: #5d7227;
  --theme-gradient1: #5d7227;
  --theme-gradient2: #203f15;
}

/*-----------------------------------------------------------------------------------

    Template Name:Multikart
    Template URI: themes.pixelstrap.com/multikart
    Description: This is E-commerce website
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

    ----------------------------------------------------------------------------------- */
/* 1. General CSS
   1.1. Button CSS
   1.2. Title CSS
   1.3. Brand logo CSS */
/* 02. Header CSS */
/* 03. Home slider CSS  */
/* 04. Instagram CSS  */
/* 05. Blog CSS */
/* 06. Service CSS  */
/* 07. About CSS  */
/* 08. Banner Timer CSS */
/* 09. Product Box CSS */
/* 10. Theme tab CSS */
/* 11. Banner CSS */
/* 12. Collection Banner CSS */
/* 13. Product-box slider CSS */
/* 14. Modal popup CSS */
/* 15. Lookbook CSS */
/* 16. Footer CSS */
/* 17. Loader CSS*/
/* 18. Tap to top CSS */
/* 19. color CSS */
/* 20. Facebook Chat CSS */
/* 21. Inner pages CSS */
/* 22. RTL CSS */
/* 23. Menu CSS */
/* 24. Responsive CSS */
/*=====================
    1.General CSS start
    ==========================*/
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: Lato, sans-serif;
  position: relative;
  background: #ffffff;
  font-size: 14px;
  overflow-x: hidden;
  min-width: unset !important;
}

h1 {
  font-size: 60px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase;
}
h1 span {
  font-size: 107px;
  font-weight: 700;
  color: var(--theme-deafult);
}

h2 {
  font-size: 36px;
  color: #222222;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 24px;
  font-weight: 400;
  color: #777777;
  letter-spacing: 0.03em;
}

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  letter-spacing: 0.05em;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 24px;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

li {
  display: inline-block;
}

p {
  font-size: 14px;
  color: #777777;
  line-height: 1;
}

a {
  transition: 0.5s ease;
}
a:hover {
  text-decoration: none;
  transition: 0.5s ease;
}
a:focus {
  outline: none;
}

button:focus {
  outline: none;
}

section,
.section-t-space {
  padding-top: 70px;
}

:focus {
  outline: unset;
}

.form-control {
  height: 50px;
}

[data-notify=progressbar] {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
}

.progress-bar {
  background-color: #19a340;
}

.progress-bar-info {
  background-color: #00829a;
}

.section-b-space {
  padding-bottom: 70px;
}

.p-t-0 {
  padding-top: 0;
}

hr.style1 {
  width: 75px;
  height: 3px;
  margin-top: 13px;
  background-color: var(--theme-deafult);
  text-align: center;
}

.no-arrow .owl-next,
.no-arrow .owl-prev {
  display: none !important;
}

.form-control {
  border-radius: 0;
}

.small-section {
  padding-top: 35px;
  padding-bottom: 35px;
}

.banner-padding {
  padding-top: 30px;
}

.border-section {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.border-b {
  border-bottom: 1px solid #38352f;
}

.border-bottom-grey {
  border-bottom: 1px solid #efefef;
}

.border-top-grey {
  border-top: 1px solid #efefef;
}

.darken-layout {
  background-color: #393230;
}

.dark-layout {
  background-color: #2d2a25;
}

.light-layout {
  background-color: #f9f9f9;
}

.white-layout {
  background-color: #ffffff;
}

.bg-light0 {
  background-color: #d0edff;
}

.bg-light1 {
  background-color: #ffc0cb;
}

.bg-light2 {
  background-color: #63b4f2;
}

.white {
  background-color: #ffffff;
  border: 1px solid #aaa;
}

.pink {
  background-color: #ffc0cb;
}

.red {
  background-color: #ff647f;
}

.green {
  background-color: #6fb866;
}

.yellow {
  background-color: #f2f896;
}

.skyblue {
  background-color: #7ee7ec;
}

.blue {
  background-color: #63b4f2;
}

.orange {
  background-color: #ffb17a;
}

.purple {
  background-color: #eeb1d7;
}

.violet {
  background-color: #a06da0;
}

.brown {
  background-color: #a57372;
}

.lightgreen {
  background-color: #90ee90;
}

.olive {
  background-color: #808000;
}

.navy {
  background-color: #505c80;
}

.black {
  background-color: #000000;
}

.gray,
.grey {
  background-color: #777777;
}

.bg-blog {
  background-color: #eeeeee;
}

del {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400;
}

.br-default .br-unit {
  font-size: 18px !important;
  width: unset !important;
  height: unset !important;
}
.br-default .br-selected:after {
  color: #ffa200 !important;
}

.owl-prev,
.owl-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.sticky.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  background: #fff;
  box-shadow: 0px 0px 8px #ddd;
}
.sticky.fixed .top-header {
  display: none;
}
.sticky.header2.fixed .top-header {
  display: none;
}
.sticky.header2.fixed .main-menu .brand-logo {
  padding-top: 26px;
  padding-bottom: 26px;
}
.sticky.header2.fixed .main-menu .menu-left .navbar {
  padding: 28px 45px 28px 0;
}
.sticky.header2.fixed .main-menu .menu-right .icon-nav li.onhover-div {
  padding-top: 30px;
  padding-bottom: 30px;
}
.sticky.header2.fixed .main-nav-center .sm > li > a {
  padding-top: 16px;
  padding-bottom: 16px;
}
.sticky.header2.fixed .main-nav-center .sm > li .lable-nav {
  top: 1px;
}

.rtl-btn {
  position: fixed;
  right: 0;
  width: 42px;
  height: 41px;
  color: #000000;
  transition: all ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  text-decoration: none;
  background: #ffffff;
  top: 33%;
  border: 1px solid white;
  -webkit-box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
  box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
}
.rtl-btn .RTL_cls {
  position: relative;
  z-index: 99;
  display: block;
  float: right;
  font-size: 14px;
  text-decoration: none;
  padding-left: 8px;
  white-space: nowrap;
  color: black;
}
.rtl-btn .RTL_cls .cart_qty_cls {
  font-weight: 400;
}

.bg-grey {
  background-color: #f7f7f7;
}

/*=====================
    1.1.Button CSS start
    ==========================*/
button {
  cursor: pointer;
}

.btn {
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
  transition: 0.3s ease-in-out;
}
.btn:hover {
  transition: 0.3s ease-in-out;
}
.btn:focus {
  box-shadow: none;
}

.btn-solid {
  padding: 13px 29px;
  color: #ffffff;
  letter-spacing: 0.05em;
  border: 2px solid var(--theme-deafult);
  background-image: -webkit-linear-gradient(30deg, var(--theme-deafult) 50%, transparent 50%);
  background-image: linear-gradient(30deg, var(--theme-deafult) 50%, transparent 50%);
  background-size: 850px;
  background-repeat: no-repeat;
  background-position: 0;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.btn-solid:hover {
  background-position: 100%;
  color: #000000;
  background-color: #ffffff;
}
.btn-solid.black-btn {
  background-image: -webkit-linear-gradient(30deg, #222222 50%, transparent 50%);
  background-image: linear-gradient(30deg, #222222 50%, transparent 50%);
  border: 2px solid #222222;
}
.btn-solid:focus {
  color: #ffffff;
}
.btn-solid.btn-gradient {
  background: var(--theme-deafult);
  background: linear-gradient(180deg, var(--theme-gradient1) 0%, var(--theme-gradient2) 99%);
  transition: background 300ms ease-in-out;
  background-size: 300% 100%;
  border: none;
}
.btn-solid.btn-gradient:hover {
  background: linear-gradient(-180deg, var(--theme-gradient2) 0%, var(--theme-gradient1) 99%);
  transition: background 300ms ease-in-out;
  color: white;
}
.btn-solid.btn-green {
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
  border: none;
  background-color: var(--theme-deafult);
}
.btn-solid.btn-green:hover {
  background-color: var(--theme-deafult);
  background-image: none;
  color: white;
}
.btn-solid.btn-sm, .btn-group-sm > .btn-solid.btn {
  padding: 9px 16px;
}
.btn-solid.btn-xs {
  padding: 5px 8px;
  text-transform: capitalize;
}

.btn-outline {
  display: inline-block;
  padding: 13px 29px;
  letter-spacing: 0.05em;
  border: 2px solid var(--theme-deafult);
  position: relative;
  color: #000000;
}
.btn-outline:before {
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: var(--theme-deafult);
  z-index: -2;
}
.btn-outline:hover, .btn-outline:focus {
  color: #ffffff !important;
  background-color: var(--theme-deafult);
}
.btn-outline:hover i, .btn-outline:focus i {
  color: #ffffff !important;
}
.btn-outline:hover:before, .btn-outline:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}
.btn-outline.btn-sm, .btn-group-sm > .btn-outline.btn {
  padding: 9px 16px;
  font-size: 12px;
}

button.btn.btn-solid:active, button.btn.btn-outline:active {
  background-image: -webkit-linear-gradient(30deg, var(--theme-deafult) 50%, transparent 50%);
  background-image: linear-gradient(30deg, var(--theme-deafult) 50%, transparent 50%);
  color: #ffffff;
  background: var(--theme-deafult);
}

.btn-classic:hover {
  background-color: var(--theme-deafult);
}

.btn-theme {
  background-color: var(--theme-deafult);
  color: #ffffff;
}

/*=====================
    1.2.Title CSS start
    ==========================*/
.title1 {
  text-align: center;
}
.title1 h4 {
  color: var(--theme-deafult);
  padding-bottom: 10px;
  margin-bottom: 0;
  margin-top: -3px;
}
.title1 .title-inner1 {
  padding-bottom: 15px;
  margin-bottom: 30px;
  position: relative;
}
.title1 .title-inner1:after {
  position: absolute;
  height: 5px;
  width: 70px;
  background-color: var(--theme-deafult);
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}
.title1.title5 .title-inner1 {
  padding-bottom: 0;
  margin-bottom: 0;
}
.title1.title5 .title-inner1:after {
  display: none;
}
.title1.title5 hr[role=tournament6] {
  border: 0px solid;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--theme-deafult), rgba(0, 0, 0, 0));
  display: block;
  width: 25%;
  margin: 15px auto 30px auto;
}
.title1.title5 hr[role=tournament6]::before {
  position: absolute;
  background-color: #efefef;
  border: 1px solid;
  border-color: var(--theme-deafult);
  padding: 5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin: -6px 0px 0px 0;
  content: "";
}

.title2 {
  text-align: center;
}
.title2 h4 {
  color: var(--theme-deafult);
  padding-bottom: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-top: -3px;
}
.title2 .title-inner2 {
  margin-bottom: 50px;
  position: relative;
  display: inline-block;
  padding: 0 25px;
}
.title2 .title-inner2:after {
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: var(--theme-deafult);
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 3px;
  z-index: -1;
}

.title3 {
  text-align: center;
}
.title3 h4 {
  color: var(--theme-deafult);
  padding-bottom: 10px;
  margin-bottom: 5px;
  margin-top: -3px;
}
.title3 .title-inner3 {
  margin-bottom: 20px;
  position: relative;
  margin-top: -5px;
}
.title3 .line {
  position: relative;
  height: 5px;
  width: 45px;
  background-color: var(--theme-deafult);
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  margin-bottom: 50px;
}
.title3 .line:after, .title3 .line:before {
  position: absolute;
  height: 5px;
  width: 10px;
  content: "";
  background-color: var(--theme-deafult);
}
.title3 .line:after {
  right: 50px;
}
.title3 .line:before {
  left: 50px;
}

.title4 {
  text-align: center;
}
.title4 .title-inner4 {
  padding-bottom: 25px;
  margin-bottom: 0;
  position: relative;
  margin-top: -5px;
}
.title4 .line {
  position: relative;
  height: 1px;
  width: 180px;
  background-color: var(--theme-deafult);
  margin: 0 auto;
  margin-bottom: 55px;
  bottom: 0;
  top: 0;
}
.title4 .line:after, .title4 .line:before {
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  background-color: var(--theme-deafult);
  top: -4px;
}
.title4 .line:after {
  left: 78px;
}
.title4 .line:before {
  left: 64px;
}
.title4 .line span:after, .title4 .line span:before {
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  background-color: var(--theme-deafult);
  top: -4px;
}
.title4 .line span:after {
  right: 78px;
}
.title4 .line span:before {
  right: 64px;
}

.title-borderless {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px;
}

/*=====================
    1.3.Brand logo CSS start
    ==========================*/
.logo-block a:focus {
  outline: none;
}
.logo-block img {
  filter: grayscale(100%);
  opacity: 0.5;
  transition: 0.5s ease;
  margin: 0 auto;
}
.logo-block img:focus {
  outline: none;
}
.logo-block img:hover {
  filter: grayscale(0);
  opacity: 1;
  transition: 0.5s ease;
}

/*=====================
    02.Header CSS start
    ==========================*/
header {
  background-color: #ffffff;
}

.left-header .top-header .header-dropdown > li {
  padding: 15px 25px !important;
}
.left-header .top-header .header-dropdown > li:first-child {
  padding-right: 0 !important;
}

.onhover-dropdown {
  position: relative;
  font-size: 14px;
}
.onhover-dropdown:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 3px;
}
.onhover-dropdown .onhover-show-div {
  top: 50px;
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
  transition: all linear 0.3s;
  min-width: 160px;
  text-align: left;
  -webkit-box-shadow: 0 0 1px 0 #ebebeb;
  -moz-box-shadow: 0 0 1px 0 #ebebeb;
  box-shadow: 0 0 1px 0 #ebebeb;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  padding: 15px 20px;
  border-radius: 0 !important;
}
.onhover-dropdown .onhover-show-div.product-page-full {
  z-index: 99999;
}
.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}

.onhover-div {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: Lato, sans-serif;
}
.onhover-div img,
.onhover-div i {
  cursor: pointer;
}
.onhover-div .show-div {
  top: 103px;
  position: absolute;
  z-index: 9;
  background-color: #ffffff;
  transition: all linear 0.3s;
  min-width: 236px;
  text-align: left;
  -webkit-box-shadow: 0 0 1px 0 #ebebeb;
  -moz-box-shadow: 0 0 1px 0 #ebebeb;
  box-shadow: 0 0 1px 0 #ebebeb;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  right: 0;
  padding: 0;
  border-radius: 0 !important;
}
.onhover-div:hover .show-div {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}

.search-overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: white;
}
.search-overlay > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-overlay > div .overlay-content {
  width: 100%;
}
.search-overlay > div .overlay-content .form-control:focus {
  box-shadow: none;
}
.search-overlay > div .overlay-content input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #777777;
  border-radius: 0;
  padding: 20px 0;
}
.search-overlay > div .overlay-content input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777777;
  font-size: 18px;
}
.search-overlay > div .overlay-content input::-moz-placeholder {
  /* Firefox 19+ */
  color: #777777;
  font-size: 18px;
}
.search-overlay > div .overlay-content input:-ms-input-placeholder {
  /* IE 10+ */
  color: #777777;
  font-size: 18px;
}
.search-overlay > div .overlay-content input:-moz-placeholder {
  /* Firefox 18- */
  color: #777777;
  font-size: 18px;
}
.search-overlay > div .overlay-content button {
  position: absolute;
  top: 10px;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  color: #777777;
  right: 10px;
}
.search-overlay > div .closebtn {
  position: fixed;
  top: 10px;
  right: 25px;
  color: #777777;
  font-size: 40px;
  cursor: pointer;
}

.top-header {
  background-color: #f8f8f8;
}
.top-header .header-contact {
  padding: 15px 0;
}
.top-header .header-contact li {
  color: #999999;
  font-size: 14px;
  padding-right: 25px;
}
.top-header .header-contact li i {
  color: var(--theme-deafult);
  padding-right: 10px;
}
.top-header .header-dropdown li {
  cursor: pointer;
  color: #999999;
  padding: 15px 25px;
}
.top-header .header-dropdown li:first-child {
  padding-right: 0;
}
.top-header .header-dropdown li:last-child {
  padding-left: 0;
}
.top-header .header-dropdown li a {
  text-transform: capitalize;
  color: #999999;
}
.top-header .header-dropdown li i {
  padding-right: 5px;
  transition: all 0.5s ease;
}
.top-header .header-dropdown li ul li {
  padding-top: 0;
  padding-bottom: 0;
}
.top-header .header-dropdown li a i {
  color: #999999;
  transition: all 0.5s ease;
}
.top-header .header-dropdown li a:hover i {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.top-header .header-dropdown li:hover i {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.top-header .header-dropdown li:hover a {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.top-header .header-dropdown .onhover-dropdown .onhover-show-div li {
  padding-left: 0;
  display: flex;
  font-size: 15px;
  padding-bottom: 5px;
}
.top-header .header-dropdown .onhover-dropdown .onhover-show-div li:last-child {
  padding-bottom: 0;
}
.top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #333333;
}
.top-header .header-dropdown .onhover-dropdown .onhover-show-div li:hover a {
  color: var(--theme-deafult);
}
.top-header.top-header-dark {
  background-color: #222222;
}
.top-header.top-header-dark .header-contact li {
  color: #777777;
}
.top-header.top-header-dark .header-contact li i {
  color: #777777;
}
.top-header.top-header-dark .header-dropdown li {
  color: #777777;
}
.top-header.top-header-dark .header-dropdown li a {
  color: #777777;
}
.top-header.top-header-dark .header-dropdown li a i {
  color: #777777;
}
.top-header.top-header-dark .header-dropdown li a:hover i {
  color: var(--theme-deafult);
}
.top-header .layout3-menu .main-menu .menu-left .navbar i {
  font-size: 22px;
}
.top-header.top-header-dark2 {
  background-color: #2d2a25;
}
.top-header.top-header-dark2 .header-contact li {
  color: #7f786d;
}
.top-header.top-header-dark2 .header-contact li i {
  color: #7f786d;
}
.top-header.top-header-dark2 .header-dropdown li {
  color: #7f786d;
}
.top-header.top-header-dark2 .header-dropdown li a {
  color: #7f786d;
}
.top-header.top-header-dark2 .header-dropdown li a i {
  color: #7f786d;
}
.top-header.top-header-dark2 .header-dropdown li a:hover i {
  color: var(--theme-deafult);
}
.top-header.top-header-dark3 {
  background-color: #393230;
}
.top-header.top-header-dark3 .header-contact li {
  color: #948e8c;
}
.top-header.top-header-dark3 .header-contact li i {
  color: #948e8c;
}
.top-header.top-header-dark3 .header-dropdown li {
  color: #948e8c;
}
.top-header.top-header-dark3 .header-dropdown li a {
  color: #948e8c;
}
.top-header.top-header-dark3 .header-dropdown li a i {
  color: #948e8c;
}
.top-header.top-header-dark3 .header-dropdown li a:hover i {
  color: var(--theme-deafult);
}

.absolute-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.main-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}
.main-menu.product-full-page {
  z-index: 9999;
}
.main-menu .brand-logo {
  display: inline-block;
  padding-top: 35px;
  padding-bottom: 35px;
}
.main-menu .menu-left {
  display: flex;
  align-items: center;
}
.main-menu .menu-left .navbar {
  display: inline-block;
  padding: 40px 45px 40px 0;
}
.main-menu .menu-left .navbar i {
  font-size: 24px;
  color: #222222;
  cursor: pointer;
}
.main-menu .menu-left .navbar.white-navbar i {
  color: #ffffff;
}
.main-menu .menu-left.around-border .navbar > a {
  border: 2px solid var(--theme-deafult);
  padding: 11px 10px 6px;
}
.main-menu .menu-left.category-nav-right .navbar {
  padding-right: 0;
  padding-left: 45px;
}
.main-menu .menu-left.category-nav-right .navbar > a {
  border: 2px solid #f5f2f2;
  padding: 11px 10px 6px;
}
.main-menu .menu-left.category-nav-right .navbar i {
  color: #938d8c;
}
.main-menu .menu-right {
  display: flex;
  float: right;
}
.main-menu .menu-right .icon-nav {
  display: inline-block;
}
.main-menu .menu-right .icon-nav li {
  padding-left: 20px;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart {
  padding: 15px 15px;
  min-width: 270px;
  left: unset;
  right: 0;
  max-height: 315px;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart::-webkit-scrollbar {
  width: 3px;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart::-webkit-scrollbar-thumb {
  background: var(--theme-deafult);
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart h5 {
  margin-bottom: 0;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li {
  position: relative;
  padding-bottom: 10px;
  width: 100%;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li:last-child {
  padding-bottom: 0;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img {
  height: 90px;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body {
  align-self: center;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
  color: #333333;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
  color: #777777;
  font-size: 16px;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
  position: absolute;
  top: 0;
  right: 0;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i {
  color: #a1a1a1;
  transition: all 0.3s ease;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
  color: #000000;
  transition: all 0.3s ease;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-top: 1px solid #f1f5f4;
  border-bottom: 1px solid #f1f5f4;
  padding-top: 10px;
  padding-bottom: 10px;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
  text-transform: capitalize;
  margin-bottom: 0;
  color: #999999;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 span {
  float: right;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  font-size: 16px;
  color: #333333;
  text-transform: capitalize;
  font-weight: 700;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
  color: var(--theme-deafult);
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons .checkout {
  float: right;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
  padding: 15px 20px 20px;
  min-width: 175px;
  right: 0;
  left: unset;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
  font-size: 16px;
  text-transform: capitalize;
  color: #333333;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6:first-child {
  margin-top: 0;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li {
  display: flex;
  padding-bottom: 0;
  padding-left: 17px;
  padding-top: 5px;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div li {
  padding: 0;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control {
  border-radius: 0;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}
.main-menu .menu-right .icon-nav .onhover-div .show-div li .search-btn {
  padding: 8px 12px;
  background-color: var(--theme-deafult);
  color: #ffffff;
}
.main-menu .menu-right .icon-nav.white-icon .onhover-div img {
  filter: brightness(5);
}
.main-menu .menu-right .icon-nav.white-icon .onhover-div .show-div img {
  filter: none;
}

.nav-cat li {
  position: relative;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
}
.nav-cat li .round-cat {
  height: 50px;
  width: 50px;
  border: 1px solid #dddddd;
  padding: 12px;
  border-radius: 100%;
  margin-right: 10px;
}
.nav-cat li a {
  color: #222222;
  font-size: 16px;
  text-transform: uppercase;
}

/*=====================
    03.Home slider CSS start
    ==========================*/
.home-slider .layout-7 .home,
.center-slider .layout-7 .home {
  height: 60vh;
}
.home-slider .layout-7 .home .slider-contain,
.center-slider .layout-7 .home .slider-contain {
  height: 60vh;
}
.home-slider .owl-prev,
.home-slider .owl-next,
.center-slider .owl-prev,
.center-slider .owl-next {
  opacity: 0;
  transform: scale(1);
  transition: all 0.5s ease;
  margin-top: -10px;
  border-radius: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.home-slider .owl-prev i,
.home-slider .owl-next i,
.center-slider .owl-prev i,
.center-slider .owl-next i {
  font-size: 8px;
}
.home-slider .owl-next,
.center-slider .owl-next {
  right: 1px;
}
.home-slider .owl-prev,
.center-slider .owl-prev {
  left: 1px;
  z-index: 1;
}
.home-slider .home,
.center-slider .home {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 75vh;
}
.home-slider .slider-contain,
.center-slider .slider-contain {
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center;
}
.home-slider .slider-contain h1,
.center-slider .slider-contain h1 {
  margin-bottom: 0;
  margin-top: 10px;
  color: #222222;
}
.home-slider .slider-contain h4,
.center-slider .slider-contain h4 {
  font-weight: 700;
  letter-spacing: 0.4em;
  color: #777777;
  margin-bottom: 0;
  margin-top: -3px;
}
.home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline,
.center-slider .slider-contain .btn-solid,
.center-slider .slider-contain .btn-outline {
  margin-top: 20px;
}
.home-slider .p-left .slider-contain,
.center-slider .p-left .slider-contain {
  justify-content: flex-start;
}
.home-slider .p-right .slider-contain,
.center-slider .p-right .slider-contain {
  justify-content: flex-end;
}
.home-slider .p-center .slider-contain,
.center-slider .p-center .slider-contain {
  justify-content: center;
}
.home-slider.text-white .slider-contain h1,
.home-slider.text-white .slider-contain h4,
.center-slider.text-white .slider-contain h1,
.center-slider.text-white .slider-contain h4 {
  color: #ffffff;
}
.home-slider:hover .owl-prev,
.home-slider:hover .owl-next,
.center-slider:hover .owl-prev,
.center-slider:hover .owl-next {
  opacity: 1;
  transform: scale(2.5);
  transition: all 0.5s ease;
}
.home-slider:hover .owl-prev:before,
.home-slider:hover .owl-next:before,
.center-slider:hover .owl-prev:before,
.center-slider:hover .owl-next:before {
  opacity: 1;
}
.home-slider:hover .owl-next,
.center-slider:hover .owl-next {
  right: 100px;
}
.home-slider:hover .owl-prev,
.center-slider:hover .owl-prev {
  left: 100px;
}

.home-slider-container .slider-contain {
  padding: 0 100px;
}

.center-slider {
  border-left: 1px solid #f1f5f4;
  border-right: 1px solid #f1f5f4;
}

.banner-slider .height-banner {
  height: 100%;
}
.banner-slider .home {
  height: 81vh;
}
.banner-slider .home .slider-contain {
  height: 81vh;
}
.banner-slider .home-banner > div img {
  width: 100%;
}
.banner-slider .home-banner > div:last-child img {
  margin-top: 30px;
}

.absolute-banner {
  margin-top: -105px;
}
.absolute-banner .absolute-bg {
  background-color: white;
  position: relative;
  padding: 25px;
  box-shadow: 0 0 8px 0 #ddd;
  z-index: 1;
}

/*=====================
    04.Instagram CSS start
    ==========================*/
.slick-instagram {
  margin-bottom: -5px;
}

.instagram-box {
  position: relative;
  background-color: var(--theme-deafult);
  overflow: hidden;
}
.instagram-box img {
  width: 100%;
}
.instagram-box .overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--theme-deafult);
  top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(3) rotate(90deg);
}
.instagram-box .overlay i {
  font-size: 60px;
  color: #ffffff;
}
.instagram-box:hover .overlay {
  opacity: 0.5;
  transition: 0.5s ease;
  transform: scale(1) rotate(0deg);
}

/*=====================
    05.Blog CSS start
    ==========================*/
.blog-bg {
  background-color: #f1f5f4;
}

.blog .owl-item {
  line-height: 1;
}

.classic-effect {
  position: relative;
  overflow: hidden;
}
.classic-effect:before, .classic-effect:after {
  background: var(--theme-deafult);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s steps(4);
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}
.classic-effect:before {
  left: 0;
  transition-delay: 0s;
}
.classic-effect:after {
  left: 25%;
  transition-delay: 0.025s;
}
.classic-effect span:before, .classic-effect span:after {
  background: var(--theme-deafult);
  width: 25%;
  position: absolute;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s steps(4);
  transition: all 0.3s steps(4);
  z-index: 1;
  bottom: 100%;
  top: 0;
}
.classic-effect span:before {
  left: 50%;
  transition-delay: 0.05s;
}
.classic-effect span:after {
  left: 75%;
  transition-delay: 0.15s;
}
.classic-effect:hover:before, .classic-effect:hover:after {
  bottom: 0;
  opacity: 0.5;
}
.classic-effect:hover span:before, .classic-effect:hover span:after {
  bottom: 0;
  opacity: 0.5;
}

.basic-effect {
  position: relative;
  overflow: hidden;
}
.basic-effect img {
  transition: all 0.5s ease;
}
.basic-effect:after {
  position: absolute;
  content: "";
  background: linear-gradient(-180deg, rgba(var(--theme-gradient1), 0.8) 0%, rgba(var(--theme-gradient2), 0.8) 99%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
}
.basic-effect:hover:after {
  opacity: 1;
  transition: all 0.5s ease;
}
.basic-effect:hover img {
  transform: scale(1.2) rotate(5deg);
  transition: all 0.5s ease;
}

.blog-details {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}
.blog-details h4 {
  color: var(--theme-deafult);
  margin-top: 25px;
  line-height: 1;
  margin-bottom: 0;
}
.blog-details p {
  color: #222222;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.03em;
  margin-top: 10px;
  margin-bottom: 0;
  transition: all 0.5s ease;
}
.blog-details p:hover {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.blog-details h6 {
  line-height: 1;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}

.blog-2 .blog-details h6 {
  padding: 5px 0;
}

/*=====================
    06.Service CSS start
    ==========================*/
.service-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-block .media {
  padding-top: 23px;
  padding-bottom: 23px;
  align-items: center;
}
.service-block svg {
  margin-right: 12px;
  width: 60px;
  height: 60px;
  transition: all 0.5s ease;
}
.service-block svg path {
  fill: var(--theme-deafult);
}
.service-block img {
  margin-right: 12px;
}
.service-block h4 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.service-block p {
  text-transform: capitalize;
  margin-bottom: 0;
}
.service-block + .service-block {
  border-left: 1px solid #dddddd;
}
.service-block:hover svg {
  animation: pulse 1000ms ease-in-out;
  transition: all 0.3s ease;
}
.service-block:hover h4 {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}

.service-block1 {
  text-align: center;
}
.service-block1 svg {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
}
.service-block1 svg path {
  fill: var(--theme-deafult);
}
.service-block1 img {
  margin-bottom: 20px;
}
.service-block1 h4 {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.service-block1 p {
  letter-spacing: 0.03em;
  margin-bottom: -3px;
  line-height: 1.5;
}
.service-block1:hover svg {
  animation: pulse 1000ms ease-in-out;
  transition: all 0.3s ease;
}
.service-block1:hover h4 {
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}

/*=====================
    07.About CSS start
    ==========================*/
.about-text p {
  line-height: 28px;
  letter-spacing: 0.06em;
  text-align: center;
  margin-bottom: 50px;
}

/*=====================
   08.Banner Timer CSS start
==========================*/
.banner-timer {
  background-size: cover;
  margin-left: 20px;
  margin-right: 20px;
}
.banner-timer .banner-text {
  padding-top: 45px;
  padding-bottom: 45px;
}
.banner-timer .banner-text h2 {
  margin-bottom: 0;
  text-align: center;
}
.banner-timer .banner-text h2 span {
  color: var(--theme-deafult);
}
.banner-timer .timer-box {
  justify-content: center;
  display: flex;
}

.timer {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  margin-top: 30px;
  background-color: #2d2a25;
  display: inline-block;
}
.timer p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
}
.timer span {
  width: 70px;
  display: inline-block;
}
.timer span .timer-cal {
  font-size: 12px;
  color: #777777;
}
.timer span .padding-l {
  padding-left: 22px;
  display: inline;
}
.timer span .timer-num {
  width: auto !important;
}

/*=====================
    9.Category CSS start
==========================*/
.category-block .category-image {
  margin: 0 auto;
  text-align: center;
  border: 1px solid #dddddd;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
}
.category-block .category-details {
  margin-top: 15px;
  text-align: center;
}
.category-block .category-details h5 {
  font-weight: 700;
  margin: 0 auto;
  transition: 0.5s ease;
  text-transform: uppercase;
}
.category-block:hover .category-image {
  background-color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.category-block:hover .category-image img {
  filter: brightness(0) invert(1);
  transition: all 0.5s ease;
}
.category-block:hover .category-details h5 {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}

.category-border {
  background-color: #f1f5f4;
  padding: 20px 10px;
}
.category-border .border-padding {
  padding: 0 10px;
}
.category-border div .category-banner {
  padding: 0;
  position: relative;
  overflow: hidden;
}
.category-border div .category-banner img {
  transform: scale(1);
  transition: 0.5s ease;
}
.category-border div .category-banner:hover img {
  transform: scale(1.1) translateX(14px);
  transition: 0.5s ease;
}
.category-border div .category-banner:hover h2 {
  color: var(--theme-deafult);
  transition: 0.5s ease;
}
.category-border div .category-banner .category-box {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category-border div .category-banner .category-box h2 {
  background-color: #ffffff;
  display: inline-block;
  padding: 20px 35px;
  margin-bottom: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
  transition: 0.5s ease;
}

.category-bg {
  position: relative;
  z-index: 1;
  padding: 40px 50px;
}
.category-bg .image-block img {
  width: 100%;
}
.category-bg .image-block.even:after {
  bottom: 94%;
}
.category-bg .image-block:after {
  content: "";
  position: absolute;
  background-color: var(--theme-deafult);
  height: 0;
  width: 40px;
  z-index: 1;
  right: 0;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  transition: 0.5s ease;
  margin-top: -40px;
}
.category-bg .image-block:hover:after {
  opacity: 1;
  transition: 0.5s ease;
  height: 80px;
}
.category-bg .contain-block {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #ffffff;
}
.category-bg .contain-block.even:after {
  top: 94%;
}
.category-bg .contain-block:after {
  content: "";
  position: absolute;
  background-color: var(--theme-deafult);
  height: 0;
  width: 40px;
  z-index: 1;
  right: 0;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  transition: 0.5s ease;
  bottom: 94%;
}
.category-bg .contain-block:hover h2 {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.category-bg .contain-block:hover h6 span {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.category-bg .contain-block:hover .category-btn {
  background-image: linear-gradient(30deg, #222222 50%, transparent 50%);
  border: 2px solid #222222;
  transition: all 0.5s ease;
}
.category-bg .contain-block:hover:after {
  opacity: 1;
  transition: all 0.5s ease;
  height: 80px;
}
.category-bg .contain-block h2 {
  margin-bottom: 0;
  margin-top: 15px;
  transition: 0.5s ease;
}
.category-bg .contain-block h6 {
  color: var(--theme-deafult);
  text-transform: uppercase;
  letter-spacing: 0.3em;
  line-height: 1;
  margin-bottom: 0;
}
.category-bg .contain-block h6 span {
  color: #7f786d;
  letter-spacing: 0.03em;
  font-weight: 700;
  transition: all 0.5s ease;
}
.category-bg .contain-block .category-btn {
  letter-spacing: 0.07em;
  margin-bottom: 25px;
  margin-top: 25px;
  transition: all 0.5s ease;
}
.category-bg:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: var(--theme-deafult);
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.category-m .owl-item > div {
  margin: 0 20px;
}
.category-m .category-wrapper {
  border: 1px solid #dddada;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}
.category-m .category-wrapper > div:after, .category-m .category-wrapper > div:before {
  content: "";
  width: 1px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: var(--theme-deafult);
  transition-delay: 0s;
}
.category-m .category-wrapper > div:before {
  left: 0;
  top: 0;
}
.category-m .category-wrapper > div:after {
  right: 0;
  bottom: 0;
}
.category-m .category-wrapper:after, .category-m .category-wrapper:before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  transition: all 0.2s linear;
  background: var(--theme-deafult);
}
.category-m .category-wrapper:before {
  right: 0;
  top: 0;
}
.category-m .category-wrapper:after {
  left: 0;
  bottom: 0;
}
.category-m .category-wrapper:hover {
  transition: all 0.5s ease;
}
.category-m .category-wrapper:hover:after, .category-m .category-wrapper:hover:before {
  transition-delay: 0s;
  width: 100%;
}
.category-m .category-wrapper:hover > div:after, .category-m .category-wrapper:hover > div:before {
  transition-delay: 0.2s;
  height: 100%;
}
.category-m .category-wrapper img {
  display: inline;
}
.category-m .category-wrapper h4 {
  text-transform: uppercase;
  color: #2d2a25;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 25px;
  padding-top: 25px;
}
.category-m .category-wrapper .btn {
  margin-top: 20px;
}
.category-m .category-wrapper .category-link li {
  display: block;
  text-transform: capitalize;
  margin-top: 5px;
}
.category-m .category-wrapper .category-link li:first-child {
  margin-top: 0;
}
.category-m .category-wrapper .category-link li a {
  color: #948e8c;
}
.category-m .category-wrapper .category-link li a:hover {
  color: var(--theme-deafult);
}

.background {
  background-color: #f1f5f4;
  padding: 20px 10px;
  margin: 0;
}
.background .contain-bg {
  width: 100%;
  background-color: #ffffff;
  padding-top: 45px;
  padding-bottom: 45px;
  text-align: center;
  transition: all 0.5s ease;
}
.background .contain-bg h4 {
  color: #222222;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  transition: all 0.5s ease;
}
.background .contain-bg:hover {
  background-color: var(--theme-deafult);
}
.background .contain-bg:hover h4 {
  color: white;
  transition: all 0.5s ease;
}

/*=====================
    09.Product Box CSS start
    ==========================*/
.br[_ngcontent-c25] {
  margin-top: 5px !important;
}

.br[_ngcontent-c16] {
  margin-top: 5px !important;
}

.br[_ngcontent-c17] {
  margin-top: 5px !important;
}

.search-product .product-box .product-detail,
.search-product .product-box .product-info,
.search-product .product-wrap .product-detail,
.search-product .product-wrap .product-info {
  padding-left: 0;
}

.product-m .owl-item .img-wrapper {
  margin: 0 10px;
}

.color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  transition: all 0.1s ease;
  vertical-align: middle;
}

.size-variant {
  padding-top: 10px;
}
.size-variant li {
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 14px;
  text-align: center;
  color: #777777;
  border: 1px solid;
  line-height: 20px;
  margin-right: 5px;
  text-transform: lowercase;
  cursor: pointer;
}
.size-variant li.square {
  border-radius: 0;
}
.size-variant li.round {
  border-radius: 100%;
}

.no-slider .product-box {
  width: 100%;
  flex: 0 0 25%;
  max-width: calc(25% - 30px);
  margin: 0 15px 30px;
}
.no-slider .product-box:nth-last-child(-n+4) {
  margin: 0 15px 0;
}

.product-para p {
  margin-bottom: 0;
  padding-bottom: 30px;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.product-box .product-detail h4 del,
.product-box .product-info h4 del,
.product-wrap .product-detail h4 del,
.product-wrap .product-info h4 del {
  color: #aaa;
  font-size: 14px;
}

.product-box .img-wrapper .product-thumb-list li img,
.product-wrap .img-wrapper .product-thumb-list li img {
  width: 45px;
}

.product-box,
.product-wrap {
  position: relative;
  transition: all 0.5s ease;
}
.product-box .img-block,
.product-wrap .img-block {
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}
.product-box .img-block .front,
.product-wrap .img-block .front {
  opacity: 1;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}
.product-box .img-block .back,
.product-wrap .img-block .back {
  opacity: 0;
  position: absolute;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
  transform: translateX(-100px);
}
.product-box .img-block .lable-wrapper,
.product-wrap .img-block .lable-wrapper {
  margin: 0 auto;
  top: 40px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  transition: all 0.5s ease;
  z-index: 2;
}
.product-box .img-block .lable-wrapper .lable1,
.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable2 {
  font-size: 14px;
  padding: 10px 14px 10px 20px;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
}
.product-box .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable1 {
  background-color: var(--theme-deafult);
  color: #ffffff;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable2 {
  background-color: #ffffff;
  color: #000000;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.product-box .img-wrapper,
.product-wrap .img-wrapper {
  position: relative;
  overflow: hidden;
}
.product-box .img-wrapper .front,
.product-wrap .img-wrapper .front {
  opacity: 1;
  top: 0;
  left: 0;
  text-align: center;
  transition: all 0.5s ease;
}
.product-box .img-wrapper .back,
.product-wrap .img-wrapper .back {
  opacity: 0;
  position: absolute;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
  transform: translateX(-100px);
}
.product-box .img-wrapper .cart-box,
.product-wrap .img-wrapper .cart-box {
  position: absolute;
  margin: 0 auto;
  display: inline-block;
  right: 0;
  left: 0;
  border-radius: 50px;
  width: max-content;
  padding: 12px 15px;
  box-shadow: 0 0 12px 0 #dddddd;
  bottom: 30px;
  background-color: #ffffff;
  opacity: 0;
  transition: all 0.2s ease;
}
.product-box .img-wrapper .cart-box button,
.product-wrap .img-wrapper .cart-box button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}
.product-box .img-wrapper .cart-box i,
.product-wrap .img-wrapper .cart-box i {
  color: #6f6f6f;
  font-size: 18px;
  padding-left: 8px;
  padding-right: 8px;
  transition: all 0.2s ease;
  display: inline-block;
}
.product-box .img-wrapper .cart-box i:hover,
.product-wrap .img-wrapper .cart-box i:hover {
  color: var(--theme-deafult);
  transition: all 0.2s ease;
}
.product-box .img-wrapper .lable-block .lable3,
.product-wrap .img-wrapper .lable-block .lable3 {
  border-radius: 100%;
  background-color: var(--theme-deafult);
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  padding: 9px 4px;
  text-transform: uppercase;
  color: #ffffff;
  top: 7px;
  left: 7px;
  z-index: 1;
}
.product-box .img-wrapper .lable-block .lable4,
.product-wrap .img-wrapper .lable-block .lable4 {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  top: 7px;
  right: 7px;
  letter-spacing: 0.1em;
  z-index: 1;
  color: #212529;
}
.product-box .cart-info,
.product-box .cart-wrap,
.product-wrap .cart-info,
.product-wrap .cart-wrap {
  position: absolute;
  bottom: 40px;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  right: 0;
  left: 0;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease;
}
.product-box .cart-info a [class^=icon-],
.product-box .cart-info a [class*=" icon-"],
.product-box .cart-wrap a [class^=icon-],
.product-box .cart-wrap a [class*=" icon-"],
.product-wrap .cart-info a [class^=icon-],
.product-wrap .cart-info a [class*=" icon-"],
.product-wrap .cart-wrap a [class^=icon-],
.product-wrap .cart-wrap a [class*=" icon-"] {
  display: inline-block;
}
.product-box .cart-info.cart-wrap,
.product-box .cart-wrap.cart-wrap,
.product-wrap .cart-info.cart-wrap,
.product-wrap .cart-wrap.cart-wrap {
  bottom: 0;
  text-align: right;
  left: unset;
}
.product-box .cart-info.cart-wrap i,
.product-box .cart-wrap.cart-wrap i,
.product-wrap .cart-info.cart-wrap i,
.product-wrap .cart-wrap.cart-wrap i {
  display: block;
  padding-bottom: 10px;
  padding-top: 10px;
}
.product-box .cart-info.cart-wrap.cart-effect-left,
.product-box .cart-wrap.cart-wrap.cart-effect-left,
.product-wrap .cart-info.cart-wrap.cart-effect-left,
.product-wrap .cart-wrap.cart-wrap.cart-effect-left {
  left: 0;
  right: unset;
}
.product-box .cart-info button,
.product-box .cart-wrap button,
.product-wrap .cart-info button,
.product-wrap .cart-wrap button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}
.product-box .cart-info i,
.product-box .cart-wrap i,
.product-wrap .cart-info i,
.product-wrap .cart-wrap i {
  color: #6f6f6f;
  font-size: 18px;
  padding-right: 10px;
  padding-left: 10px;
}
.product-box .cart-info i:hover,
.product-box .cart-wrap i:hover,
.product-wrap .cart-info i:hover,
.product-wrap .cart-wrap i:hover {
  color: var(--theme-deafult);
}
.product-box .cart-detail,
.product-wrap .cart-detail {
  position: absolute;
  top: 15px;
  right: 20px;
  opacity: 0;
}
.product-box .cart-detail i,
.product-wrap .cart-detail i {
  color: #6f6f6f;
  font-size: 18px;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}
.product-box .cart-detail button,
.product-wrap .cart-detail button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
}
.product-box .product-detail,
.product-box .product-info,
.product-wrap .product-detail,
.product-wrap .product-info {
  padding-left: 10px;
}
.product-box .product-detail .rating,
.product-box .product-info .rating,
.product-wrap .product-detail .rating,
.product-wrap .product-info .rating {
  margin-top: 15px;
}
.product-box .product-detail .rating .pro-rating .br-units,
.product-box .product-info .rating .pro-rating .br-units,
.product-wrap .product-detail .rating .pro-rating .br-units,
.product-wrap .product-info .rating .pro-rating .br-units {
  justify-content: center;
}
.product-box .product-detail .rating i,
.product-box .product-info .rating i,
.product-wrap .product-detail .rating i,
.product-wrap .product-info .rating i {
  padding-right: 5px;
}
.product-box .product-detail .rating i:nth-child(-n+4),
.product-box .product-info .rating i:nth-child(-n+4),
.product-wrap .product-detail .rating i:nth-child(-n+4),
.product-wrap .product-info .rating i:nth-child(-n+4) {
  color: #ffa200;
}
.product-box .product-detail .rating i:last-child,
.product-box .product-info .rating i:last-child,
.product-wrap .product-detail .rating i:last-child,
.product-wrap .product-info .rating i:last-child {
  color: #dddddd;
}
.product-box .product-detail p,
.product-box .product-info p,
.product-wrap .product-detail p,
.product-wrap .product-info p {
  display: none;
}
.product-box .product-detail h6,
.product-box .product-info h6,
.product-wrap .product-detail h6,
.product-wrap .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 5px;
  transition: all 0.5s ease;
  font-size: 16px;
  margin-top: -17px;
}
.product-box .product-detail h4,
.product-box .product-info h4,
.product-wrap .product-detail h4,
.product-wrap .product-info h4 {
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  margin-bottom: 0;
  transition: all 0.5s ease;
}
.product-box .product-detail .color-variant,
.product-box .product-info .color-variant,
.product-wrap .product-detail .color-variant,
.product-wrap .product-info .color-variant {
  padding-top: 12px;
}
.product-box .product-detail .color-variant li,
.product-box .product-info .color-variant li,
.product-wrap .product-detail .color-variant li,
.product-wrap .product-info .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  transition: all 0.1s ease;
  cursor: pointer;
  vertical-align: middle;
}
.product-box .product-detail .color-variant li.white,
.product-box .product-info .color-variant li.white,
.product-wrap .product-detail .color-variant li.white,
.product-wrap .product-info .color-variant li.white {
  background-color: #ffffff;
  border: 1px solid #aaa;
}
.product-box .product-detail .color-variant li.pink,
.product-box .product-info .color-variant li.pink,
.product-wrap .product-detail .color-variant li.pink,
.product-wrap .product-info .color-variant li.pink {
  background-color: #ffc0cb;
}
.product-box .product-detail .color-variant li.red,
.product-box .product-info .color-variant li.red,
.product-wrap .product-detail .color-variant li.red,
.product-wrap .product-info .color-variant li.red {
  background-color: #ff647f;
}
.product-box .product-detail .color-variant li.green,
.product-box .product-info .color-variant li.green,
.product-wrap .product-detail .color-variant li.green,
.product-wrap .product-info .color-variant li.green {
  background-color: #6fb866;
}
.product-box .product-detail .color-variant li.yellow,
.product-box .product-info .color-variant li.yellow,
.product-wrap .product-detail .color-variant li.yellow,
.product-wrap .product-info .color-variant li.yellow {
  background-color: #f2f896;
}
.product-box .product-detail .color-variant li.skyblue,
.product-box .product-info .color-variant li.skyblue,
.product-wrap .product-detail .color-variant li.skyblue,
.product-wrap .product-info .color-variant li.skyblue {
  background-color: #7ee7ec;
}
.product-box .product-detail .color-variant li.blue,
.product-box .product-info .color-variant li.blue,
.product-wrap .product-detail .color-variant li.blue,
.product-wrap .product-info .color-variant li.blue {
  background-color: #63b4f2;
}
.product-box .product-detail .color-variant li.orange,
.product-box .product-info .color-variant li.orange,
.product-wrap .product-detail .color-variant li.orange,
.product-wrap .product-info .color-variant li.orange {
  background-color: #ffb17a;
}
.product-box .product-detail .color-variant li.purple,
.product-box .product-info .color-variant li.purple,
.product-wrap .product-detail .color-variant li.purple,
.product-wrap .product-info .color-variant li.purple {
  background-color: #eeb1d7;
}
.product-box .product-detail .color-variant li.violet,
.product-box .product-info .color-variant li.violet,
.product-wrap .product-detail .color-variant li.violet,
.product-wrap .product-info .color-variant li.violet {
  background-color: #a06da0;
}
.product-box .product-detail .color-variant li.brown,
.product-box .product-info .color-variant li.brown,
.product-wrap .product-detail .color-variant li.brown,
.product-wrap .product-info .color-variant li.brown {
  background-color: #a57372;
}
.product-box .product-detail .color-variant li.lightgreen,
.product-box .product-info .color-variant li.lightgreen,
.product-wrap .product-detail .color-variant li.lightgreen,
.product-wrap .product-info .color-variant li.lightgreen {
  background-color: #90ee90;
}
.product-box .product-detail .color-variant li.olive,
.product-box .product-info .color-variant li.olive,
.product-wrap .product-detail .color-variant li.olive,
.product-wrap .product-info .color-variant li.olive {
  background-color: #808000;
}
.product-box .product-detail .color-variant li.navy,
.product-box .product-info .color-variant li.navy,
.product-wrap .product-detail .color-variant li.navy,
.product-wrap .product-info .color-variant li.navy {
  background-color: #505c80;
}
.product-box .product-detail .color-variant li.black,
.product-box .product-info .color-variant li.black,
.product-wrap .product-detail .color-variant li.black,
.product-wrap .product-info .color-variant li.black {
  background-color: #000000;
}
.product-box .product-detail .color-variant li.gray,
.product-box .product-detail .color-variant li .grey,
.product-box .product-info .color-variant li.gray,
.product-box .product-info .color-variant li .grey,
.product-wrap .product-detail .color-variant li.gray,
.product-wrap .product-detail .color-variant li .grey,
.product-wrap .product-info .color-variant li.gray,
.product-wrap .product-info .color-variant li .grey {
  background-color: #777777;
}
.product-box .product-info,
.product-wrap .product-info {
  padding: 0;
  text-align: center;
  position: relative;
}
.product-box .product-info .br-default .br-units,
.product-wrap .product-info .br-default .br-units {
  justify-content: center;
}
.product-box .product-info .add-btn,
.product-wrap .product-info .add-btn {
  position: absolute;
  bottom: 110px;
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 1;
}
.product-box .product-info .add-btn i,
.product-wrap .product-info .add-btn i {
  color: var(--theme-deafult);
}
.product-box .product-info .add-btn .btn-outline,
.product-wrap .product-info .add-btn .btn-outline {
  transition: all 0.2s ease;
  color: var(--theme-deafult);
}
.product-box .product-info .add-btn .btn-outline:hover,
.product-wrap .product-info .add-btn .btn-outline:hover {
  color: #ffffff;
}
.product-box .product-info .add-btn .btn-outline:hover i,
.product-wrap .product-info .add-btn .btn-outline:hover i {
  color: #ffffff;
}
.product-box.effect-center .front img,
.product-wrap.effect-center .front img {
  transition: all 0.5s ease;
}
.product-box.effect-center .img-wrapper .cart-box,
.product-wrap.effect-center .img-wrapper .cart-box {
  bottom: 20%;
  transition: all 0.5s ease;
}
.product-box:hover.effect-center .front img,
.product-wrap:hover.effect-center .front img {
  opacity: 0.3;
  transition: all 0.5s ease;
}
.product-box:hover.effect-center .img-wrapper .cart-box,
.product-wrap:hover.effect-center .img-wrapper .cart-box {
  bottom: 35%;
  transition: all 0.5s ease;
}
.product-box:hover .img-block .first,
.product-box:hover .img-wrapper .first,
.product-wrap:hover .img-block .first,
.product-wrap:hover .img-wrapper .first {
  opacity: 0;
  transition: all 0.5s ease;
}
.product-box:hover .img-block .back,
.product-box:hover .img-wrapper .back,
.product-wrap:hover .img-block .back,
.product-wrap:hover .img-wrapper .back {
  opacity: 1;
  transition: all 0.5s ease;
  transform: translateX(0);
}
.product-box:hover .cart-info,
.product-wrap:hover .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
}
.product-box:hover .cart-info button,
.product-wrap:hover .cart-info button {
  animation: fadeInUp 300ms ease-in-out;
}
.product-box:hover .cart-info a:nth-child(2) i,
.product-wrap:hover .cart-info a:nth-child(2) i {
  animation: fadeInUp 500ms ease-in-out;
}
.product-box:hover .cart-info a:nth-child(3) i,
.product-wrap:hover .cart-info a:nth-child(3) i {
  animation: fadeInUp 700ms ease-in-out;
}
.product-box:hover .cart-info a:nth-child(4) i,
.product-wrap:hover .cart-info a:nth-child(4) i {
  animation: fadeInUp 1000ms ease-in-out;
}
.product-box:hover .cart-wrap button,
.product-wrap:hover .cart-wrap button {
  animation: fadeInRight 300ms ease-in-out;
}
.product-box:hover .cart-wrap a:nth-child(2) i,
.product-wrap:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInRight 500ms ease-in-out;
}
.product-box:hover .cart-wrap a:nth-child(3) i,
.product-wrap:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInRight 700ms ease-in-out;
}
.product-box:hover .cart-wrap a:nth-child(4) i,
.product-wrap:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInRight 1000ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left button,
.product-wrap:hover .cart-wrap.cart-effect-left button {
  animation: fadeInLeft 300ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left a:nth-child(2) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(2) i {
  animation: fadeInLeft 500ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left a:nth-child(3) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(3) i {
  animation: fadeInLeft 700ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left a:nth-child(4) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(4) i {
  animation: fadeInLeft 1000ms ease-in-out;
}
.product-box:hover .cart-detail,
.product-wrap:hover .cart-detail {
  opacity: 1;
  transition: all 0.5s ease;
}
.product-box:hover .cart-detail button,
.product-wrap:hover .cart-detail button {
  animation: fadeInRight 300ms ease-in-out;
}
.product-box:hover .cart-detail a:nth-child(2) i,
.product-wrap:hover .cart-detail a:nth-child(2) i {
  animation: fadeInRight 500ms ease-in-out;
}
.product-box:hover .cart-detail a:nth-child(3) i,
.product-wrap:hover .cart-detail a:nth-child(3) i {
  animation: fadeInRight 700ms ease-in-out;
}
.product-box:hover .cart-detail a:nth-child(4) i,
.product-wrap:hover .cart-detail a:nth-child(4) i {
  animation: fadeInRight 1000ms ease-in-out;
}
.product-box:hover .product-info .add-btn,
.product-wrap:hover .product-info .add-btn {
  opacity: 1;
  transition: all 0.2s ease;
  animation: fadeInUp 500ms ease-in-out;
}
.product-box:hover .img-wrapper .cart-box,
.product-wrap:hover .img-wrapper .cart-box {
  opacity: 1;
  transition: all 0.2s ease;
  animation: fadeInUp 400ms ease-in-out;
}

/*=====================
    10.Theme tab CSS start
    ==========================*/
.vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  padding: 15px 20px;
}

.theme-tab {
  position: relative;
}
.theme-tab .product-box .product-detail,
.theme-tab .product-box .product-info,
.theme-tab .product-wrap .product-detail,
.theme-tab .product-wrap .product-info {
  padding-left: 0;
}
.theme-tab .tab-content > .active .product-box,
.theme-tab .tab-content > .active .tab-box {
  animation: zoomIn 300ms ease-in-out;
}
.theme-tab .tab-content .product-tab .tab-box {
  background-color: #ffffff;
  width: 100%;
  flex: 0 0 25%;
  max-width: calc(25% - 10px);
  margin: 0 5px 10px;
}
.theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+4) {
  margin: 0 5px 0;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 img {
  height: 250px;
  padding: 15px;
  transition: all 0.5s ease;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body {
  margin-top: -18px;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating {
  margin-top: 0;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i {
  padding-right: 0;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:nth-child(-n+4) {
  color: #ffa200;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:last-child {
  color: #dddddd;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
  margin-right: 35px;
  line-height: 17px;
  margin-top: -10px;
  margin-bottom: 0;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
  font-size: 20px;
  margin-top: 5px;
  font-weight: 700;
  color: #222222;
  margin-bottom: 0;
}
.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  margin-top: 20px;
  transition: all 0.1s ease;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: middle;
}
.theme-tab .tab-content .product-tab .tab-box:hover .product-box2 img {
  transform: scale(1.03);
  transition: all 0.5s ease;
}
.theme-tab .tab-title ul.nav,
.theme-tab .tab-title2 ul.nav {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: -6px;
}
.theme-tab .tab-title .nav-link,
.theme-tab .tab-title2 .nav-link {
  padding: 0;
}
.theme-tab .tab-title .nav-link.active,
.theme-tab .tab-title2 .nav-link.active {
  color: var(--theme-deafult);
  background-color: transparent;
}
.theme-tab .tab-title li.nav-item,
.theme-tab .tab-title2 li.nav-item {
  padding-left: 25px;
  padding-right: 25px;
}
.theme-tab .tab-title a.nav-link,
.theme-tab .tab-title2 a.nav-link {
  color: #2d2a25;
  text-transform: uppercase;
}
.theme-tab .marijuana-tab .nav-link {
  padding: 0;
}
.theme-tab .marijuana-tab .nav-link.active {
  color: #dafe69;
  background-color: transparent;
}
.theme-tab .marijuana-tab a.nav-link {
  color: #ffffff;
  text-transform: uppercase;
}
.theme-tab .tab-title2 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.theme-tab .tab-title2:after {
  content: "";
  position: absolute;
  border: 1px solid #f1f5f4;
  width: 100%;
  left: 0;
  top: 17px;
  z-index: -1;
}
.theme-tab .tab-title2 .current {
  background-color: #ffffff;
}
.theme-tab .tab-title2 .current a {
  background-color: #ffffff;
}

.tab-bg {
  position: relative;
  padding: 70px 50px 60px 50px;
}
.tab-bg.tab-grey-bg {
  background-color: #f9f9f9;
}
.tab-bg:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-deafult);
  opacity: 0.09;
  z-index: -1;
}
.tab-bg .theme-tab .tab-title {
  margin-bottom: 30px;
}

/*=====================
    11.Banner CSS start
    ==========================*/
.full-banner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 190px;
  padding-bottom: 190px;
  background-position: center;
}
.full-banner.parallax-layout {
  padding-top: 350px;
  padding-bottom: 350px;
}
.full-banner.parallax-layout .banner-contain h4 {
  color: #7f786d;
}
.full-banner.banner-layout-3 .banner-contain h4 {
  color: #ffffff;
}
.full-banner.banner-layout-3 .banner-contain h3 {
  color: #ffffff;
  margin-top: 10px;
}
.full-banner.banner-layout-3 .banner-contain .color {
  color: var(--theme-deafult);
  line-height: 1;
  margin-top: -13px;
}
.full-banner.parallax {
  background-attachment: fixed;
}
.full-banner.p-left .banner-contain {
  float: left;
}
.full-banner.p-right .banner-contain {
  float: right;
}
.full-banner.p-center .banner-contain {
  left: 0;
  right: 0;
  margin: 0 auto;
}
.full-banner .banner-contain .btn-solid,
.full-banner .banner-contain .btn-outline {
  margin-top: 20px;
}
.full-banner .banner-contain h2 {
  font-size: 100px;
  font-weight: 700;
  color: var(--theme-deafult);
  text-transform: uppercase;
  margin-top: -15px;
}
.full-banner .banner-contain h3 {
  font-size: 60px;
  color: #333333;
  text-transform: uppercase;
  font-weight: 700;
}
.full-banner .banner-contain h4 {
  font-size: 24px;
  color: #777777;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  padding-top: 10px;
  margin-bottom: -5px;
  line-height: 1.3;
}

.parallax-cls section:nth-child(odd) .full-banner {
  background-position: right;
}
.parallax-cls section:nth-child(even) .full-banner {
  background-position: left;
}
.parallax-cls section .banner-contain {
  margin-top: -12px;
}

/*=====================
    12.Collection Banner CSS start
    ==========================*/
.collection-banner {
  position: relative;
  overflow: hidden;
}
.collection-banner img {
  transition: all 0.5s ease;
  width: 100%;
}
.collection-banner.p-left .contain-banner {
  justify-content: flex-start;
}
.collection-banner.p-right .contain-banner {
  justify-content: flex-end;
}
.collection-banner.p-center .contain-banner {
  justify-content: center;
}
.collection-banner .contain-banner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px;
}
.collection-banner .contain-banner.banner-3 {
  padding-left: 50px;
  padding-right: 50px;
}
.collection-banner .contain-banner.banner-3 h2 {
  font-size: 36px;
  letter-spacing: 0.05em;
  color: white;
  margin-top: 5px;
  margin-bottom: -7px;
}
.collection-banner .contain-banner.banner-3 h4 {
  color: #ffffff;
}
.collection-banner .contain-banner.banner-4 {
  padding-left: 50px;
  padding-right: 50px;
}
.collection-banner .contain-banner.banner-4 h2 {
  font-size: 28px;
  letter-spacing: 0.03em;
  color: white;
  margin-bottom: -7px;
}
.collection-banner .contain-banner h4 {
  color: var(--theme-deafult);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  margin-top: -3px;
}
.collection-banner .contain-banner h2 {
  font-size: 60px;
  font-weight: 700;
  color: #222222;
  letter-spacing: 0.1em;
  margin-bottom: -7px;
}
.collection-banner:hover img {
  transform: scale(1.05);
  transition: all 0.5s ease;
}

.banner-furniture {
  padding-left: 15px;
  padding-right: 15px;
}
.banner-furniture .collection-banner .contain-banner.banner-3 h2 {
  color: #222222;
  margin-bottom: 0;
}
.banner-furniture .collection-banner .contain-banner.banner-3 h4 {
  color: var(--theme-deafult);
}

.banner-goggles .collection-banner .contain-banner.banner-3 h2 {
  color: #222222;
}
.banner-goggles .collection-banner .contain-banner.banner-3 h4 {
  color: var(--theme-deafult);
}

/*=====================
    13.Product-box slider CSS start
    ==========================*/
.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.layout7-product .product-box {
  padding: 2px;
}
.layout7-product .product-box img {
  width: 100%;
}
.layout7-product .product-box .cart-info {
  right: 7px;
  bottom: 7px;
}
.layout7-product .product-box .details-product {
  position: absolute;
  transition: all 0.5s ease;
  opacity: 0;
  bottom: 15px;
  left: 15px;
}
.layout7-product .product-box .details-product h6,
.layout7-product .product-box .details-product h4 {
  margin-bottom: 0;
}
.layout7-product .product-box .details-product h6 {
  color: #000000;
}
.layout7-product .product-box:hover .details-product {
  opacity: 1;
  animation: fadeIn 500ms ease-in-out;
  transition: all 0.5s ease;
}

.img-zoom-result {
  background-size: 1614px 2064px !important;
}

.offer-slider .owl-item {
  line-height: 1;
}

.center-slider .product-box {
  box-shadow: none;
}
.center-slider .product-box .img-wrapper img {
  padding: 15px;
  width: 100%;
  margin: 0 auto;
}
.center-slider .owl-next:before,
.center-slider .owl-prev:before {
  box-shadow: 0 0 8px 0 #dddddd;
}
.center-slider .title2 .title-inner2 {
  margin-bottom: 0;
}
.center-slider:hover .product-box {
  box-shadow: none;
}
.center-slider:hover .owl-next {
  right: 20px;
  transform: scale(1.8);
}
.center-slider:hover .owl-prev {
  left: 20px;
  transform: scale(1.8);
}

.theme-card {
  position: relative;
}
.theme-card.center-align {
  display: flex;
  height: 100%;
  align-items: center;
}
.theme-card.center-align .offer-slider .product-box2 .media .media-body {
  margin-top: 0;
}
.theme-card.card-border {
  border: 1px solid #dddddd;
}
.theme-card.card-border h5 {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
}
.theme-card.card-border .owl-prev,
.theme-card.card-border .owl-next {
  top: -45px;
}
.theme-card.card-border .owl-next {
  right: 30px;
}
.theme-card.card-border .owl-prev {
  right: 50px;
}
.theme-card.card-border .offer-slider {
  padding-bottom: 20px;
}
.theme-card.card-border .offer-slider img {
  padding: 15px 15px 15px 30px;
}
.theme-card.card-border .offer-slider .media .media-body a h6 {
  margin-right: 25px;
}
.theme-card h5 {
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.theme-card h5.title-border {
  border-bottom: 1px solid #dddddd;
}
.theme-card .owl-prev,
.theme-card .owl-next {
  top: -35px;
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
}
.theme-card .owl-prev:before,
.theme-card .owl-next:before {
  font: normal normal normal 30px/1 FontAwesome;
  color: #222222;
  opacity: 0.6;
}
.theme-card .owl-next {
  right: 0;
}
.theme-card .owl-next:before {
  content: "\f105";
}
.theme-card .owl-prev {
  left: unset;
  right: 25px;
}
.theme-card .owl-prev:before {
  content: "\f104";
}
.theme-card .offer-slider img {
  height: 160px;
  padding: 15px 15px 15px 0 !important;
}
.theme-card .offer-slider > div .media:last-child .media-body {
  margin-top: 15px;
}
.theme-card .offer-slider > div .media:last-child img {
  margin-bottom: -15px;
}
.theme-card .offer-slider .media .media-body .rating {
  margin-top: 0;
}
.theme-card .offer-slider .media .media-body .rating i {
  padding-right: 5px;
}
.theme-card .offer-slider .media .media-body .rating i:nth-child(-n+4) {
  color: #ffa200;
}
.theme-card .offer-slider .media .media-body .rating i:last-child {
  color: #dddddd;
}
.theme-card .offer-slider .media .media-body a h6 {
  margin-right: 61px;
  line-height: 17px;
  margin-top: -10px;
  margin-bottom: 0;
}
.theme-card .offer-slider .media .media-body h4 {
  margin-top: 10px;
  font-weight: 700;
  color: #222222;
  margin-bottom: 0;
}

.full-box .theme-card .offer-slider .product-box2 .media img {
  height: 300px;
  transform: scale(1);
  transition: all 0.5s ease;
}
.full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
  margin-right: 20px;
}
.full-box .theme-card .offer-slider .product-box2 .media .media-body h4 {
  font-size: 20px;
  margin-top: 5px;
}
.full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  margin-top: 15px;
  transition: all 0.1s ease;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: middle;
}
.full-box .theme-card .offer-slider .product-box2 + .product-box2 {
  border-top: 1px solid #f1f5f4;
}
.full-box .theme-card .offer-slider .product-box2:hover .media img {
  transform: scale(1.03);
  transition: all 0.5s ease;
}

/*=====================
    14.Modal popup CSS start
    ==========================*/
.modal-open {
  padding-right: 0 !important;
}

#sizemodal {
  padding-right: 0 !important;
}

.theme-modal {
  padding-right: 0 !important;
}
.theme-modal .modal-dialog .modal-content .modal-body {
  padding: 15px;
  background-image: linear-gradient(135deg, var(--theme-deafult) 5.77%, #ffffff 5.77%, #ffffff 25%, #111111 25%, #111111 30.77%, #ffffff 30.77%, #ffffff 50%, var(--theme-deafult) 50%, var(--theme-deafult) 55.77%, #ffffff 55.77%, #ffffff 75%, #111111 75%, #111111 80.77%, #ffffff 80.77%, #ffffff 100%);
  background-size: 36.77px 36.77px;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
  background-color: #ffffff;
  padding: 45px;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
  padding-right: 10px;
  padding-top: 5px;
  position: absolute;
  right: 17px;
  top: 0;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close span {
  color: #000000;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
  border-bottom: 1px dashed #777777;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-weight: 700;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
  margin-bottom: 30px;
  text-align: center;
  color: #272727;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form {
  margin: 0 auto;
  text-align: center;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form > div {
  display: flex;
  margin-bottom: 10px;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  height: 50px;
  border-radius: 0;
  width: 100%;
  letter-spacing: 0.05em;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
  margin-bottom: 40px;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
  margin-bottom: 30px;
  text-align: center;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group {
  margin: 0 auto;
  text-align: center;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  max-width: 640px;
  left: 0;
  right: 0;
  margin: 0 auto;
  letter-spacing: 0.05em;
}
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-solid,
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-outline {
  margin-top: 10px;
  text-align: center;
}
.theme-modal .modal-dialog .quick-view-modal button.close {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 5px;
}
.theme-modal .modal-dialog .quick-view-modal .view-detail {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg {
  padding: 25px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media a img {
  max-width: 150px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body {
  padding: 0 15px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
  font-size: 16px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 i {
  margin-right: 8px;
  color: #fff;
  background: #19a340;
  font-size: 14px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons a {
  display: inline-block;
  font-size: 16px;
  padding: 6px 10px;
  margin: 0 6px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box {
  padding-left: 10px;
  padding-right: 10px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail {
  text-align: center;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 {
  font-weight: 700;
  text-transform: capitalize;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
  color: var(--theme-deafult);
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .upsell_payment {
  padding-top: 15px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell {
  margin: 20px 0 10px;
}
.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell h4 {
  font-weight: 600;
}
.theme-modal.exit-modal .media .media-body h4 {
  font-size: 20px;
  line-height: 1.2;
  color: var(--theme-deafult);
  font-weight: 500;
}
.theme-modal.exit-modal .media .media-body h5 {
  font-size: 18px;
  color: #777777;
  margin-bottom: 0;
}

/*=====================
    15.lookbook CSS start
    ==========================*/
.lookbook .lookbook-block {
  position: relative;
}
.lookbook .lookbook-block .lookbook-dot {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 29px;
  height: 29px;
  line-height: 29px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 50%;
  left: 12%;
  background-color: #ffffff;
}
.lookbook .lookbook-block .lookbook-dot.dot2 {
  top: 70%;
  left: 27%;
}
.lookbook .lookbook-block .lookbook-dot.dot3 {
  top: 28%;
  left: 30%;
}
.lookbook .lookbook-block .lookbook-dot.dot4 {
  top: 67%;
  left: 8%;
}
.lookbook .lookbook-block .lookbook-dot.dot5 {
  top: 40%;
  left: 35%;
}
.lookbook .lookbook-block .lookbook-dot.dot6 {
  top: 80%;
  left: 58%;
}
.lookbook .lookbook-block .lookbook-dot.dot7 {
  top: 67%;
  left: 45%;
}
.lookbook .lookbook-block .lookbook-dot.dot8 {
  top: 43%;
  left: 39%;
}
.lookbook .lookbook-block .lookbook-dot.dot9 {
  top: 32%;
  left: 50%;
}
.lookbook .lookbook-block .lookbook-dot.dot10 {
  top: 60%;
  left: 50%;
}
.lookbook .lookbook-block .lookbook-dot.dot11 {
  top: 50%;
  left: 45%;
}
.lookbook .lookbook-block .lookbook-dot.dot12 {
  top: 75%;
  left: 45%;
}
.lookbook .lookbook-block .lookbook-dot.dot13 {
  top: 40%;
  left: 45%;
}
.lookbook .lookbook-block .lookbook-dot.dot14 {
  top: 76%;
  left: 45%;
}
.lookbook .lookbook-block .lookbook-dot.dot15 {
  top: 50%;
  left: 15%;
}
.lookbook .lookbook-block .lookbook-dot:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  zoom: 1;
  background-color: transparent\9 ;
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.lookbook .lookbook-block .lookbook-dot span {
  text-align: center;
  justify-content: center;
  display: flex;
}
.lookbook .lookbook-block .lookbook-dot .dot-showbox {
  visibility: hidden;
  top: -98px;
  left: 150%;
  position: absolute;
  width: 130px;
  background-color: #ffffff;
  -webkit-box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
  box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
}
.lookbook .lookbook-block .lookbook-dot .dot-showbox img {
  margin-top: 0;
}
.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  padding: 5px;
  text-align: center;
}
.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 20px;
}
.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
  font-weight: 700;
}
.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
  text-transform: uppercase;
  color: var(--theme-deafult);
  margin-bottom: 0;
}
.lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid #ffffff;
  border-bottom: 8px solid transparent;
  top: 41%;
  left: -8px;
}
.lookbook .lookbook-block .lookbook-dot:hover {
  background-color: var(--theme-deafult);
  -webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  z-index: 10;
}
.lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
  animation: fadeInUp 400ms ease-in-out;
  visibility: visible;
  transition: all 0.5s ease;
}
.lookbook .lookbook-block .lookbook-dot:hover span {
  color: #ffffff;
}
.lookbook .lookbook-block .lookbook-dot:hover:before {
  -webkit-transform: scale(1.3);
}

/*=====================
    16.look-book demo CSS start
==========================*/
.lookbook-section {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}
.lookbook-section .lookbook-img > div:last-child img {
  margin-top: 25px;
}

/*=====================
17.3.Electronics Demo CSS
==========================*/
.layout-8 {
  padding: 0 80px;
}
.layout-8 .layout-8-bg {
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  animation: 10s linear 0s normal none infinite animate;
}
.layout-8 .layout-8-bg .layout7-product .product-box .details-product {
  opacity: 1;
}
.layout-8 .layout-8-bg .layout7-product .product-box:hover .details-product {
  animation: none;
}

.white-bg {
  background-color: #ffffff;
}

@keyframes animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 500px;
  }
}
/*=====================
    17.4.Fashion-3 Demo CSS
==========================*/
.box-layout-body {
  background-color: #f9f9f9;
}
.box-layout-body .box-layout-header {
  background-color: #ffffff;
}
.box-layout-body .box-layout {
  margin-top: -70px;
  margin-bottom: 5px;
  box-shadow: 0 0 8px 0 #ddd;
  position: relative;
  background-color: white;
  z-index: 1;
}
.box-layout-body .box-layout .full-banner {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 200px;
  padding-bottom: 200px;
}

/*=====================
    17.5.Beauty Demo css
==========================*/
.beauty-about .about-text p {
  text-align: left;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.about-section .small-section {
  padding-top: 20px;
}
.about-section .service .service-block1 img {
  margin-bottom: 10px;
}
.about-section .service .service-block1 h5 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
}

.video-modal .modal-body {
  padding: 0;
}
.video-modal iframe {
  height: 500px;
  width: 100%;
  vertical-align: middle;
  border: none;
}

.video-section .video-img {
  position: relative;
  overflow: hidden;
}
.video-section .video-img img {
  transition: all 0.5s ease;
  transform: scale(1);
}
.video-section .video-img .play-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-section .video-img .play-btn span {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: var(--theme-deafult);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.video-section .video-img .play-btn i {
  font-size: 35px;
  padding-left: 5px;
  color: #ffffff;
}
.video-section .video-img:hover img {
  transform: scale(1.03);
  transition: all 0.5s ease;
}

/*=====================
    17.6..Flower Demo css
==========================*/
.flower-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
}

.bg-block {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  padding: 70px 50px 60px 50px;
}
.bg-block .title4 .line {
  margin-bottom: 25px;
}
.bg-block .theme-tab .tab-title {
  margin-bottom: 30px;
}

/*=====================
    17.7.Nursery Demo css
==========================*/
.layout-20 {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px;
}

/*=====================
    17.2.Full-page scroll CSS start
==========================*/
.full-scroll-menu {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: transparent;
}
.full-scroll-menu .container-fluid, .full-scroll-menu .container-sm, .full-scroll-menu .container-md, .full-scroll-menu .container-lg, .full-scroll-menu .container-xl {
  padding-left: 60px;
  padding-right: 60px;
}

.full-scroll-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.full-scroll-footer .sub-footer {
  background-color: transparent;
}
.full-scroll-footer .sub-footer .container-fluid, .full-scroll-footer .sub-footer .container-sm, .full-scroll-footer .sub-footer .container-md, .full-scroll-footer .sub-footer .container-lg, .full-scroll-footer .sub-footer .container-xl {
  padding-left: 60px;
  padding-right: 60px;
}

.home-slider.fullpage .home {
  height: calc(100vh - 2px);
}
.home-slider.fullpage .home .slider-contain {
  height: calc(100vh - 2px);
  padding-left: 25px;
}
.home-slider.fullpage .home .slider-contain h1 {
  font-style: italic;
  font-size: 50px;
  margin-top: -12px;
}
.home-slider.fullpage .home .slider-contain p {
  max-width: 600px;
  line-height: 1.5;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
}
.home-slider.fullpage .owl-next,
.home-slider.fullpage .owl-prev {
  display: none !important;
}
.home-slider.fullpage:hover .owl-next,
.home-slider.fullpage:hover .owl-prev {
  display: none !important;
}
.home-slider.fullpage .slick-dots {
  top: 50%;
  bottom: unset;
  width: unset;
  left: 20px;
  transform: translateY(-50%);
}
.home-slider.fullpage .slick-dots li {
  display: flex;
}
.home-slider.fullpage .slick-dots li button:before {
  font-size: 15px;
  color: var(--theme-deafult);
  opacity: 0.5;
}
.home-slider.fullpage .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-slider.instagram-slider .home {
  height: 50vh;
}
.home-slider.instagram-slider .home .slider-contain {
  height: 50vh;
}
.home-slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.video-layout {
  height: calc(100vh - 5px);
  width: 100vw;
  object-fit: fill;
}

/*=====================
 Add to cart popup
==========================*/
.add_to_cart {
  position: fixed;
  background-color: #ffffff;
  z-index: 9999;
  transition: all 0.5s ease;
}
.add_to_cart ::-webkit-scrollbar {
  width: 3px;
  height: 7px;
}
.add_to_cart ::-webkit-scrollbar-track {
  background: #dddddd;
}
.add_to_cart ::-webkit-scrollbar-thumb {
  background: #888888;
}
.add_to_cart ::-webkit-scrollbar-thumb:hover {
  background: #555555;
}
.add_to_cart .empty-cart-cls {
  padding: 30px 44px;
}
.add_to_cart .cart-inner {
  background-color: #ffffff;
  z-index: 9;
  position: relative;
  top: 0;
}
.add_to_cart .cart-inner .cart_media {
  padding: 0 20px;
}
.add_to_cart .cart-inner .cart_media li {
  position: relative;
  padding-bottom: 10px;
  width: 100%;
}
.add_to_cart .cart-inner .cart_media li .media {
  align-items: center;
  height: 100%;
}
.add_to_cart .cart-inner .cart_media li .media img {
  height: 90px;
}
.add_to_cart .cart-inner .cart_media li .media .media-body {
  align-self: center;
}
.add_to_cart .cart-inner .cart_media li .media .media-body h4 {
  color: #333333;
}
.add_to_cart .cart-inner .cart_media li .media .media-body h4 span {
  color: #777777;
  font-size: 16px;
}
.add_to_cart .cart-inner .cart_media li .total {
  border-top: 1px solid #f1f5f4;
  border-bottom: 1px solid #f1f5f4;
  padding-top: 10px;
  padding-bottom: 10px;
}
.add_to_cart .cart-inner .cart_media li .total h5 {
  text-transform: capitalize;
  margin-bottom: 0;
  color: #999999;
}
.add_to_cart .cart-inner .cart_media li .total h5 span {
  float: right;
}
.add_to_cart .cart-inner .cart_media li .buttons a {
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 700;
  transition: all 0.5s ease;
}
.add_to_cart .cart-inner .cart_media li .buttons a:hover {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.add_to_cart .cart-inner .cart_media li .buttons .checkout {
  float: right;
}
.add_to_cart .cart-inner .cart_media .close-circle {
  position: absolute;
  top: 0;
  right: 0;
}
.add_to_cart .cart-inner .cart_media .close-circle i {
  color: #a1a1a1;
  font-size: 16px;
  transition: all 0.5s ease;
}
.add_to_cart .cart-inner .cart_media .close-circle i:hover {
  color: #000000;
  transition: all 0.5s ease;
}
.add_to_cart .cart-inner .cart_top {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  align-items: center;
}
.add_to_cart .cart-inner .cart_top h3 {
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}
.add_to_cart .cart-inner .cart_top .close-cart {
  margin-left: auto;
}
.add_to_cart .cart-inner .cart_top .close-cart i {
  color: black;
  font-size: 18px;
}
.add_to_cart .overlay {
  visibility: hidden;
  opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}
.add_to_cart.openSide {
  transition: all 0.5s ease;
}
.add_to_cart.openSide .overlay {
  opacity: 0.8;
  visibility: visible;
  transition: all 0.5s ease;
}
.add_to_cart.left {
  left: -300px;
  top: 0;
  height: 100vh;
  width: 300px;
}
.add_to_cart.left .cart-inner {
  height: 100vh;
  overflow: auto;
}
.add_to_cart.left.openSide {
  left: 0;
}
.add_to_cart.right {
  right: -300px;
  height: 100vh;
  width: 300px;
  top: 0;
}
.add_to_cart.right .cart-inner {
  height: 100vh;
  overflow: auto;
}
.add_to_cart.right.openSide {
  right: 0;
}
.add_to_cart.top, .add_to_cart.bottom {
  height: 218px;
  width: 100%;
}
.add_to_cart.top .cart_media, .add_to_cart.bottom .cart_media {
  display: flex;
  width: 100%;
}
.add_to_cart.top .cart_media .cart_product, .add_to_cart.bottom .cart_media .cart_product {
  display: flex;
  width: 75%;
  overflow-y: auto;
}
.add_to_cart.top .cart_media .cart_product li, .add_to_cart.bottom .cart_media .cart_product li {
  min-width: 245px;
  max-width: 225px;
  margin-right: 18px;
  padding-bottom: 0;
}
.add_to_cart.top .cart_media .cart_total, .add_to_cart.bottom .cart_media .cart_total {
  width: 25%;
}
.add_to_cart.top .cart-inner, .add_to_cart.bottom .cart-inner {
  height: 218px;
}
.add_to_cart.top .cart-inner .cart_top, .add_to_cart.bottom .cart-inner .cart_top {
  margin-bottom: 0;
  background-color: #fbfbfb;
  padding: 15px 44px;
  border-bottom: none;
}
.add_to_cart.top .cart-inner .cart_media, .add_to_cart.bottom .cart-inner .cart_media {
  padding: 0;
}
.add_to_cart.top .cart-inner .cart_media .cart_product,
.add_to_cart.top .cart-inner .cart_media .cart_total, .add_to_cart.bottom .cart-inner .cart_media .cart_product,
.add_to_cart.bottom .cart-inner .cart_media .cart_total {
  padding: 30px 44px;
}
.add_to_cart.top {
  top: -300px;
}
.add_to_cart.top.openSide {
  top: 0;
}
.add_to_cart.bottom {
  bottom: -300px;
}
.add_to_cart.bottom.openSide {
  bottom: 0;
}

/*=====================
    16.footer CSS start
    ==========================*/
footer {
  position: relative;
}
footer .footer-lable {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: var(--theme-deafult);
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .footer-lable:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10px solid var(--theme-deafult);
  border-right: 10px solid transparent;
  top: 0;
  right: -10px;
}
footer .footer-lable i {
  color: white;
  font-size: 25px;
}
footer .footer-theme .footer-mobile-title {
  display: none;
}
footer .darken-layout p {
  color: #938d8c;
}
footer .darken-layout .sub-title .footer-title.active .according-menu {
  font: normal normal normal 14px/1 FontAwesome;
}
footer .darken-layout .sub-title .footer-title.active .according-menu.before {
  content: "\f068";
}
footer .darken-layout .sub-title h4 {
  color: #ffffff;
}
footer .darken-layout .sub-title li a {
  color: #938d8c;
}
footer .darken-layout .sub-title .contact-list i {
  color: #938d8c;
}
footer .darken-layout .sub-title .contact-list li {
  color: #938d8c;
}
footer .darken-layout .footer-social i {
  color: #938d8c;
}
footer p {
  line-height: 32px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
}

.footer-light .subscribe {
  display: flex;
  height: 100%;
  align-items: center;
  border-right: 1px solid #dddddd;
  text-align: left;
}
.footer-light .subscribe p {
  line-height: 1;
}
.footer-light .subscribe h4 {
  margin-bottom: 10px;
}
.footer-light .subscribe-form {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.footer-theme2 {
  text-align: center;
}
.footer-theme2 .footer-mobile-title {
  display: none !important;
}
.footer-theme2 .contact-details li a {
  color: #7f786d;
}
.footer-theme2 .contact-details li a:hover {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.footer-theme2.footer-border {
  border: 20px solid #f1f5f4;
}
.footer-theme2 .footer-logo {
  margin-bottom: 18px;
}
.footer-theme2 p {
  line-height: 32px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  color: #7f786d;
}
.footer-theme2 h4 {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 25px;
}
.footer-theme2 .contact-details li {
  display: block;
  color: #7f786d;
  padding: 0 50px;
  line-height: 2.2;
}
.footer-theme2 .contact-details li:last-child {
  margin-bottom: -8px;
}
.footer-theme2 .subscribe-block {
  border-left: 1px solid #38352f;
  border-right: 1px solid #38352f;
  padding: 10px 110px;
}
.footer-theme2 .subscribe-block h2 {
  color: #ffffff;
  margin-bottom: 30px;
}
.footer-theme2 .subscribe-block .btn-solid {
  margin-top: 10px;
}
.footer-theme2 .subscribe-block .form-group {
  margin-bottom: 0;
}
.footer-theme2 .subscribe-block .form-control {
  text-align: center;
  letter-spacing: 5px;
  font-size: 12px;
  padding: 15px 0;
  border-radius: 0;
}
.footer-theme2 .footer-link.link-white h4,
.footer-theme2 .footer-link-b.link-white h4 {
  color: #222222;
}
.footer-theme2 .footer-link.link-white a,
.footer-theme2 .footer-link-b.link-white a {
  color: #777777;
  transition: 0.5s ease;
}
.footer-theme2 .footer-link.link-white a:hover,
.footer-theme2 .footer-link-b.link-white a:hover {
  color: #2d2a25;
  transition: 0.5s ease;
}
.footer-theme2 .footer-link h4,
.footer-theme2 .footer-link-b h4 {
  padding-bottom: 20px;
}
.footer-theme2 .footer-link li,
.footer-theme2 .footer-link-b li {
  padding-right: 15px;
  padding-left: 15px;
  text-transform: capitalize;
}
.footer-theme2 .footer-link a,
.footer-theme2 .footer-link-b a {
  color: #7f786d;
  transition: 0.5s ease;
}
.footer-theme2 .footer-link a:hover,
.footer-theme2 .footer-link-b a:hover {
  color: #f5f2f2;
  transition: 0.5s ease;
}
.footer-theme2 .footer-link-b h4 {
  margin-top: 50px;
}
.footer-theme2.section-light h2,
.footer-theme2.section-light h4 {
  color: #222222;
}
.footer-theme2.section-light .footer-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.footer-theme2.section-light .footer-block h4 {
  padding-bottom: 25px;
}
.footer-theme2.section-light .footer-block .contact-details li a {
  color: #7f786d;
}
.footer-theme2.section-light .footer-block .contact-details li a:hover {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.footer-theme2.section-light .footer-block .subscribe-white {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 85px 65px;
  border-left: 20px solid #f1f5f4;
  border-right: 20px solid #f1f5f4;
}
.footer-theme2.section-light .footer-block .subscribe-white:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-deafult);
  opacity: 0.4;
  z-index: -1;
}
.footer-theme2.section-light .footer-block .subscribe-white .form-group {
  margin-bottom: 0;
}
.footer-theme2.section-light .footer-block .subscribe-white .form-control {
  border: none;
  border-radius: 0;
}
.footer-theme2.section-light .footer-block .subscribe-white h2 {
  margin-bottom: 30px;
  margin-top: -5px;
}
.footer-theme2.section-light .footer-block .subscribe-white .btn-solid {
  margin-top: 10px;
}
.footer-theme2.section-light .footer-block .subscribe-white .form-control {
  text-align: center;
  letter-spacing: 5px;
  font-size: 12px;
  padding: 15px 0;
}

.subscribe {
  text-align: center;
}
.subscribe h4 {
  color: #222222;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 15px;
  margin-top: -5px;
}
.subscribe h4 i {
  color: var(--theme-deafult);
  padding-right: 5px;
  font-size: 23px;
}

.subscribe-form {
  display: inline-flex;
}
.subscribe-form .form-group {
  margin-bottom: 0;
}
.subscribe-form .form-control {
  padding: 13px;
  width: 345px;
  border-radius: 0;
  border: 1px solid #eeeeee;
}
.subscribe-form .form-control.name-cls {
  width: 265px;
}
.subscribe-form.classic-form .form-control {
  border-radius: 0;
  border: none;
  background-color: #f5f2f2;
  margin-left: 15px;
}
.subscribe-form.classic-form .btn-solid {
  margin-bottom: 0 !important;
}

.footer-social,
.social-white {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.footer-social li,
.social-white li {
  display: inline-block;
  padding-right: 45px;
}
.footer-social i,
.social-white i {
  font-size: 20px;
  color: #000000;
  transition: 0.5s ease;
}
.footer-social i:hover,
.social-white i:hover {
  color: var(--theme-deafult) !important;
  transition: 0.5s ease;
}

.social-white li {
  padding-left: 22px;
  padding-right: 22px;
}

.footer-theme .footer-logo {
  margin-bottom: 18px;
}
.footer-theme .sub-title h4 {
  color: #222222;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;
}
.footer-theme .sub-title li {
  position: relative;
  display: inherit;
  padding-top: 13px;
  text-transform: capitalize;
  font-size: 14px;
  color: #777777;
}
.footer-theme .sub-title li a {
  position: relative;
  color: #777777;
}
.footer-theme .sub-title li a:before {
  position: absolute;
  top: 20px;
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--theme-deafult);
  transition: 0.5s ease;
}
.footer-theme .sub-title li:hover a:before {
  width: 100%;
  height: 2px;
  transition: 0.5s ease;
}
.footer-theme .sub-title .contact-list i {
  position: absolute;
  left: 0;
  top: 21px;
}
.footer-theme .sub-title .contact-list li {
  position: relative;
  padding-left: 25px;
  line-height: 28px;
}
.footer-theme .sub-title .contact-list li a:hover {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.footer-theme .sub-title .contact-list li:hover {
  color: #999999;
  transition: all 0.3s ease;
}
.footer-theme .sub-title .contact-list li:hover a:before {
  display: none;
}
.footer-theme .sub-title .contact-list li:last-child {
  margin-bottom: -5px;
}

.sub-footer {
  background-color: #ffffff;
}
.sub-footer.dark-subfooter {
  background-color: #2e2726;
}
.sub-footer.dark-subfooter p {
  color: #938d8c;
}
.sub-footer.darker-subfooter {
  background-color: #25221e;
}
.sub-footer.darker-subfooter p {
  color: #7f786d;
}
.sub-footer.black-subfooter {
  background-color: #222222;
}
.sub-footer.black-subfooter p {
  color: #777777;
}
.sub-footer p {
  color: #777777;
  margin-bottom: 0;
  padding: 20px 0;
}
.sub-footer .payment-card-bottom {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sub-footer .payment-card-bottom li {
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
}

/*=====================
    17.Loader CSS start
    ==========================*/
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
}
.loader-wrapper .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: var(--theme-deafult);
  border-radius: 50%;
}
.loader-wrapper .loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  border: 0 solid white;
  transform: translate(-50%, -50%);
  animation: loading 1000ms ease-out forwards infinite;
  transition: all 0.3s ease;
}

@keyframes loading {
  0% {
    border: 0 solid white;
    transition: all 0.3s ease;
  }
  20% {
    border: 8px solid white;
    width: 0;
    height: 0;
    transition: all 0.3s ease;
  }
  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
  }
}
/*=====================
    18.Tab to top CSS start
    ==========================*/
.tap-top {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 8;
  display: none;
  color: #fff;
  text-align: center;
  background: var(--theme-deafult);
  border: none;
  font-size: 22px;
  padding: 7px;
  cursor: pointer;
}
.tap-top.top-cls {
  bottom: 130px;
}

/*=====================
    19.Color CSS start
    ==========================*/
.color1 {
  background: #ff4c3b;
}

.color2 {
  background: #3fdda7;
}

.color3 {
  background: #f0b54d;
}

.color4 {
  background: #e4604a;
}

.color5 {
  background: #d4b196;
}

.color6 {
  background: #866e6c;
}

.color7 {
  background: #cc2121;
}

.color8 {
  background: #dc457e;
}

.color9 {
  background: #6d7e87;
}

.color10 {
  background: #fa869b;
}

.color11 {
  background: #81ba00;
}

.color12 {
  background: #fe816d;
}

.color-picker {
  position: fixed;
  right: -190px;
  top: 35%;
  width: 190px;
  z-index: 1;
  transition: all 0.3s ease;
}
.color-picker .settings-header {
  background: #f3f3f3;
  padding: 5px 15px;
  box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);
}
.color-picker .settings-header h3 {
  color: black;
  margin: 0;
  font-size: 20px;
  padding: 4px 9px;
}
.color-picker .section {
  background: white;
  padding: 10px;
  border: 1px solid #f3f3f3;
  overflow: auto;
}
.color-picker .section i {
  font-size: 16px;
  margin-right: 10px;
}
.color-picker a.handle {
  position: absolute;
  right: 190px;
  width: 42px;
  height: 41px;
  color: #000000;
  transition: all ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  z-index: -1;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  text-decoration: none;
  background: #ffffff;
  top: -80px;
  border: 1px solid white;
  box-shadow: 0 0 4px rgba(4, 4, 4, 0.1);
}
.color-picker a.handle i {
  margin-left: 5px;
}
.color-picker a.handle:hover {
  color: var(--theme-deafult);
}
.color-picker .colors a {
  width: 32px;
  height: 32px;
  margin: 5px;
  float: left;
  transition: all ease-in 0.3s;
}
.color-picker .skin a {
  display: block;
  width: 70px;
  height: 30px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 5px;
  float: left;
  text-align: center;
  line-height: 30px;
  border: 2px transparent solid;
}
.color-picker .skin a.actt {
  border: 2px white solid;
}

.handle i {
  animation: ring-rotate 3s infinite linear;
}
.handle:hover {
  background-color: white;
}

@keyframes ring-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sec-position {
  position: relative;
  top: -80px;
}

/*=====================
    23.Fb chat CSS start
==========================*/
#fb-root {
  display: block;
}

/*=====================
  21.Inner pages CSS start
  ==========================*/
.product-thumb-list {
  position: absolute;
  bottom: 0;
  left: 0;
}
.product-thumb-list li {
  display: block;
  opacity: 0.3;
}
.product-thumb-list li.active {
  opacity: 1;
}

.cart_qty_cls {
  position: absolute;
  background: var(--theme-deafult);
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  top: 20%;
  right: -8px;
  padding: 3px 3px;
}

.product-filter-tags li {
  display: inline-flex;
  margin-bottom: 30px;
}
.product-filter-tags li a.filter_tag {
  color: #777777;
  border: 1px solid #777777;
  padding: 4px 10px 4px 12px;
  text-transform: capitalize;
  border-radius: 15px;
  font-size: 13px;
}
.product-filter-tags li a i {
  padding-left: 6px;
  font-size: 10px;
}
.product-filter-tags li.clear_filter {
  float: right;
  font-weight: 600;
  color: #000;
}
.product-filter-tags li.clear_filter a {
  color: var(--theme-deafult);
}

.product-right {
  /*!
  * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
  * Copyright 2015 Daniel Cardoso <@DanielCardoso>
  * Licensed under MIT
  */
  /*
  * Animation
  */
}
.product-right .size-box ul li.active {
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 3px #77777769;
  -moz-box-shadow: 1px 1px 3px #77777769;
  box-shadow: 1px 1px 3px #77777769;
}
.product-right .product-infinitescroll {
  margin: 50px 20px 0 20px;
}
.product-right .loading-more.active {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--theme-deafult);
  cursor: context-menu;
}
.product-right .la-square-spin,
.product-right .la-square-spin > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.product-right .la-square-spin {
  display: block;
  font-size: 0;
  color: #fff;
}
.product-right .la-square-spin.la-dark {
  color: #333;
}
.product-right .la-square-spin > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.product-right .la-square-spin {
  width: 32px;
  height: 32px;
}
.product-right .la-square-spin > div {
  width: 100%;
  height: 100%;
  border-radius: 0;
  -webkit-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
  -moz-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
  -o-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
  animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
}
.product-right .la-square-spin.la-sm {
  width: 16px;
  height: 16px;
}
.product-right .la-square-spin.la-2x {
  width: 64px;
  height: 64px;
}
.product-right .la-square-spin.la-3x {
  width: 96px;
  height: 96px;
}
@-webkit-keyframes square-spin {
  0% {
    -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
  25% {
    -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    transform: perspective(100px) rotateX(180deg) rotateY(180deg);
  }
  75% {
    -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
    transform: perspective(100px) rotateX(0) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(100px) rotateX(0) rotateY(360deg);
    transform: perspective(100px) rotateX(0) rotateY(360deg);
  }
}
@-moz-keyframes square-spin {
  0% {
    -moz-transform: perspective(100px) rotateX(0) rotateY(0);
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
  25% {
    -moz-transform: perspective(100px) rotateX(180deg) rotateY(0);
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }
  50% {
    -moz-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    transform: perspective(100px) rotateX(180deg) rotateY(180deg);
  }
  75% {
    -moz-transform: perspective(100px) rotateX(0) rotateY(180deg);
    transform: perspective(100px) rotateX(0) rotateY(180deg);
  }
  100% {
    -moz-transform: perspective(100px) rotateX(0) rotateY(360deg);
    transform: perspective(100px) rotateX(0) rotateY(360deg);
  }
}
@-o-keyframes square-spin {
  0% {
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
  25% {
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }
  50% {
    transform: perspective(100px) rotateX(180deg) rotateY(180deg);
  }
  75% {
    transform: perspective(100px) rotateX(0) rotateY(180deg);
  }
  100% {
    transform: perspective(100px) rotateX(0) rotateY(360deg);
  }
}
@keyframes square-spin {
  0% {
    -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
    -moz-transform: perspective(100px) rotateX(0) rotateY(0);
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
  25% {
    -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
    -moz-transform: perspective(100px) rotateX(180deg) rotateY(0);
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    -moz-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    transform: perspective(100px) rotateX(180deg) rotateY(180deg);
  }
  75% {
    -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
    -moz-transform: perspective(100px) rotateX(0) rotateY(180deg);
    transform: perspective(100px) rotateX(0) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(100px) rotateX(0) rotateY(360deg);
    -moz-transform: perspective(100px) rotateX(0) rotateY(360deg);
    transform: perspective(100px) rotateX(0) rotateY(360deg);
  }
}
.product-right .br-default .br-selected:after {
  color: #ffa200d1;
}
.product-right a {
  text-decoration: none;
  outline: none;
}
.product-right .br-default .br-unit {
  margin-right: 1px;
  font-size: 18px;
  height: 18px;
  width: 18px;
  line-height: 16px;
}
.product-right .rate-center .br-default .br-units {
  justify-content: center;
}
.product-right .btn.disabled,
.product-right .btn:disabled {
  opacity: 0.65;
  pointer-events: none;
  touch-action: none;
}
.product-right .pro_inventory {
  margin-bottom: 20px;
}
.product-right .inventory-scroll.nostripes.active {
  height: 10px;
  position: relative;
  margin: 10px 0;
  background: #eae9e9;
  border-radius: 25px;
  display: block;
}
.product-right .pro_inventory .inventory-scroll > span {
  display: block;
  height: 100%;
  float: left;
  background-color: #15b300;
  border-radius: 35px;
}
.product-right .empty_collection_cls span {
  color: #777;
  padding: 10px 20px;
  display: inline-block;
  font-size: 20px;
}
.product-right .avalibility {
  font-size: 15px;
  font-weight: 600;
  color: var(--theme-deafult);
}

.error-section {
  padding: 130px 0 140px;
  text-align: center;
}
.error-section h1 {
  font-size: 200px;
  font-weight: 900;
  margin: 0;
  line-height: 0.8;
  color: #aaaaaa;
}
.error-section h2 {
  color: #aaaaaa;
  font-weight: 500;
  letter-spacing: 3px;
  margin: 40px 0;
}

.breadcrumb-section {
  background-color: #f8f8f8;
  padding: 30px 0;
}
.breadcrumb-section .page-title {
  display: flex;
  height: 100%;
  align-items: center;
}
.breadcrumb-section .page-title h2 {
  font-size: 16px;
  margin-bottom: 0;
}
.breadcrumb-section .breadcrumb {
  background-color: transparent;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 14px;
  color: #555555;
  font-weight: 600;
  margin: 0;
  padding-right: 0;
}
.breadcrumb-section .breadcrumb a {
  color: #555555;
  font-weight: 600;
}

.about-head {
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  height: 100%;
}
.about-head h2 {
  color: var(--theme-deafult);
  margin-bottom: 0;
}

.about-page p {
  margin-bottom: 0;
}
.about-page .banner-section {
  border-radius: 5px;
  overflow: hidden;
}
.about-page h4 {
  margin-bottom: 10px;
  margin-top: 30px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
}
.about-page p {
  line-height: 2;
  letter-spacing: 0.03em;
}

.testimonial {
  background-color: #f7f7f7;
}
.testimonial .testimonial-slider .media img {
  border-radius: 100%;
  border: 1px solid #dddddd;
  padding: 5px;
  height: 130px;
  width: 130px;
}
.testimonial .testimonial-slider .media h5 {
  font-weight: 700;
  color: var(--theme-deafult);
  margin-bottom: 0;
  margin-top: 7px;
}
.testimonial .testimonial-slider .media .media-body {
  padding: 30px 75px 30px 30px;
}
.testimonial .testimonial-slider .media .media-body p {
  line-height: 2;
  letter-spacing: 0.03em;
}
.testimonial .testimonial-slider .slick-track .owl-item:nth-child(even) .media {
  border-left: 1px solid #dddddd;
  padding-left: 50px;
}

.team {
  position: relative;
}
.team img {
  margin: 0 auto;
  padding: 0 10px;
}
.team .owl-prev,
.team .owl-next {
  position: absolute;
  top: -45px;
}
.team .owl-prev:before,
.team .owl-next:before {
  font: normal normal normal 30px/1 FontAwesome;
  color: #222222;
  opacity: 0.6;
}
.team .owl-prev {
  left: unset;
  right: 20px;
}
.team .owl-prev:before {
  content: "\f104";
}
.team .owl-next {
  right: 0;
}
.team .owl-next:before {
  content: "\f105";
}
.team h2 {
  color: #222222;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 10px;
}
.team h4,
.team h6 {
  text-align: center;
}
.team h4 {
  color: var(--theme-deafult);
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px;
}
.team h6 {
  margin-bottom: -3px;
}

.collection-filter-block {
  margin-bottom: 30px;
  padding: 0 30px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
.collection-filter-block .product-service {
  padding: 30px 0;
}
.collection-filter-block .product-service .media {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dddddd;
}
.collection-filter-block .product-service .media svg {
  height: 40px;
  width: 40px;
}
.collection-filter-block .product-service .media svg path {
  fill: var(--theme-deafult);
}
.collection-filter-block .product-service .media .media-body {
  padding-left: 10px;
}
.collection-filter-block .product-service .media .media-body h4 {
  margin-bottom: 5px;
}
.collection-filter-block .product-service .media .media-body p {
  margin-bottom: 0;
  text-transform: capitalize;
}
.collection-filter-block .product-service .media:last-child {
  padding-bottom: 0;
}

.collection-collapse-block {
  padding-bottom: 24px;
}
.collection-collapse-block .collapse-block-title {
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  margin: 30px 0 0 0;
}
.collection-collapse-block .collapse-block-title:after {
  position: absolute;
  display: block;
  top: 0;
  right: -3px;
  content: "\f0d7";
  font-family: FontAwesome;
  color: #444444;
}
.collection-collapse-block.open .collection-collapse-block-content {
  max-height: 500px;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.collection-collapse-block .collection-collapse-block-content {
  max-height: 0;
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.collection-collapse-block .collection-collapse-block-content .color-selector {
  margin-top: 30px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter {
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 30px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter.color-filter .collection-filter-checkbox label {
  padding-left: 25px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li {
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 18px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li a {
  color: #777777;
  padding-bottom: 10px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:last-child a {
  padding-bottom: 0;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
  margin-bottom: 12px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:last-child {
  margin-bottom: 0;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox span {
  width: 14px;
  height: 14px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  top: 2px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  color: #777777;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:before {
  top: 1px;
  height: 15px;
  width: 15px;
  background: #ffffff;
  border: 1px solid #777777;
  box-shadow: none;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:after {
  top: 1px;
  height: 15px;
  width: 15px;
  background-size: 65%;
}
.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
}

.color-selector ul li {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #444444;
}
.color-selector ul .color-1 {
  background-color: white;
}
.color-selector ul .color-2 {
  background-color: grey;
}
.color-selector ul .color-3 {
  background-color: red;
}
.color-selector ul .color-4 {
  background-color: purple;
}
.color-selector ul .color-5 {
  background-color: blue;
}
.color-selector ul .color-6 {
  background-color: yellow;
}
.color-selector ul .color-7 {
  background-color: green;
}
.color-selector ul li.active {
  border: 1px solid black;
  position: relative;
}
.color-selector ul li.active:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 10px;
  right: 4px;
  height: 15px;
  width: 15px;
  background-size: 70%;
  background-repeat: no-repeat;
  position: absolute;
}

.collection-sidebar-banner {
  margin-top: 30px;
}
.collection-sidebar-banner img {
  width: 100%;
}

.top-banner-wrapper .top-banner-content h4 {
  font-weight: 600;
  color: #444444;
  margin-bottom: 15px;
}
.top-banner-wrapper .top-banner-content h5 {
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.top-banner-wrapper .top-banner-content p {
  line-height: 1.5;
  color: #777777;
  margin-bottom: 0;
}

.product-wrapper-grid.list-view .product-wrap .product-info {
  text-align: left;
  align-self: center;
  padding-left: 15px;
}
.product-wrapper-grid.list-view .product-box {
  display: flex;
  padding-bottom: 0;
}
.product-wrapper-grid.list-view .product-box .ng-star-inserted {
  display: flex;
  padding-bottom: 0;
}
.product-wrapper-grid.list-view .product-box > div {
  display: flex;
  padding-bottom: 0;
}
.product-wrapper-grid.list-view .product-box .img-wrapper,
.product-wrapper-grid.list-view .product-box .img-block {
  width: 25%;
}
.product-wrapper-grid.list-view .product-box .product-detail {
  padding-left: 15px !important;
  align-self: center;
  text-align: left !important;
  width: 75%;
}
.product-wrapper-grid.list-view .product-box .product-detail .rating {
  margin-top: 0;
}
.product-wrapper-grid.list-view .product-box .product-detail p {
  display: block !important;
  margin-bottom: 10px;
  line-height: 18px;
}
.product-wrapper-grid.list-view .product-box .product-detail h4 del {
  margin-top: 5px;
}
.product-wrapper-grid.list-view .product-box .product-detail .color-variant {
  padding-top: 10px;
}
.product-wrapper-grid.list-view .product-box .product-detail h6 {
  font-weight: 700;
}
.product-wrapper-grid.list-view .product-box .product-detail p {
  display: none;
}

.collection-product-wrapper .product-top-filter {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}
.collection-product-wrapper .product-top-filter .product-filter-content,
.collection-product-wrapper .product-top-filter .popup-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
.collection-product-wrapper .product-top-filter .popup-filter .search-count,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  border-right: 1px solid #dddddd;
  padding: 20px;
  text-transform: capitalize;
  width: 35%;
}
.collection-product-wrapper .product-top-filter .product-filter-content .search-count h5,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup h5,
.collection-product-wrapper .product-top-filter .popup-filter .search-count h5,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup h5 {
  margin-bottom: 0;
}
.collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  padding: 20px;
  width: 10%;
}
.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li {
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
  margin-right: 14px;
}
.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  padding: 20px;
  width: 20%;
  border-right: 1px solid #dddddd;
}
.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul {
  display: flex;
  justify-content: flex-end;
}
.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li {
  margin-left: 10px;
}
.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li img,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li img {
  height: 18px;
  cursor: pointer;
}
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  position: relative;
  width: 20%;
}
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  border: 0;
  padding: 20px 0;
  border-right: 1px solid #dddddd;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: url("/assets/images/dropdown.png") no-repeat 90%;
}
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select:focus {
  outline: unset;
}
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select option {
  padding: 20px 0;
}
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  width: 15%;
}
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 10px;
}
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  border-right: none;
}
.collection-product-wrapper .product-top-filter .popup-filter {
  justify-content: unset;
}
.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar {
  width: 3px;
}
.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-track {
  background: #dddddd;
}
.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb {
  background: #888888;
}
.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb:hover {
  background: #555555;
}
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  position: relative;
  cursor: pointer;
  width: 20%;
  background: url("/assets/images/dropdown.png") no-repeat 95%;
}
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
  font-size: 16px;
  color: #222222;
}
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
  background-color: #f8f8f8;
  width: 270px;
  height: 600px;
  overflow-y: scroll;
}
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-filter-block {
  border: none;
}
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .theme-card {
  padding: 0 30px;
}
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner {
  padding: 0 30px;
}
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner img {
  width: 100%;
}
.collection-product-wrapper .product-top-filter .popup-filter .open-popup {
  display: none;
  position: absolute;
  z-index: 9;
  top: 75px;
  left: 0;
  border: 1px solid #f1f5f4;
  box-shadow: 0 0 5px #ddd;
  min-width: 330px;
}
.collection-product-wrapper .product-top-filter .popup-filter .open-popup.open {
  display: block;
}
.collection-product-wrapper .product-top-filter .popup-filter .open-popup .collection-filter-block {
  margin-bottom: 0;
}
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
  width: 20%;
}
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  padding-right: 45px;
  font-size: 16px;
}
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 35px;
}
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  padding: 20px;
}
.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  width: 20%;
}
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
  right: 25px !important;
}
.collection-product-wrapper .product-wrapper-grid .product-box .product-detail,
.collection-product-wrapper .product-wrapper-grid .product-box .product-info,
.collection-product-wrapper .product-wrapper-grid .product-wrap .product-detail,
.collection-product-wrapper .product-wrapper-grid .product-wrap .product-info {
  padding-left: 0;
}
.collection-product-wrapper .product-wrapper-grid .product-box {
  margin-top: 50px;
}
.collection-product-wrapper .product-wrapper-grid .product-box .product-detail p {
  display: none;
}
.collection-product-wrapper .product-wrapper-grid .product-five {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h6 {
  font-size: 13px;
}
.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h4 {
  font-size: 18px;
}
.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .rating i {
  padding-right: 0;
}
.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .img-wrapper .lable-block .lable3 {
  padding: 8px 4px;
  font-size: 12px;
}

.filter-main-btn {
  display: none;
  margin-bottom: 20px;
  cursor: pointer;
}
.filter-main-btn span {
  width: 40%;
  border-radius: 0;
  font-size: 20px;
}

.collection-mobile-back {
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
  display: none;
}
.collection-mobile-back span {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  cursor: pointer;
}
.collection-mobile-back span i {
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
}

.product-pagination {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  margin: 50px 0;
}
.product-pagination .pagination {
  border-radius: 0;
}
.product-pagination .pagination .page-item {
  border-radius: 0;
}
.product-pagination .pagination .page-item a {
  padding: 18px;
  border-bottom: 0;
  border-top: 0;
  color: #777777;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.product-pagination .pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.product-pagination .pagination .page-item.active a {
  color: var(--theme-deafult);
  background-color: #ffffff;
  border-color: #dddddd;
}
.product-pagination .product-search-count-bottom {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding-right: 15px;
}
.product-pagination .product-search-count-bottom h5 {
  color: #777777;
  font-size: 14px;
  margin-bottom: 0;
  padding: 5px 0;
}

.bundle {
  margin-top: 10px;
}
.bundle .bundle_img {
  display: flex;
}
.bundle .bundle_img .img-box img {
  max-width: 115px;
  border: 1px solid #ddd;
  padding: 2px;
}
.bundle .bundle_img .plus {
  display: flex;
  align-items: center;
  padding: 0 7px;
  font-size: 22px;
}
.bundle .bundle_detail {
  margin-top: 15px;
}
.bundle .bundle_detail .price_product {
  color: #000000;
  padding-left: 3px;
  font-weight: 700;
}

.theme_checkbox label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  text-transform: capitalize;
  color: #333333;
}
.theme_checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.theme_checkbox label input:checked ~ .checkmark:after {
  display: block;
}
.theme_checkbox label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}
.theme_checkbox label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.collection .collection-block {
  text-align: center;
}
.collection .collection-block img {
  margin-bottom: 20px;
}
.collection .collection-block .collection-content {
  text-align: center;
}
.collection .collection-block .collection-content h3 {
  margin-bottom: 10px;
  color: #222222;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.05em;
}
.collection .collection-block .collection-content h4 {
  color: #222222;
  margin-bottom: 10px;
  font-size: 16px;
}
.collection .collection-block .collection-content p {
  line-height: 1.5;
}
.collection .collection-block .collection-content .btn-solid,
.collection .collection-block .collection-content .btn-outline {
  padding: 10px 25px;
}

.faq-section .accordion.theme-accordion .card {
  margin-top: 10px;
  border-radius: 0;
  overflow: hidden;
  border-bottom: 1px solid #dddddd;
}
.faq-section .accordion.theme-accordion .card :not(:first-of-type):not(:last-of-type),
.faq-section .accordion.theme-accordion .card .theme-accordion .card:first-of-type {
  border-bottom: 1px solid #dddddd;
}
.faq-section .accordion.theme-accordion .card:first-child {
  margin-top: 0;
}
.faq-section .accordion.theme-accordion .card .card-header {
  padding: 0;
  border-bottom: 0;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}
.faq-section .accordion.theme-accordion .card .card-header button {
  color: #333333;
  text-align: left;
  font-size: 17px;
  padding: 18px;
  font-weight: 600;
}
.faq-section .accordion.theme-accordion .card .card-header button:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 6px solid #000000;
  right: 20px;
  top: 25px;
  transition: all 0.3s ease;
}
.faq-section .accordion.theme-accordion .card .card-header button[aria-expanded=true]:before {
  border-top: 0;
  border-bottom: 6px solid #000000;
  transition: all 0.3s ease;
}
.faq-section .accordion.theme-accordion .card .card-header button:hover {
  text-decoration: none;
  color: var(--theme-deafult);
  transition: all 0.3s ease;
}
.faq-section .accordion.theme-accordion .card .card-body {
  padding: 15px 18px;
}
.faq-section .accordion.theme-accordion .card .card-body p {
  margin-bottom: 0;
  font-size: 14px;
  color: #555555;
  line-height: 1.8;
  letter-spacing: 0.06em;
}

.pwd-page {
  padding: 140px 0;
  text-align: center;
}
.pwd-page h2 {
  margin-bottom: 25px;
  color: #222222;
}
.pwd-page .theme-form input {
  border-color: #dddddd;
  font-size: 15px;
  padding: 15px 25px;
  margin-bottom: 15px;
  height: inherit;
  text-align: center;
}
.pwd-page .theme-form .btn-solid {
  margin: 0 auto;
}

.login-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222222;
  font-weight: 700;
  margin-top: -4px;
}
.login-page .theme-card {
  padding: 30px;
  border: 1px solid #dddddd;
  line-height: 1;
}
.login-page .theme-card .theme-form label {
  text-transform: capitalize;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.login-page .theme-card .theme-form input {
  border-color: #dddddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}
.login-page .authentication-right {
  height: calc(100% - 48px);
}
.login-page .authentication-right h6 {
  text-transform: uppercase;
  color: #333333;
  font-weight: 600;
  margin-bottom: 20px;
}
.login-page .authentication-right p {
  font-size: 14px;
  color: #555555;
  line-height: 2;
  margin-bottom: 1rem;
}

.register-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222222;
  font-weight: 700;
  margin-top: -4px;
}
.register-page .theme-card {
  padding: 30px;
  border: 1px solid #dddddd;
  line-height: 1;
}
.register-page .theme-card .theme-form label {
  text-transform: capitalize;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.register-page .theme-card .theme-form input {
  border-color: #dddddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}

.loading-more.active {
  color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
  padding: 10px;
}

.search-block {
  padding: 40px 0;
}
.search-block .form-header .form-group input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #aaaaaa;
  border-radius: 0;
  padding: 20px 0;
}
.search-block .form-header .form-group input:focus {
  box-shadow: none;
}
.search-block .form-header .form-group i {
  padding-right: 10px;
}
.search-block .form-header .form-group .btn {
  z-index: 0;
}
.search-block .form-header button {
  position: absolute;
  top: 10px;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  color: #aaaaaa;
  right: 10px;
}

.contact-page .map iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.contact-page .contact-right {
  padding-top: 30px;
  padding-bottom: 30px;
}
.contact-page .contact-right ul li {
  position: relative;
  padding-left: 150px;
  margin-top: 45px;
  display: block;
}
.contact-page .contact-right ul li:first-child {
  margin-top: 0;
}
.contact-page .contact-right ul li .contact-icon {
  position: absolute;
  left: 0;
  text-align: center;
  width: 130px;
  border-right: 1px solid #dddddd;
}
.contact-page .contact-right ul li .contact-icon i {
  font-size: 20px;
  color: var(--theme-deafult);
}
.contact-page .contact-right ul li .contact-icon h6 {
  text-transform: uppercase;
  padding-top: 5px;
  font-weight: 600;
  color: var(--theme-deafult);
}
.contact-page .contact-right ul li p {
  font-weight: 600;
  margin-bottom: 0;
  line-height: 30px;
}
.contact-page .theme-form label {
  text-transform: capitalize;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.contact-page .theme-form input {
  border-color: #dddddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}
.contact-page .theme-form textarea {
  border-color: #dddddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 30px;
  height: inherit;
}
.contact-page .form-control {
  border-radius: 0;
}

.qty-box .input-group {
  justify-content: center;
}
.qty-box .input-group span button {
  background: #ffffff !important;
  border: 1px solid #ced4da;
}
.qty-box .input-group .form-control {
  text-align: center;
  width: 80px;
  flex: unset;
}
.qty-box .input-group button {
  background-color: transparent;
  border: 0;
  color: #777777;
  cursor: pointer;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  z-index: 0;
}
.qty-box .input-group button i {
  font-weight: 900;
  color: #222222;
}
.qty-box .input-group .icon {
  padding-right: 0;
}

.cart-section .table tbody + tbody,
.wishlist-section .table tbody + tbody {
  border-top: none;
}
.cart-section .cart-buttons,
.wishlist-section .cart-buttons {
  padding-top: 35px;
}
.cart-section .cart-buttons > div:last-child,
.wishlist-section .cart-buttons > div:last-child {
  text-align: right;
  padding-right: 59px;
}
.cart-section .wishlist-buttons,
.wishlist-section .wishlist-buttons {
  padding-top: 35px;
  text-align: right;
}
.cart-section .wishlist-buttons:last-child a,
.wishlist-section .wishlist-buttons:last-child a {
  margin-left: 15px;
}
.cart-section .cart-table,
.wishlist-section .cart-table {
  overflow: hidden;
  margin-bottom: 0;
}
.cart-section .cart-table thead th,
.wishlist-section .cart-table thead th {
  border-bottom-width: 1px;
  font-weight: 900;
  color: #222222;
  text-transform: uppercase;
  font-size: 14px;
  border-top: 0;
  text-align: center;
  padding: 0 0.75rem 0.75rem 0.75rem;
}
.cart-section tbody h2,
.wishlist-section tbody h2 {
  margin-bottom: 0;
}
.cart-section tbody tr td,
.wishlist-section tbody tr td {
  min-width: 210px;
  vertical-align: middle;
  color: #777777;
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
}
.cart-section tbody tr td a,
.cart-section tbody tr td p,
.wishlist-section tbody tr td a,
.wishlist-section tbody tr td p {
  color: #777777;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.cart-section tbody tr td a img,
.cart-section tbody tr td p img,
.wishlist-section tbody tr td a img,
.wishlist-section tbody tr td p img {
  height: 90px;
}
.cart-section tbody tr td .td-color,
.wishlist-section tbody tr td .td-color {
  color: var(--theme-deafult);
}
.cart-section tbody tr td h2,
.wishlist-section tbody tr td h2 {
  font-size: 24px;
  color: #222222;
  font-weight: 400;
}
.cart-section tbody tr td .mobile-cart-content,
.wishlist-section tbody tr td .mobile-cart-content {
  display: none;
  justify-content: center;
  margin-top: 10px;
}
.cart-section tbody tr td .mobile-cart-content .qty-box,
.wishlist-section tbody tr td .mobile-cart-content .qty-box {
  border-right: 1px solid #dddddd;
  border-radius: 5px;
}
.cart-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control,
.wishlist-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
  width: 48px;
  padding: 6px;
}
.cart-section tbody tr td .mobile-cart-content .col-xs-3,
.wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
}
.cart-section tbody tr td .mobile-cart-content h2,
.wishlist-section tbody tr td .mobile-cart-content h2 {
  font-size: 20px;
}
.cart-section tfoot tr th,
.wishlist-section tfoot tr th {
  padding-top: 35px;
  text-align: right;
}
.cart-section tfoot tr td,
.wishlist-section tfoot tr td {
  padding-top: 35px;
  padding-bottom: 0;
  text-align: right;
  border: none;
  padding-right: 63px;
}
.cart-section tfoot tr td h2,
.wishlist-section tfoot tr td h2 {
  font-size: 24px;
  margin-bottom: 0;
}
.cart-section tfoot tr td:first-child,
.wishlist-section tfoot tr td:first-child {
  width: 70%;
  font-size: 15px;
  padding-right: 10px;
  text-transform: capitalize;
  font-weight: 700;
}

.blog-detail-page .blog-detail p:last-child {
  margin-bottom: -5px;
}
.blog-detail-page .blog-detail img {
  margin-bottom: 40px;
}
.blog-detail-page .blog-detail h3 {
  color: #222222;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-top: -6px;
}
.blog-detail-page .blog-detail p {
  color: #777777;
  line-height: 1.8;
}
.blog-detail-page .blog-detail .post-social {
  color: #777777;
  text-align: left;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
}
.blog-detail-page .blog-detail .post-social li + li {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #dddddd;
}
.blog-detail-page .blog-advance > div:last-child p:last-child {
  margin-bottom: 0;
}
.blog-detail-page .blog-advance img {
  margin-bottom: 25px;
  margin-top: 25px;
}
.blog-detail-page .blog-advance ul {
  list-style-type: decimal;
  list-style-position: inside;
  line-height: 2.5;
  font-size: 15px;
}
.blog-detail-page .blog-advance ul li {
  display: list-item;
  font-weight: 700;
}
.blog-detail-page .blog-advance p {
  line-height: 1.8;
}
.blog-detail-page .comment-section {
  border-bottom: 1px solid #dddddd;
}
.blog-detail-page .comment-section li {
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #dddddd;
}
.blog-detail-page .comment-section li img {
  height: 65px;
  width: 65px;
  margin-right: 20px;
  border-radius: 100%;
}
.blog-detail-page .comment-section li h6 {
  color: #444444;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
}
.blog-detail-page .comment-section li h6 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  color: #777777;
  margin-left: 20px;
}
.blog-detail-page .comment-section li p {
  line-height: 1.8;
  margin-bottom: -4px;
  letter-spacing: 0.09em;
}
.blog-detail-page .blog-contact h2 {
  margin-bottom: 30px;
  margin-top: -6px;
}
.blog-detail-page .blog-contact .theme-form label {
  text-transform: capitalize;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
}
.blog-detail-page .blog-contact .theme-form input,
.blog-detail-page .blog-contact .theme-form textarea {
  border-color: #dddddd;
  font-size: 14px;
  padding: 17px 25px;
  margin-bottom: 30px;
}

.blog .title-inner1 {
  margin-bottom: 35px;
}

.blog-page .blog-sidebar h6 {
  margin-bottom: 0;
  line-height: 20px;
  color: #333333;
  font-weight: 700;
}
.blog-page .blog-sidebar p {
  line-height: 20px;
  margin-bottom: 0;
  color: #555555;
}
.blog-page .blog-sidebar h4 {
  color: #333333;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  letter-spacing: 1px;
}
.blog-page .blog-sidebar .theme-card {
  border: 1px solid #dddddd;
  padding: 30px;
  background-color: #ffffff;
}
.blog-page .blog-sidebar .theme-card .recent-blog li .media img {
  width: 100px;
  margin-right: 10px;
}
.blog-page .blog-sidebar .theme-card .recent-blog li + li {
  margin-top: 30px;
}
.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
  background-color: var(--theme-deafult);
  height: 70px;
  width: 70px;
  margin: 0 15px 0 0;
  padding: 15px;
}
.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date span {
  display: block;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.blog-page .blog-sidebar .theme-card .popular-blog li + li {
  margin-top: 30px;
}
.blog-page .blog-sidebar .theme-card .popular-blog li p {
  margin-top: 10px;
}
.blog-page .blog-sidebar .theme-card + .theme-card {
  margin-top: 30px;
}
.blog-page .blog-media {
  margin-bottom: 30px;
}
.blog-page .blog-media:last-child {
  margin-bottom: 0;
}
.blog-page .blog-media .blog-right {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.blog-page .blog-media .blog-right h6 {
  color: var(--theme-deafult);
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: -7px;
}
.blog-page .blog-media .blog-right h4 {
  color: #333333;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 15px;
}
.blog-page .blog-media .blog-right p {
  line-height: 1.8;
  margin-bottom: -5px;
  letter-spacing: 0.04em;
}
.blog-page .blog-media .blog-right ul {
  color: #777777;
  margin-bottom: 15px;
}
.blog-page .blog-media .blog-right ul li {
  font-size: 14px;
  line-height: 1.5;
}
.blog-page .blog-media .blog-right ul li i {
  padding-right: 3px;
}
.blog-page .blog-media .blog-right ul li + li {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #dddddd;
}

.product-slick .slick-list,
.rtl-product-slick .slick-list,
.product-right-slick .slick-list,
.rtl-product-right-slick .slick-list {
  overflow: visible;
}
.product-slick .owl-prev,
.product-slick .owl-next,
.rtl-product-slick .owl-prev,
.rtl-product-slick .owl-next,
.product-right-slick .owl-prev,
.product-right-slick .owl-next,
.rtl-product-right-slick .owl-prev,
.rtl-product-right-slick .owl-next {
  opacity: 0;
  transform: scale(1);
  transition: all 0.5s ease;
  margin-top: -10px;
}
.product-slick .owl-prev:before,
.product-slick .owl-next:before,
.rtl-product-slick .owl-prev:before,
.rtl-product-slick .owl-next:before,
.product-right-slick .owl-prev:before,
.product-right-slick .owl-next:before,
.rtl-product-right-slick .owl-prev:before,
.rtl-product-right-slick .owl-next:before {
  font: normal normal normal 14px/1 FontAwesome;
  opacity: 1;
  color: black;
  background-color: white;
  border-radius: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-slick .owl-next,
.rtl-product-slick .owl-next,
.product-right-slick .owl-next,
.rtl-product-right-slick .owl-next {
  right: 1px;
}
.product-slick .owl-next:before,
.rtl-product-slick .owl-next:before,
.product-right-slick .owl-next:before,
.rtl-product-right-slick .owl-next:before {
  content: "\f105";
}
.product-slick .owl-prev,
.rtl-product-slick .owl-prev,
.product-right-slick .owl-prev,
.rtl-product-right-slick .owl-prev {
  left: 1px;
  z-index: 1;
}
.product-slick .owl-prev:before,
.rtl-product-slick .owl-prev:before,
.product-right-slick .owl-prev:before,
.rtl-product-right-slick .owl-prev:before {
  content: "\f104";
}
.product-slick .owl-item > div,
.rtl-product-slick .owl-item > div,
.product-right-slick .owl-item > div,
.rtl-product-right-slick .owl-item > div {
  margin-bottom: 15px;
}
.product-slick:hover .owl-prev,
.product-slick:hover .owl-next,
.rtl-product-slick:hover .owl-prev,
.rtl-product-slick:hover .owl-next,
.product-right-slick:hover .owl-prev,
.product-right-slick:hover .owl-next,
.rtl-product-right-slick:hover .owl-prev,
.rtl-product-right-slick:hover .owl-next {
  opacity: 1;
  transform: scale(1.05);
  transition: all 0.5s ease;
}
.product-slick:hover .owl-prev:before,
.product-slick:hover .owl-next:before,
.rtl-product-slick:hover .owl-prev:before,
.rtl-product-slick:hover .owl-next:before,
.product-right-slick:hover .owl-prev:before,
.product-right-slick:hover .owl-next:before,
.rtl-product-right-slick:hover .owl-prev:before,
.rtl-product-right-slick:hover .owl-next:before {
  opacity: 1;
}
.product-slick:hover .owl-next,
.rtl-product-slick:hover .owl-next,
.product-right-slick:hover .owl-next,
.rtl-product-right-slick:hover .owl-next {
  right: 20px;
}
.product-slick:hover .owl-prev,
.rtl-product-slick:hover .owl-prev,
.product-right-slick:hover .owl-prev,
.rtl-product-right-slick:hover .owl-prev {
  left: 20px;
}

.owl-item > div.owl-thumb {
  margin: 5px 5px 0px 0px;
  border: 1px solid #dddddd;
  transition: all 0.5s ease;
  cursor: pointer;
}
.owl-item > div.owl-thumb.active {
  border-color: #aaaaaa;
  transition: all 0.5s ease;
}

.slider-nav .owl-item > div,
.rtl-slider-nav .owl-item > div,
.slider-right-nav .owl-item > div,
.rtl-slider-right-nav .owl-item > div {
  margin: 15px 15px 0 15px;
  border: 1px solid #dddddd;
  transition: all 0.5s ease;
}
.slider-nav .owl-item.slick-current > div,
.rtl-slider-nav .owl-item.slick-current > div,
.slider-right-nav .owl-item.slick-current > div,
.rtl-slider-right-nav .owl-item.slick-current > div {
  border-color: #aaaaaa;
  transition: all 0.5s ease;
}
.slider-nav .owl-item.slick-active,
.rtl-slider-nav .owl-item.slick-active,
.slider-right-nav .owl-item.slick-active,
.rtl-slider-right-nav .owl-item.slick-active {
  transition: all 0.5s ease;
}

.slider-right-nav .owl-item:first-child > div,
.rtl-slider-right-nav .owl-item:first-child > div {
  margin-top: 0;
}

.pro_sticky_info {
  border: 1px solid #dddddd;
  padding: 20px;
}

.is_stuck {
  margin-top: 120px;
}

.image-scroll div > div {
  padding-bottom: 20px;
}
.image-scroll div > div:last-child {
  padding: 0;
}

.check-out {
  margin-bottom: 30px;
}

.emply-cart-cls h4 {
  color: #656565;
}

.product-right p {
  margin-bottom: 0;
  line-height: 1.5em;
}
.product-right .product-title {
  color: #222222;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
}
.product-right .border-product {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #dddddd;
}
.product-right h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 1.2em;
}
.product-right h3 {
  font-size: 26px;
  color: #222222;
  margin-bottom: 15px;
}
.product-right h4 {
  font-size: 16px;
  margin-bottom: 7px;
}
.product-right h4 del {
  color: #777777;
}
.product-right h4 span {
  padding-left: 5px;
  color: var(--theme-deafult);
}
.product-right .color-variant {
  margin-bottom: 10px;
}
.product-right .color-variant li {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.product-right .color-variant li.white {
  background-color: #ffffff;
  border: 1px solid #aaa;
}
.product-right .color-variant li.pink {
  background-color: #ffc0cb;
}
.product-right .color-variant li.red {
  background-color: #ff647f;
}
.product-right .color-variant li.green {
  background-color: #6fb866;
}
.product-right .color-variant li.yellow {
  background-color: #f2f896;
}
.product-right .color-variant li.skyblue {
  background-color: #7ee7ec;
}
.product-right .color-variant li.blue {
  background-color: #63b4f2;
}
.product-right .color-variant li.orange {
  background-color: #ffb17a;
}
.product-right .color-variant li.purple {
  background-color: #eeb1d7;
}
.product-right .color-variant li.violet {
  background-color: #a06da0;
}
.product-right .color-variant li.brown {
  background-color: #a57372;
}
.product-right .color-variant li.lightgreen {
  background-color: #90ee90;
}
.product-right .color-variant li.olive {
  background-color: #808000;
}
.product-right .color-variant li.navy {
  background-color: #505c80;
}
.product-right .color-variant li.black {
  background-color: #000000;
}
.product-right .color-variant li.gray,
.product-right .color-variant li .grey {
  background-color: #777777;
}
.product-right .color-variant li.active {
  border: 1px solid black;
  position: relative;
}
.product-right .color-variant li.active:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 10px;
  right: 4px;
  height: 15px;
  width: 15px;
  background-size: 70%;
  background-repeat: no-repeat;
  position: absolute;
}
.product-right .product-buttons {
  margin-bottom: 20px;
}
.product-right .product-buttons .btn-solid,
.product-right .product-buttons .btn-outline {
  padding: 7px 25px;
}
.product-right .product-buttons a:last-child {
  margin-left: 10px;
}
.product-right .product-description h6 span {
  float: right;
}
.product-right .product-description .qty-box {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.product-right .product-description .qty-box .input-group {
  justify-content: unset;
  width: unset;
}
.product-right .product-description .qty-box .input-group .form-control {
  border-right: none;
}
.product-right .size-box {
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-right .size-box ul li {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #f7f7f7;
  text-align: center;
}
.product-right .size-box ul li a {
  color: #222222;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.product-right .size-box ul li.active {
  background-color: #f7f7f7;
}
.product-right .product-icon {
  display: flex;
}
.product-right .product-icon .product-social {
  margin-top: 5px;
}
.product-right .product-icon .product-social li {
  padding-right: 30px;
}
.product-right .product-icon .product-social li a {
  color: #333333;
  transition: all 0.3s ease;
}
.product-right .product-icon .product-social li a i {
  font-size: 18px;
}
.product-right .product-icon .product-social li a:hover {
  color: var(--theme-deafult);
}
.product-right .product-icon .product-social li:last-child {
  padding-right: 0;
}
.product-right .product-icon .wishlist-btn {
  background-color: transparent;
  border: none;
}
.product-right .product-icon .wishlist-btn i {
  border-left: 1px solid #dddddd;
  font-size: 18px;
  padding-left: 10px;
  margin-left: 5px;
  transition: all 0.5s ease;
}
.product-right .product-icon .wishlist-btn span {
  padding-left: 10px;
  font-size: 18px;
}
.product-right .product-icon .wishlist-btn:hover i {
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.product-right .payment-card-bottom {
  margin-top: 10px;
}
.product-right .payment-card-bottom ul li {
  padding-right: 10px;
}
.product-right .timer {
  margin-top: 10px;
  background-color: #f7f7f7;
}
.product-right .timer p {
  color: #222222;
}
.product-right.product-form-box {
  text-align: center;
  border: 1px solid #dddddd;
  padding: 20px;
}
.product-right.product-form-box .product-description .qty-box {
  margin-bottom: 5px;
}
.product-right.product-form-box .product-description .qty-box .input-group {
  justify-content: center;
  width: 100%;
}
.product-right.product-form-box .product-buttons {
  margin-bottom: 0;
}
.product-right.product-form-box .timer {
  margin-bottom: 10px;
  text-align: left;
}

.single-product-tables {
  display: flex;
  margin-top: 20px;
}
.single-product-tables table {
  width: 20%;
}
.single-product-tables table tr {
  height: 35px;
}
.single-product-tables table tr td:first-child {
  font-weight: 600;
}
.single-product-tables.detail-section {
  margin-top: 0;
}
.single-product-tables.detail-section table {
  width: 55%;
}

.product-accordion .btn-link {
  color: #222222;
}
.product-accordion .btn-link:hover {
  text-decoration: none;
}
.product-accordion .card {
  border: none;
}
.product-accordion .card-body {
  width: 100%;
  height: auto;
}
.product-accordion .card-header {
  padding: 5px 8px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
  background-color: rgba(127, 127, 127, 0.03);
}

.product-related h2 {
  color: #222222;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 20px;
  line-height: 0.8;
}

.rating {
  margin-top: 0;
}
.rating i {
  padding-right: 5px;
}
.rating i:nth-child(-n+4) {
  color: #ffa200;
}
.rating i:last-child {
  color: #dddddd;
}
.rating .three-star {
  padding-bottom: 5px;
}
.rating .three-star i {
  color: #acacac;
}
.rating .three-star i:nth-child(-n+3) {
  color: #ffd200;
}

.tab-border {
  border: 1px solid #dddddd;
  margin: 0;
  padding: 8px 0;
}
.tab-border .nav-border {
  border-bottom: 0;
  border-right: 1px solid #dddddd;
}

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  padding: 15px 20px;
}

.tab-product,
.product-full-tab {
  padding-top: 30px;
}
.tab-product .nav-material.nav-tabs,
.tab-product .nav-tabs.nav-tabs,
.product-full-tab .nav-material.nav-tabs,
.product-full-tab .nav-tabs.nav-tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
}
.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.tab-product .nav-tabs.nav-tabs .nav-item .nav-link,
.product-full-tab .nav-material.nav-tabs .nav-item .nav-link,
.product-full-tab .nav-tabs.nav-tabs .nav-item .nav-link {
  color: #212121;
  text-align: center;
  padding: 0 15px 20px 15px;
  text-transform: uppercase;
  border: 0;
}
.tab-product .nav-material.nav-tabs .nav-item .material-border,
.tab-product .nav-tabs.nav-tabs .nav-item .material-border,
.product-full-tab .nav-material.nav-tabs .nav-item .material-border,
.product-full-tab .nav-tabs.nav-tabs .nav-item .material-border {
  border-bottom: 2px solid var(--theme-deafult);
  opacity: 0;
}
.tab-product .nav-material.nav-tabs .nav-link.active,
.tab-product .nav-tabs.nav-tabs .nav-link.active,
.product-full-tab .nav-material.nav-tabs .nav-link.active,
.product-full-tab .nav-tabs.nav-tabs .nav-link.active {
  color: var(--theme-deafult);
  background-color: transparent;
  border-bottom: 2px solid var(--theme-deafult);
}
.tab-product .nav-material.nav-tabs .nav-link.active ~ .material-border,
.tab-product .nav-tabs.nav-tabs .nav-link.active ~ .material-border,
.product-full-tab .nav-material.nav-tabs .nav-link.active ~ .material-border,
.product-full-tab .nav-tabs.nav-tabs .nav-link.active ~ .material-border {
  transition: all 0.3s ease;
  opacity: 1;
}
.tab-product .theme-form input,
.product-full-tab .theme-form input {
  border-color: #dddddd;
  font-size: 15px;
  padding: 15px 25px;
  margin-bottom: 15px;
  height: inherit;
  text-align: left;
}
.tab-product .theme-form .btn-solid,
.product-full-tab .theme-form .btn-solid {
  margin: 0 auto;
}
.tab-product .theme-form textarea,
.product-full-tab .theme-form textarea {
  border-color: #dddddd;
  font-size: 15px;
  padding: 17px 25px;
  margin-bottom: 15px;
  height: inherit;
}
.tab-product .nav-material .tab-content p,
.product-full-tab .nav-material .tab-content p {
  padding: 20px;
  margin-bottom: -6px;
  line-height: 2;
  letter-spacing: 0.05em;
  margin-top: -10px;
  padding-bottom: 0;
}
.tab-product .nav-material .tab-content .media,
.product-full-tab .nav-material .tab-content .media {
  margin-top: 20px;
}
.tab-product .title,
.product-full-tab .title {
  padding-right: 45px;
  color: var(--theme-deafult);
  padding-bottom: 20px;
}
.tab-product .theme-slider .slick-arrow,
.product-full-tab .theme-slider .slick-arrow {
  top: -45px;
  height: auto;
}
.tab-product .theme-slider .slick-arrow :before,
.product-full-tab .theme-slider .slick-arrow :before {
  color: #000000;
  font-size: 18px;
}
.tab-product .product-box,
.product-full-tab .product-box {
  position: relative;
  margin: 5px;
}
.tab-product .product-box:hover,
.product-full-tab .product-box:hover {
  box-shadow: 0 0 12px 0 #dddddd;
}
.tab-product .product-box:hover .lbl-1,
.product-full-tab .product-box:hover .lbl-1 {
  opacity: 1;
  transition: all 0.3s ease;
}
.tab-product .product-box:hover .lbl-2,
.product-full-tab .product-box:hover .lbl-2 {
  opacity: 1;
  animation: flipInY 1000ms ease-in-out;
}
.tab-product .product-box:hover .color-variant li,
.product-full-tab .product-box:hover .color-variant li {
  opacity: 1 !important;
  animation: fadeInUp 500ms ease-in-out;
}
.tab-product .product-box .img-block,
.product-full-tab .product-box .img-block {
  min-height: unset;
}
.tab-product .product-box .cart-info,
.product-full-tab .product-box .cart-info {
  position: absolute;
  padding: 10px 0;
  top: 25%;
  right: 15px;
  width: 40px;
  margin-right: 0;
}
.tab-product .product-box .cart-info i,
.product-full-tab .product-box .cart-info i {
  padding-right: 0;
}
.tab-product .product-box .cart-info a,
.tab-product .product-box .cart-info button,
.product-full-tab .product-box .cart-info a,
.product-full-tab .product-box .cart-info button {
  color: #333333;
  transition: all 0.3s ease;
  background-color: #ffffff;
  height: 35px;
  width: 35px;
  margin: 7px 0;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  box-shadow: 0 0 12px 0 #dddddd;
}
.tab-product .product-box .cart-info a :hover,
.tab-product .product-box .cart-info button :hover,
.product-full-tab .product-box .cart-info a :hover,
.product-full-tab .product-box .cart-info button :hover {
  transition: all 0.3s ease;
  color: var(--theme-deafult);
}
.tab-product .product-box .lbl-1,
.product-full-tab .product-box .lbl-1 {
  background-color: var(--theme-deafult);
  padding: 2px 20px 2px 10px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 15px;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0;
}
.tab-product .product-box .lbl-1:before,
.product-full-tab .product-box .lbl-1:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 12px solid var(--theme-deafult);
  border-bottom: 13px solid var(--theme-deafult);
  border-right: 7px solid #ffffff;
}
.tab-product .product-box .lbl-2,
.product-full-tab .product-box .lbl-2 {
  font-size: 14px;
  top: 15px;
  position: absolute;
  right: 10px;
  color: #333333;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 0;
}
.tab-product .product-box a,
.product-full-tab .product-box a {
  color: #0072bb;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}
.tab-product .product-box .color-variant,
.product-full-tab .product-box .color-variant {
  position: absolute;
  top: -35px;
  width: 100%;
}
.tab-product .product-box .slick-slide img,
.product-full-tab .product-box .slick-slide img {
  display: block;
}
.tab-product .product-box .product-details,
.product-full-tab .product-box .product-details {
  position: relative;
}
.tab-product .product-box .product-details .color-variant,
.product-full-tab .product-box .product-details .color-variant {
  position: absolute;
  top: -35px;
  width: 100%;
}
.tab-product .product-box .product-details .color-variant li,
.product-full-tab .product-box .product-details .color-variant li {
  opacity: 0;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin: 0 3px;
  transition: all 0.3s ease;
  cursor: pointer;
  vertical-align: middle;
}
.tab-product .product-box .product-details h6,
.product-full-tab .product-box .product-details h6 {
  color: #333333;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.tab-product .product-box .product-details .price,
.product-full-tab .product-box .product-details .price {
  padding-bottom: 10px;
  font-size: 16px;
  color: var(--theme-deafult);
}

.product-full-tab {
  padding-top: 70px;
}

.product-load-more .col-grid-box {
  display: none;
}

.load-more-sec {
  margin-top: 40px;
  text-align: center;
}
.load-more-sec a {
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  padding: 10px 0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  width: 100%;
}

.product_image_4 > div:nth-child(-n+2) {
  margin-bottom: 25px;
}

.quick-view {
  width: 100%;
  max-width: 1080px;
  max-height: 600px;
  position: relative;
}

.checkout-page .checkout-title {
  margin-bottom: 25px;
}
.checkout-page .checkout-title h3 {
  color: #444444;
  font-weight: 700;
}
.checkout-page .checkout-form .checkout-details {
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #dddddd;
  padding: 40px;
}
.checkout-page .checkout-form .check-out .form-group:last-child {
  margin-bottom: -5px;
}
.checkout-page .checkout-form .check-out .form-group:last-child label {
  margin-bottom: -5px;
}
.checkout-page .checkout-form .form-group {
  position: relative;
  margin-bottom: 25px;
}
.checkout-page .checkout-form .form-group h3 {
  color: #444444;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px;
}
.checkout-page .checkout-form .form-group .field-label {
  line-height: 24px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 10px;
  font-weight: 700;
}
.checkout-page .checkout-form .form-group .field-label span {
  font-size: 16px;
  color: #222222;
  font-weight: 600;
}
.checkout-page .checkout-form .form-group label {
  color: #777777;
}
.checkout-page .checkout-form select {
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -moz-appearance: none;
  background: url("/assets/images/dropdown.png") no-repeat 95%;
}
.checkout-page .checkout-form input[type=text], .checkout-page .checkout-form input[type=email], .checkout-page .checkout-form input[type=password], .checkout-page .checkout-form input[type=tel], .checkout-page .checkout-form input[type=number], .checkout-page .checkout-form input[type=url] {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #dddddd;
}
.checkout-page .checkout-form select,
.checkout-page .checkout-form textarea {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #dddddd;
}
.checkout-page .check-box {
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px;
}
.checkout-page .check-box label {
  position: relative;
  top: -1px;
  font-weight: normal;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  color: #333333;
}
.checkout-page .lower-content {
  margin-top: 30px;
}
.checkout-page .lower-content .order-column {
  margin-bottom: 40px;
}

.order-box {
  position: relative;
  margin-bottom: 50px;
}
.order-box .title-box {
  position: relative;
  padding-bottom: 25px;
  color: #444444;
  font-weight: 600;
  font-size: 22px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
}
.order-box .title-box span {
  position: relative;
  width: 35%;
  float: right;
  line-height: 1.2em;
}
.order-box .qty {
  position: relative;
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
}
.order-box .qty li {
  position: relative;
  display: block;
  font-size: 15px;
  color: #444444;
  line-height: 20px;
  margin-bottom: 20px;
}
.order-box .qty li span {
  float: right;
  font-size: 18px;
  line-height: 20px;
  color: #232323;
  font-weight: 400;
  width: 35%;
}
.order-box .sub-total {
  position: relative;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 30px;
}
.order-box .sub-total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.order-box .sub-total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: var(--theme-deafult);
  font-weight: 400;
  width: 35%;
  float: right;
}
.order-box .sub-total .shopping-option label {
  position: relative;
  font-size: 14px;
  line-height: unset;
  padding-left: 5px;
  color: #6f6f6f;
}
.order-box .sub-total .shipping {
  width: 35%;
  float: right;
}
.order-box .total {
  position: relative;
  margin-bottom: 40px;
}
.order-box .total li {
  position: relative;
  display: block;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}
.order-box .total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: var(--theme-deafult);
  font-weight: 400;
}
.order-box .total li span {
  float: right;
  font-size: 15px;
  line-height: 20px;
  color: #444444;
  font-weight: 400;
  width: 35%;
  display: block;
}

.payment-box {
  position: relative;
}
.payment-box .upper-box {
  position: relative;
}
.payment-box .payment-options {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}
.payment-box .payment-options li {
  display: flex;
  margin-bottom: 15px;
}
.payment-box .payment-options li .radio-option {
  position: relative;
}
.payment-box .payment-options li .radio-option label {
  position: relative;
  padding-left: 30px;
  text-transform: capitalize;
  color: #6f6f6f;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
.payment-box .payment-options li .radio-option input[type=radio] {
  position: absolute;
  left: 0;
  top: 5px;
}
.payment-box .payment-options li .radio-option label .small-text {
  position: relative;
  display: none;
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
  color: #666666;
  margin-top: 10px;
}
.payment-box .payment-options li .radio-option label img {
  position: relative;
  display: block;
  max-width: 100%;
  margin-left: -30px;
  margin-top: 5px;
}

.stripe-section {
  text-transform: uppercase;
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #dddddd;
}
.stripe-section h5 {
  font-weight: 700;
}
.stripe-section .content h5 {
  color: #4f4f4f;
  font-weight: 400;
}
.stripe-section .content table {
  width: 100%;
}
.stripe-section .content table tr {
  border-bottom: 1px solid #f1f1f1;
}
.stripe-section .content table tr td {
  padding: 8px 0;
  color: #9a9a9a;
}
.stripe-section .content table tr td:last-child {
  text-align: right;
}

.margin-cls {
  margin-top: 25px;
}

.dashboard-left a {
  text-decoration: none;
  color: #000;
  outline: none;
}
.dashboard-left .block-title h2 {
  font-size: 24px;
  padding-bottom: 20px;
}
.dashboard-left .block-content {
  border: 1px solid #ddd;
  padding: 15px;
}
.dashboard-left .block-content ul li {
  display: flex;
  transition: all 0.5s ease;
}
.dashboard-left .block-content ul li.active {
  color: var(--theme-deafult);
}
.dashboard-left .block-content ul li.active a {
  color: var(--theme-deafult);
}
.dashboard-left .block-content ul li:before {
  content: "\f105";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 3px;
}
.dashboard-left .block-content ul li:hover {
  padding-left: 10px;
  color: #ff0000;
  transition: all 0.5s ease;
}
.dashboard-left .block-content ul li:hover a {
  color: #ff0000;
  transition: all 0.5s ease;
}

.dashboard-left.open {
  left: 0px;
}

.padding-cls {
  padding-left: 50px;
  padding-right: 50px;
}

.dashboard-right p {
  color: #5f5f5f;
  line-height: 20px;
}
.dashboard-right .dashboard {
  border: 1px solid #ddd;
  padding: 30px;
}
.dashboard-right .dashboard .page-title h2 {
  font-size: 22px;
  margin-bottom: 15px;
}
.dashboard-right .dashboard .welcome-msg p {
  margin-bottom: 0;
}

.dashboard .box-head h2 {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: capitalize;
  color: #333;
}
.dashboard .box a {
  color: var(--theme-deafult);
}
.dashboard .box h6 {
  margin-bottom: 0;
}
.dashboard .box .box-title {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  margin-bottom: 5px;
  padding: 12px 0;
  position: relative;
  width: 100%;
}
.dashboard .box .box-title h3 {
  font-size: 16px;
  margin: 0;
  text-transform: capitalize;
  color: #333;
}
.dashboard .box .box-title > a {
  position: absolute;
  right: 0;
  top: 12px;
  color: var(--theme-deafult);
}
.dashboard .box address {
  margin-bottom: 0;
}

.box-content h6 {
  font-size: 14px;
  line-height: 25px !important;
  margin-bottom: 0;
}
.box-content a {
  color: var(--theme-deafult);
}

.account-sidebar {
  display: none;
  cursor: pointer;
  background-color: var(--theme-deafult);
  color: #ffffff;
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 5px;
}

.success-text {
  text-align: center;
}
.success-text i {
  font-size: 50px;
  color: #4ead4e;
}
.success-text h2 {
  margin-top: 10px;
  margin-bottom: 15px;
}
.success-text p {
  font-size: 18px;
  text-transform: capitalize;
}
.success-text p:last-child {
  margin-bottom: 0;
}
.success-text.order-fail i {
  color: var(--theme-deafult);
}

.order-success-sec h4 {
  font-weight: 700;
  text-transform: capitalize;
}
.order-success-sec .order-detail li {
  display: flex;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}
.order-success-sec .payment-mode p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  letter-spacing: 0.05em;
}

.delivery-sec {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  background-color: #f9f9f9;
}
.delivery-sec h2,
.delivery-sec h3 {
  color: #000000;
  text-transform: capitalize;
}

.product-order h3 {
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: -2px;
}
.product-order .product-order-detail {
  margin-top: 15px;
}
.product-order .product-order-detail img {
  height: 130px;
}
.product-order .product-order-detail .order_detail {
  display: flex;
  align-items: center;
}
.product-order .product-order-detail .order_detail h4 {
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 700;
}
.product-order .product-order-detail .order_detail h5 {
  text-transform: capitalize;
  margin-bottom: 0;
}
.product-order .total-sec {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 15px 0;
  padding: 15px 0;
}
.product-order .total-sec ul li {
  font-size: 18px;
  display: block;
  text-transform: capitalize;
}
.product-order .total-sec ul li span {
  float: right;
}
.product-order .final-total h3 {
  display: block;
}
.product-order .final-total h3 span {
  float: right;
}

.compare-section .compare-part {
  position: relative;
}
.compare-section .compare-part .close-btn {
  background-color: transparent;
  border: none;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 0;
}
.compare-section .compare-part .img-secton {
  padding: 25px 15px 15px;
}
.compare-section .compare-part .img-secton img,
.compare-section .compare-part .img-secton .bg-size {
  width: 50%;
  margin: 0 auto;
}
.compare-section .compare-part .img-secton a h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  margin-top: 10px;
}
.compare-section .compare-part .img-secton h5 {
  margin-bottom: 0;
  line-height: 1.2;
}
.compare-section .compare-part .detail-part .title-detail {
  background-color: #f7f8fa;
  text-align: center;
  padding: 8px 0;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.compare-section .compare-part .detail-part .title-detail h5 {
  margin-bottom: 0;
  text-transform: uppercase;
}
.compare-section .compare-part .detail-part .inner-detail {
  padding: 15px;
}
.compare-section .compare-part .detail-part .inner-detail p {
  margin-bottom: 0;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
.compare-section .compare-part .detail-part .inner-detail p span {
  padding-right: 15px;
}
.compare-section .compare-part .btn-part {
  text-align: center;
  padding: 15px;
  border-top: 1px solid #ddd;
}
.compare-section .compare-part .btn-part .btn-solid,
.compare-section .compare-part .btn-part .btn-outline {
  padding: 5px 10px;
}
.compare-section .owl-item {
  border: 1px solid #ddd;
}

.compare-padding {
  padding-bottom: 50px;
}
.compare-padding h2 {
  margin-bottom: 20px;
}

.compare-lable {
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 20px;
  left: 16px;
  letter-spacing: 0.1em;
  z-index: 1;
}

.compare-page .table-wrapper {
  padding-bottom: 0;
}
.compare-page .table-wrapper .table {
  border: 1px solid #eeeeee;
  text-transform: capitalize;
  color: #222222;
}
.compare-page .table-wrapper .table .product-name {
  width: 15%;
}
.compare-page .table-wrapper .table .featured-image {
  width: 185px;
}
.compare-page .table-wrapper .table td {
  border-top: none;
}
.compare-page .table-wrapper .table thead .th-compare td {
  font-size: 14px;
  font-weight: 700;
  background: #f8f8f8;
  border-right: 1px solid #eee;
}
.compare-page .table-wrapper .table thead .th-compare th {
  text-align: left;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}
.compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  float: left;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}
.compare-page .table-wrapper .table tbody tr th {
  background: #f8f8f8;
}
.compare-page .table-wrapper .table tbody tr td {
  border-right: 1px solid #eee;
  position: relative;
}
.compare-page .table-wrapper .table tbody tr th,
.compare-page .table-wrapper .table tbody tr td {
  padding: 15px;
  vertical-align: top;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
}
.compare-page .table-wrapper .table tbody tr .grid-link__title {
  font-size: 15px;
}
.compare-page .table-wrapper .table tbody tr .product_price {
  margin: 15px 0;
}
.compare-page .table-wrapper .table tbody tr .product_price span.money {
  padding-left: 5px;
}
.compare-page .table-wrapper .table tbody tr p {
  color: #222222;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
}
.compare-page .table-wrapper .table tbody tr .add-to-cart,
.compare-page .table-wrapper .table tbody tr .select-option-cls {
  padding: 6px 10px;
}

.typography_section .typography-box {
  margin-bottom: 30px;
}
.typography_section .typography-box p {
  line-height: 22px;
}
.typography_section .typography-box .headings {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
  background-color: rgba(247, 247, 247, 0.5);
  padding: 8px 12px;
}
.typography_section .typography-box .headings h3 {
  text-transform: capitalize;
  color: #2d2d2d;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px;
}
.typography_section .typography-box .headings span {
  color: #777777;
}
.typography_section .typography-box .headings span code {
  color: #777777;
}
.typography_section .typography-box .typo-content.heading_content h1,
.typography_section .typography-box .typo-content.heading_content h2,
.typography_section .typography-box .typo-content.heading_content h3,
.typography_section .typography-box .typo-content.heading_content h4,
.typography_section .typography-box .typo-content.heading_content h5,
.typography_section .typography-box .typo-content.heading_content h6 {
  font-weight: 400;
  color: black;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
}
.typography_section .typography-box .typo-content .sub-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-size: 18px;
  color: #222222;
  text-transform: capitalize;
}
.typography_section .typography-box .typo-content.product-pagination {
  border: none;
  margin: 0;
}
.typography_section .typography-box .typo-content.product-pagination .pagination .page-item {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.typography_section .typography-box .typo-content.product-pagination .pagination .page-item a {
  padding: 10px 14px;
}
.typography_section .typography-box .typo-content.input_button input {
  margin-bottom: 10px;
}
.typography_section .typography-box .typo-content.input_button label {
  font-size: 16px;
  text-transform: capitalize;
  padding-left: 5px;
}
.typography_section .typography-box .typo-content.loader-typo {
  width: 100%;
  justify-content: center;
  display: flex;
}
.typography_section .typography-box .typo-content.loader-typo .pre-loader {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: var(--theme-deafult);
  border-radius: 50%;
}
.typography_section .typography-box .typo-content.loader-typo .pre-loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  border: 0 solid white;
  transform: translate(-50%, -50%);
  animation: loading 1000ms ease-out forwards infinite;
  transition: all 0.3s ease;
}
.typography_section .typography-box .typo-content .footer-social {
  margin-top: 0;
  justify-content: center;
}
.typography_section .typography-box:last-child {
  margin-bottom: 0;
}
.typography_section code {
  color: rgba(var(--theme-deafult), 0.6);
  background-color: #f7f7f7;
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px;
}

.review-page .comment-section {
  border-bottom: none !important;
}
.review-page .comment-section li:first-child {
  border-top: none;
  padding-top: 0;
}
.review-page .comment-section li:last-child {
  padding-bottom: 0;
}
.review-page .comnt-sec {
  margin-top: 10px;
}
.review-page .comnt-sec li {
  margin-right: 15px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border-top: none !important;
}
.review-page .comnt-sec li a {
  color: #777777;
  font-size: 16px;
}
.review-page .comnt-sec li a i {
  margin-right: 5px;
}

.template-password {
  background: url("/assets/images/coming-soon.jpg");
  background-size: cover;
  height: 100vh;
}
.template-password #container {
  height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.template-password #container > div {
  width: 100%;
}
.template-password #container #login {
  width: 100%;
  margin-bottom: 120px;
}
.template-password #container #login h2 {
  color: var(--theme-deafult);
  font-size: 30px;
  font-weight: 400;
}
.template-password #container #login .theme-form h3 {
  color: #999999;
  font-size: 20px;
}
.template-password #container #login .theme-form input {
  border-color: #dddddd;
  font-size: 12px;
  padding: 17px 25px;
  margin-bottom: 15px;
  height: inherit;
}
.template-password #container #login .theme-form input:focus {
  box-shadow: none;
}
.template-password #container #powered {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}
.template-password #container #owner a {
  color: var(--theme-deafult);
}

.review-page .comment-section {
  border-bottom: none !important;
}
.review-page .comment-section li:first-child {
  border-top: none;
  padding-top: 0;
}
.review-page .comment-section li:last-child {
  padding-bottom: 0;
}
.review-page .comnt-sec {
  margin-top: 10px;
}
.review-page .comnt-sec li {
  margin-right: 15px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border-top: none !important;
}
.review-page .comnt-sec li a {
  color: #777777;
  font-size: 16px;
}
.review-page .comnt-sec li a i {
  margin-right: 5px;
}

.vendor-cover .bg-size {
  height: 350px;
}

.vendor-profile {
  margin-top: -70px;
}
.vendor-profile .profile-left {
  display: flex;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
}
.vendor-profile .profile-left .profile-image {
  text-align: center;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vendor-profile .profile-left .profile-image img {
  width: 60%;
}
.vendor-profile .profile-left .profile-image h3 {
  font-weight: 700;
  margin-bottom: 5px;
  color: black;
}
.vendor-profile .profile-left .profile-image h6 {
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}
.vendor-profile .profile-left .profile-detail {
  border-left: 1px solid #efefef;
  padding-left: 20px;
  margin-left: 20px;
  width: 60%;
  display: flex;
  align-items: center;
}
.vendor-profile .profile-left .profile-detail h6 {
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}
.vendor-profile .profile-left .profile-detail p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5;
}
.vendor-profile .profile-left .profile-detail p + p {
  margin-top: 10px;
}
.vendor-profile .profile-left .vendor-contact {
  border-left: 1px solid #efefef;
  padding-left: 20px;
  margin-left: 20px;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.vendor-profile .profile-left .vendor-contact .footer-social {
  margin-top: 0;
  margin-bottom: 12px;
  justify-content: center;
}
.vendor-profile .profile-left .vendor-contact .footer-social i {
  color: #989898;
}
.vendor-profile .profile-left .vendor-contact .footer-social li {
  padding: 0 5px;
}
.vendor-profile .profile-left .vendor-contact h6 {
  text-transform: capitalize;
  color: black;
  font-weight: 700;
  margin-bottom: 8px;
}
.vendor-profile .profile-left .vendor-contact a {
  text-transform: capitalize;
}
.vendor-profile .profile-left + .profile-left {
  margin-top: 40px;
}
.vendor-profile .page-link {
  border-color: #efefef;
  background-color: #f9f9f9;
}
.vendor-profile .page-link:hover {
  background-color: #f1f1f1;
}

.become-vendor {
  background-color: #f7f7f7;
  overflow: hidden;
}
.become-vendor h4 {
  margin-bottom: 40px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
}
.become-vendor .step-bg {
  position: relative;
}
.become-vendor .step-bg:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  display: block;
}
.become-vendor .step-bg .row {
  margin-left: -50px;
  margin-right: -50px;
}
.become-vendor .step-bg .row > div {
  padding-left: 50px;
  padding-right: 50px;
}
.become-vendor .step-bg .row > div:nth-child(1), .become-vendor .step-bg .row > div:nth-child(3) {
  margin-bottom: 30px;
}
.become-vendor .step-bg .row > div:nth-child(2) {
  margin-top: 30px;
}
.become-vendor .step-bg h4 {
  margin-bottom: 10px;
}
.become-vendor .step-bg .step-box {
  position: relative;
  padding: 50px 35px 33px;
  box-shadow: 0 15px 39px 0 rgba(8, 18, 109, 0.08);
  transition: all 0.3s ease;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.become-vendor .step-bg .step-box .steps {
  position: relative;
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  margin: 0 auto 25px auto;
  color: var(--theme-deafult);
  text-align: center;
  font-weight: 500;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(28px + 12 * (100vw - 320px) / 1600);
}
.become-vendor .step-bg .step-box p {
  line-height: 1.5;
  margin-bottom: 0;
}

.start-selling h4 {
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
}
.start-selling p {
  line-height: 2;
  letter-spacing: 0.03em;
}
.start-selling .btn {
  margin-top: 20px;
}

.vendor-dashboard .dashboard-box {
  padding: 20px 10px;
  background-color: #f9f9f9;
}
.vendor-dashboard .dashboard-box .date-picker-box {
  display: flex;
}
.vendor-dashboard .dashboard-box .date-picker-box .input-group {
  width: 50%;
  padding: 0 10px;
}

.dashboard-section .dashboard-sidebar {
  position: sticky;
  top: 30px;
  z-index: 1;
  padding: 30px 0 15px 0;
  background-color: #f8f8f8;
}
.dashboard-section .dashboard-sidebar .profile-top {
  padding: 0 16px;
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
}
.dashboard-section .dashboard-sidebar .profile-top .profile-image {
  position: relative;
}
.dashboard-section .dashboard-sidebar .profile-top .profile-image img {
  width: 130px;
  margin: 0 auto;
  display: block;
  border-radius: 100%;
}
.dashboard-section .dashboard-sidebar .profile-top .profile-image .profile-edit {
  position: absolute;
  bottom: 0;
  right: 30%;
}
.dashboard-section .dashboard-sidebar .profile-top .profile-image .profile-edit svg {
  stroke: var(--theme-deafult);
}
.dashboard-section .dashboard-sidebar .profile-top .profile-detail {
  text-align: center;
  margin-top: 15px;
}
.dashboard-section .dashboard-sidebar .profile-top .profile-detail h5 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 5px;
}
.dashboard-section .dashboard-sidebar .profile-top .profile-detail h6 {
  color: #777777;
  margin-bottom: 3px;
}
.dashboard-section .dashboard-sidebar .faq-tab .nav-tabs {
  box-shadow: none;
  padding: 0;
}
.dashboard-section .counter-section .counter-box {
  background-color: #f8f8f8;
  padding: 30px 30px;
  display: flex;
  align-items: center;
  border-left: 3px solid var(--theme-deafult);
  border-radius: 8px;
}
.dashboard-section .counter-section .counter-box img {
  height: 50px;
  margin-right: 20px;
}
.dashboard-section .counter-section .counter-box h3 {
  font-weight: 700;
  color: var(--theme-deafult);
  margin-bottom: 4px;
  font-size: 20px;
}
.dashboard-section .counter-section .counter-box h5 {
  margin-bottom: 0;
  text-transform: capitalize;
}
.dashboard-section .faq-content .card {
  border: none;
  background-color: #f8f8f8;
  margin-top: 30px;
}
.dashboard-section .dashboard-table h3 {
  text-transform: capitalize;
  font-size: 16px;
  color: black;
  margin-bottom: 15px;
}
.dashboard-section .dashboard-table img {
  width: 50px;
}
.dashboard-section .dashboard-table .table thead th {
  border-top: none;
}
.dashboard-section .dashboard-table .table th,
.dashboard-section .dashboard-table .table td {
  vertical-align: middle;
  text-transform: capitalize;
  text-align: center;
}
.dashboard-section .top-sec {
  display: flex;
  margin-bottom: 15px;
}
.dashboard-section .top-sec h3 {
  text-transform: capitalize;
  color: black;
  font-size: 20px;
}
.dashboard-section .top-sec .btn {
  margin-left: auto;
  text-transform: capitalize;
  padding: 7px 14px;
}
.dashboard-section .dashboard-box .dashboard-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.dashboard-section .dashboard-box .dashboard-title h4 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
}
.dashboard-section .dashboard-box .dashboard-title span {
  margin-left: 30px;
  padding: 2px 16px;
  border-radius: 2px;
  text-transform: capitalize;
  color: var(--theme-deafult);
  cursor: pointer;
}
.dashboard-section .dashboard-box .dashboard-detail ul li {
  display: block;
  margin-bottom: 10px;
}
.dashboard-section .dashboard-box .dashboard-detail ul li .details {
  display: flex;
}
.dashboard-section .dashboard-box .dashboard-detail ul li .details h6 {
  text-transform: capitalize;
  margin-bottom: 0;
}
.dashboard-section .dashboard-box .dashboard-detail ul li .details span {
  margin-left: calc(10px + 20 * (100vw - 320px) / 1600);
  border-radius: 2px;
  text-transform: capitalize;
  color: var(--theme-deafult);
  cursor: pointer;
}
.dashboard-section .dashboard-box .dashboard-detail ul li .details .left {
  width: 150px;
  margin-right: 15px;
}
.dashboard-section .dashboard-box .dashboard-detail ul li .details .left h6 {
  color: #4e4e4e;
}
.dashboard-section .dashboard-box .dashboard-detail ul li .details .right {
  display: flex;
  align-items: center;
}
.dashboard-section .dashboard-box .dashboard-detail ul li .details .right h6 {
  font-weight: 500;
}
.dashboard-section .dashboard-box .dashboard-detail ul li:last-child {
  margin-bottom: 0;
}
.dashboard-section .dashboard-box .dashboard-detail .account-setting h5 {
  text-transform: capitalize;
}
.dashboard-section .dashboard-box .dashboard-detail .account-setting .row > div .form-check {
  margin-bottom: 10px;
  padding-left: 0;
}
.dashboard-section .dashboard-box .dashboard-detail .account-setting .row > div .form-check:last-child {
  margin-bottom: 0;
}
.dashboard-section .dashboard-box .dashboard-detail .account-setting .btn {
  margin-top: 15px;
}
.dashboard-section .dashboard-box .dashboard-detail .account-setting + .account-setting {
  margin-top: 15px;
}
.dashboard-section .radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
}
.dashboard-section .radio_animated:before {
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 0;
  left: 0.125rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.75rem;
  background: var(--theme-deafult);
  border-radius: 50%;
}
.dashboard-section .radio_animated:after {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background: #ffffff;
  border: 2px solid #e8ebf2;
  border-radius: 50%;
}
.dashboard-section .radio_animated:checked:before {
  transform: scale(1, 1);
}

.faq-tab .nav-tabs {
  display: block;
  border-bottom: none;
  background-color: white;
  padding: 10px 0;
}
.faq-tab .nav-tabs .nav-item {
  display: block;
  margin-bottom: 0;
}
.faq-tab .nav-tabs .nav-item .nav-link {
  text-transform: capitalize;
  color: black;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  border: none;
  transition: all 0.5s ease;
  border-radius: 0;
  background-color: #f8f8f8;
}
.faq-tab .nav-tabs .nav-item .nav-link.active {
  border: none;
  border-right: 2px solid var(--theme-deafult);
  border-radius: 0;
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.faq-tab .nav-tabs .nav-item .nav-link:hover {
  border: none;
  border-right: 2px solid var(--theme-deafult);
  color: var(--theme-deafult);
  transition: all 0.5s ease;
}

/*=====================
    22.RTL CSS start
    ==========================*/
[dir=rtl] .owl-item {
  float: left;
}

[dir=rtl] .tools-parallax-product .owl-next {
  left: -25px !important;
}
[dir=rtl] .tools-parallax-product .owl-prev {
  right: -25px !important;
  left: unset !important;
}

[dir=rtl] .owl-prev {
  left: 1px;
  right: unset;
}

[dir=rtl] .owl-next {
  left: unset;
  right: 1px;
}

.rtl .no-found h3,
.rtl .no-found p {
  text-align: center;
}

.rtl .brand-logo img.img-fluid {
  transform: scaleX(-1);
}

.setting-box {
  direction: ltr;
}
.setting-box h1,
.setting-box h2,
.setting-box h3,
.setting-box h4,
.setting-box h5,
.setting-box h6,
.setting-box p {
  text-align: left !important;
}

body.rtl {
  direction: rtl;
}

.rtl header .main-navbar .nav-menu > li {
  float: right;
}
.rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
  text-align: right;
}
.rtl header .main-navbar .nav-menu > li .nav-submenu li {
  text-align: right;
}
.rtl header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow {
  right: auto;
  left: 15px;
  transform: rotate(180deg);
}
.rtl header .sidenav .sidebar-menu li {
  text-align: right;
}
.rtl header .sidenav .sidebar-menu li ul li a:before {
  right: 35px;
}
.rtl header .sidenav .sidebar-menu li ul.mega-menu li a:before {
  right: 0;
}
.rtl .product-thumb-list {
  left: unset;
  right: 0;
}
.rtl .j-box .product-box .cart-info {
  left: 0 !important;
}
.rtl .header-gym .main-menu .menu-left .navbar {
  padding: 20px 0 20px 45px;
}
.rtl .main-menu .menu-left .navbar {
  padding: 40px 0 40px 45px;
}
.rtl .form_search button {
  left: 5px;
  right: unset;
}
.rtl .rtl-right-text {
  text-align: right;
}
.rtl .size-text {
  text-align: right !important;
}
.rtl .detail-cannabis .detail_section h4,
.rtl .detail-cannabis .detail_section p {
  text-align: center;
}
.rtl .tab-left .theme-tab .left-side .tab-title {
  margin-left: 100px;
  text-align: right;
  margin-right: 0;
}
.rtl .review-page .comnt-sec {
  text-align: right;
}
.rtl .review-page .comnt-sec li {
  margin-right: 0;
  margin-left: 15px;
}
.rtl .review-page .comnt-sec li a i {
  margin-right: 0;
  margin-left: 5px;
}
.rtl .gym-product .product-box .product-detail h6,
.rtl .gym-product .product-box .product-detail h4 {
  text-align: center;
}
.rtl .tools-grey .product-box .product-info h6,
.rtl .tools-grey .product-box .product-info h4 {
  text-align: center;
}
.rtl .tools-grey .product-box .cart-info {
  left: 0 !important;
  right: 0 !important;
}
.rtl .tools_product .media .br.br-default {
  justify-content: flex-start;
}
.rtl .tools-parallax-product .tools-description .tools-form .btn-find {
  float: right;
}
.rtl .left-header .sidenav {
  right: 0;
}
.rtl .left-header .sidenav .left-sidebar_center {
  padding: 40px 30px 40px 0;
}
.rtl .left-header .sidenav .pixelstrap li a .sub-arrow {
  left: 7px;
  transform: rotate(180deg);
}
.rtl .left-header .top-header {
  padding-left: 0;
  padding-right: 300px;
}
.rtl .left-header .search-overlay {
  padding-right: 300px;
  padding-left: 0;
}
.rtl .left-sidebar_space {
  padding-left: 0;
  padding-right: 300px;
}
.rtl .portfolio-section.metro-section .product-box .cart-wrap {
  right: unset;
  left: 7px;
}
.rtl .portfolio-section.metro-section .product-box .product-detail h4,
.rtl .portfolio-section.metro-section .product-box .product-detail h6 {
  text-align: center;
}
.rtl .br-default .br-unit {
  margin-right: 0;
  margin-left: 5px;
}
.rtl .br.br-default {
  display: flex;
  justify-content: right;
}
.rtl .owl-carousel .br.br-default {
  display: flex;
  justify-content: flex-end;
}
.rtl .absolute_banner .collection-banner .absolute-contain h3,
.rtl .absolute_banner .collection-banner .absolute-contain h4 {
  text-align: center;
}
.rtl .no-slider .br.br-default {
  display: flex;
  justify-content: right;
}
.rtl .blog.blog_box .blog-details a p {
  text-align: right;
}
.rtl .blog.blog_box .blog-details .read-cls {
  float: right;
}
.rtl .service-block1 h4,
.rtl .service-block1 p {
  text-align: center;
}
.rtl .beauty-about .text-center {
  text-align: left !important;
}
.rtl .beauty-about .about-text p {
  text-align: right;
}
.rtl .beauty-about .service-block1 h5 {
  text-align: center;
}
.rtl .pets-box .product-box .product-detail h6,
.rtl .pets-box .product-box .product-detail h4 {
  text-align: center;
}
.rtl .theme-tab .br[_ngcontent-c24] {
  text-align: right;
  display: flex;
  justify-content: right;
}
.rtl .theme-tab .br[_ngcontent-c29] {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.rtl .br[_ngcontent-c29] {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.rtl .br-units[_ngcontent-c29] {
  justify-content: flex-end;
  text-align: right;
  display: flex;
}
.rtl .br[_ngcontent-c24] {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.rtl .br[_ngcontent-c26] {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.rtl h1,
.rtl h2,
.rtl h3,
.rtl h4,
.rtl h5,
.rtl h6,
.rtl p {
  text-align: right;
}
.rtl .color-variant li {
  margin-right: 0px !important;
  margin-left: 5px !important;
}
.rtl .br-units[_ngcontent-c25] {
  direction: rtl !important;
}
.rtl ul {
  -webkit-padding-start: 0;
}
.rtl .product-right .rating {
  text-align: right !important;
}
.rtl .product-right .pro_inventory .inventory-scroll > span {
  float: right;
}
.rtl .timer {
  padding-left: 0;
  padding-right: 40px;
}
.rtl .timer span .padding-l {
  padding-left: 0;
  padding-right: 22px;
}
.rtl .home.text-left h1,
.rtl .home.text-left h2,
.rtl .home.text-left h3,
.rtl .home.text-left h4,
.rtl .home.text-left h5,
.rtl .home.text-left h6 {
  text-align: left;
}
.rtl .empty-cart-cls h3,
.rtl .empty-cart-cls h4 {
  text-align: center;
}
.rtl .product-right .product-buttons a:last-child {
  margin-right: 10px;
  margin-left: 0;
}
.rtl .owl-carousel {
  direction: ltr;
}
.rtl .stripe-section .content table tr td:first-child {
  text-align: right;
}
.rtl .mr-3 {
  margin-right: 0 !important;
  margin-left: 5px;
}
.rtl .offset-md-3,
.rtl .offset-lg-2,
.rtl .offset-xl-3,
.rtl .offset-lg-3,
.rtl .offset-md-2 {
  margin: 0 auto;
}
.rtl .title1 h4,
.rtl .title1 h2,
.rtl .title2 h4,
.rtl .title2 h2,
.rtl .title3 h4,
.rtl .title3 h2,
.rtl .title4 h4,
.rtl .title4 h2 {
  text-align: center;
}
.rtl .search-overlay > div .closebtn {
  right: unset;
  left: 25px;
}
.rtl .search-block .form-header button {
  left: 10px;
  right: unset;
}
.rtl .cart_qty_cls {
  left: -12px;
  right: unset;
}
.rtl .size-variant {
  text-align: right;
}
.rtl .size-variant li {
  margin-left: 5px;
  margin-right: unset;
}
.rtl .home-slider .text-center p,
.rtl .home-slider .text-center h1,
.rtl .home-slider .text-center h2,
.rtl .home-slider .text-center h3,
.rtl .home-slider .text-center h4,
.rtl .home-slider .text-center h5,
.rtl .home-slider .text-center h6,
.rtl .center-slider .text-center p,
.rtl .center-slider .text-center h1,
.rtl .center-slider .text-center h2,
.rtl .center-slider .text-center h3,
.rtl .center-slider .text-center h4,
.rtl .center-slider .text-center h5,
.rtl .center-slider .text-center h6 {
  text-align: center !important;
}
.rtl .home-slider .text-left p,
.rtl .home-slider .text-left h1,
.rtl .home-slider .text-left h2,
.rtl .home-slider .text-left h3,
.rtl .home-slider .text-left h4,
.rtl .home-slider .text-left h5,
.rtl .home-slider .text-left h6,
.rtl .center-slider .text-left p,
.rtl .center-slider .text-left h1,
.rtl .center-slider .text-left h2,
.rtl .center-slider .text-left h3,
.rtl .center-slider .text-left h4,
.rtl .center-slider .text-left h5,
.rtl .center-slider .text-left h6 {
  text-align: left;
}
.rtl .home-slider .text-right p,
.rtl .home-slider .text-right h1,
.rtl .home-slider .text-right h2,
.rtl .home-slider .text-right h3,
.rtl .home-slider .text-right h4,
.rtl .home-slider .text-right h5,
.rtl .home-slider .text-right h6,
.rtl .center-slider .text-right p,
.rtl .center-slider .text-right h1,
.rtl .center-slider .text-right h2,
.rtl .center-slider .text-right h3,
.rtl .center-slider .text-right h4,
.rtl .center-slider .text-right h5,
.rtl .center-slider .text-right h6 {
  text-align: right;
}
.rtl .home-slider .p-left .slider-contain,
.rtl .center-slider .p-left .slider-contain {
  justify-content: flex-start;
}
.rtl .home-slider .p-right .slider-contain,
.rtl .center-slider .p-right .slider-contain {
  justify-content: flex-end;
}
.rtl .home-slider .slider-contain h1,
.rtl .home-slider .slider-contain h2,
.rtl .home-slider .slider-contain h3,
.rtl .home-slider .slider-contain h4,
.rtl .home-slider .slider-contain h5,
.rtl .home-slider .slider-contain h6,
.rtl .center-slider .slider-contain h1,
.rtl .center-slider .slider-contain h2,
.rtl .center-slider .slider-contain h3,
.rtl .center-slider .slider-contain h4,
.rtl .center-slider .slider-contain h5,
.rtl .center-slider .slider-contain h6 {
  text-align: left;
}
.rtl .home-slider .slider-contain .btn-solid,
.rtl .home-slider .slider-contain .btn-outline,
.rtl .center-slider .slider-contain .btn-solid,
.rtl .center-slider .slider-contain .btn-outline {
  float: inherit;
}
.rtl .home-slider .owl-next,
.rtl .center-slider .owl-next {
  right: unset;
  left: 1px;
}
.rtl .home-slider .owl-next:before,
.rtl .center-slider .owl-next:before {
  transform: rotate(180deg);
}
.rtl .home-slider .owl-prev,
.rtl .center-slider .owl-prev {
  left: unset;
  right: 1px;
}
.rtl .home-slider .owl-prev:before,
.rtl .center-slider .owl-prev:before {
  transform: rotate(180deg);
}
.rtl .home-slider:hover .owl-next,
.rtl .center-slider:hover .owl-next {
  right: unset;
  left: 100px;
}
.rtl .home-slider:hover .owl-prev,
.rtl .center-slider:hover .owl-prev {
  left: unset;
  right: 100px;
}
.rtl .pwd-page h2 {
  text-align: center;
}
.rtl .product-box .color-variant {
  -webkit-padding-start: 0;
}
.rtl .product-box .product-info h4,
.rtl .product-box .product-info h6 {
  text-align: center;
}
.rtl .product-box .product-info .br.br-default {
  justify-content: center;
}
.rtl .product-box .product-detail .rating {
  text-align: right;
}
.rtl .product-box .product-detail .color-variant {
  text-align: right;
  padding: 0;
  padding-top: 15px;
}
.rtl .product-box .product-detail.text-center .color-variant {
  text-align: center !important;
}
.rtl .product-box .img-block .lable-wrapper .lable1 {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.rtl .product-box .img-block .lable-wrapper .lable2 {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.rtl .product-box .img-wrapper .lable-block .lable3 {
  left: unset;
  right: 7px;
}
.rtl .product-box .img-wrapper .lable-block .lable4 {
  left: 7px;
  right: unset;
}
.rtl .product-box .cart-detail {
  left: 20px;
  right: unset;
}
.rtl .product-box .cart-info.cart-wrap {
  left: 10px;
  right: unset;
}
.rtl .product-box:hover .cart-wrap button {
  animation: fadeInLeft 300ms ease-in-out;
}
.rtl .product-box:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInLeft 500ms ease-in-out;
}
.rtl .product-box:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInLeft 700ms ease-in-out;
}
.rtl .product-box:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInLeft 1000ms ease-in-out;
}
.rtl .product-box:hover .cart-detail button {
  animation: fadeInLeft 300ms ease-in-out;
}
.rtl .product-box:hover .cart-detail a:nth-child(2) i {
  animation: fadeInLeft 500ms ease-in-out;
}
.rtl .product-box:hover .cart-detail a:nth-child(3) i {
  animation: fadeInLeft 700ms ease-in-out;
}
.rtl .product-box:hover .cart-detail a:nth-child(4) i {
  animation: fadeInLeft 1000ms ease-in-out;
}
.rtl .footer-light .subscribe {
  border-right: none;
  border-left: 1px solid #dddddd;
}
.rtl .footer-light .subscribe h4 {
  text-align: right;
}
.rtl .subscribe h4 {
  text-align: center;
}
.rtl .subscribe h4 i {
  padding-left: 5px;
  padding-right: 0;
}
.rtl .add_to_cart .cart-inner .cart_top .close-cart {
  margin-left: unset;
  margin-right: auto;
}
.rtl .add_to_cart .cart-inner .cart_media li .total h5 span {
  float: left;
}
.rtl .add_to_cart .cart-inner .cart_media .close-circle {
  left: 0;
  right: unset;
}
.rtl .service-block svg,
.rtl .service-block img {
  margin-left: 12px;
  margin-right: 0;
}
.rtl .service-block + .service-block {
  border-right: 1px solid #dddddd;
  border-left: none;
}
.rtl .blog-details h4,
.rtl .blog-details h6 {
  text-align: center;
}
.rtl .blog-details a p {
  text-align: center;
}
.rtl .instagram h2 {
  text-align: center;
}
.rtl .category-block .category-details h5 {
  text-align: center;
}
.rtl .category-bg .contain-block h6 {
  text-align: center;
}
.rtl .about-text p {
  text-align: center;
}
.rtl .collection-banner.p-left .contain-banner {
  justify-content: flex-end !important;
}
.rtl .collection-banner.p-right .contain-banner {
  justify-content: flex-start !important;
}
.rtl .collection-banner.p-center .contain-banner {
  justify-content: center !important;
}
.rtl .collection-banner.text-center .contain-banner h1,
.rtl .collection-banner.text-center .contain-banner h2,
.rtl .collection-banner.text-center .contain-banner h3,
.rtl .collection-banner.text-center .contain-banner h4,
.rtl .collection-banner.text-center .contain-banner h5,
.rtl .collection-banner.text-center .contain-banner h6 {
  text-align: center;
}
.rtl .collection-banner.text-left .contain-banner h1,
.rtl .collection-banner.text-left .contain-banner h2,
.rtl .collection-banner.text-left .contain-banner h3,
.rtl .collection-banner.text-left .contain-banner h4,
.rtl .collection-banner.text-left .contain-banner h5,
.rtl .collection-banner.text-left .contain-banner h6 {
  text-align: left;
}
.rtl .collection-banner.text-right .contain-banner h1,
.rtl .collection-banner.text-right .contain-banner h2,
.rtl .collection-banner.text-right .contain-banner h3,
.rtl .collection-banner.text-right .contain-banner h4,
.rtl .collection-banner.text-right .contain-banner h5,
.rtl .collection-banner.text-right .contain-banner h6 {
  text-align: right;
}
.rtl .collection-banner .contain-banner {
  justify-content: flex-end;
}
.rtl .collection-banner .contain-banner h1,
.rtl .collection-banner .contain-banner h2,
.rtl .collection-banner .contain-banner h3,
.rtl .collection-banner .contain-banner h4,
.rtl .collection-banner .contain-banner h5,
.rtl .collection-banner .contain-banner h6 {
  text-align: left;
}
.rtl .full-banner.p-left .banner-contain {
  float: left;
}
.rtl .full-banner.p-right .banner-contain {
  float: right;
}
.rtl .full-banner.p-center .banner-contain {
  left: 0;
  right: 0;
  margin: 0 auto;
}
.rtl .full-banner.p-center .banner-contain h3,
.rtl .full-banner.p-center .banner-contain h4 {
  text-align: center;
}
.rtl .full-banner.p-center .banner-contain .btn-solid,
.rtl .full-banner.p-center .banner-contain .btn-outline {
  float: unset;
}
.rtl .full-banner.text-center .banner-contain h2,
.rtl .full-banner.text-center .banner-contain h3,
.rtl .full-banner.text-center .banner-contain h4 {
  text-align: center;
}
.rtl .full-banner.text-left .banner-contain h2,
.rtl .full-banner.text-left .banner-contain h3,
.rtl .full-banner.text-left .banner-contain h4 {
  text-align: left;
}
.rtl .full-banner.text-right .banner-contain h2,
.rtl .full-banner.text-right .banner-contain h3,
.rtl .full-banner.text-right .banner-contain h4 {
  text-align: right;
}
.rtl .theme-card .media {
  direction: rtl;
}
.rtl .theme-card .owl-prev {
  left: 0;
  right: unset;
}
.rtl .theme-card .owl-next {
  right: unset;
  left: 25px;
}
.rtl .theme-card .offer-slider img {
  padding: 15px 0 15px 15px;
}
.rtl .theme-card .offer-slider .media .media-body a h6 {
  margin-left: 61px;
  margin-right: 0;
}
.rtl .theme-card .offer-slider .media .media-body .br.br-default {
  justify-content: flex-start;
}
.rtl .theme-card .offer-slider .media .media-body .rating {
  text-align: right;
}
.rtl .theme-card .offer-slider .media .media-body .rating i {
  padding-left: 5px;
}
.rtl .theme-card.card-border h5 {
  padding-left: 0;
  padding-right: 50px;
}
.rtl .theme-card.card-border .owl-prev {
  left: 30px;
}
.rtl .theme-card.card-border .owl-next {
  left: 50px;
}
.rtl .theme-card.card-border .offer-slider img {
  padding: 15px 30px 15px 15px;
}
.rtl .background .contain-bg h4 {
  text-align: center;
}
.rtl .center-slider .offer-slider .product-box .product-info h6,
.rtl .center-slider .offer-slider .product-box .product-info h4 {
  text-align: center;
}
.rtl .center-slider .offer-slider .product-box .product-info .color-variant {
  text-align: center;
}
.rtl .center-slider:hover .owl-next {
  left: 20px;
  right: unset;
}
.rtl .center-slider:hover .owl-prev {
  left: unset;
  right: 20px;
}
.rtl .theme-tab .tab-title {
  -webkit-padding-start: 0;
}
.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
  padding: 15px 0 15px 15px;
}
.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .br.br-default {
  justify-content: right;
}
.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating {
  text-align: right;
}
.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
  margin-left: 35px;
  margin-right: 0;
}
.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant {
  text-align: right;
  -webkit-padding-start: 0;
}
.rtl .category-m .category-wrapper h4 {
  text-align: center;
}
.rtl .category-m .category-wrapper .category-link {
  -webkit-padding-start: 0;
}
.rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant {
  text-align: right;
  -webkit-padding-start: 0;
}
.rtl .full-box .center-slider .offer-slider .product-box .product-detail.text-center .rating .br.br-default {
  justify-content: center;
}
.rtl .compare-page .table-wrapper .table tbody tr th,
.rtl .compare-page .table-wrapper .table tbody tr td {
  text-align: right;
}
.rtl .compare-page .table-wrapper .table thead .th-compare td {
  text-align: right;
}
.rtl .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  float: right;
}
.rtl .product-wrap .product-detail .rating,
.rtl .product-wrap .product-detail h4,
.rtl .product-wrap .product-detail h6,
.rtl .product-wrap .product-detail .color-variant,
.rtl .product-wrap .product-info .rating,
.rtl .product-wrap .product-info h4,
.rtl .product-wrap .product-info h6,
.rtl .product-wrap .product-info .color-variant {
  text-align: center;
}
.rtl .product-wrap .product-detail .color-variant li,
.rtl .product-wrap .product-info .color-variant li {
  margin: 0 2px;
}
.rtl .product-wrap .product-detail.text-center .br.br-default,
.rtl .product-wrap .product-info.text-center .br.br-default {
  justify-content: center;
}
.rtl .product-wrap .product-detail.text-center h6,
.rtl .product-wrap .product-detail.text-center h4,
.rtl .product-wrap .product-info.text-center h6,
.rtl .product-wrap .product-info.text-center h4 {
  text-align: center;
}
.rtl .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}
.rtl .breadcrumb-item + .breadcrumb-item:before {
  padding-right: 0;
  padding-left: 0.5rem;
}
.rtl .top-header .header-dropdown li:first-child {
  padding-right: 0;
  padding-left: 25px;
}
.rtl .main-menu .menu-right .icon-nav .onhover-div .show-div {
  box-shadow: 0 0 8px 0 #dddddd;
}
.rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img.mr-3 {
  margin-right: unset !important;
  margin-left: 1rem !important;
}
.rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
  right: unset;
  left: 0;
}
.rtl .main-menu .menu-left .navbar i {
  float: unset;
}
.rtl .main-nav-center .toggle-nav {
  left: 175px;
  right: 0;
}
.rtl .testimonial .testimonial-slider .slick-track .owl-item .media h5,
.rtl .testimonial .testimonial-slider .slick-track .owl-item .media h6 {
  text-align: center;
}
.rtl .team .owl-next {
  right: unset;
  left: 20px;
}
.rtl .team .owl-prev {
  right: unset;
  left: 0;
}
.rtl .team h4,
.rtl .team h6 {
  text-align: center;
}
.rtl .blog-page .blog-sidebar .theme-card .recent-blog {
  text-align: right;
}
.rtl .blog-page .blog-sidebar .theme-card .recent-blog li .media img {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
  margin: 0 0 0 15px;
}
.rtl .blog-page .blog-media .blog-right ul {
  text-align: right;
}
.rtl .blog-page .blog-media .blog-right ul li + li {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #dddddd;
  padding-left: 0;
  margin-left: 0;
  border-left: none;
}
.rtl .blog-detail-page .blog-detail .post-social {
  text-align: right;
}
.rtl .blog-detail-page .blog-detail .post-social li + li {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #dddddd;
  padding-left: 0;
  margin-left: 0;
  border-left: none;
}
.rtl .blog-detail-page .blog-advance ul {
  text-align: right;
}
.rtl .blog-detail-page .comment-section li img {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .blog-detail-page .blog-contact .theme-form label,
.rtl .blog-detail-page .blog-contact .theme-form .btn-solid,
.rtl .blog-detail-page .blog-contact .theme-form .btn-outline {
  float: right;
}
.rtl .cart-section tbody tr td h2,
.rtl .cart-section tbody tr td p,
.rtl .wishlist-section tbody tr td h2,
.rtl .wishlist-section tbody tr td p {
  text-align: center;
}
.rtl .cart-section tfoot tr td:first-child,
.rtl .wishlist-section tfoot tr td:first-child {
  padding-right: 0;
  padding-left: 10px;
  text-align: left;
}
.rtl .cart-section .cart-buttons > div:last-child,
.rtl .wishlist-section .cart-buttons > div:last-child {
  text-align: left;
  padding-left: 59px;
  padding-right: 0;
}
.rtl .cart-section .cart-buttons > div:first-child .btn-solid,
.rtl .cart-section .cart-buttons > div:first-child .btn-outline,
.rtl .wishlist-section .cart-buttons > div:first-child .btn-solid,
.rtl .wishlist-section .cart-buttons > div:first-child .btn-outline {
  float: right;
}
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child {
  margin-right: 0;
  margin-left: 14px;
}
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
  border-right: none;
  border-left: 1px solid #dddddd;
  padding-right: 0;
  padding-left: 20px;
}
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
  border-right: none;
  border-left: 1px solid #dddddd;
  padding: 20px;
  background: url("/assets/images/dropdown.png") no-repeat 5%;
}
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
  border-right: none;
  border-left: 1px solid #dddddd;
}
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
  padding: 20px;
  background: url("/assets/images/dropdown.png") no-repeat 5%;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
  left: unset;
  right: 0;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count {
  border-right: none;
  border-left: 1px solid #dddddd;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count select {
  border-right: none;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul {
  float: right;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
  margin-right: 0;
  margin-left: 14px;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
  background: url("/assets/images/dropdown.png") no-repeat 5%;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  background: url("/assets/images/dropdown.png") no-repeat 5%;
}
.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  text-align: right;
  background: url("/assets/images/dropdown.png") no-repeat 5%;
}
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  text-align: right;
}
.rtl .qty-box .input-group span button {
  transform: rotate(180deg);
}
.rtl .product-pagination .product-search-count-bottom {
  padding-right: 0;
  padding-left: 15px;
}
.rtl .product-pagination .pagination .page-item a i {
  transform: rotate(180deg);
}
.rtl .custom-control-label:after {
  right: -1.5rem;
  left: unset;
}
.rtl .contact-page .theme-form label {
  float: right;
}
.rtl .contact-page .theme-form .btn-solid,
.rtl .contact-page .theme-form .btn-outline {
  float: right;
}
.rtl .contact-page .contact-right ul li {
  padding-right: 150px;
  padding-left: 0;
}
.rtl .contact-page .contact-right ul li .contact-icon {
  left: unset;
  border-left: 1px solid #dddddd;
  border-right: 0;
  right: 0;
}
.rtl .contact-page .contact-right ul li .contact-icon h6 {
  text-align: center;
}
.rtl .faq-section .accordion.theme-accordion .card .card-header button {
  float: right;
  text-align: right;
}
.rtl .faq-section .accordion.theme-accordion .card .card-header button:before {
  right: unset;
  left: 20px;
}
.rtl .login-page .theme-card {
  text-align: right;
}
.rtl .login-page .theme-card .theme-form label {
  float: right;
}
.rtl .register-page .theme-card .theme-form label {
  float: right;
}
.rtl .typography_section .typography-box .typo-content {
  text-align: right;
}
.rtl .typography_section .typography-box .typo-content .mr-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}
.rtl .typography_section .typography-box .typo-content.input_button label {
  padding-right: 5px;
  padding-left: 0;
}
.rtl .typography_section .typography-box .typo-buttons {
  text-align: right;
}
.rtl .typography_section .typography-box .headings {
  text-align: right;
}
.rtl .template-password #container #login .actions {
  text-align: center;
}
.rtl .template-password #container #login .theme-form h3 {
  text-align: center;
}
.rtl .template-password #container #login h2 {
  text-align: center;
}
.rtl .template-password #container #powered p {
  text-align: center;
}
.rtl .order-success-sec .order-detail {
  text-align: right;
}
.rtl .product-right .rating,
.rtl .product-right .color-variant {
  text-align: center;
}
.rtl .product-right.product-form-box h4,
.rtl .product-right.product-form-box h3,
.rtl .product-right.product-form-box h6 {
  text-align: center;
}
.rtl .product-right .product-icon .product-social li {
  padding-right: 0;
  padding-left: 30px;
}
.rtl .product-right .product-icon .wishlist-btn i {
  border-left: none;
  border-right: 1px solid #dddddd;
  padding-left: 0;
  margin-left: 0;
  padding-right: 10px;
  margin-right: 5px;
}
.rtl .product-right .product-icon .wishlist-btn span {
  padding-left: 0;
  padding-right: 10px;
}
.rtl .product-right .payment-card-bottom {
  text-align: right;
}
.rtl .product-right .payment-card-bottom ul li {
  padding-right: 0;
  padding-left: 10px;
}
.rtl .authentication-page .text-center h3,
.rtl .authentication-page .text-center p {
  text-align: center;
}
.rtl .single-product-tables {
  text-align: right;
}
.rtl .single-product-tables.detail-section {
  text-align: right;
}
.rtl .tab-product .theme-form input,
.rtl .product-full-tab .theme-form input {
  text-align: right;
}
.rtl .tab-product .tab-content .theme-form .media .ml-3,
.rtl .product-full-tab .tab-content .theme-form .media .ml-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}
.rtl .theme-form {
  text-align: right;
}
.rtl .collection .collection-block .collection-content h3,
.rtl .collection .collection-block .collection-content h4,
.rtl .collection .collection-block .collection-content p {
  text-align: center;
}
.rtl .rtl-product-right-slick .owl-next,
.rtl .rtl-product-slick .owl-next {
  right: unset;
  left: 1px;
  transform: rotate(180deg);
}
.rtl .rtl-product-right-slick .owl-prev,
.rtl .rtl-product-slick .owl-prev {
  left: unset;
  right: 1px;
  transform: rotate(180deg);
}
.rtl .rtl-product-right-slick:hover .owl-next,
.rtl .rtl-product-slick:hover .owl-next {
  right: unset;
  left: 20px;
}
.rtl .rtl-product-right-slick:hover .owl-prev,
.rtl .rtl-product-slick:hover .owl-prev {
  left: unset;
  right: 20px;
}
.rtl .collection-wrapper .product-right .product-buttons {
  text-align: center;
}
.rtl .collection-wrapper .product-right .size-box {
  text-align: center;
}
.rtl .collection-wrapper .product-right .size-box ul li {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .collection-wrapper .product-right .product-description h6 span {
  float: left;
}
.rtl .collection-mobile-back span i {
  float: left;
}
.rtl .modal-header .close {
  padding: unset;
  margin: unset;
}
.rtl .collection-collapse-block .collapse-block-title:after {
  left: -3px;
  right: unset;
}
.rtl .collection-filter-block .product-service .media .media-body {
  padding-right: 10px;
  padding-left: 0;
}
.rtl .collection-sidebar-banner {
  text-align: right;
}
.rtl .tab-border .nav-border {
  border-right: 0;
  border-left: 1px solid #dddddd;
}
.rtl .product-pagination .pagination .page-item.active a {
  border-right: none;
}
.rtl .custom-control {
  padding-left: 0;
  padding-right: 1.5rem;
}
.rtl .collection-collapse-block .collection-collapse-block-content .color-selector ul {
  text-align: right;
}
.rtl .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
  text-align: right;
}
.rtl .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  padding-right: 25px;
  padding-left: 0;
}
.rtl .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
  right: unset;
  left: 17px;
  padding-right: 0;
  padding-left: 10px;
}
.rtl .theme-modal .modal-dialog .quick-view-modal button.close {
  left: 10px;
  right: 0;
}
.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
  text-align: center;
}
.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 i {
  margin-left: 8px;
  margin-right: 0;
}
.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6,
.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h4 {
  text-align: center;
}
.rtl .custom-control-label:before {
  right: -1.5rem;
  left: 0;
}
.rtl .product-wrapper-grid.list-view .product-box .product-detail {
  padding-right: 15px !important;
  padding-left: 0 !important;
}
.rtl .top-header .header-contact {
  text-align: right;
}
.rtl .top-header .header-contact li {
  padding-right: 0;
  padding-left: 25px;
}
.rtl .top-header .header-contact li i {
  padding-right: 0;
  padding-left: 10px;
}
.rtl .top-header .header-dropdown {
  text-align: left;
}
.rtl .top-header .header-dropdown .onhover-show-div li {
  padding-right: 0;
}
.rtl .top-header .header-dropdown li i {
  padding-right: 0;
  padding-left: 5px;
}
.rtl .main-menu .menu-right {
  float: left;
}
.rtl .search-overlay > div .overlay-content button {
  right: unset;
  left: 10px;
}
.rtl .footer-theme .sub-title {
  text-align: right;
}
.rtl .footer-theme .sub-title li a:before {
  right: 0;
}
.rtl .footer-theme .sub-title .contact-list li {
  padding-left: 0;
  padding-right: 25px;
}
.rtl .footer-theme .sub-title .contact-list i {
  left: unset;
  right: 0;
}
.rtl .footer-logo {
  text-align: right;
}
.rtl .footer-social li,
.rtl .social-white li {
  padding-right: 0;
  padding-left: 45px;
}
.rtl .sub-footer .payment-card-bottom li {
  padding-left: 0;
  padding-right: 15px;
}
.rtl .footer-theme2 .footer-block h4,
.rtl .footer-theme2 .footer-block h2,
.rtl .footer-theme2 .footer-title h4,
.rtl .footer-theme2 .footer-title h2 {
  text-align: center;
}
.rtl .footer-theme2 .footer-logo {
  text-align: center;
}
.rtl .footer-theme2 .social-white li {
  padding-left: 22px;
  padding-right: 22px;
}
.rtl .dark-layout h2 {
  text-align: center;
}
.rtl .dark-layout .footer-contant p {
  text-align: center;
}
.rtl .subscribe-form.classic-form .form-control {
  margin-left: 0;
  margin-right: 15px;
}
.rtl .rtl-text .product-right .color-variant,
.rtl .rtl-text .product-right .size-box,
.rtl .rtl-text .product-right .product-buttons,
.rtl .rtl-text .product-right .border-product {
  text-align: right;
}
.rtl .search-block .form-header .input-group i {
  padding-right: 0;
  padding-left: 10px;
}
.rtl .payment-box .payment-options li .radio-option {
  text-align: right;
}
.rtl .payment-box .payment-options li .radio-option label {
  padding-right: 30px;
  padding-left: 0;
}
.rtl .payment-box .payment-options li .radio-option input[type=radio] {
  right: 0;
  left: unset;
}
.rtl .order-box .total li .count {
  text-align: right;
}
.rtl .order-box .sub-total .shipping {
  text-align: right;
}
.rtl .order-box .sub-total .shopping-option label {
  padding-left: 0;
  padding-right: 10px;
}
.rtl .order-box .sub-total li .count {
  text-align: right;
}
.rtl .order-box .qty li span {
  text-align: right;
}
.rtl .order-box .title-box span {
  text-align: right;
}
.rtl .error-section h1,
.rtl .error-section h2 {
  text-align: center;
}
.rtl .dashboard .box .box-title > a {
  right: unset;
  left: 0;
}
.rtl .dashboard .box address {
  text-align: right;
}
.rtl .success-text h2,
.rtl .success-text p {
  text-align: center;
}
.rtl .product-order .product-order-detail {
  text-align: right;
}
.rtl .product-order .final-total h3 span {
  float: left;
}
.rtl .delivery-sec h2,
.rtl .delivery-sec h3 {
  text-align: center;
}

/*=====================
  Fashion-2 Demo CSS
==========================*/
.box-layout-body {
  background-color: #f9f9f9;
}
.box-layout-body .box-layout-header {
  background-color: #ffffff;
}
.box-layout-body .box-layout {
  margin-top: -70px;
  margin-bottom: 5px;
  box-shadow: 0 0 8px 0 #ddd;
  position: relative;
  background-color: white;
}
.box-layout-body .box-layout .full-banner {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 200px;
  padding-bottom: 200px;
}

/*=====================
  Portfolio CSS
==========================*/
.portfolio-section {
  padding-bottom: 40px;
}
.portfolio-section .article-title {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  color: white;
  transition: all 0.5s ease;
  text-align: center;
  float: left;
  font-size: 18px;
  line-height: 24px;
}
.portfolio-section .article-title a {
  color: white;
}
.portfolio-section .isotopeSelector {
  float: left;
  width: 100%;
  position: relative;
  animation: zoomIn 0.4s linear;
}
.portfolio-section .isotopeSelector .overlay {
  position: relative;
  border: 1px solid #dddddd;
}
.portfolio-section .isotopeSelector img {
  width: 100%;
  height: auto;
}
.portfolio-section .isotopeSelector {
  margin-bottom: 30px;
}
.portfolio-section .isotopeSelector img {
  transition: all 0.4s ease;
}
.portfolio-section .isotopeSelector .overlay-background {
  transform: scale(0);
  transition: all 0.4s ease;
}
.portfolio-section .isotopeSelector .overlay-background i {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 10px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  color: var(--theme-deafult);
  font-size: 22px;
  display: none;
  transition: all 0.4s ease;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.portfolio-section .isotopeSelector:hover img {
  transform: scale(1.2) rotate(2deg);
  transition: all 0.4s ease;
}
.portfolio-section .isotopeSelector:hover .overlay-background {
  transform: scale(1);
  border: 3px solid white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(var(--theme-deafult), 0.2);
  transition: all 0.4s ease;
}
.portfolio-section .isotopeSelector:hover .overlay-background i {
  display: flex;
  transition: all 0.4s ease;
}
.portfolio-section .border-portfolio {
  margin: 3px;
  overflow: hidden;
}
.portfolio-section #form1 {
  padding-bottom: 20px;
}
.portfolio-section .filter-button {
  padding: 0 24px;
  font-size: 20px;
  line-height: 35px;
  border: none;
  background-color: white;
}
.portfolio-section .filter-button.active {
  background-color: var(--theme-deafult);
  color: #ffffff;
}
.portfolio-section.fullwidth-portfolio .isotopeSelector {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 14px;
}
.portfolio-section.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center;
}
.portfolio-section.metro-section .product-box .product-detail h6 {
  color: #525252;
  padding-top: 0;
  margin-top: -2px;
}
.portfolio-section.metro-section .product-box .product-detail h4 {
  font-weight: 400;
  color: #000000;
}
.portfolio-section.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1;
}
.portfolio-section.metro-section .product-box .cart-wrap button {
  border: 1px solid #ffffff;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}
.portfolio-section.metro-section .product-box .cart-wrap button i {
  padding: 7px;
  color: #333333;
}
.portfolio-section.metro-section .product-box .cart-wrap a i {
  border-radius: 100%;
  border: 1px solid #ffffff;
  margin: 5px 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 7px;
  color: #333333;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolio-section.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px;
}
.portfolio-section.metro-section .product-box:hover .cart-wrap button {
  animation: none;
}
.portfolio-section.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1;
}
.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInDown 500ms ease-in-out;
}
.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInDown 700ms ease-in-out;
}
.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInDown 1000ms ease-in-out;
}

.filter-section .filter-container {
  text-align: center;
  padding: 0 0 20px;
}
.filter-section .filter-container ul.filter > li {
  padding: 0 25px;
}
.filter-section .filter-container ul.filter > li > a {
  color: var(--theme-deafult);
  font-size: 20px;
  line-height: 40px;
}
.filter-section .filter-container ul.filter > li > a:hover, .filter-section .filter-container ul.filter > li > a:focus {
  text-decoration: none;
  color: var(--theme-deafult);
  outline: none;
}
.filter-section .filter-container ul.filter > li.active {
  background-color: var(--theme-deafult);
}
.filter-section .filter-container ul.filter > li.active a {
  color: #ffffff;
}
.filter-section .filter-container ul.filter > li span {
  color: #ddd;
  font-size: 20px;
  display: inline-block;
  margin: 0 5px;
}
.filter-section .filter-container .filter > li {
  padding: 0;
  margin: 0;
}
.filter-section .title1 .title-inner1 {
  margin-top: -5px;
}
.filter-section h1 {
  text-align: center;
}

.demo-colored-wrap {
  background: #ecf0f1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.offer-box {
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  display: flex;
  left: -415px;
  transition: all 0.5s ease;
  z-index: 9;
}
.offer-box img {
  margin-right: 18px;
}
.offer-box.toggle-cls {
  left: 0;
  transition: all 0.5s ease;
}
.offer-box .heading-right h3 {
  position: absolute;
  top: 50%;
  right: 0;
  width: 230px;
  height: 36px;
  line-height: 36px;
  letter-spacing: 5px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  transform: translate(50%, -50%) rotate(-90deg);
  text-align: center;
  background-color: var(--theme-deafult);
  margin-bottom: 0;
  cursor: pointer;
}

/*=====================
    electronic demo CSS start
==========================*/
.layout-8 {
  padding: 0 80px;
}
.layout-8 .layout-8-bg {
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  animation: 10s linear 0s normal none infinite animate;
}
.layout-8 .layout-8-bg .layout7-product .product-box .details-product {
  opacity: 1;
}
.layout-8 .layout-8-bg .layout7-product .product-box:hover .details-product {
  animation: none;
}

.white-bg {
  background-color: #ffffff;
}

@keyframes animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 500px;
  }
}
/*=====================
    pets demo CSS start
==========================*/
.pets-box .product-box .img-wrapper .cart-info {
  position: absolute;
  padding: 10px;
  border: none;
  transform: perspective(500px) rotateX(90deg);
  bottom: 0;
  z-index: 1;
}
.pets-box .product-box .img-wrapper .cart-info:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-deafult);
  opacity: 0.3;
  z-index: -1;
}
.pets-box .product-box .img-wrapper .cart-info i {
  padding: 5px;
  margin: 0 3px;
  background-color: white;
}
.pets-box .product-box .img-wrapper .cart-info a {
  border-left: none;
}
.pets-box .product-box .product-detail {
  text-align: center;
  background-color: #fafafa;
}
.pets-box .product-box .product-detail .rating {
  margin-top: 0;
  padding-top: 15px;
}
.pets-box .product-box:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}
.pets-box .product-box:hover .cart-info {
  transform: perspective(500px) rotateX(0deg);
}
.pets-box .br.br-default {
  margin: 0 0 15px 0;
  justify-content: center;
  display: flex;
}
.pets-box .product-m .owl-item .img-wrapper {
  margin: 0;
}

.small-slider .home-slider .home {
  height: 55vh;
}
.small-slider .home-slider .home .slider-contain {
  height: 55vh;
}
.small-slider .home-slider .home .slider-contain h4,
.small-slider .home-slider .home .slider-contain h1 {
  color: #000000;
}

.banner-6 .collection-banner .contain-banner.banner-3 h2 {
  color: #222222;
}

.absolute_banner {
  margin-bottom: 22px;
}
.absolute_banner .collection-banner {
  overflow: unset;
}
.absolute_banner .collection-banner .absolute-contain {
  position: absolute;
  background-color: #ffffff;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  min-width: 85%;
  text-align: center;
  transition: all 0.5s ease;
}
.absolute_banner .collection-banner .absolute-contain h3 {
  color: var(--theme-deafult);
  text-transform: capitalize;
  margin-top: -5px;
  font-weight: 700;
}
.absolute_banner .collection-banner .absolute-contain h4 {
  color: #000000;
  margin-bottom: 0;
}
.absolute_banner .collection-banner:hover img {
  transform: none;
}

.banner-top-cls {
  margin-top: 30px;
}

.j-box .product-box {
  width: auto;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 10px;
}
.j-box .product-box .cart-info {
  display: flex;
  left: 0;
  background-color: rgba(250, 250, 250, 0.84);
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  bottom: -30px;
  transition: all 0.5s ease;
}
.j-box .product-box .cart-info a {
  border-left: 1px solid #dddddd;
}
.j-box .product-box .cart-info a i {
  padding: 12px;
}
.j-box .product-box .product-detail {
  padding-left: 15px;
  padding-bottom: 15px;
  background-color: #fafafa;
}
.j-box .product-box .product-detail .rating {
  margin-top: 0;
  padding-top: 15px;
}
.j-box .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 0;
}
.j-box .product-box:hover .img-wrapper .cart-info button {
  animation: none;
}
.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  animation: none;
}
.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  animation: none;
}
.j-box .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  animation: none;
}

.pet-parallax {
  position: relative;
}
.pet-parallax .pet-decor {
  position: absolute;
  left: 110px;
  bottom: -164px;
}
.pet-parallax .banner-contain h4,
.pet-parallax .banner-contain h3,
.pet-parallax .banner-contain p {
  color: #212121;
}
.pet-parallax .banner-contain p {
  max-width: 75%;
  margin: 0 auto;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: 0.04em;
}
.pet-parallax .banner-contain h4 {
  letter-spacing: 0.05em;
  padding-top: 0 !important;
  margin-bottom: 0;
  line-height: 1.3;
  margin-top: -7px;
}
.pet-parallax .banner-contain h3 {
  margin: 15px 0;
  font-size: 48px;
}
.pet-parallax .full-banner {
  padding-top: 130px;
  padding-bottom: 130px;
}

footer.pet-layout-footer .white-layout {
  background-color: #f5e1d0;
}
footer.pet-layout-footer .footer-brand-logo {
  margin-bottom: 20px;
}
footer.pet-layout-footer .sub-footer.black-subfooter {
  background-color: var(--theme-deafult);
}
footer.pet-layout-footer .sub-footer p {
  padding: 15px 0;
  color: #ffffff;
}
footer.pet-layout-footer .social-white {
  display: block;
  margin-top: 40px;
  margin-bottom: 30px;
}

.form_search {
  height: 45px;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 525px;
  background-color: #f5f5f5;
  border-radius: 25px;
}
.form_search input {
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: darkgrey;
  border: none;
  outline: none;
  background: none;
  padding: 0 10px;
}
.form_search button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
}
.form_search button i {
  font-size: 18px;
  color: var(--theme-deafult);
}

.blog.blog_box .blog-details {
  text-align: left;
  padding: 0;
}
.blog.blog_box .blog-details p {
  font-size: 14px;
  text-transform: none;
  color: #333333;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 3px;
}
.blog.blog_box .blog-details a p {
  color: #40494f;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 10px;
}
.blog.blog_box .blog-details .read-cls {
  text-transform: capitalize;
  font-weight: 700;
  color: #000000;
}

/*=====================
    Metro layout  CSS start
==========================*/
.header-metro {
  position: absolute;
  width: 100%;
}
.header-metro .top-header {
  background-color: transparent;
  z-index: 9;
  position: relative;
}
.header-metro .metro {
  background-color: white;
  z-index: 9;
  position: relative;
}

.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center;
}
.metro-section .product-box .product-detail h6 {
  color: #525252;
  padding-top: 0;
  margin-top: -2px;
}
.metro-section .product-box .product-detail h4 {
  font-weight: 400;
  color: #000000;
}
.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1;
}
.metro-section .product-box .cart-wrap button {
  border: 1px solid #ffffff;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}
.metro-section .product-box .cart-wrap button i {
  padding: 7px;
  color: #333333;
}
.metro-section .product-box .cart-wrap a i {
  border-radius: 100%;
  border: 1px solid #ffffff;
  margin: 5px 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 7px;
  color: #333333;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px;
}
.metro-section .product-box:hover .cart-wrap button {
  animation: none;
}
.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1;
}
.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInDown 500ms ease-in-out;
}
.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInDown 700ms ease-in-out;
}
.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInDown 1000ms ease-in-out;
}

/*=====================
    GYM layout  CSS start
==========================*/
.gym-slider .home-slider .home {
  height: 85vh;
}
.gym-slider .home-slider .home .slider-contain {
  height: 85vh;
}
.gym-slider .home-slider .home .slider-contain h4,
.gym-slider .home-slider .home .slider-contain h1 {
  color: #ffffff;
}

.metro-slider .home-slider .home {
  background-position-y: 70%;
}
.metro-slider .home-slider .home .slider-contain {
  margin-top: 75px;
}
.metro-slider .home-slider .home .slider-contain h4,
.metro-slider .home-slider .home .slider-contain h1 {
  color: #000000;
}

.gym-parallax .title1 h2 {
  color: white;
}
.gym-parallax .product-para p {
  color: white;
}
.gym-parallax .instagram-box .overlay {
  background: linear-gradient(-180deg, rgba(var(--theme-gradient1), 0.8) 0%, rgba(var(--theme-gradient2), 0.8) 99%);
}

.gym-product {
  text-align: center;
}
.gym-product .product-box {
  background-color: white;
  padding: 15px;
  box-shadow: 0 0 8px 0 #ddd;
  transition: all 0.5s ease;
}
.gym-product .product-box .img-wrapper {
  position: unset;
}
.gym-product .product-box .img-wrapper .cart-info {
  z-index: 1;
  display: flex;
  left: 0;
  height: 100%;
  align-items: center;
  bottom: 0;
  top: 0;
}
.gym-product .product-box .img-wrapper .cart-info i {
  background: linear-gradient(180deg, var(--theme-gradient1) 0%, var(--theme-gradient2) 99%);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  margin: 0 5px;
  transition: all 0.5s ease;
}
.gym-product .product-box .img-wrapper .cart-info button:hover i,
.gym-product .product-box .img-wrapper .cart-info a:hover i {
  color: white;
}
.gym-product .product-box .product-detail h4 {
  color: #222222 !important;
}
.gym-product .product-box .product-detail h6 {
  color: #777777;
}
.gym-product .product-box .br.br-default {
  display: flex;
  justify-content: center;
}
.gym-product .product-box:hover {
  transform: scale(1.02);
  transition: all 0.5s ease;
}
.gym-product .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
}
.gym-product .product-box:hover .img-wrapper .cart-info button {
  animation: fadeInUp 300ms ease-in-out;
}
.gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  animation: fadeInUp 500ms ease-in-out;
}
.gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  animation: fadeInUp 700ms ease-in-out;
}
.gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  animation: fadeInUp 1000ms ease-in-out;
}
.gym-product .partition-cls > div:nth-child(-n+4) {
  margin-bottom: 30px;
}

.gym-blog .blog-details {
  text-align: left;
  padding-left: 0;
}
.gym-blog .blog-details p {
  margin-bottom: 3px;
}
.gym-blog hr.style1 {
  margin-left: 0;
}

.gym-banner .collection-banner .contain-banner h4,
.gym-banner .collection-banner .contain-banner h2 {
  color: #ffffff;
}

.bg-img-gym {
  background-image: url("/assets/images/gym-bg.jpg");
}
.bg-img-gym .dark-layout {
  background-color: transparent;
}
.bg-img-gym .footer-theme2 p {
  color: #ffffff;
}
.bg-img-gym .footer-theme2 .contact-details li {
  color: #ffffff;
}
.bg-img-gym .footer-theme2 .contact-details li a {
  color: #ffffff;
}
.bg-img-gym .footer-theme2 .footer-link a,
.bg-img-gym .footer-theme2 .footer-link-b a {
  color: #ebebeb;
}
.bg-img-gym .sub-footer.darker-subfooter {
  background-color: black;
}
.bg-img-gym .sub-footer.darker-subfooter p {
  color: #ffffff;
}

.tap-top.gradient-top {
  background: linear-gradient(180deg, var(--theme-gradient1) 0%, var(--theme-gradient2) 99%);
}

.banner-furniture {
  padding-left: 15px;
  padding-right: 15px;
}
.banner-furniture .collection-banner .contain-banner.banner-3 h2 {
  color: #222222;
  margin-bottom: 0;
}
.banner-furniture .collection-banner .contain-banner.banner-3 h4 {
  color: var(--theme-deafult);
}

.gym-slider .home-slider .home {
  height: 85vh;
}
.gym-slider .home-slider .home .slider-contain {
  height: 85vh;
}
.gym-slider .home-slider .home .slider-contain h4,
.gym-slider .home-slider .home .slider-contain h1 {
  color: #ffffff;
}

.header-gym {
  position: absolute;
  width: 100%;
  z-index: 2;
  background-color: transparent;
}
.header-gym .cart_qty_cls {
  top: 11%;
}
.header-gym .main-navbar .nav-menu > li > a .lable-nav {
  top: 2px;
}
.header-gym .main-menu {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 15px;
  z-index: 1;
}
.header-gym .main-menu .menu-left .navbar {
  padding: 20px 45px 20px 0;
}
.header-gym .main-menu .menu-left .navbar i {
  color: white;
}
.header-gym .main-menu .brand-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}
.header-gym .main-menu .onhover-div .show-div {
  top: 70px;
}
.header-gym .sidebar-back i {
  color: black;
}
.header-gym .sm-horizontal > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
}
.header-gym .sm-horizontal > li > a:hover, .header-gym .sm-horizontal > li > a:active, .header-gym .sm-horizontal > li > a:focus, .header-gym .sm-horizontal > li > a.highlighted {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
}
.header-gym .onhover-div {
  padding-top: 20px;
  padding-bottom: 20px;
}
.header-gym .onhover-div > div img {
  filter: brightness(54);
}
.header-gym .top-header {
  z-index: 1;
  position: relative;
  background: transparent;
}
.header-gym .top-header .header-contact li {
  color: #fff;
}
.header-gym .top-header .header-contact li i {
  color: #fff;
}
.header-gym .top-header .header-dropdown li {
  color: #ffffff;
}
.header-gym .top-header .header-dropdown li a {
  color: #ffffff;
}
.header-gym .top-header .header-dropdown li a i {
  color: #ffffff;
  padding-right: 8px;
}
.header-gym .top-header.blue-layout {
  border-bottom: 1px solid #223b7d;
}

.btn-gradient {
  background: var(--theme-deafult);
  background: linear-gradient(180deg, #01effc 0%, var(--theme-gradient2) 99%);
  transition: background 300ms ease-in-out;
  background-size: 300% 100%;
  border: none;
}
.btn-gradient:hover {
  background: linear-gradient(-180deg, var(--theme-gradient2) 0%, #01effc 99%);
  transition: background 300ms ease-in-out;
  color: white;
}

/*=====================
    Left Sidebar CSS start
==========================*/
.left-header .top-header {
  padding-left: 300px;
  background-color: #ffffff;
}
.left-header .top-header .header-dropdown .mobile-wishlist img,
.left-header .top-header .header-dropdown .mobile-account img {
  display: block;
}
.left-header .top-header .header-dropdown .mobile-wishlist i,
.left-header .top-header .header-dropdown .mobile-account i {
  display: none;
}
.left-header .onhover-div {
  padding-top: 15px;
  padding-bottom: 15px;
}
.left-header .onhover-div > div img {
  width: 20px;
  height: auto;
}
.left-header .onhover-div .show-div {
  top: 56px;
}
.left-header .sidenav {
  left: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
}
.left-header .sidenav .sidebar-back {
  display: none;
}
.left-header .sidenav nav {
  background-color: #ffffff;
}
.left-header .sidenav .pixelstrap > li > a {
  padding: 10px 0 20px 0;
}
.left-header .sidenav .pixelstrap > li > a:hover, .left-header .sidenav .pixelstrap > li > a:focus {
  padding: 10px 0 20px 0;
  background-color: #ffffff;
}
.left-header .sidenav .pixelstrap > li .lable-nav {
  top: -12px;
  background-color: #eeeeee;
}
.left-header .sidenav .pixelstrap > li .lable-nav:before {
  border-top: 5px solid #ff4c3b;
}
.left-header .sidenav .pixelstrap > li .lable-nav.grey-lable {
  background-color: #4d6171;
}
.left-header .sidenav .pixelstrap > li .lable-nav.grey-lable:before {
  border-top: 5px solid #4d6171;
}
.left-header .sidenav .left-sidebar_search {
  width: 100%;
  background-color: #ffffff;
}
.left-header .sidenav .left-sidebar_search input {
  height: 55px;
  width: 95%;
  border: none;
  padding-left: 15px;
  background-color: transparent;
}
.left-header .sidenav .left-sidebar_search .btn-search {
  position: absolute;
  right: 10px;
  top: 20px;
  border: none;
  background-color: transparent;
}
.left-header .sidenav .left-sidebar_center {
  padding: 40px 0 30px 30px;
  background-color: #ffffff;
}
.left-header .sidenav .bottom-section {
  padding: 70px 25px;
}
.left-header .sidenav .bottom-section .icon_settings li {
  display: inline-block;
}
.left-header .sidenav .bottom-section .icon_settings .shopping-cart {
  position: relative;
}
.left-header .sidenav .bottom-section .icon_settings .shopping-cart .shopping-count {
  width: 18px;
  height: 18px;
  background-color: #ff4c3b;
  color: white;
  position: absolute;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -7px;
  right: 7px;
}
.left-header .sidenav .bottom-section ul li {
  display: inline-block;
  padding: 8px 15px 8px 0;
}
.left-header .sidenav .bottom-section ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #222222;
}
.left-header .sidenav .bottom-section ul li a i {
  font-size: 18px;
  padding-right: 5px;
  color: #222222;
}
.left-header .sidenav .bottom-section .leftside_setting .language,
.left-header .sidenav .bottom-section .leftside_setting .currency {
  display: flex;
}
.left-header .sidenav .bottom-section .leftside_setting .language h5,
.left-header .sidenav .bottom-section .leftside_setting .currency h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  color: #999;
  margin-top: 0;
}
.left-header .sidenav .bottom-section .leftside_setting .language select,
.left-header .sidenav .bottom-section .leftside_setting .currency select {
  margin-left: 10px;
  border: none;
  outline: none;
  text-transform: capitalize;
  color: #222222;
}
.left-header .sidenav .bottom-section .leftside_setting h5 {
  margin-top: 10px;
}
.left-header .sidenav .leftside_social {
  position: relative;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #dddddd;
}
.left-header .sidenav .leftside_social .footer-social {
  margin-top: 0;
}
.left-header .sidenav .leftside_social .footer-social i {
  font-size: 18px;
}
.left-header .sidenav .leftside_social .footer-social li {
  padding: 10px;
}
.left-header .sidenav .leftside_social .call_us h5 {
  text-align: center;
}
.left-header .sidenav .leftside_social .call_us h5 span {
  display: block;
}
.left-header .main-menu {
  justify-content: flex-end;
}
.left-header .main-menu .brand-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed #dddddd;
}
.left-header .main-menu .menu-right .header-dropdown > li img {
  width: 20px;
  height: auto;
}
.left-header .main-menu .menu-right .icon-nav .onhover-div > img {
  width: 20px;
  height: auto;
}
.left-header .main-menu .menu-left .navbar {
  display: none;
}
.left-header .main-menu .menu-left .mobile-logo {
  display: none;
}
.left-header .search-overlay {
  padding-left: 300px;
}

.left-sidebar_space {
  padding-left: 300px;
}

.header-6 .top-header {
  background-color: var(--theme-deafult);
}
.header-6 .top-header .header-contact li {
  color: #ffffff;
}
.header-6 .top-header .header-contact li i {
  color: #ffffff;
}
.header-6 .top-header .header-dropdown li {
  color: #ffffff;
}
.header-6 .top-header .header-dropdown li a {
  color: #ffffff;
}
.header-6 .top-header .header-dropdown li a i {
  color: white;
}
.header-6 .mobile-search {
  display: none;
}

.form_search {
  height: 45px;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 525px;
  background-color: #f5f5f5;
  border-radius: 25px;
}
.form_search input {
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: darkgrey;
  border: none;
  outline: none;
  background: none;
  padding: 0 10px;
}
.form_search button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
}
.form_search button i {
  font-size: 18px;
  color: var(--theme-deafult);
}

.header-tools {
  position: absolute;
  width: 100%;
  top: 35px;
  z-index: 2;
  background-color: transparent;
}
.header-tools .container {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
}
.header-tools .top-header {
  background-color: transparent;
}
.header-tools .top-header .container {
  background-color: var(--theme-deafult);
}
.header-tools .top-header .header-contact {
  padding: 10px 0;
}
.header-tools .top-header .header-contact li {
  color: #ffffff;
}
.header-tools .top-header .header-dropdown li {
  color: #ffffff;
  padding: 10px 25px;
  padding-right: 0;
}
.header-tools .top-header .header-dropdown li a {
  color: #ffffff;
}
.header-tools .top-header .header-dropdown li a i {
  color: #ffffff;
}
.header-tools .top-header .header-dropdown li:hover i {
  color: #ffffff;
}
.header-tools .logo-menu-part {
  background-color: #fff;
  border-bottom: 2px solid var(--theme-deafult);
}
.header-tools .main-menu .menu-left .navbar {
  padding: 25px 45px 25px 0;
}
.header-tools .main-menu .brand-logo {
  padding-top: 25px;
  padding-bottom: 25px;
}
.header-tools .sm-horizontal > li > a {
  padding-top: 25px;
  padding-bottom: 25px;
}
.header-tools .sm-horizontal > li > a:hover, .header-tools .sm-horizontal > li > a:active, .header-tools .sm-horizontal > li > a:focus, .header-tools .sm-horizontal > li > a.highlighted {
  padding-top: 25px;
  padding-bottom: 25px;
}
.header-tools .sm-horizontal li .lable-nav {
  top: 5px;
}
.header-tools .onhover-div {
  padding-top: 25px;
  padding-bottom: 25px;
}
.header-tools .cart_qty_cls {
  top: 16%;
  right: -14px;
}

.tools-bg {
  background-color: #f5f5f5;
}
.tools-bg section {
  background-color: #f5f5f5;
}

.tools-parallax.small-slider .home-slider .home .slider-contain h4,
.tools-parallax.small-slider .home-slider .home .slider-contain h1 {
  color: #ffffff;
}

.tools-parallax-product.full-banner {
  padding-top: 110px;
  padding-bottom: 110px;
}
.tools-parallax-product .tools-description h3 {
  color: #ffffff;
  text-transform: uppercase;
  margin-top: -5px;
}
.tools-parallax-product .tools-description .tools-form .search-box {
  width: 75%;
  margin: 20px 0;
}
.tools-parallax-product .tools-description .tools-form .search-box select {
  text-transform: capitalize;
}
.tools-parallax-product .tools-description .tools-form .btn-find {
  width: 75%;
  padding: 7px 29px;
  background-size: 1000px;
}
.tools-parallax-product .tools-grey .owl-prev,
.tools-parallax-product .tools-grey .owl-next {
  transform: scale(1.5);
}

.tools_slider .home-slider .home .container {
  position: relative;
}
.tools_slider .home-slider .home .tools-parts {
  position: absolute;
  top: 0;
  right: 10%;
  display: flex;
  align-items: center;
  height: 75vh;
  margin-top: 50px;
}
.tools_slider .home-slider .home .tools-parts1 {
  position: absolute;
  top: 0;
  right: 20%;
  display: flex;
  align-items: center;
  height: 75vh;
  margin-top: 50px;
}
.tools_slider .home-slider .slider-contain {
  margin-top: 70px;
  margin-left: 150px;
}
.tools_slider .home-slider .slider-contain h4,
.tools_slider .home-slider .slider-contain h1 {
  color: #ffffff;
}

.tools-grey .product-m .owl-item .img-wrapper {
  margin: 0;
}
.tools-grey .product-box {
  width: auto;
  display: inline-block;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  margin: 0 10px;
}
.tools-grey .product-box .img-wrapper .lable-block {
  transition: all 0.5s ease;
}
.tools-grey .product-box .cart-info {
  display: flex;
  left: 0;
  bottom: -30px;
  transition: all 0.5s ease;
}
.tools-grey .product-box .cart-info .mobile-quick-view {
  display: none;
}
.tools-grey .product-box .cart-info a {
  background-color: #eef0f1;
  border-radius: 5px;
}
.tools-grey .product-box .cart-info a i {
  color: #555555;
}
.tools-grey .product-box .cart-info a:hover {
  background-color: var(--theme-deafult);
  transition: all 0.5s ease;
}
.tools-grey .product-box .cart-info a:hover i {
  color: #ffffff;
  transition: all 0.5s ease;
}
.tools-grey .product-box .cart-info button {
  width: 55%;
  background-color: #eef0f1;
  color: #555555;
  border-radius: 5px;
  margin: 0 5px;
  transition: all 0.5s ease;
}
.tools-grey .product-box .cart-info button i {
  display: none;
}
.tools-grey .product-box .cart-info button:hover {
  background-color: var(--theme-deafult);
  color: #ffffff;
  transition: all 0.5s ease;
}
.tools-grey .product-box .quick-view-part {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  background-color: var(--theme-deafult);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
}
.tools-grey .product-box .quick-view-part i {
  font-size: 18px;
  color: #ffffff;
}
.tools-grey .product-box .product-info {
  padding-left: 15px;
  padding-bottom: 15px;
  background-color: #ffffff;
  border-top: 1px solid rgba(221, 221, 221, 0.2);
}
.tools-grey .product-box .product-info .rating {
  margin-top: 0;
  padding-top: 15px;
}
.tools-grey .product-box .br.br-default {
  display: flex;
  justify-content: center;
}
.tools-grey .product-box:hover .img-wrapper .front img {
  opacity: 0.05;
  transition: all 0.5s ease;
}
.tools-grey .product-box:hover .img-wrapper .cart-info {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 10px;
}
.tools-grey .product-box:hover .img-wrapper .cart-info button {
  animation: none;
}
.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
  animation: none;
}
.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
  animation: none;
}
.tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
  animation: none;
}
.tools-grey .product-box:hover .img-wrapper .lable-block {
  display: none;
  transition: all 0.5s ease;
}
.tools-grey .product-box:hover .quick-view-part {
  opacity: 1;
  transition: all 0.5s ease;
}
.tools-grey .product-box:hover .ribbon {
  display: none;
  transition: all 0.5s ease;
}
.tools-grey .ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  transition: all 0.5s ease;
}
.tools-grey .ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: var(--theme-deafult);
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 19px;
  left: -21px;
}
.tools-grey .ribbon span:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid var(--theme-deafult);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--theme-deafult);
}
.tools-grey .ribbon span:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid var(--theme-deafult);
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--theme-deafult);
}

.category-tools .category-m .category-wrapper {
  background-color: white;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.banner-tools {
  margin-top: 30px;
  border: 1px solid #dddddd;
  overflow: hidden;
}
.banner-tools img {
  transition: all 0.5s ease;
}
.banner-tools:hover img {
  transform: scale(1.05);
  transition: all 0.5s ease;
}

.tools-brand .row {
  background-color: white;
  padding: 25px 0;
  box-shadow: 0 0 8px 0 #ddd;
  margin: 0;
}

.sticky-footer {
  background-image: url("/assets/images/tools-bg.jpg");
}
.sticky-footer > section {
  background-color: transparent !important;
}
.sticky-footer > section p {
  color: #938d8c;
}
.sticky-footer > section .sub-title .footer-title.active .according-menu {
  font: normal normal normal 14px/1 FontAwesome;
}
.sticky-footer > section .sub-title .footer-title.active .according-menu.before {
  content: "\f068";
}
.sticky-footer > section .sub-title h4 {
  color: #ffffff;
}
.sticky-footer > section .sub-title li a {
  color: #938d8c;
}
.sticky-footer > section .sub-title .contact-list i {
  color: #938d8c;
}
.sticky-footer > section .sub-title .contact-list li {
  color: #938d8c;
}
.sticky-footer > section .footer-social i {
  color: #938d8c;
}
.sticky-footer > div.sub-footer {
  background-color: #141111 !important;
}
.sticky-footer > div.sub-footer p {
  color: #c0c0c0 !important;
}

.green-gradient .top-header {
  background: var(--theme-deafult);
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
}
.green-gradient .top-header .header-contact {
  padding: 10px 0;
}
.green-gradient .top-header .header-contact li {
  color: #ffffff;
}
.green-gradient .top-header .header-contact li i {
  color: #ffffff;
}
.green-gradient .top-header .header-dropdown li {
  color: #ffffff;
  padding: 10px 25px;
}
.green-gradient .top-header .header-dropdown li a {
  color: #ffffff;
}
.green-gradient .top-header .header-dropdown li a i {
  color: #ffffff;
}
.green-gradient .pixelstrap li .lable-nav {
  background: var(--theme-deafult);
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
}

.service_slide {
  position: relative;
}
.service_slide .home-slider .home {
  background-blend-mode: overlay;
}
.service_slide .home-slider .slider-contain {
  height: 60vh;
}
.service_slide .service-home {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
}
.service_slide .service-home .service-block1 {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 25px 0;
}
.service_slide .service-home .service-block1 h4 {
  margin-bottom: 0;
}
.service_slide .service-home .service-block1 p {
  text-transform: capitalize;
}
.service_slide .service-home .service-block1 svg {
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
}
.service_slide .service-home .service-block1:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.85);
}

.tab-left .theme-tab {
  display: flex;
}
.tab-left .theme-tab .left-side {
  width: 30%;
}
.tab-left .theme-tab .left-side .left-tab-title {
  margin-bottom: 38px;
}
.tab-left .theme-tab .left-side .left-tab-title h4 {
  color: #dafe69;
  margin-top: -3px;
}
.tab-left .theme-tab .left-side .left-tab-title h3 {
  color: #ffffff;
}
.tab-left .theme-tab .left-side .tab-title {
  margin-right: 100px;
  text-align: left;
}
.tab-left .theme-tab .left-side .tab-title a {
  text-transform: capitalize;
}
.tab-left .theme-tab .left-side li {
  width: 100%;
  padding: 7px 0;
  border-bottom: 1px solid #606060;
}
.tab-left .theme-tab .left-side li a {
  color: #ffffff;
}
.tab-left .theme-tab .left-side li:first-child {
  border-top: 1px solid #606060;
}
.tab-left .theme-tab .left-side li.current a {
  color: #dafe69;
}
.tab-left .theme-tab .tab-content-cls {
  width: 70%;
}

.border-box.tools-grey .ribbon span {
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
}
.border-box.tools-grey .product-box {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #dddddd;
}
.border-box.tools-grey .product-box .cart-info a:hover {
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
}
.border-box.tools-grey .product-box .cart-info button:hover {
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
}
.border-box.tools-grey .product-box .quick-view-part {
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
}

.detail-cannabis .detail_section {
  text-align: center;
}
.detail-cannabis .detail_section > div {
  box-shadow: -1px 2px 5px 1px #ececec;
  padding: 45px 25px;
  background-color: white;
}
.detail-cannabis .detail_section p {
  margin-bottom: 0;
  line-height: 24px;
  letter-spacing: 0.05em;
}
.detail-cannabis .detail_section h4 {
  font-weight: 700;
}
.detail-cannabis .detail_section svg {
  width: 50px;
  margin-bottom: 15px;
  fill: var(--theme-deafult);
}

footer.footer-5 {
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
}
footer.footer-5 .dark-layout {
  background-color: transparent;
}
footer.footer-5 .sub-footer {
  background-color: transparent;
}
footer.footer-5 .sub-footer > .container {
  border-top: 1px solid #ffffff;
}
footer.footer-5 .sub-footer p {
  color: #ffffff;
}
footer.footer-5 .footer-theme2 p {
  color: #ffffff;
}
footer.footer-5 .footer-theme2 .contact-details li {
  color: #ffffff;
}
footer.footer-5 .footer-theme2 .contact-details li a {
  color: #ffffff;
}
footer.footer-5 .footer-theme2 .subscribe-block {
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.tap-top.green-top {
  background-image: linear-gradient(to right, var(--theme-gradient1), var(--theme-gradient2));
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.quickviewm .modal-dialog .modal-content .modal-body {
  background-image: none;
}

.ng5-slider .ng5-slider-pointer {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  top: -8px !important;
  background-color: var(--theme-deafult) !important;
  z-index: 3;
  border-radius: 16px !important;
}

.ng5-slider .ng5-slider-pointer:after {
  content: "";
  width: 14px !important;
  height: 14px !important;
  position: absolute;
  top: 3px !important;
  left: 3px !important;
  border-radius: 16px !important;
  background: #fff;
}

.ng5-slider .ng5-slider-selection {
  background: var(--theme-deafult) !important;
}

.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
  background: #fff !important;
}

.collection-brand-filter::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.collection-brand-filter::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.collection-brand-filter::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.62, var(--theme-deafult)), color-stop(0.86, var(--theme-deafult)));
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.openFilterbar {
  left: -15px !important;
}

/*=====================
  theme setting box CSS start
==========================*/
.color1 {
  background: #ff4c3b;
}

.color2 {
  background: #3fdda7;
}

.color3 {
  background: #f0b54d;
}

.color4 {
  background: #e4604a;
}

.color5 {
  background: #d4b196;
}

.color6 {
  background: #866e6c;
}

.color7 {
  background: #cc2121;
}

.color8 {
  background: #dc457e;
}

.color9 {
  background: #6d7e87;
}

.color10 {
  background: #fa869b;
}

.color11 {
  background: #81ba00;
}

.color12 {
  background: #fe816d;
}

.color13 {
  background: #01effc;
}

.color14 {
  background: #5d7227;
}

.color15 {
  background: #ff9944;
}

.color16 {
  background: #5fcbc4;
}

.color17 {
  background: #e38888;
}

.color18 {
  background: #000000;
}

.color-picker {
  position: fixed;
  right: -190px;
  top: calc(38vh + 220px);
  width: 190px;
  z-index: 1;
  transition: all 0.3s ease;
}
.color-picker .settings-header {
  background: #f3f3f3;
  padding: 5px 15px;
  box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);
}
.color-picker .settings-header h3 {
  color: black;
  margin: 0;
  font-size: 20px;
  padding: 4px 9px;
}
.color-picker .section {
  background: white;
  padding: 10px;
  border: 1px solid #f3f3f3;
  overflow: auto;
}
.color-picker .section i {
  font-size: 16px;
  margin-right: 10px;
}
.color-picker a.handle {
  position: absolute;
  right: 190px;
  width: 45px;
  height: 41px;
  transition: all ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  z-index: -1;
  text-decoration: none;
  background-color: #f9f9f9;
  box-shadow: -5.985px -0.419px 30px 0 rgba(0, 0, 0, 0.08), inset -2px 0 10px 0 rgba(0, 0, 0, 0.04);
  top: -80px;
  color: #000000;
}
.color-picker a.handle i {
  margin-left: 5px;
}
.color-picker .colors a {
  width: 32px;
  height: 32px;
  margin: 5px;
  float: left;
  transition: all ease-in 0.3s;
}
.color-picker .skin a {
  display: block;
  width: 70px;
  height: 30px;
  margin-left: 0;
  margin-top: 0;
  margin-right: 5px;
  float: left;
  text-align: center;
  line-height: 30px;
  border: 2px transparent solid;
}
.color-picker .skin a.actt {
  border: 2px white solid;
}

.dark-light {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  top: calc(50% + 34px);
  cursor: pointer;
}

.setting-sidebar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f7f7f7;
  width: 40px;
  height: 40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  transition: all 0.5s ease;
  z-index: 2;
}
.setting-sidebar i {
  animation: ring-rotate 3s infinite linear;
  font-size: 20px;
  color: #3c3c3c;
  margin-left: 10px;
}
.setting-sidebar.open-icon {
  right: 351px;
  transition: all 0.5s ease;
}

.setting_box_body {
  position: relative;
  background-color: white;
  z-index: 9;
  height: 100vh;
}

.setting-box {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 350px;
  right: -360px;
  background-color: white;
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
  transition: all 0.5s ease;
}
.setting-box ::-webkit-scrollbar {
  width: 3px;
  height: 7px;
}
.setting-box ::-webkit-scrollbar-track {
  background: #dddddd;
}
.setting-box ::-webkit-scrollbar-thumb {
  background: #888888;
}
.setting-box ::-webkit-scrollbar-thumb:hover {
  background: #555555;
}
.setting-box.opensetting {
  right: 0;
  transition: all 0.5s ease;
}
.setting-box.opensetting .overlay {
  visibility: visible;
  transition: all 0.5s ease;
}
.setting-box .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: inset 0px 0px 6px rgba(115, 115, 115, 0.09);
  background-color: #f3f3f3;
}
.setting-box .setting-title {
  padding: 20px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 0;
}
.setting-box .setting-title h4 {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
}
.setting-box .setting-title.active .according-menu {
  font: normal normal normal 14px/1 FontAwesome;
}
.setting-box .setting-title.active .according-menu:before {
  content: "\f068";
  position: absolute;
  right: 2px;
  top: 2px;
}
.setting-box .setting-title .according-menu {
  font: normal normal normal 14px/1 FontAwesome;
}
.setting-box .setting-title .according-menu:before {
  content: "\f067";
  position: absolute;
  right: 2px;
  top: 2px;
}
.setting-box .setting-contant {
  padding: 0px;
  border-bottom: 1px solid #efefef;
  max-height: 0;
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.setting-box .setting-contant.opensubmenu {
  padding: 20px;
  overflow-y: scroll;
  max-height: 500px;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.setting-box .setting-contant .setting_buttons .setting_btn {
  background-color: var(--theme-deafult);
  color: white;
  width: 100%;
}
.setting-box .setting-contant .setting_buttons li:first-child {
  width: 49%;
}
.setting-box .setting-contant .setting_buttons li.active {
  box-shadow: 0px 0px 5px 0px #929292;
}
.setting-box .setting-contant .setting_buttons li:last-child {
  margin-left: 10px;
  width: 46%;
}
.setting-box .setting-contant .color-box li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.setting-box .setting-contant .color-box li input {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 34px;
  height: 34px;
  background-color: transparent;
  margin-right: 5px;
}
.setting-box .setting-contant .color-box li span {
  text-transform: capitalize;
  margin-bottom: 2px;
  font-size: 15px;
  color: #000000;
}
.setting-box .setting-contant .color-box li.active {
  position: relative;
}
.setting-box .setting-contant .color-box li.active a {
  box-shadow: 0px 0px 5px 0px #929292;
}
.setting-box .setting-contant .color-box li.active:after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
  top: 13px;
  height: 15px;
  width: 15px;
  background-size: 80%;
  background-repeat: no-repeat;
  position: absolute;
  left: 14px;
  filter: invert(100);
}
.setting-box .buy_btn {
  padding: 20px;
}
.setting-box .buy_btn a {
  background-color: #f3f3f3;
  color: #3c3c3c;
  padding: 10px;
  transition: all 0.5s ease;
}
.setting-box .buy_btn a i {
  margin-right: 5px;
  font-size: 18px;
}
.setting-box .buy_btn a img {
  filter: grayscale(100);
}
.setting-box .overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}

.demo-section .title-text h3 {
  color: var(--theme-deafult);
  margin-bottom: 24px;
  display: inline-block;
  padding-bottom: 5px;
  font-size: 40px;
  position: relative;
}
.demo-section .title-text h3 i {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: -20px;
}
.demo-section .demo-effects {
  margin-bottom: 30px;
}
.demo-section .demo-effects h4 {
  text-transform: capitalize;
}
.demo-section .demo-effects .set-position {
  display: block;
}
.demo-section .demo-effects > div {
  text-decoration: none;
  color: black;
}
.demo-section .demo-effects > div .layout-container {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px #ececec;
}
.demo-section .demo-effects > div .layout-container.demo1 {
  background-image: url("/assets/images/demo/home/1.jpg");
}
.demo-section .demo-effects > div .layout-container.demo2 {
  background-image: url("/assets/images/demo/home/2.jpg");
}
.demo-section .demo-effects > div .layout-container.demo3 {
  background-image: url("/assets/images/demo/home/3.jpg");
}
.demo-section .demo-effects > div .layout-container.demo4 {
  background-image: url("/assets/images/demo/home/4.jpg");
}
.demo-section .demo-effects > div .layout-container.demo5 {
  background-image: url("/assets/images/demo/home/5.jpg");
}
.demo-section .demo-effects > div .layout-container.demo6 {
  background-image: url("/assets/images/demo/home/6.jpg");
}
.demo-section .demo-effects > div .layout-container.demo7 {
  background-image: url("/assets/images/demo/home/7.jpg");
}
.demo-section .demo-effects > div .layout-container.demo8 {
  background-image: url("/assets/images/demo/home/8.jpg");
}
.demo-section .demo-effects > div .layout-container.demo9 {
  background-image: url("/assets/images/demo/home/9.jpg");
}
.demo-section .demo-effects > div .layout-container.demo10 {
  background-image: url("/assets/images/demo/home/10.jpg");
}
.demo-section .demo-effects > div .layout-container.demo11 {
  background-image: url("/assets/images/demo/home/11.jpg");
}
.demo-section .demo-effects > div .layout-container.demo12 {
  background-image: url("/assets/images/demo/home/12.jpg");
}
.demo-section .demo-effects > div .layout-container.demo13 {
  background-image: url("/assets/images/demo/home/13.jpg");
}
.demo-section .demo-effects > div .layout-container.demo14 {
  background-image: url("/assets/images/demo/home/14.jpg");
}
.demo-section .demo-effects > div .layout-container.demo15 {
  background-image: url("/assets/images/demo/home/15.jpg");
}
.demo-section .demo-effects > div .layout-container.shop1 {
  background-image: url("/assets/images/demo/shop/1.jpg");
}
.demo-section .demo-effects > div .layout-container.shop2 {
  background-image: url("/assets/images/demo/shop/2.jpg");
}
.demo-section .demo-effects > div .layout-container.shop3 {
  background-image: url("/assets/images/demo/shop/3.jpg");
}
.demo-section .demo-effects > div .layout-container.product1 {
  background-image: url("/assets/images/demo/product/1.jpg");
}
.demo-section .demo-effects > div .layout-container.product2 {
  background-image: url("/assets/images/demo/product/2.jpg");
}
.demo-section .demo-effects > div .layout-container.product3 {
  background-image: url("/assets/images/demo/product/3.jpg");
}
.demo-section .demo-effects > div .layout-container.product4 {
  background-image: url("/assets/images/demo/product/4.jpg");
}
.demo-section .demo-effects > div .layout-container.product5 {
  background-image: url("/assets/images/demo/product/5.jpg");
}
.demo-section .demo-effects > div .layout-container.product6 {
  background-image: url("/assets/images/demo/product/6.jpg");
}
.demo-section .demo-effects > div .layout-container.product7 {
  background-image: url("/assets/images/demo/product/7.jpg");
}
.demo-section .demo-effects > div .demo-text h4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #000000;
}
.demo-section .demo-effects > div .demo-text h4 span {
  font-weight: 500;
}
.demo-section .demo-effects > div .demo-text .demo-btn {
  margin-top: 10px;
}
.demo-section .demo-effects > div .demo-text .demo-btn .btn {
  border: 1px solid #ddd;
  background-color: white;
  width: 90px;
  color: var(--theme-deafult);
  padding: 5px 10px;
  border-radius: 30px;
}
.demo-section .demo-effects > div .demo-text .demo-btn .btn:hover {
  background-color: var(--theme-deafult);
  color: white;
}
.demo-section .demo-effects:last-child {
  margin-bottom: 0;
}
.demo-section .demo-effects.effect-2 .layout-container {
  background-size: cover;
  background-repeat: no-repeat;
}
.demo-section .demo-effects.effect-2:hover .layout-container {
  background-position: top !important;
}
.demo-section .demo-effects.effect-3 .layout-container {
  background-size: cover;
  background-repeat: no-repeat;
}
.demo-section .demo-effects.effect-3:hover .layout-container {
  background-position: top !important;
}
.demo-section .demo-effects:hover .layout-container {
  background-position: center 100% !important;
}

@keyframes ring-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sec-position {
  position: relative;
  top: -80px;
}

#toast-container > div {
  opacity: 1;
}

.toast-container .ngx-toastr {
  border-radius: 15px;
  width: 345px;
}

.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}

.toast-success {
  background-color: #51A351 !important;
}

.toast-error {
  background-color: #BD362F !important;
}

.toast-info {
  background-color: #2F96B4 !important;
}

.toast-warning {
  background-color: #F89406 !important;
}

div#loading-bar {
  color: var(--theme-deafult) !important;
}
div#loading-bar .bar {
  background: var(--theme-deafult) !important;
}

#loading-bar-spinner {
  top: 15px !important;
  left: unset !important;
  right: 0 !important;
  margin-right: 20px;
  color: var(--theme-deafult) !important;
}
#loading-bar-spinner .spinner-icon {
  width: 16px !important;
  height: 16px !important;
}

.sidenav nav {
  overflow-y: auto;
}

.sidebar-unset {
  overflow-y: unset !important;
}

/* modal animation */
.modal-content {
  animation: modal-animation 0.5s ease;
}

@keyframes modal-animation {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}
.max-h-275 .owl-carousel .owl-item {
  max-width: 33% !important;
  max-height: 275px !important;
}

.max-h-135.max-w-210 .owl-carousel .owl-item {
  max-width: 33% !important;
  max-height: 135px !important;
}

/*=====================
    28.Dark CSS start
==========================*/
body.dark {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cfd4da;
}

.dark h1 {
  color: #ffffff;
}
.dark h2 {
  color: #f1f3f5;
}
.dark h3 {
  color: #eaedef;
}
.dark h4 {
  color: #e7eaec;
}
.dark h5 {
  color: #dee2e6;
}
.dark h6 {
  color: #cfd4da;
}
.dark p {
  color: #cfd4da;
}
.dark pre {
  color: #cfd4da;
}
.dark li {
  color: #cfd4da;
}
.dark a {
  color: #cbcbcb;
}
.dark .page-link {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .breadcrumb-section {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .light-layout {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .form-control {
  color: #cfd4da;
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border: 1px solid #404040;
}
.dark .btn-solid {
  color: #ffffff !important;
}
.dark .btn-solid:hover {
  color: #222222 !important;
}
.dark select {
  color: #cfd4da;
}
.dark option {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .sticky.fixed {
  background: #2b2b2b;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.dark .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
  box-shadow: none;
  border-color: #404040;
}
.dark .tab-product .tabs .card-header,
.dark .product-full-tab .tabs .card-header {
  background-color: #2b2b2b;
}
.dark .tab-product .tabs .card-header .nav-tabs .nav-item .nav-link,
.dark .product-full-tab .tabs .card-header .nav-tabs .nav-item .nav-link {
  color: white;
}
.dark .tab-product .tabs .card-header .nav-tabs .nav-item .nav-link.active,
.dark .product-full-tab .tabs .card-header .nav-tabs .nav-item .nav-link.active {
  color: #ff4c3b;
}
.dark .dashboardtab.card .card-header {
  background-color: #2b2b2b;
}
.dark .dashboardtab.card .card-header li a {
  background-color: #2b2b2b;
  color: white;
}
.dark .dashboardtab.card .card-header li a.active {
  color: #ff4c3b;
}
.dark .dashboardtab ul li .nav-link {
  background-color: #232323;
}
.dark .faq-section .accordion.theme-accordion .card .card-header .btn {
  background-color: #232323;
  color: #cbcbcb;
}
.dark .modal-content .modal-body {
  background-color: #232323;
}
.dark header {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark header.header-5 .pixelstrap a, .dark header.header-5 .pixelstrap:hover, .dark header.header-5 .pixelstrap:active {
  color: #222222;
}
.dark header.header-5 .main-navbar .nav-menu {
  background-color: transparent;
}
.dark header.header-5 .main-navbar .nav-menu > li > a {
  color: #222222;
}
.dark header.header-gym {
  background-color: transparent;
}
.dark header.header-gym .top-header {
  background-color: transparent;
}
.dark header.header-gym .main-navbar .nav-menu {
  background-color: transparent;
}
.dark header.header-metro .metro {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark header.header-metro .top-header .header-contact li {
  color: #999999;
}
.dark header.header-metro .top-header .header-dropdown li {
  color: #999999;
}
.dark header.header-metro .top-header .header-dropdown li a i {
  color: #999999;
}
.dark header.header-tools {
  background-color: transparent;
  transition: all 0.3s ease;
}
.dark header.header-tools .top-header {
  background-color: transparent;
}
.dark header.header-tools .top-header .container {
  background-color: var(--theme-deafult);
}
.dark header.header-tools .logo-menu-part > .container {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark header.header-tools .container {
  background-color: #2b2b2b;
}
.dark header.left-header .top-header {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark header.left-header .main-menu .menu-right .header-dropdown > li img {
  filter: invert(100);
}
.dark header.left-header .onhover-div > div img {
  filter: invert(100);
}
.dark header.left-header .sidenav nav {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark header.left-header .sidenav .left-sidebar_center {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark header.left-header .sidenav .pixelstrap > li > a {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark header.green-gradient {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark header.green-gradient .top-header {
  background-color: #232323;
  transition: all 0.3s ease;
  background: #232323;
  background-image: none;
}
.dark .top-header {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .border-section {
  border-color: #404040;
}
.dark .main-navbar .nav-menu {
  background-color: #2b2b2b;
}
.dark .main-navbar .nav-menu .back-btn .mobile-back {
  color: #f1f3f5;
}
.dark .main-navbar .nav-menu > li > a {
  color: #f1f3f5;
}
.dark .main-navbar .nav-menu > li .nav-submenu {
  background-color: #2b2b2b;
  box-shadow: none;
}
.dark .main-navbar .nav-menu > li .nav-submenu li a {
  color: #f1f3f5;
}
.dark .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
  background-color: #2b2b2b;
  box-shadow: none;
}
.dark .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
  color: #f1f3f5;
}
.dark .main-navbar .nav-menu > li .mega-menu-container {
  background-color: #2b2b2b;
  box-shadow: none;
}
.dark .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
  color: #f1f3f5;
}
.dark .sm-vertical {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .sidenav nav {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .sidenav nav .sidebar-menu {
  background-color: #232323;
}
.dark .sidenav nav .sidebar-menu li a {
  color: #f1f3f5;
}
.dark .sidenav nav .sidebar-menu li .mega-menu {
  background-color: #232323;
  box-shadow: none;
}
.dark .sidenav nav .sidebar-menu li .mega-menu .link-section ul {
  background-color: #232323;
}
.dark .sidenav nav .sidebar-menu li ul {
  background-color: #232323;
}
.dark .sidenav .sidebar-back {
  color: #f1f3f5;
  border-color: #404040;
}
.dark .pixelstrap ul {
  background: #232323;
  box-shadow: none;
}
.dark .pixelstrap ul a {
  color: #e7eaec;
}
.dark .pixelstrap ul a:hover, .dark .pixelstrap ul a:focus, .dark .pixelstrap ul a:active, .dark .pixelstrap ul a.highlighted {
  color: #e7eaec;
}
.dark .pixelstrap .home-menu,
.dark .pixelstrap .feature-menu,
.dark .pixelstrap .category-menu,
.dark .pixelstrap .full-mega-menu,
.dark .pixelstrap .clothing-menu {
  box-shadow: 0 0 1px 0 #2b2b2b;
}
.dark .onhover-div > div img {
  filter: brightness(100);
}
.dark .onhover-div .show-div {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}
.dark .search-overlay {
  background-color: #2b2b2b;
}
.dark .theme-tab .nav-tabs a {
  color: #cbcbcb;
}
.dark .theme-tab .nav-tabs a.active {
  color: #ff4c3b;
}
.dark .error-section h1 {
  color: #e6e6e6;
}
.dark .breadcrumb-item.active {
  color: #e7eaec;
}
.dark .breadcrumb-section .breadcrumb a {
  color: #f7f7f7;
}
.dark .main-menu .menu-left .navbar i {
  color: #f7f7f7;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
  color: #cfd4da;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  color: #cfd4da;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
  color: #e7eaec;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
  color: #dee2e6;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
  color: #ffffff;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-color: #404040;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
  color: #dee2e6;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  color: #cbcbcb;
}
.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
  color: #ff4c3b;
}
.dark .footer-theme .sub-title li {
  color: #cfd4da;
}
.dark .footer-theme .sub-title li a {
  color: #cfd4da;
}
.dark .footer-theme .sub-title h4 {
  color: #e7eaec;
}
.dark .footer-social i,
.dark .social-white i {
  color: #cfd4da;
}
.dark .footer-light .subscribe {
  border-color: #404040;
}
.dark .footer-theme2 .contact-details li {
  color: #cfd4da;
}
.dark .footer-theme2 .contact-details li a {
  color: #e7eaec;
}
.dark .footer-theme2 .footer-link a,
.dark .footer-theme2 .footer-link-b a {
  color: #cbcbcb;
}
.dark .footer-theme2 .footer-link a:hover,
.dark .footer-theme2 .footer-link-b a:hover {
  color: #ffffff;
}
.dark .top-header .header-contact li {
  color: #cfd4da;
}
.dark .top-header .header-dropdown li {
  color: #cfd4da;
}
.dark .top-header .header-dropdown li a i {
  color: #cfd4da;
}
.dark .top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #cbcbcb;
}
.dark .top-header.top-header-dark .header-dropdown li a {
  color: #cbcbcb;
}
.dark .top-header.top-header-dark .header-contact li i {
  color: #cbcbcb;
}
.dark .top-header.top-header-dark2 .header-dropdown li a {
  color: #cfd4da;
}
.dark .top-header.top-header-dark2 .header-contact li i {
  color: #cfd4da;
}
.dark .top-header.top-header-dark3 .header-dropdown li a {
  color: #cbcbcb;
}
.dark .top-header.top-header-dark3 .header-contact li i {
  color: #cbcbcb;
}
.dark .blog-page .blog-sidebar .theme-card .popular-tag li span {
  background-color: #232323;
}
.dark .onhover-dropdown .onhover-show-div {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}
.dark .testimonial {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
  border-color: #404040;
}
.dark .testimonial .testimonial-slider .media img {
  border-color: #404040;
}
.dark .team h2 {
  border-color: #404040;
}
.dark .service-block + .service-block {
  border-color: #404040;
}
.dark .pixelstrap.light-font-menu li > a {
  color: #f1f3f5;
}
.dark .theme-tab .tab-title a,
.dark .theme-tab .tab-title2 a {
  color: #cbcbcb;
}
.dark .theme-tab .tab-title .current a,
.dark .theme-tab .tab-title2 .current a {
  color: #ff4c3b;
}
.dark .theme-tab .tab-title2:after {
  border-color: #404040;
}
.dark .theme-tab .tab-title2 .nav-item .active {
  background-color: #232323;
}
.dark .product-box .product-detail h4,
.dark .product-box .product-info h4,
.dark .product-wrap .product-detail h4,
.dark .product-wrap .product-info h4 {
  color: #e7eaec;
}
.dark .theme-card .offer-slider .media .media-body h4 {
  color: #e7eaec;
}
.dark .theme-card .slick-prev:before,
.dark .theme-card .slick-next:before {
  color: #ffffff;
}
.dark .theme-card h5.title-border {
  border-color: #404040;
}
.dark .theme-card.card-border {
  border-color: #404040;
}
.dark .dark-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .sub-footer.darker-subfooter {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .sub-footer.darker-subfooter p {
  color: #cfd4da;
}
.dark .blog-page .blog-media .blog-right h4 {
  color: #e7eaec;
}
.dark .blog-page .blog-media .blog-right ul {
  color: #cfd4da;
}
.dark .blog-page .blog-sidebar .theme-card {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .blog-page .blog-sidebar h4 {
  color: #e7eaec;
}
.dark .blog-page .blog-sidebar h6 {
  color: #cfd4da;
}
.dark .blog-page .blog-sidebar p {
  color: #cfd4da;
}
.dark .blog-detail-page .blog-detail h3 {
  color: #eaedef;
}
.dark .blog-detail-page .blog-detail p {
  color: #cfd4da;
}
.dark .blog-detail-page .post-social {
  color: #cfd4da;
  border-color: #404040;
}
.dark .blog-detail-page .post-social li + li {
  border-color: #404040;
}
.dark .blog-detail-page .comment-section {
  border-color: #404040;
}
.dark .blog-detail-page .comment-section li {
  border-color: #404040;
}
.dark .blog-detail-page .comment-section li h6 {
  color: #cfd4da;
}
.dark .blog-detail-page .comment-section li h6 span {
  color: #929292;
}
.dark .blog-detail-page .blog-contact .theme-form label {
  color: #cfd4da;
}
.dark .blog-detail-page .blog-contact .theme-form input,
.dark .blog-detail-page .blog-contact .theme-form textarea {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .cart-section .cart-table thead th,
.dark .wishlist-section .cart-table thead th {
  color: #f1f3f5;
}
.dark .cart-section tbody tr td,
.dark .wishlist-section tbody tr td {
  border-color: #404040;
}
.dark .cart-section tbody tr td a,
.dark .wishlist-section tbody tr td a {
  color: #cbcbcb;
}
.dark .cart-section tbody tr td h2,
.dark .wishlist-section tbody tr td h2 {
  color: #f1f3f5;
}
.dark .table tbody + tbody {
  border-color: #404040;
}
.dark .table thead th {
  border-color: #404040;
}
.dark .top-banner-wrapper .top-banner-content h4 {
  color: #e7eaec;
}
.dark .top-banner-wrapper .top-banner-content h5 {
  color: #dee2e6;
}
.dark .top-banner-wrapper .top-banner-content p {
  color: #cfd4da;
}
.dark .collection-product-wrapper .product-top-filter {
  border-color: #404040;
}
.dark .collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.dark .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
.dark .collection-product-wrapper .product-top-filter .popup-filter .search-count,
.dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  border-color: #404040;
}
.dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.dark .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
  border-color: #404040;
}
.dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.dark .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
  border-color: #404040;
}
.dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
  color: #cbcbcb;
}
.dark .product-pagination {
  border-color: #404040;
}
.dark .product-pagination .pagination .page-item.active a {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .product-pagination .pagination .page-item.disabled .page-link {
  background-color: #2b2b2b;
  border-color: #404040;
}
.dark .product-pagination .product-search-count-bottom {
  border-color: #404040;
}
.dark .product-pagination .product-search-count-bottom h5 {
  color: #dee2e6;
}
.dark .portfolio-section.metro-section .product-box .product-detail {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .portfolio-section.metro-section .product-box .product-detail h4 {
  color: #e7eaec;
}
.dark .portfolio-section.metro-section .product-box .product-detail h6 {
  color: #cfd4da;
}
.dark .portfolio-section.metro-section .product-box .cart-wrap button {
  border-color: #404040;
  background-color: rgba(43, 43, 43, 0.8);
  transition: all 0.3s ease;
}
.dark .portfolio-section.metro-section .product-box .cart-wrap button i {
  color: #cbcbcb;
}
.dark .portfolio-section.metro-section .product-box .cart-wrap a i {
  border-color: #404040;
  background-color: rgba(43, 43, 43, 0.8);
  transition: all 0.3s ease;
  color: #cbcbcb;
}
.dark .collection-filter-block {
  border-color: #404040;
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .collection-collapse-block .collapse-block-title {
  color: #eaedef;
}
.dark .collection-collapse-block .collapse-block-title:after {
  color: #eaedef;
}
.dark .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  color: #929292;
}
.dark .load-more-sec a {
  border-color: #404040;
}
.dark .checkout-page .checkout-title h3 {
  color: #eaedef;
}
.dark .checkout-page .checkout-form .form-group .field-label {
  color: #cfd4da;
}
.dark .checkout-page .checkout-form input[type=text], .dark .checkout-page .checkout-form input[type=email], .dark .checkout-page .checkout-form input[type=password], .dark .checkout-page .checkout-form input[type=tel], .dark .checkout-page .checkout-form input[type=number], .dark .checkout-page .checkout-form input[type=url] {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .checkout-page .checkout-form select,
.dark .checkout-page .checkout-form textarea {
  border-color: #404040;
}
.dark .checkout-page .checkout-form .checkout-details {
  background-color: #232323;
  transition: all 0.3s ease;
  border: 1px solid #404040;
}
.dark .order-box .title-box {
  color: #eaedef;
  border-color: #404040;
}
.dark .order-box .qty {
  border-color: #404040;
}
.dark .order-box .qty li {
  color: #cfd4da;
}
.dark .order-box .qty li span {
  color: #dee2e6;
}
.dark .order-box .sub-total {
  border-color: #404040;
}
.dark .order-box .sub-total li {
  color: #cfd4da;
}
.dark .order-box .sub-total .shopping-option label {
  color: #929292;
}
.dark .order-box .total li {
  color: #cfd4da;
}
.dark .payment-box .payment-options li .radio-option label {
  color: #929292;
}
.dark .collection .collection-block .collection-content h4 {
  color: #e7eaec;
}
.dark .collection .collection-block .collection-content h3 {
  color: #eaedef;
}
.dark .table th,
.dark .table td {
  border-color: #404040;
}
.dark .compare-page .table-wrapper .table {
  color: #f1f3f5;
  border-color: #404040;
}
.dark .compare-page .table-wrapper .table thead .th-compare td {
  background: #232323;
  border-color: #404040;
}
.dark .compare-page .table-wrapper .table thead .th-compare th {
  border-color: #404040;
}
.dark .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  color: #cfd4da;
}
.dark .compare-page .table-wrapper .table tbody tr th {
  border-color: #404040;
  background: #232323;
}
.dark .compare-page .table-wrapper .table tbody tr td {
  border-color: #404040;
}
.dark .compare-page .table-wrapper .table tbody tr p {
  color: #cfd4da;
}
.dark .compare-section .compare-part .detail-part .title-detail {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .compare-section .compare-part .btn-part {
  border-color: #404040;
}
.dark .compare-section .compare-part .close-btn {
  color: #e7eaec;
}
.dark .compare-section .owl-item {
  border-color: #404040;
}
.dark .contact-page .theme-form label {
  color: #cfd4da;
}
.dark .contact-page .theme-form input {
  border-color: #404040;
}
.dark .contact-page .theme-form textarea {
  border-color: #404040;
}
.dark .contact-page .contact-right ul li .contact-icon {
  border-color: #404040;
}
.dark .dashboard .box-head h2 {
  color: #f1f3f5;
}
.dark .dashboard .box .box-title {
  border-color: #404040;
}
.dark .dashboard .box .box-title h3 {
  color: #eaedef;
}
.dark .dashboard-left .block-content {
  border-color: #404040;
}
.dark .dashboard-left .block-content ul li a {
  color: #cbcbcb;
}
.dark .dashboard-right .dashboard {
  border-color: #404040;
}
.dark .white-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .border-bottom-grey {
  border-color: #404040;
}
.dark .layout-8 .layout-8-bg {
  background-blend-mode: overlay;
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .card {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .card .card-header {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .faq-section .accordion.theme-accordion .card {
  border-color: #404040;
}
.dark .faq-section .accordion.theme-accordion .card .card-header {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .faq-section .accordion.theme-accordion .card .card-header button {
  color: #dee2e6;
}
.dark .faq-section .accordion.theme-accordion .card .card-header button[aria-expanded=true]:before {
  border-bottom-color: #404040;
}
.dark .faq-section .accordion.theme-accordion .card .card-header button:before {
  border-top-color: #404040;
}
.dark .faq-section .accordion.theme-accordion .card .card-body p {
  color: #cfd4da;
}
.dark.box-layout-body {
  background-color: #2b2b2b;
}
.dark.box-layout-body .box-layout-header {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark.box-layout-body .box-layout.bg-image {
  background-color: #232323;
  background: none;
  transition: all 0.3s ease;
  background-image: none;
  box-shadow: none;
}
.dark.box-layout-body .light-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark.box-layout-body .sub-footer {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .full-box .theme-card .offer-slider .product-box2 + .product-box2 {
  border-top: none;
}
.dark .center-slider {
  border-color: #404040;
}
.dark .bg-block {
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none;
}
.dark .theme-tab .tab-content .product-tab .tab-box {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
  color: #e7eaec;
}
.dark .flower-bg {
  background-image: none !important;
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none;
}
.dark .pwd-page .theme-form input {
  border-color: #404040;
}
.dark .full-scroll-menu {
  background-color: transparent;
  transition: all 0.3s ease;
}
.dark .full-scroll-footer .sub-footer {
  background-color: transparent;
  transition: all 0.3s ease;
}
.dark .portfolio-section .filter-button {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cfd4da;
}
.dark .portfolio-section .filter-button.active {
  background-color: #ff4c3b;
  transition: all 0.3s ease;
  color: #ffffff;
}
.dark .portfolio-section .isotopeSelector:hover .overlay-background {
  border-color: #dddddd;
}
.dark .portfolio-section .isotopeSelector:hover .overlay-background i {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .portfolio-section .isotopeSelector .overlay {
  border-color: #dddddd;
}
.dark .bg-img-gym .dark-layout {
  background-color: transparent;
  transition: all 0.3s ease;
}
.dark .bg-img-gym .sub-footer.darker-subfooter {
  background-color: #000000;
  transition: all 0.3s ease;
}
.dark .category-block .category-image {
  border-color: #404040;
}
.dark .category-block .category-image.svg-image {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .j-box .product-box {
  border-color: #404040;
}
.dark .j-box .product-box .product-detail {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .j-box .product-box .cart-info {
  background-color: rgba(35, 35, 35, 0.84);
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .j-box .product-box .cart-info a {
  border-color: #000000;
}
.dark .white-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .footer-theme2.footer-border {
  border-color: #404040;
}
.dark .footer-theme2.section-light .footer-block .subscribe-white {
  border-color: #404040;
}
.dark .footer-theme2.section-light .footer-block h4 {
  color: #e7eaec;
}
.dark .footer-theme2.section-light .footer-block .contact-details li a {
  color: #e7eaec;
}
.dark .footer-theme2 .footer-link.link-white h4 {
  color: #e7eaec;
}
.dark .jewel-footer .sub-footer.black-subfooter {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .jewel-footer .sub-footer p {
  color: #cfd4da;
}
.dark .absolute-banner .absolute-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
}
.dark .login-page .theme-card {
  border-color: #404040;
}
.dark .login-page .theme-card .theme-form input {
  border-color: #404040;
}
.dark .login-page .theme-card .theme-form label {
  color: #929292;
}
.dark .login-page .authentication-right h6,
.dark .login-page .authentication-right p {
  color: #cfd4da;
}
.dark .lookbook .lookbook-block .lookbook-dot:before {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .blog-left .blog-details h4 {
  color: #e7eaec;
}
.dark .blog-left .blog-details h6 {
  color: #cfd4da;
}
.dark footer.footer-black .below-section {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark footer.footer-black .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark footer.footer-black .upside .small-section {
  background-color: #383838;
  transition: all 0.3s ease;
}
.dark footer.footer-black .subscribe h4 {
  color: #e7eaec;
}
.dark footer.footer-black .subscribe p {
  color: #cfd4da;
}
.dark footer.footer-black.footer-light .subscribe {
  border-color: #929292;
}
.dark footer.pet-layout-footer .white-layout {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark footer.pet-layout-footer .sub-footer.black-subfooter {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark footer.footer-classic .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark footer.footer-5 {
  background-image: unset;
}
.dark footer.footer-5 .footer-theme2 .subscribe-block {
  border-color: #404040;
}
.dark footer.footer-5 .sub-footer > .container {
  border-color: #404040;
}
.dark .logo-block img {
  filter: invert(100);
}
.dark .logo-block img:hover {
  filter: invert(100);
}
.dark .bg-white {
  background-color: #2b2b2b !important;
  transition: all 0.3s ease;
}
.dark .product-box .img-wrapper .cart-box,
.dark .product-wrap .img-wrapper .cart-box {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  box-shadow: none;
}
.dark .tab-bg.tab-grey-bg {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .delivery-sec {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .product-order .total-sec {
  border-color: #404040;
}
.dark .blog.blog_box .blog-details p {
  color: #cfd4da;
}
.dark .blog.blog_box .blog-details a p {
  color: #cfd4da;
}
.dark .blog.blog_box .blog-details .read-cls {
  color: #cbcbcb;
}
.dark .product-right .product-title {
  color: #cfd4da;
}
.dark .product-right .border-product {
  border-color: #404040;
}
.dark .product-right .product-icon .product-social li a {
  color: #cbcbcb;
}
.dark .product-right .product-icon .wishlist-btn {
  color: #cbcbcb;
}
.dark .product-right .product-icon .wishlist-btn i {
  border-color: #404040;
}
.dark .product-right.product-form-box {
  border-color: #404040;
}
.dark .product-right.product-form-box .timer {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .product-right .timer {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .product-right .timer p {
  color: #cfd4da;
}
.dark .product-right .size-box ul li {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .product-right .size-box ul li.active {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .product-right .size-box ul li a {
  color: #cbcbcb;
}
.dark .product-right h4 del {
  color: #929292;
}
.dark .timer span .timer-cal {
  color: #929292;
}
.dark .qty-box .input-group span button {
  background: #2b2b2b !important;
  border-color: #404040;
}
.dark .qty-box .input-group button i {
  color: #929292;
}
.dark .nav-tabs {
  border-color: #404040;
}
.dark .nav-tabs .nav-link.active {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .tab-product .nav-material .nav-tabs .nav-item .nav-link {
  color: #cbcbcb;
  color: #ffffff;
}
.dark .tab-product .nav-material .nav-tabs .nav-link {
  color: #ffffff;
}
.dark .tab-product .nav-material .nav-tabs .nav-link.active {
  color: #ff4c3b;
}
.dark .product-full-tab .nav-material .nav-tabs .nav-link {
  color: #ffffff;
}
.dark .product-full-tab .nav-material .nav-tabs .nav-link.active {
  color: #ff4c3b;
}
.dark .tab-product .theme-form input,
.dark .tab-product .theme-form textarea,
.dark .product-full-tab .theme-form input,
.dark .product-full-tab .theme-form textarea {
  border-color: #404040;
}
.dark .product-related h2 {
  border-color: #404040;
}
.dark .product-accordion .btn-link {
  color: #e7eaec;
}
.dark .product-accordion .card-header {
  border-color: rgba(64, 64, 64, 0.125);
}
.dark .theme_checkbox label {
  color: #929292;
}
.dark .theme_checkbox label .checkmark {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .theme_checkbox label .checkmark:after {
  border-color: #ffffff;
}
.dark .bundle .bundle_detail .price_product {
  color: #ffffff;
}
.dark .modal-content {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark button.close {
  color: #ffffff;
}
.dark .modal-header {
  border-color: #404040;
}
.dark .collection-filter-block .product-service .media {
  border-color: #404040;
}
.dark .pro_sticky_info {
  border-color: #404040;
}
.dark .tab-border {
  border-color: #404040;
}
.dark .register-page .theme-card {
  border-color: #404040;
}
.dark .register-page .theme-card .theme-form input {
  border-color: #404040;
}
.dark .register-page .theme-card .theme-form label {
  color: #929292;
}
.dark .category-border {
  background-color: #404040;
  transition: all 0.3s ease;
}
.dark .category-border div .category-banner .category-box h2 {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .background {
  background-color: #404040;
  transition: all 0.3s ease;
}
.dark .background .contain-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .background .contain-bg h4 {
  color: #e7eaec;
}
.dark .background .contain-bg:hover h4 {
  color: #ff4c3b;
}
.dark .blog-bg {
  background-color: #404040;
  transition: all 0.3s ease;
}
.dark .sub-footer.black-subfooter {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark.tools-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark.tools-bg section {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark.tools-bg .tools-service {
  background-color: transparent;
}
.dark .category-tools .category-m .category-wrapper {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .category-m .category-wrapper {
  border-color: #404040;
}
.dark .category-m .category-wrapper h4 {
  color: #e7eaec;
}
.dark .category-m .category-wrapper .category-link li a {
  color: #cbcbcb;
}
.dark .tools-grey .product-box .product-info {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: rgba(64, 64, 64, 0.2);
}
.dark .tools-grey .product-box .cart-info button {
  background-color: #232323;
  transition: all 0.3s ease;
  color: #cbcbcb;
}
.dark .tools-grey .product-box .cart-info a {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .tools-grey .product-box .cart-info a i {
  color: #cbcbcb;
}
.dark .tools-grey .product-box .img-wrapper .front {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .tools-brand .row {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}
.dark .typography_section .typography-box .headings {
  background-color: rgba(35, 35, 35, 0.5);
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .typography_section .typography-box .headings h3 {
  color: #eaedef;
}
.dark .typography_section .typography-box .headings span {
  color: #929292;
}
.dark .typography_section .typography-box .headings span code {
  color: #929292;
}
.dark .typography_section .typography-box .typo-content .sub-title {
  color: #f1f3f5;
  border-color: rgba(64, 64, 64, 0.7);
}
.dark .typography_section .typo-content.heading_content h1,
.dark .typography_section .typo-content.heading_content h2,
.dark .typography_section .typo-content.heading_content h3,
.dark .typography_section .typo-content.heading_content h4,
.dark .typography_section .typo-content.heading_content h5,
.dark .typography_section .typo-content.heading_content h6 {
  color: white;
}
.dark .typography_section .typo-content.product-pagination .pagination .page-item {
  border-color: #404040;
}
.dark .typography_section code {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .absolute_banner .collection-banner .absolute-contain {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none;
}
.dark .absolute_banner .collection-banner .absolute-contain h4 {
  color: #e7eaec;
}
.dark .absolute-product .product-box {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .absolute-product .product-box .product-detail .cart-bottom {
  border-color: #404040;
}
.dark .absolute-product .product-box .product-detail .cart-bottom i {
  color: #cbcbcb;
}
.dark .absolute-product .product-box .product-detail .cart-bottom i:hover {
  color: #ffffff;
}
.dark .box-product .full-box .theme-card .offer-slider .product-box2 {
  box-shadow: none;
}
.dark .box-product .full-box .theme-card .offer-slider .product-box2 .media {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom {
  border-color: #404040;
}
.dark .insta-title {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .tab-bg {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .darken-layout {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .darken-layout p {
  color: #cfd4da;
}
.dark .darken-layout .sub-title .contact-list li {
  color: #cfd4da;
}
.dark .darken-layout .sub-title .contact-list i {
  color: #cfd4da;
}
.dark .darken-layout .footer-social i {
  color: #cfd4da;
}
.dark .sub-footer.dark-subfooter p {
  color: #cfd4da;
}
.dark .cart-section tbody tr td a,
.dark .cart-section tbody tr td p,
.dark .wishlist-section tbody tr td a,
.dark .wishlist-section tbody tr td p {
  color: #cbcbcb;
}
.dark .footer-title {
  border-color: #404040;
}
.dark .category-bg {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .category-bg .contain-block {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .category-bg .contain-block h2 {
  color: #f1f3f5;
}
.dark .category-bg .contain-block h6 span {
  color: #929292;
}
.dark .service_slide .service-home .service-block1 {
  background-color: rgba(35, 35, 35, 0.9);
  transition: all 0.3s ease;
}
.dark .service_slide .service-home .service-block1:nth-child(even) {
  background-color: rgba(35, 35, 35, 0.7);
  transition: all 0.3s ease;
}
.dark .service_slide .service-home .service-block1 svg path {
  fill: #ffffff;
}
.dark .bg-grey {
  background-color: #232323;
  transition: all 0.3s ease;
}
.dark .detail-cannabis .detail_section > div {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  box-shadow: none;
}
.dark .detail-cannabis .detail_section svg {
  fill: #ffffff;
}
.dark .border-box.tools-grey .product-box {
  border-color: #404040;
}
.dark .theme-modal .modal-dialog .modal-content .modal-body {
  background-image: linear-gradient(135deg, #ff4c3b 5.77%, #232323 5.77%, #232323 25%, #f1f3f5 25%, #f1f3f5 30.77%, #232323 30.77%, #232323 50%, #ff4c3b 50%, #ff4c3b 55.77%, #232323 55.77%, #232323 75%, #f1f3f5 75%, #f1f3f5 80.77%, #232323 80.77%, #232323 100%);
}
.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
  color: #e7eaec;
}
.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close span {
  color: #ffffff;
}
.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
  color: #f1f3f5;
}
.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects > div .layout-container {
  box-shadow: none;
}
.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects > div .demo-text h4 {
  color: #e7eaec;
}
.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects > div .demo-text .demo-btn .btn {
  border-color: #404040;
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cbcbcb;
}
.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .title-text h3 {
  color: #eaedef;
}
.dark .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
  color: #cbcbcb;
}
.dark .theme-modal.exit-modal .media .media-body h5 {
  color: #dee2e6;
}
.dark .theme-modal.exit-modal .stop {
  filter: invert(100);
}
.dark #quick-view .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}
.dark .addcart_btm_popup {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040;
}
.dark .addcart_btm_popup .fixed_cart i {
  color: #cbcbcb;
}
.dark .review-page .comnt-sec li a {
  color: #cbcbcb;
}
.dark .sitemap_page ul li a {
  color: #cbcbcb;
}
.dark .sitemap_page ul li a:hover {
  color: #ff4c3b;
}
.dark .sitemap_page ul ul li a {
  color: #dee2e6;
}
.dark .effect-cls:before, .dark .effect-cls:after {
  filter: invert(100);
}
.dark .game-product .product-box .cart-info a i {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cbcbcb;
}
.dark .demo-right a {
  background-color: #000000;
  transition: all 0.3s ease;
}
.dark .rtl-btn,
.dark .dark-light {
  background-color: #000000;
  box-shadow: none;
}
.dark .color-picker a.handle {
  background-color: #000000;
  transition: all 0.3s ease;
  color: #cbcbcb;
}
.dark .setting-box .setting-title h4 {
  color: #333333;
}
.dark .add_to_cart .cart-inner {
  background-color: #2b2b2b;
}
.dark .add_to_cart .cart-inner .cart_media li .media .media-body h4 {
  color: rgba(255, 255, 255, 0.7);
}
.dark .add_to_cart .cart-inner .cart_media li .total {
  border-color: #404040;
}
.dark .add_to_cart .cart-inner .cart_top {
  border-color: #404040;
}
.dark .add_to_cart .cart-inner .cart_top .close-cart i {
  color: #ffffff;
}
.dark .add_to_cart .cart-inner .cart_top h3 {
  color: #ffffff;
}
.dark .add_to_cart.bottom .cart-inner .cart_top, .dark .add_to_cart.top .cart-inner .cart_top {
  background-color: #232323;
}
.dark .stripe-section {
  background-color: #232323;
  border-color: #404040;
}
.dark .stripe-section .content h5 {
  color: rgba(255, 255, 255, 0.8);
}
.dark .stripe-section .content table tr {
  border-color: #404040;
}

/*=====================
    27.Menu css start
==========================*/
header .main-navbar {
  text-align: center;
}
header .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}
header .main-navbar .nav-menu > li {
  position: relative;
  float: left;
  display: block;
}
header .main-navbar .nav-menu > li > a {
  display: block;
  padding-right: 45px;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0;
  letter-spacing: 0.07em;
  position: relative;
}
header .main-navbar .nav-menu > li > a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}
header .main-navbar .nav-menu > li > a .sub-arrow:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
}
header .main-navbar .nav-menu > li > a .lable-nav {
  position: absolute;
  background-color: var(--theme-deafult);
  padding: 2px 7px;
  color: #ffffff;
  top: 20px;
  text-transform: uppercase;
  font-size: 9px;
  left: 0;
  line-height: 1.3;
}
header .main-navbar .nav-menu > li > a .lable-nav:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--theme-deafult);
  top: 15px;
  left: 5px;
}
header .main-navbar .nav-menu > li > a .lable-nav.lable-red {
  background-color: #ff4c3b;
}
header .main-navbar .nav-menu > li > a .lable-nav.lable-red:before {
  border-top: 5px solid #ff4c3b;
}
header .main-navbar .nav-menu > li .nav-submenu {
  position: absolute;
  left: 0;
  z-index: 4;
  box-shadow: 0 0 1px 0 #ebebeb;
  padding: 15px 0px 15px 0px;
  background: white;
}
header .main-navbar .nav-menu > li .nav-submenu li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
header .main-navbar .nav-menu > li .nav-submenu li a {
  border: 0 !important;
  background: transparent;
  color: #222222;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 5px 35px;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
}
header .main-navbar .nav-menu > li .nav-submenu li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--theme-deafult);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}
header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow:before {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
}
header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .main-navbar .nav-menu > li .mega-menu-container {
  position: absolute;
  left: 0;
  z-index: 4;
  box-shadow: 0 0 1px 0 #ebebeb;
  padding: 30px;
  background: white;
  width: 100%;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
  width: 20%;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content {
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
  border: 0 !important;
  background: transparent;
  color: #222222;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 3px 0;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
  position: relative;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--theme-deafult);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
header .main-navbar .nav-menu > li:hover > a {
  color: var(--theme-deafult);
}
header .main-navbar .nav-menu > li.mega-menu {
  position: unset;
}
header.header-gym .main-navbar .nav-menu > li > a {
  color: white;
  padding-top: 22px;
  padding-bottom: 22px;
}
header.header-tools .main-navbar .nav-menu > li > a {
  padding-top: 25px;
  padding-bottom: 25px;
}
header.header-tools .main-navbar .nav-menu > li > a .lable-nav {
  top: 0;
}
header .sidenav {
  height: 100vh;
  width: 300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -300px;
  background-color: #ffffff;
  transition: all 0.5s ease;
}
header .sidenav .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  cursor: pointer;
}
header .sidenav nav {
  background-color: #ffffff;
  z-index: 9;
  position: relative;
  height: 100vh;
  top: 0;
}
header .sidenav.openSide {
  left: 0;
  transition: all 0.5s ease;
}
header .sidenav.openSide .sidebar-overlay {
  opacity: 0.8;
  visibility: visible;
}
header .sidenav .sidebar-overlay {
  visibility: hidden;
  opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-transition: all 1s;
  -webkit-transition: all 1s;
  transition: all 1s;
}
header .sidenav .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
  margin-left: 50px;
  margin-top: 50px;
  color: #938d8c;
}
header .sidenav .sidebar-menu {
  background-color: #ffffff;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
}
header .sidenav .sidebar-menu li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
  float: none;
}
header .sidenav .sidebar-menu li a {
  padding: 10px 50px 20px 40px;
  position: relative;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  display: block;
}
header .sidenav .sidebar-menu li ul {
  position: absolute;
  width: 200px;
  left: 100%;
  top: 0;
  padding: 15px 0px;
  display: none;
  background-color: #fff;
}
header .sidenav .sidebar-menu li ul li a {
  padding: 5px 35px;
}
header .sidenav .sidebar-menu li ul li a:active, header .sidenav .sidebar-menu li ul li a:focus, header .sidenav .sidebar-menu li ul li a.highlighted {
  padding: 5px 35px;
}
header .sidenav .sidebar-menu li .mega-menu {
  position: absolute;
  left: 100%;
  width: 815px !important;
  padding: 15px 30px 25px !important;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .sidenav .sidebar-menu li .mega-menu li ul {
  position: relative;
  width: 100%;
  left: inherit;
}
header .sidenav .pixelstrap li a {
  padding: 10px 50px 20px 40px;
}
header .sidenav .pixelstrap li ul li a {
  padding: 5px 35px;
}
header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
  padding: 5px 35px;
}
header .sidenav .pixelstrap li .clothing-menu li a {
  padding: 5px 0 !important;
}
header .sidenav .pixelstrap li .clothing-menu li a:focus {
  font-size: 14px;
}
header .sidenav .pixelstrap li .clothing-menu li a:hover {
  font-size: 14px;
  padding: 5px 0;
}
header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
  padding: 5px 0 !important;
}
header .layout3-menu .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}
header .layout3-menu .main-navbar .nav-menu > li {
  position: relative;
  float: left;
  display: block;
}
header .layout3-menu .main-navbar .nav-menu > li > a {
  text-transform: capitalize;
  padding-right: 34px;
  letter-spacing: 0.04em;
}
header .layout3-menu .main-navbar .nav-menu > li.mega-menu {
  position: unset;
}

.toggle-nav {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer;
}
.toggle-nav i {
  font-size: 24px;
  color: var(--theme-deafult);
}

.mega-menu-banner:hover:before {
  display: none;
}

.new-tag {
  padding-left: 3px;
  color: var(--theme-deafult);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.up-text {
  position: relative;
}
.up-text span {
  position: absolute;
  top: -5px;
  right: -10px;
  color: var(--theme-deafult);
  font-size: 12px;
  font-weight: 700;
}

.mega-box .link-section .demo ul li a {
  position: relative;
}
.mega-box .link-section .demo ul li a:hover, .mega-box .link-section .demo ul li a:focus {
  padding-top: 3px;
  padding-bottom: 3px;
}
.mega-box .link-section .demo .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: var(--theme-deafult);
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3;
}
.mega-box .link-section .demo .title {
  border-bottom: 2px solid red !important;
}

.rtl .link-section {
  float: right;
}
.rtl .mega-box .link-section .demo ul li a span img {
  right: unset;
  left: -120px;
}

@media (min-width: 1200px) {
  .responsive-btn {
    display: none;
  }

  header .main-navbar .nav-menu > li .nav-submenu {
    width: 200px;
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:hover .nav-sub-childmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .mega-menu-container {
    display: none;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .main-navbar .nav-menu > li:hover .nav-submenu {
    display: block;
  }
  header .main-navbar .nav-menu > li:hover .mega-menu-container {
    display: block;
  }
  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: auto;
    right: 7px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }
  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
  }
  header .sidenav .sidebar-menu li > ul {
    display: none;
  }
  header .sidenav .sidebar-menu li > ul li a {
    padding: 5px 35px;
    text-transform: capitalize;
  }
  header .sidenav .sidebar-menu li > ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: var(--theme-deafult);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li > ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li > ul li a .sub-arrow {
    margin-top: -17px;
  }
  header .sidenav .sidebar-menu li:hover > ul {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu {
    position: absolute;
    left: 100%;
    width: 815px !important;
    padding: 15px 30px 25px !important;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 #ebebeb;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section h5 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 14px;
    margin-top: 15px;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul {
    position: relative;
    width: 100%;
    left: inherit;
    display: block;
    padding: 0;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a {
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: var(--theme-deafult);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li .mega-menu li .mega-menu-banner {
    padding: 0;
  }
  header .sidenav .pixelstrap li a {
    padding: 10px 50px 20px 40px;
  }
  header .sidenav .pixelstrap li ul li a {
    padding: 5px 35px;
  }
  header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
    padding: 5px 35px;
  }
  header .sidenav .pixelstrap li .clothing-menu li a {
    padding: 5px 0 !important;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:focus {
    font-size: 14px;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:hover {
    font-size: 14px;
    padding: 5px 0;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
    padding: 5px 0 !important;
  }

  .toggle-nav {
    display: none;
  }

  .mobile-back {
    display: none !important;
  }

  .main-nav-center > nav {
    text-align: center;
  }
  .main-nav-center .sm {
    display: inline-block;
    vertical-align: middle;
  }
  .main-nav-center .sm > li .lable-nav {
    top: 3px;
  }
  .main-nav-center .sm > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .layout3-menu .pixelstrap a {
    text-transform: capitalize;
    padding-right: 34px;
    letter-spacing: 0.04em;
  }
  .layout3-menu .pixelstrap .sub-arrow {
    right: 12px;
  }

  .rtl header .main-navbar .nav-menu > li .nav-submenu li a:before {
    right: 35px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li a:hover:before {
    right: 35px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    right: unset;
    left: -200px;
  }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
    right: 0;
  }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover::before {
    right: 0;
  }
  .rtl .mega .full-mega-menu {
    float: left !important;
    left: 50% !important;
    right: auto !important;
  }
  .rtl .mega .full-mega-menu.center-mega-menu {
    margin-right: 0 !important;
    margin-left: 0 !important;
    left: unset !important;
    right: 50% !important;
  }
  .rtl .layout3-menu .light-font-menu li > a, .rtl .layout3-menu .light-font-menu li > a:hover, .rtl .layout3-menu .light-font-menu li > a:active {
    padding-right: 0;
    padding-left: 28px;
  }
  .rtl .layout3-menu .light-font-menu .sub-arrow {
    left: -5px;
    right: auto;
  }
  .rtl .sidenav .sidebar-back i {
    float: left;
  }
  .rtl header.header-5 .top-header .header-dropdown > li:last-child {
    padding: 15px 25px;
    padding-left: 0;
  }
}
@media (max-width: 1440px) {
  .rtl .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
    margin-right: 162px !important;
  }
  .rtl .pixelstrap .mega .full-mega-menu.layout-3 {
    margin-right: 0 !important;
  }
}
@media (max-width: 1367px) {
  .nav-menu a {
    font-size: 14px !important;
  }
}
@media (max-width: 1199px) {
  header .main-navbar .nav-menu {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll;
  }
  header .main-navbar .nav-menu.opennav {
    right: 0;
  }
  header .main-navbar .nav-menu .back-btn {
    display: block;
    width: 100%;
  }
  header .main-navbar .nav-menu .back-btn .mobile-back {
    padding: 20px;
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  header .main-navbar .nav-menu > li {
    display: block;
    float: none;
    width: 100%;
  }
  header .main-navbar .nav-menu > li a {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 700;
    position: relative;
  }
  header .main-navbar .nav-menu > li a .sub-arrow {
    right: 0;
  }
  header .main-navbar .nav-menu > li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .main-navbar .nav-menu > li a .lable-nav {
    left: 15px;
    top: -8px;
  }
  header .main-navbar .nav-menu > li .nav-submenu {
    width: 100%;
    padding: 0;
    position: relative;
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu.opensubmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a {
    text-transform: uppercase;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow {
    right: 0;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    display: none;
    position: relative;
    right: 0;
    width: 100%;
    padding: 0;
    background: rgba(255, 85, 69, 0.04);
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.opensubchild {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
    padding: 5px 35px 5px 50px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container {
    display: none;
    padding: 0 30px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container.opensubmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
    width: 100%;
    padding: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 {
    margin-bottom: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow {
    font: bold 16px/34px monospace !important;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow:before {
    content: "+";
    position: absolute;
    right: -16px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content {
    display: none;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content.opensubmegamenu {
    display: block;
    padding: 0 15px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
    line-height: 1.7;
  }
  header.header-gym .main-navbar .nav-menu > li > a {
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header .sidenav .sidebar-menu li a {
    padding: 10px 25px;
    font-weight: 700;
  }
  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }
  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .sidenav .sidebar-menu li ul {
    position: relative;
    left: 0;
    width: 100%;
    display: none;
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li ul.opensub1 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  header .sidenav .sidebar-menu li ul li ul {
    display: none;
    background: rgba(255, 85, 69, 0.04);
  }
  header .sidenav .sidebar-menu li ul li ul.opensub2 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li ul li a {
    padding: 5px 45px;
  }
  header .sidenav .sidebar-menu li ul li ul li ul {
    display: none;
    background: rgba(255, 85, 69, 0.04);
  }
  header .sidenav .sidebar-menu li ul li ul li ul.opensub3 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li ul li ul li ul {
    display: none;
  }
  header .sidenav .sidebar-menu li ul li ul li ul li ul.opensub4 {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu {
    width: 100% !important;
    padding: 0 30px !important;
    position: relative;
    display: none;
    left: 0;
  }
  header .sidenav .sidebar-menu li .mega-menu.opensidesubmenu {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section h5 {
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section ul {
    display: block;
    padding: 0;
    background: #fff;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section ul li a {
    padding: 5px 0px 5px 10px !important;
    font-size: 12px;
    font-weight: 400;
  }
  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner {
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner img {
    padding: 20px 0 0;
  }

  .header-2 .main-navbar .nav-menu > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mega-box .link-section .demo ul li a span img {
    top: 30px;
    right: -60px;
  }

  .mega-box.col {
    flex-basis: unset;
  }

  .sidenav {
    overflow-y: auto;
  }

  .main-menu.border-section {
    border: none;
  }
  .main-menu .menu-left .main-menu-right .toggle-nav {
    position: absolute;
    z-index: 2;
    right: 145px;
    top: 6px;
  }
  .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
    font-size: 18px;
    padding-left: 5px;
    text-transform: uppercase;
  }

  .main-nav-center .toggle-nav {
    position: absolute;
    z-index: 99;
    right: 175px;
    top: -105px;
  }

  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    top: -1px;
  }

  .rtl .mega-box .link-section .demo ul li a span img {
    left: -70px;
  }
  .rtl .pixelstrap a .sub-arrow {
    right: unset;
    left: 0;
  }
  .rtl .pixelstrap li .lable-nav {
    left: unset;
    right: 15px;
  }
  .rtl .pixelstrap li .lable-nav:before {
    left: unset;
    right: 5px;
  }
}
@media (max-width: 991px) {
  .top-header .header-contact {
    display: none;
  }
}
@media (min-width: 578px) {
  .main-menu .menu-right .icon-nav .mobile-search i,
.main-menu .menu-right .icon-nav .mobile-cart i,
.main-menu .menu-right .icon-nav .mobile-setting i {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search .close-circle i,
.main-menu .menu-right .icon-nav .mobile-cart .close-circle i,
.main-menu .menu-right .icon-nav .mobile-setting .close-circle i {
    display: block;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    display: inline-block;
  }
}
@media (max-width: 577px) {
  .top-header {
    height: 0;
  }

  .main-nav-center .toggle-nav {
    right: 15px;
    top: -85px;
  }

  .mobile-fix-option {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    background-color: #333333;
    width: 100vw;
    z-index: 9;
    box-shadow: 0 2px 5px 0 #a8a8a8;
  }

  .header-5 .top-header .header-dropdown .mobile-wishlist img,
.header-5 .top-header .header-dropdown .mobile-account img {
    filter: brightness(5);
  }
  .header-5 .main-menu .brand-logo {
    margin: 0;
    left: 0;
    transform: unset;
  }

  .top-header .header-dropdown .mobile-account,
.top-header .header-dropdown .mobile-wishlist {
    position: fixed;
    bottom: 20px;
    right: 32%;
    font-size: 0;
    padding: 0;
    z-index: 9;
  }
  .top-header .header-dropdown .mobile-account i,
.top-header .header-dropdown .mobile-wishlist i {
    font-size: 22px;
    font-family: themify;
    color: #999999;
  }
  .top-header .header-dropdown .mobile-account i:before,
.top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e602";
  }
  .top-header .header-dropdown .mobile-account .onhover-show-div,
.top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    top: unset;
    bottom: 42px;
    right: 0;
  }
  .top-header .header-dropdown .mobile-account:hover i,
.top-header .header-dropdown .mobile-wishlist:hover i {
    color: #999999;
  }
  .top-header .header-dropdown .mobile-wishlist {
    right: 64%;
  }
  .top-header .header-dropdown .mobile-wishlist i {
    font-family: themify;
    color: #999999 !important;
  }
  .top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e634";
  }

  .main-menu .menu-left.category-nav-right .navbar {
    padding-left: 0 !important;
  }
  .main-menu .menu-left .navbar {
    padding: 30px 45px 30px 0 !important;
  }
  .main-menu .menu-right .icon-nav .mobile-setting,
.main-menu .menu-right .icon-nav .mobile-cart,
.main-menu .menu-right .icon-nav .mobile-search {
    position: fixed;
    bottom: 20px;
    right: 16%;
    font-size: 0;
    padding: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .show-div,
.main-menu .menu-right .icon-nav .mobile-cart .show-div,
.main-menu .menu-right .icon-nav .mobile-search .show-div {
    top: -58px;
    left: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
.main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
.main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
    top: unset;
    bottom: 42px;
    left: -118px !important;
  }
  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: inline-block;
    font-size: 22px;
    color: #999999;
  }
  .main-menu .menu-right .icon-nav .mobile-setting > div img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .setting {
    top: unset;
    bottom: 42px;
    left: unset !important;
  }
  .main-menu .menu-right .icon-nav .mobile-cart {
    right: 48%;
  }
  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i {
    font-size: 14px;
  }
  .main-menu .menu-right .icon-nav .mobile-cart i {
    display: inline-block;
    font-size: 22px;
    color: #999999;
  }
  .main-menu .menu-right .icon-nav .mobile-cart > div img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search {
    right: 80%;
    z-index: 1;
  }
  .main-menu .menu-right .icon-nav .mobile-search i {
    display: inline-block;
    font-size: 22px;
    color: #999999;
  }
  .main-menu .menu-right .icon-nav .mobile-search img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-group {
    margin-bottom: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    font-size: 14px;
  }

  .brand-logo {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .toggle-nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rtl .main-nav-center .toggle-nav {
    right: unset;
    left: 15px;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting,
.rtl .main-menu .menu-right .icon-nav .mobile-cart,
.rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 16%;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div,
.rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div,
.rtl .main-menu .menu-right .icon-nav .mobile-search .show-div {
    left: unset;
    right: 0;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
.rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
.rtl .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
    left: unset !important;
    right: -118px !important;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .setting {
    left: unset !important;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-cart {
    right: unset;
    left: 48%;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 80%;
  }
  .rtl .main-menu .menu-left .navbar {
    padding: 30px 0 30px 25px !important;
  }
  .rtl .top-header .header-dropdown .mobile-account,
.rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 32%;
  }
  .rtl .top-header .header-dropdown .mobile-account .onhover-show-div,
.rtl .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    right: 0;
  }
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 64%;
  }
  .rtl .layout3-menu .brand-logo {
    left: unset;
  }
}
@media (max-width: 480px) {
  .brand-logo img {
    height: 25px;
  }
}
/*=====================
24.Responsive CSS start
==========================*/
@media (min-width: 1430px) {
  .container {
    max-width: 1400px;
  }
}
@media (max-width: 1430px) {
  .left-header .top-header {
    padding-left: 0;
  }
  .left-header .top-header .header-contact {
    display: none;
  }
  .left-header .sidenav {
    left: -300px;
  }
  .left-header .sidenav.open-side {
    left: 0;
  }
  .left-header .sidenav .sidebar-back {
    display: block;
    color: black;
    border-bottom: 1px solid #1a1b2d;
  }
  .left-header .sidenav .brand-logo {
    display: none;
  }
  .left-header .sidenav .left-sidebar_center {
    padding: 0 0 25px 25px;
  }
  .left-header .main-menu .menu-left .navbar {
    display: block;
  }
  .left-header .main-menu .menu-left .mobile-logo {
    display: block;
    border: none;
  }
  .left-header .search-overlay {
    padding-left: 0;
  }

  .left-sidebar_space {
    padding-left: 0;
  }

  .banner-slider .height-banner {
    height: unset;
  }

  .pet-parallax .pet-decor {
    left: 60px;
    bottom: -125px;
  }
  .pet-parallax .pet-decor img {
    width: 240px;
  }

  .tools_slider .home-slider .slider-contain {
    margin-left: 80px;
  }
  .tools_slider .home-slider .home .tools-parts img,
.tools_slider .home-slider .home .tools-parts1 img {
    width: 250px;
  }
  .tools_slider .home-slider .home .tools-parts {
    right: 0;
  }
  .tools_slider .home-slider .home .tools-parts1 {
    right: 10%;
  }
  .tools_slider .home-slider .home #tools-move2 {
    right: 7%;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: center;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #dddddd;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%;
    border-left: 1px solid #dddddd;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
    display: none;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-right: 1px solid #dddddd;
    width: 30%;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
    width: 10%;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    width: 25%;
  }

  .cart-section .cart-buttons > div:last-child {
    padding-right: 38px;
  }
  .cart-section tbody tr td {
    min-width: 175px;
  }
  .cart-section tbody tr td .qty-box .input-group .form-control {
    width: 75px;
  }
  .cart-section tfoot tr td {
    padding-right: 41px;
  }

  .product-right .product-icon .product-social li {
    padding-right: 10px;
  }

  .product-form-box .timer {
    padding-left: 17px;
  }
  .product-form-box .timer span {
    width: 55px;
  }

  .rtl .cart-section .cart-buttons > div:last-child,
.rtl .wishlist-section .cart-buttons > div:last-child {
    padding-left: 41px;
  }
  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    border-left: none;
    border-right: 1px solid #dddddd;
  }
  .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
    border-left: 1px solid #dddddd;
    border-right: none;
  }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    border-right: 1px solid #dddddd;
    padding-right: 20px;
  }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
    text-align: center;
  }
  .rtl .product-right .product-icon .product-social li {
    padding-left: 10px;
  }

  .dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    border-color: #404040;
  }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-color: #404040;
  }
}
@media (max-width: 1430px) and (min-width: 1368px) {
  .banner-timer .timer {
    margin-top: 45px;
  }
}
@media (max-width: 1430px) and (min-width: 1200px) {
  .layout3-menu {
    max-width: 100%;
  }
  .layout3-menu .main-menu .menu-left .navbar {
    padding: 40px 10px 40px 0;
  }
  .layout3-menu .pixelstrap > a {
    padding-right: 38px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap a i {
    padding: 5px;
  }
  .portfolio-section.metro-section .product-box .cart-wrap i {
    font-size: 14px;
  }
  .portfolio-section.metro-section .product-box .cart-wrap button i {
    padding: 5px;
  }

  .rtl .layout3-menu .main-menu .menu-left .navbar {
    padding: 40px 0 40px 35px;
  }
}
@media (max-width: 1367px) {
  h2 {
    font-size: 32px;
  }

  .title1 .title-inner1 {
    padding-bottom: 10px;
  }
  .title1 .title-inner1:after {
    height: 2px;
  }

  .title3 .line {
    height: 3px;
  }
  .title3 .line:after, .title3 .line:before {
    height: 3px;
  }
  .title3 .title-inner3 {
    margin-bottom: 10px;
  }

  .title4 .title-inner4 {
    padding-bottom: 15px;
  }

  hr.style1 {
    height: 1px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .home-slider:hover .owl-prev,
.home-slider:hover .owl-next {
    transform: scale(1.8);
    left: 15px;
  }
  .home-slider:hover .owl-next {
    right: 15px;
    left: unset;
  }

  .product-box .product-detail .rating i,
.product-box .product-info .rating i,
.product-wrap .product-detail .rating i,
.product-wrap .product-info .rating i {
    padding-right: 0;
  }

  .blog-details h4 {
    margin-top: 20px;
  }
  .blog-details p {
    font-size: 16px;
  }

  .category-bg .image-block.even:after {
    bottom: 91%;
  }
  .category-bg .contain-block h2 {
    font-size: 30px;
  }
  .category-bg .contain-block.even:after {
    top: 91%;
  }
  .category-bg .contain-block .category-btn {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .category-bg .contain-block:after {
    bottom: 91%;
  }

  .service-block h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .banner-timer {
    background-position: right;
  }
  .banner-timer .banner-text h2 {
    font-size: 28px;
  }

  .collection-product-wrapper .product-wrapper-grid .product-five {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .subscribe-form .form-control {
    width: 215px;
  }

  .full-banner .banner-contain h2 {
    font-size: 90px;
  }
  .full-banner .banner-contain h3 {
    font-size: 55px;
  }
  .full-banner .banner-contain h4 {
    font-size: 24px;
    padding-top: 8px;
  }
  .full-banner .banner-contain .color {
    padding-top: 0;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 30px;
  }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 28px;
  }
  .collection-banner .contain-banner h2 {
    font-size: 55px;
    letter-spacing: 0.01em;
  }
  .collection-banner .contain-banner h4 {
    letter-spacing: 0.01em;
  }

  .footer-theme2 .contact-details li {
    padding: 0 40px;
  }
  .footer-theme2 .footer-mobile-title {
    display: none !important;
  }

  .footer-theme .sub-title h4 {
    font-size: 16px;
  }
  .footer-theme .sub-title .contact-list li {
    line-height: 20px;
  }
  .footer-theme .sub-title .contact-list i {
    top: 17px;
  }

  .theme-card .offer-slider .media .media-body h4 {
    margin-top: 10px;
  }
  .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 50px;
  }

  .full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
    margin-right: 0;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 160px;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    margin-top: 15px;
  }

  .blog-page .blog-media .blog-right h4 {
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .blog-page .blog-media .blog-right h6 {
    margin-bottom: 5px;
  }
  .blog-page .blog-media .blog-right ul {
    margin-bottom: 10px;
  }
  .blog-page .blog-media .blog-right p {
    line-height: 1.4;
  }

  .pro_sticky_info {
    padding: 10px;
  }

  .is_stuck {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .rtl .offset-xl-1 {
    margin-right: 8.33333333%;
    margin-left: unset;
  }
}
@media (max-width: 1199px) {
  section,
.section-t-space {
    padding-top: 60px;
  }

  .form_search {
    display: none;
  }

  .header-6 .mobile-search {
    display: inline-block;
  }

  .header-gym .sm-horizontal > li > a {
    color: #000000;
    padding: 10px 15px !important;
  }
  .header-gym .sm-horizontal > li > a:hover, .header-gym .sm-horizontal > li > a:focus, .header-gym .sm-horizontal > li > a.active {
    padding: 10px 15px !important;
    color: #000000;
  }
  .header-gym .toggle-nav {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header-gym .main-navbar .nav-menu > li > a .lable-nav {
    top: -8px;
  }

  header.header-tools .toggle-nav {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  header.header-tools .pixelstrap > li > a {
    padding: 10px 15px;
  }
  header.header-tools .pixelstrap > li > a:hover, header.header-tools .pixelstrap > li > a:active, header.header-tools .pixelstrap > li > a:focus, header.header-tools .pixelstrap > li > a.highlighted {
    padding: 10px 15px;
  }
  header.header-tools .pixelstrap li .lable-nav {
    top: 5px;
  }
  header.header-tools .main-navbar .nav-menu > li > a {
    padding: 10px 15px !important;
  }

  .banner-tools {
    display: none;
  }

  .tab-left .theme-tab {
    display: block;
  }
  .tab-left .theme-tab .left-side {
    width: 100%;
  }
  .tab-left .theme-tab .left-side li {
    width: unset;
    border-bottom: none;
    padding: 0 5px;
  }
  .tab-left .theme-tab .left-side li:first-child {
    border-top: none;
  }
  .tab-left .theme-tab .left-side .tab-title {
    margin-right: 0;
    text-align: center;
  }
  .tab-left .theme-tab .tab-content-cls {
    width: 100%;
  }
  .tab-left .theme-tab .tab-content-cls .no-slider .product-box:last-child {
    margin-top: 30px;
  }

  .tools_product .multiple-slider .theme-tab .no-slider .product-box:last-child {
    margin-top: 30px;
  }

  .tools-parallax-product.full-banner {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .tools-parallax-product .tools-description h3 {
    font-size: 17px;
  }

  .about-text p {
    margin-bottom: 25px;
  }

  .gym-product .part-cls > div:nth-last-child(-n+2) .product-box {
    margin-top: 30px;
  }
  .gym-product .partition-cls > div:nth-child(-n+4) {
    margin-bottom: 0;
  }
  .gym-product .partition-cls > div .product-box {
    margin-top: 30px;
  }
  .gym-product .partition-cls > div:nth-child(-n+2) .product-box {
    margin-top: 0;
  }

  .footer-social {
    margin-top: 25px;
  }

  .section-b-space {
    padding-bottom: 60px;
  }

  .partition1 {
    margin-bottom: -25px;
  }
  .partition1 > div {
    margin-bottom: 25px;
  }

  .title1 h4 {
    padding-bottom: 8px;
  }
  .title1 .title-inner1 {
    padding-bottom: 12px;
    margin-bottom: 25px;
  }

  .title2 h4 {
    padding-bottom: 8px;
  }
  .title2 .title-inner2 {
    margin-bottom: 25px;
  }

  .title3 h4 {
    padding-bottom: 8px;
  }
  .title3 .title-inner3 {
    margin-bottom: 10px;
  }
  .title3 .line {
    margin-bottom: 25px;
  }

  .title4 .title-inner4 {
    padding-bottom: 15px;
  }
  .title4 .line {
    margin-bottom: 25px;
  }

  .title-borderless {
    margin-bottom: 25px;
  }

  .padding-bottom-cls {
    padding-bottom: 30px;
  }

  .banner-timer .timer {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    bottom: 8px;
    margin-top: 38px;
  }

  .footer-social li {
    padding-right: 38px;
  }

  .footer-theme2 p {
    line-height: 25px;
  }
  .footer-theme2 .subscribe-block {
    padding: 10px 70px;
  }
  .footer-theme2 .footer-mobile-title {
    display: none !important;
  }
  .footer-theme2 .contact-details li {
    line-height: 25px;
    padding: 0 10px;
  }
  .footer-theme2 .footer-link li {
    padding-right: 25px;
  }
  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 50px;
  }

  .social-white li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .service-block svg,
.service-block1 svg {
    width: 50px;
    height: 50px;
  }

  .service-block1 svg {
    margin-bottom: 15px;
  }

  .no-slider .product-box {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 30px);
    margin: 0 15px 30px;
  }

  .theme-tab .tab-title2 {
    font-size: 22px;
  }
  .theme-tab .tab-title2:after {
    top: 17px;
  }
  .theme-tab .tab-content .product-tab .tab-box {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+2) {
    margin: 0 5px 0;
  }
  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+4) {
    margin: 0 5px 10px;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 280px;
  }

  .full-banner .banner-contain h2 {
    font-size: 85px;
  }
  .full-banner .banner-contain h3 {
    font-size: 50px;
  }
  .full-banner .banner-contain h4 {
    font-size: 22px;
    padding-top: 5px;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 25px;
  }
  .collection-banner .contain-banner.banner-3 h4 {
    font-size: 12px;
  }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 24px;
  }
  .collection-banner .contain-banner.banner-4 h4 {
    font-size: 16px;
  }
  .collection-banner .contain-banner h2 {
    font-size: 40px;
  }

  .home-slider .home {
    height: 70vh;
  }
  .home-slider .slider-contain {
    height: 70vh;
  }
  .home-slider .slider-contain h1 {
    font-size: 50px;
  }

  .background .contain-bg {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 20px;
  }
  .theme-card .offer-slider .media .media-body .rating i {
    padding-right: 3px;
  }
  .theme-card.card-border .offer-slider {
    padding-top: 0;
    padding-bottom: 0;
  }

  .full-box .theme-card .offer-slider img {
    padding: 15px 0 15px 0;
  }
  .full-box .theme-card .offer-slider .product-box2 .media img {
    height: 250px;
  }
  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant {
    margin-top: 5px;
  }
  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-top: 5px;
  }

  .pet-parallax .pet-decor {
    left: 45px;
    bottom: -105px;
  }
  .pet-parallax .pet-decor img {
    width: 200px;
  }

  .home-slider.fullpage .home .slider-contain h1 {
    font-size: 35px;
  }
  .home-slider.fullpage .home .slider-contain p {
    max-width: 450px;
    font-size: 14px;
  }
  .home-slider.fullpage .home .slider-contain .btn-solid {
    padding: 10px 15px;
  }

  .about-section h2 {
    font-size: 22px;
  }
  .about-section .about-text p {
    line-height: 24px;
  }
  .about-section .service .service-block1 svg {
    margin-bottom: 5px;
  }
  .about-section .service .service-block1 h5 {
    line-height: 18px;
  }
  .about-section .small-section {
    padding-top: 20px;
  }

  .layout7-product .product-box .details-product {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
  }

  .padding-bottom-cls {
    padding-bottom: 30px;
  }

  .layout-8 {
    padding: 0 40px;
  }

  .blog-detail-page .comment-section li {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #dddddd;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
    display: none;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 30%;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%;
  }

  .testimonial .testimonial-slider .media .media-body {
    padding: 30px;
  }
  .testimonial .testimonial-slider .media .media-body p {
    line-height: 1.5;
  }

  .search-product > div:nth-last-child(1), .search-product > div:nth-last-child(2), .search-product > div:nth-last-child(3) {
    margin-top: 30px;
  }

  .cart-section tbody tr td {
    min-width: 140px;
  }
  .cart-section tbody tr td .qty-box .input-group .form-control {
    width: 52px;
  }
  .cart-section tfoot tr td {
    padding-right: 25px;
  }

  .blog-page .blog-media {
    margin-bottom: 20px;
  }
  .blog-page .blog-media .blog-right {
    display: block;
    margin-top: 15px;
  }
  .blog-page .blog-media .blog-right p {
    line-height: 1.3;
  }

  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
    margin-top: 15px;
  }

  .collection-filter-block .product-service .media .media-body h4 {
    font-size: 16px;
  }

  .product-right h2 {
    font-size: 20px;
  }

  .tab-product .tab-content.nav-material .single-product-tables table {
    width: 50%;
  }

  .product-description-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .product-description-box .product-icon .product-social li {
    padding-right: 4px;
  }
  .product-description-box .product-icon .wishlist-btn span {
    padding-left: 4px;
  }

  .product-form-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .product-form-box .timer {
    padding-left: 17px;
  }
  .product-form-box .timer span {
    width: 55px;
  }
  .product-form-box .product-buttons .btn-solid,
.product-form-box .product-buttons .btn-outline {
    padding: 7px 13px;
  }

  .product-accordion .single-product-tables table {
    width: 70%;
  }

  .tab-product .flex-column {
    flex-direction: unset !important;
    justify-content: center;
  }
  .tab-product .nav-material .nav-tabs {
    border-bottom: 1px solid #dddddd;
    border-right: 0;
  }
  .tab-product .nav-material .nav-tabs .nav-item .nav-link.active {
    background-color: unset;
  }

  .tab-border {
    padding-top: 0;
  }

  .timer span {
    width: 60px;
  }

  .rtl header .sidenav .sidebar-menu li a .sub-arrow {
    right: auto;
    left: 15px;
  }
  .rtl .tab-left .theme-tab .left-side .tab-title {
    text-align: center;
    margin-left: 0;
  }
  .rtl .cart-section .cart-buttons > div:last-child,
.rtl .wishlist-section .cart-buttons > div:last-child {
    padding-left: 17px;
  }
  .rtl .tab-border .nav-border {
    border-left: none;
  }
  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    padding-right: 20px !important;
  }
  .rtl .footer-social li {
    padding-right: 0;
    padding-left: 38px;
  }
  .rtl .footer-theme2 .social-white li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .dark .sm-horizontal {
    background-color: #2b2b2b;
    border-color: #404040;
  }
  .dark .sm-horizontal .mobile-back {
    border-color: #404040;
    color: #e7eaec;
  }
  .dark .sm-horizontal.pixelstrap ul {
    background-color: #2b2b2b;
  }
  .dark .tab-product .nav-material.nav-tabs {
    background-color: #2b2b2b;
  }
  .dark header.left-header .pixelstrap ul {
    background-color: #232323;
  }
  .dark header.header-gym .main-navbar .nav-menu {
    background-color: #2b2b2b;
  }
  .dark header.header-gym .main-navbar .nav-menu > li > a {
    color: white;
  }
  .dark header .main-navbar .nav-menu {
    border-color: rgba(255, 255, 255, 0.17);
  }
  .dark header .main-navbar .nav-menu .back-btn .mobile-back {
    border-color: rgba(255, 255, 255, 0.17);
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  .partition-f > div + div + div {
    margin-top: 30px;
  }

  .lookbook .lookbook-block .lookbook-dot .dot-showbox {
    width: 80px;
  }
  .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
    line-height: 15px;
  }
}
@media (min-width: 992px) {
  .rtl .service_slide .service-home .offset-lg-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .main-nav-center .toggle-nav {
    top: -92px;
  }

  .service_slide .service-home {
    position: relative;
  }
  .service_slide .service-home .service-block1 {
    padding: 0;
  }

  .service_slide .partition4 > div + div {
    margin-top: 0;
  }
  .service_slide .partition4 > div + div + div {
    margin-top: 30px;
  }

  .service_slide .home-slider .slider-contain {
    height: 65vh;
  }

  footer.footer-5 .footer-theme2 .subscribe-block {
    border-left: none;
    border-right: none;
  }

  .tools-parallax-product .tools-description h3 {
    text-align: center;
  }
  .tools-parallax-product .tools-description .tools-form {
    text-align: center;
  }
  .tools-parallax-product .tools-description .tools-form .search-box {
    margin: 20px auto 20px;
  }
  .tools-parallax-product .tools-description .tools-form .btn-find {
    background-size: 1100px;
  }
  .tools-parallax-product .tools-grey {
    order: -1;
    margin-bottom: 30px;
  }
  .tools-parallax-product.full-banner {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .tools_slider .home-slider .slider-contain {
    margin-left: 40px;
  }
  .tools_slider .home-slider .home .tools-parts,
.tools_slider .home-slider .home .tools-parts1 {
    margin-top: -30px;
  }
  .tools_slider .home-slider .home .tools-parts img,
.tools_slider .home-slider .home .tools-parts1 img {
    width: 180px;
  }
  .tools_slider .home-slider .home .tools-parts {
    right: 0;
  }
  .tools_slider .home-slider .home .tools-parts1 {
    right: 10%;
  }
  .tools_slider .home-slider .home #tools-move2 {
    right: 7%;
  }

  .tools_slider .home-slider .slider-contain {
    margin-top: 0;
  }

  .header-tools {
    position: relative;
    top: 0;
    background-color: white;
  }
  .header-tools .top-header {
    background-color: #ff4c3b;
  }
  .header-tools .top-header .container {
    background-color: transparent;
  }
  .header-tools .logo-menu-part {
    background-color: white;
    border-bottom: none;
  }

  .tools_product .multiple-slider > div:nth-child(-n+2) {
    margin-bottom: 0;
  }
  .tools_product .tools-grey {
    margin-top: 30px;
  }
  .tools_product .banner-tools {
    margin-left: 10px;
    margin-right: 10px;
  }

  .about-text p {
    line-height: 24px;
  }

  .tools-service .service-block + .service-block {
    border-left: none;
  }
  .tools-service .service-block svg,
.tools-service .service-block img,
.tools-service .service-block1 svg,
.tools-service .service-block1 img {
    margin-bottom: 0;
  }

  .pet-parallax .pet-decor {
    left: 34px;
    bottom: -86px;
  }
  .pet-parallax .pet-decor img {
    width: 165px;
  }
  .pet-parallax .banner-contain p {
    max-width: 100%;
  }

  .img-zoom-lens {
    display: none;
  }

  .img-zoom-result {
    display: none;
  }

  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    border-right: 1px solid #ddd;
  }
  .rtl .product-right * {
    text-align: center !important;
    justify-content: center !important;
  }
  .rtl .product-right *.size-text {
    text-align: right !important;
  }
  .rtl .rtl-text .product-right .color-variant {
    text-align: center !important;
  }

  h2 {
    font-size: 28px;
  }

  section,
.section-t-space {
    padding-top: 50px;
  }

  .section-b-space {
    padding-bottom: 50px;
  }

  .small-section {
    padding: 30px 0;
  }

  .br[_ngcontent-c25] {
    margin: 3px 0 15px 0 !important;
  }

  .product-full-tab {
    padding-top: 50px !important;
  }

  hr.style1 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 16px;
  }

  .blog-details h4 {
    margin-top: 17px;
    font-size: 13px;
  }
  .blog-details p {
    font-size: 15px;
    line-height: 1.3;
    margin-top: 10px;
  }

  .service-block svg {
    margin-bottom: 20px;
    height: auto;
    width: 46px;
  }
  .service-block img {
    margin-bottom: 20px;
  }

  .partition_3 > div + div {
    margin-top: 30px;
  }

  .partition4 > div + div + div {
    margin-top: 30px;
  }

  .onhover-div .show-div {
    top: 80px;
  }

  .main-menu .brand-logo {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .main-menu .menu-left .navbar {
    padding: 25px 45px 25px 0;
  }

  .banner-timer .banner-text h2 {
    font-size: 20px;
  }
  .banner-timer .timer span {
    width: 65px;
  }
  .banner-timer .timer p {
    font-size: 14px;
  }

  .about-text p {
    line-height: 24px;
  }

  .footer-light .subscribe {
    text-align: center;
    justify-content: center;
    border: none;
    margin-bottom: 5px;
  }
  .footer-light .subscribe p {
    display: none;
  }
  .footer-light .subscribe-form {
    justify-content: center;
    margin-bottom: 0;
  }

  .footer-theme .col {
    max-width: 50%;
    flex-basis: unset;
  }
  .footer-theme .footer-logo {
    margin-bottom: 30px;
  }
  .footer-theme .sub-title h4 {
    margin-bottom: 10px;
  }

  .footer-social {
    margin-top: 15px;
  }

  .subscribe-wrapper {
    order: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer-theme2 .footer-mobile-title {
    display: none !important;
  }
  .footer-theme2 h4 {
    padding-bottom: 10px;
  }
  .footer-theme2 .col {
    flex-basis: unset;
    padding: 20px 0;
  }
  .footer-theme2.section-light .footer-block h4 {
    padding-bottom: 15px;
  }
  .footer-theme2.section-light .footer-block .subscribe-white {
    border: none;
  }
  .footer-theme2 .footer-logo {
    margin-bottom: 20px;
  }
  .footer-theme2 .subscribe-block {
    border: none;
  }

  .social-white li {
    padding-left: 10px;
    padding-right: 10px;
  }

  .category-border div .category-banner .category-box h2 {
    padding: 5px 15px;
    font-size: 30px;
  }

  .no-slider .product-box {
    flex: 0 0 50%;
    max-width: calc(50% - 30px);
    margin: 0 15px 30px !important;
  }
  .no-slider .product-box:nth-last-child(-n+2) {
    margin: 0 15px 0 !important;
  }

  .full-banner {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .full-banner.parallax-layout {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .full-banner .banner-contain h2 {
    font-size: 60px;
  }
  .full-banner .banner-contain h3 {
    font-size: 35px;
  }
  .full-banner .banner-contain h4 {
    font-size: 20px;
    /*padding: 7px 25px;
    background-color: $black;*/
  }

  .collection-banner .contain-banner {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collection-banner .contain-banner.banner-3 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 20px;
  }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 18px;
  }
  .collection-banner .contain-banner.banner-4 h4 {
    font-size: 18px;
  }
  .collection-banner .contain-banner h2 {
    font-size: 25px;
  }

  .home-slider .home {
    height: 65vh;
  }
  .home-slider .slider-contain {
    height: 65vh;
  }
  .home-slider .slider-contain h1 {
    font-size: 36px;
  }
  .home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline {
    margin-top: 17px;
  }

  .background .contain-bg {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .background .contain-bg h4 {
    font-size: 16px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 210px;
  }

  .beauty-about .about-text p {
    text-align: center;
  }

  .theme-card .offer-slider img {
    padding: 10px 10px 10px 0;
  }
  .theme-card.card-border .offer-slider img {
    padding: 10px 10px 10px 30px;
  }

  .multiple-slider > div:nth-child(-n+2) {
    margin-bottom: 30px;
  }

  .full-box .center-slider .offer-slider .product-box .product-info h4 {
    padding-bottom: 5px;
  }
  .full-box .center-slider .offer-slider .product-box .product-info .btn-outline {
    padding: 7px 20px;
  }
  .full-box .theme-card .offer-slider .product-box2 .media img {
    height: 150px;
  }
  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-top: 0;
  }

  .layout-8 .layout-8-bg {
    padding: 0;
  }

  .alert {
    max-width: 45% !important;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
    margin-bottom: 20px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    margin-bottom: 18px;
  }
  .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 15px;
  }
  .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons a {
    font-size: 13px;
    padding: 4px 10px;
    margin: 3px 6px;
  }
  .theme-modal.exit-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 20px;
  }

  .parallax-cls .banner-contain {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 35px;
  }
  .parallax-cls section .banner-contain {
    margin-top: 0;
  }

  .about-section {
    padding-top: 30px;
  }
  .about-section h2 {
    text-align: center;
  }

  .product-box .cart-info {
    bottom: 20px;
  }

  .absolute-banner {
    margin-top: -60px;
  }

  .stripe-section {
    margin-bottom: 25px;
  }
  .stripe-section h5 {
    font-size: 14px;
    margin-bottom: 0;
  }

  .margin-cls {
    margin-top: 0;
  }

  .testimonial .testimonial-slider .media .media-body {
    padding: 0 30px;
    height: 100%;
    align-self: center;
  }
  .testimonial .testimonial-slider .media .media-body p {
    margin-bottom: 0;
  }
  .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-left: none;
    padding-left: 0;
  }

  .blog-detail-page .blog-advance ul {
    margin-bottom: 20px;
  }
  .blog-detail-page .blog-advance p:last-child {
    margin-bottom: 0;
  }

  .collection-product-wrapper .product-top-filter {
    border-top: none;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    border-top: 1px solid #dddddd;
    text-align: left;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    display: none;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    display: none;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 100%;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
    right: 15px !important;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    border-left: 1px solid #dddddd;
    padding: 21px 21px 21px 21px;
    border-top: none;
    border-bottom: none;
    font-size: 16px;
    background: url(/assets/images/dropdown.png) no-repeat 95%;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
    border-right: none !important;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    border-top: 1px solid #dddddd;
  }

  .collection-collapse-block {
    border-bottom: 1px solid #dddddd !important;
  }

  .top-banner-content h4 {
    font-size: 20px;
  }

  .filter-main-btn {
    display: block;
  }

  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 999;
    overflow-y: scroll;
    padding: 15px 0 15px 15px;
    max-width: 350px;
    transition: all 0.5s ease;
  }
  .collection-filter .collection-sidebar-banner {
    text-align: center;
  }
  .collection-filter .theme-card {
    padding-left: 30px;
    padding-right: 30px;
  }
  .collection-filter .collection-sidebar-banner {
    padding: 0 30px;
  }

  .collection-filter-block {
    border: none;
  }

  .collection-mobile-back {
    display: block;
  }

  .collection .section-t-space {
    padding-top: 30px;
  }
  .collection .partition-collection > div:nth-last-child(1) {
    margin-top: 30px;
  }
  .collection .partition-collection > div:nth-last-child(2) {
    margin-top: 30px;
  }

  .right-login {
    margin-top: 30px;
  }

  .contact-page .map iframe {
    height: 350px;
  }
  .contact-page .contact-right {
    padding-bottom: 0;
  }
  .contact-page .contact-right ul li {
    padding-left: 0;
    border-bottom: 1px solid #dddddd;
    text-align: center;
    padding-bottom: 10px;
    margin-top: 15px;
  }
  .contact-page .contact-right ul li .contact-icon {
    position: relative;
    margin: 0 auto;
    border-right: 0;
  }
  .contact-page .contact-right ul li p {
    margin-bottom: 10px;
  }

  .cart-section .cart-table thead th:last-child {
    display: none;
  }
  .cart-section tbody tr td {
    min-width: 135px;
  }
  .cart-section tbody tr td:last-child {
    display: none;
  }
  .cart-section .cart-buttons > div:last-child {
    padding-right: 15px;
  }
  .cart-section tfoot tr td {
    padding-right: 0;
  }

  .wishlist-section tbody tr td {
    min-width: 138px;
  }

  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 55%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 20px;
    background: none #ff4c3b;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .open-popup {
    text-align: left;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup > a {
    color: #ffffff;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
    height: 100vh;
    left: -350px;
    transition: all 0.5s ease;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .collection-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    display: none;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 50%;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    top: 13px;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    left: unset;
    right: 35px;
  }

  .tab-product {
    padding-top: 0;
  }

  .product-right h2 {
    margin-top: 15px;
  }

  .pro_sticky_info {
    border: none;
    padding: 10px 0;
  }

  .product-right {
    text-align: center;
    margin: 20px 0 10px 0;
  }
  .product-right .detail-section,
.product-right .product-icon {
    justify-content: center;
  }
  .product-right .product-description .qty-box {
    justify-content: center;
  }
  .product-right .size-text {
    text-align: left;
  }
  .product-right .timer {
    text-align: left;
  }
  .product-right .product-icon .product-social li {
    padding-right: 20px;
  }

  .product-form-box {
    margin-bottom: 20px;
  }

  .tab-product .nav-material .nav-tabs {
    justify-content: center;
  }

  .dashboard-left {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 0 35px 35px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 #dddddd;
  }
  .dashboard-left .block-title h2 {
    display: none;
  }
  .dashboard-left .block-content {
    border: none;
    padding: 0;
    margin-top: 20px;
  }
  .dashboard-left .block-content ul li:hover {
    padding-left: 0;
  }

  .account-sidebar {
    display: block;
  }

  .rtl .main-menu .menu-left .navbar {
    padding: 25px 0 25px 45px;
  }
  .rtl .tools-parallax-product .tools-description .tools-form .btn-find {
    float: unset;
  }
  .rtl .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-right: none;
    padding-right: 0;
  }
  .rtl .contact-page .contact-right ul li {
    padding-right: 0;
  }
  .rtl .contact-page .contact-right ul li .contact-icon {
    border-left: none;
  }
  .rtl .contact-page .contact-right ul li p {
    text-align: center;
  }
  .rtl .product-right .detail-section,
.rtl .product-right .product-icon {
    justify-content: right;
  }
  .rtl .product-right .size-text {
    text-align: right;
  }
  .rtl .product-right .product-description .qty-box {
    justify-content: right;
  }
  .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
    border-left: none;
  }
  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    padding-right: 20px !important;
  }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    padding: 21px 34px 21px 34px;
  }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-right: 1px solid #dddddd;
  }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-right: none;
  }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count h5 {
    text-align: center;
  }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    padding: 10px 34px 10px 34px;
  }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    background: none #ff4c3b;
  }
  .rtl .banner-timer .timer {
    padding-left: 10px;
  }
  .rtl .banner-timer .timer span {
    width: 55px;
  }
  .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-left: 5px;
    margin-right: unset;
  }
  .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .rating i {
    padding: 0;
  }
  .rtl .beauty-about .text-center {
    text-align: center !important;
  }
  .rtl .beauty-about .about-text p {
    text-align: center;
  }
  .rtl .footer-light .subscribe {
    border-left: none;
  }

  .dark .collection-filter {
    background-color: #2b2b2b;
  }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    border-color: #404040;
  }
  .dark .collection-mobile-back {
    border-color: #404040;
  }
  .dark .collection-mobile-back span {
    color: #929292;
  }
  .dark .collection-collapse-block {
    border-color: #404040 !important;
  }
  .dark .collection-filter {
    box-shadow: none;
  }
  .dark .contact-page .contact-right ul li {
    border-color: #404040;
  }
  .dark .dashboard-left {
    background-color: #232323;
    box-shadow: none;
  }
  .dark header.header-tools .logo-menu-part {
    background-color: #2b2b2b;
  }
}
@media (max-width: 767px) {
  .product-wrapper-grid.list-view .product-box .ng-star-inserted {
    display: block;
    border: 1px solid #eee;
    padding: 15px;
  }
  .product-wrapper-grid.list-view .product-box .img-wrapper,
.product-wrapper-grid.list-view .product-box .img-block {
    margin: 0 auto 15px;
  }
  .product-wrapper-grid.list-view .product-box .product-detail {
    padding-left: 0;
  }

  .border-box.tools-grey .product-box .img-wrapper {
    padding-bottom: 0;
  }
  .border-box.tools-grey .product-box .cart-info a,
.border-box.tools-grey .product-box .cart-info button {
    background-color: #ffffff;
  }

  .detail-cannabis .detail_section {
    margin-top: 30px;
    text-align: center;
  }
  .detail-cannabis .row > div:first-child .detail_section {
    margin-top: 0;
  }

  footer.footer-5 .sub-footer > .container {
    border-top: none;
  }
  footer.footer-5 .dark-layout .footer-title {
    border-bottom: 1px solid #ffffff;
  }

  .service_slide .home-slider .slider-contain {
    height: 60vh;
  }

  .tools-parallax-product .tools-grey .owl-prev,
.tools-parallax-product .tools-grey .owl-next {
    display: none !important;
  }
  .tools-parallax-product.full-banner {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tools_slider .home-slider .home .tools-parts,
.tools_slider .home-slider .home .tools-parts1 {
    display: none;
  }
  .tools_slider .home-slider .slider-contain {
    margin-left: 0;
    justify-content: center !important;
  }

  .tools-service .service-block + .service-block {
    margin-top: 0;
  }
  .tools-service .service-block + .service-block + .service-block {
    margin-top: 20px;
  }

  .about-text p {
    line-height: 25px;
  }

  .tools-grey .product-box .img-wrapper {
    padding-bottom: 35px;
  }
  .tools-grey .product-box .cart-info {
    bottom: 10px;
  }
  .tools-grey .product-box .cart-info button {
    font-size: 0;
    width: unset;
    background-color: #eef0f1;
  }
  .tools-grey .product-box .cart-info button i {
    display: block;
  }
  .tools-grey .product-box .cart-info button:hover i {
    color: #ffffff;
  }
  .tools-grey .product-box .cart-info .mobile-quick-view {
    display: block;
    margin-left: 5px;
  }
  .tools-grey .product-box .quick-view-part {
    display: none;
  }
  .tools-grey .product-box:hover .img-wrapper .front img {
    opacity: 1;
  }
  .tools-grey .product-box:hover .ribbon {
    display: block;
  }

  .tools-parallax-product .tools-grey .owl-prev,
.tools-parallax-product .tools-grey .owl-next {
    display: none !important;
  }
  .tools-parallax-product.full-banner {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .gym-product .product-box .img-wrapper .cart-info {
    display: block;
    height: unset;
    top: unset;
    left: unset;
  }
  .gym-product .product-box .img-wrapper .cart-info i {
    background: none;
    color: #6f6f6f;
  }
  .gym-product .product-box .img-wrapper .cart-info button:hover i,
.gym-product .product-box .img-wrapper .cart-info a:hover i {
    color: #ff4c3b;
  }
  .gym-product .product-box:hover .img-wrapper .cart-info button {
    animation: none;
  }
  .gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
    animation: none;
  }
  .gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
    animation: none;
  }
  .gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
    animation: none;
  }

  .pet-parallax .pet-decor {
    display: none;
  }

  .j-box .product-box .cart-info {
    bottom: 0;
    position: relative;
    opacity: 1;
  }
  .j-box .product-box .cart-info a i {
    padding: 10px;
  }

  .pets-box .product-box .img-wrapper .cart-info {
    transform: none;
  }
  .pets-box .product-box .img-wrapper .cart-info i {
    font-size: 16px;
  }

  .left-header .top-header .header-dropdown li {
    padding: 15px;
  }
  .left-header .main-menu .menu-right .icon-nav li {
    padding-left: 15px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap a i {
    opacity: 1;
    font-size: 16px;
    padding: 5px;
  }
  .portfolio-section.metro-section .product-box .cart-wrap button i {
    font-size: 16px;
    padding: 5px;
  }
  .portfolio-section.metro-section .product-box .product-detail {
    opacity: 1;
    bottom: 15px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .portfolio-section.metro-section .product-box .product-detail h6 {
    color: #000000;
  }
  .portfolio-section.metro-section .product-box:hover .product-detail {
    opacity: 1;
  }
  .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i, .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i, .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
    animation: none;
  }

  .layout3-menu {
    max-width: 100%;
  }
  .layout3-menu .main-menu .brand-logo img {
    height: 25px;
  }

  section,
.section-t-space {
    padding-top: 40px;
  }

  .section-b-space {
    padding-bottom: 40px;
  }

  .product-full-tab {
    padding-top: 30px !important;
  }

  .small-section {
    padding: 23px 0 20px;
  }

  .theme-tab .tab-title a,
.theme-tab .tab-title2 a {
    text-transform: capitalize;
  }

  .darken-layout .footer-title,
.dark-layout .footer-title {
    border-bottom: 1px solid #525252;
  }
  .darken-layout .subscribe-wrapper,
.dark-layout .subscribe-wrapper {
    padding-bottom: 10px;
  }

  .footer-theme2 .footer-link li,
.footer-theme2 .footer-link-b li {
    padding-left: 0;
  }

  .layout2-logo {
    padding-right: 70px;
  }

  .full-scroll-footer .sub-footer p {
    padding: 0;
    line-height: 25px;
  }

  .layout-20 {
    padding: 15px;
  }

  .about-cls .service.border-section {
    border-bottom: none;
  }
  .about-cls .service.small-section {
    padding-bottom: 0;
  }

  .footer-theme2 p {
    margin-bottom: 10px;
  }
  .footer-theme2 .footer-mobile-title {
    display: block !important;
  }
  .footer-theme2 .p-set {
    padding: 0 15px !important;
  }
  .footer-theme2 .col {
    padding: 0;
  }
  .footer-theme2 .footer-link li {
    display: flex;
    padding-top: 10px;
  }
  .footer-theme2 .footer-link h4 {
    padding-bottom: 10px;
  }
  .footer-theme2 .contact-details li {
    padding-left: 0;
    padding-top: 10px;
  }
  .footer-theme2 .footer-link-b li {
    display: flex;
    padding-top: 10px;
  }
  .footer-theme2 .footer-link-b h4 {
    margin-top: 0;
    padding-bottom: 10px;
  }
  .footer-theme2.section-light .footer-block h4 {
    padding-bottom: 10px;
  }
  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 40px 65px;
  }
  .footer-theme2.section-light .footer-block .subscribe-white h2 {
    margin-bottom: 20px;
  }
  .footer-theme2.section-light .footer-block .subscribe-white .form-group {
    display: flex;
  }
  .footer-theme2.section-light .footer-block .subscribe-white .form-group .btn-solid,
.footer-theme2.section-light .footer-block .subscribe-white .form-group .btn-outline {
    margin-top: 0;
  }
  .footer-theme2 .subscribe-block h2 {
    margin-bottom: 20px;
  }
  .footer-theme2 h4 {
    padding-bottom: 10px;
  }

  .darken-layout .footer-theme .footer-mobile-title h4 {
    color: #ffffff;
  }

  .dark-layout .small-section {
    padding-bottom: 40px;
  }
  .dark-layout section {
    padding-top: 0;
  }
  .dark-layout .section-b-space {
    border: none;
    padding-bottom: 0;
  }
  .dark-layout .subscribe-wrapper {
    order: -1;
  }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block {
    padding: 10px 60px;
  }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block h2 {
    display: none;
  }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group {
    display: flex;
  }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .btn-solid,
.dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .btn-outline {
    margin-top: 0;
  }

  .category-bg {
    padding: 30px 40px;
  }
  .category-bg .image-block.even:after {
    bottom: 85%;
  }
  .category-bg .image-block:after {
    width: 25px;
    margin-top: -25px;
  }
  .category-bg .image-block:hover:after {
    height: 50px;
  }
  .category-bg .contain-block h2 {
    font-size: 18px;
    margin-top: 8px;
  }
  .category-bg .contain-block h6 {
    letter-spacing: 0.1em;
    font-size: 12px;
  }
  .category-bg .contain-block:after {
    width: 25px;
  }
  .category-bg .contain-block.even:after {
    top: 87%;
  }
  .category-bg .contain-block .category-btn {
    margin-bottom: 10px;
    margin-top: 10px;
    letter-spacing: 0.1em;
    padding: 5px 15px;
  }
  .category-bg .contain-block:after {
    bottom: 87%;
  }
  .category-bg .contain-block:hover:after {
    height: 50px;
  }

  .layout9-box .product-box .img-block {
    min-height: unset;
  }

  .service-block .media {
    display: block;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .service-block svg,
.service-block img {
    margin-right: 0;
  }
  .service-block + .service-block {
    border-left: 0;
    margin-top: 20px;
  }
  .service-block:last-child .media .media-body p {
    margin-bottom: 5px;
  }

  .partition2 {
    margin-bottom: -25px;
  }
  .partition2 > div {
    margin-bottom: 25px;
  }

  .partition3 > div + div {
    margin-top: 30px;
  }

  .partition4 > div + div {
    margin-top: 30px;
  }

  .center-slider {
    border-left: none;
    border-right: none;
  }

  .about-text p {
    line-height: 25px;
  }

  .banner-timer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .banner-timer .banner-text {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.65);
  }
  .banner-timer .banner-text h2 {
    font-size: 15px;
  }
  .banner-timer .timer-box {
    text-align: center;
  }
  .banner-timer .timer {
    margin-top: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
  }
  .banner-timer .timer p {
    font-size: 14px;
  }
  .banner-timer .timer span {
    width: 40px;
  }
  .banner-timer .timer span .padding-l {
    padding-left: 10px;
  }

  .footer-title {
    margin-top: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
  .footer-title.footer-mobile-title {
    margin-top: 0;
  }
  .footer-title h4 {
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
  }
  .footer-title.active .according-menu {
    font: normal normal normal 14px/1 FontAwesome;
  }
  .footer-title.active .according-menu:before {
    content: "\f106";
    position: absolute;
    right: 2px;
    top: 2px;
  }
  .footer-title .according-menu {
    font: normal normal normal 14px/1 FontAwesome;
  }
  .footer-title .according-menu:before {
    content: "\f107";
    position: absolute;
    right: 2px;
    top: 2px;
  }

  .banner-slider .home-banner > div img {
    margin-top: 30px;
  }
  .banner-slider .home-banner > div:last-child img {
    margin-top: 30px;
  }

  .lookbook .row > div:last-child .lookbook-block {
    margin-top: 30px;
  }

  .lookbook-section .row > div:first-child .lookbook-img > div:last-child {
    margin-bottom: 25px;
  }
  .lookbook-section .lookbook-img > div:last-child img {
    margin-top: 0;
  }

  .box-layout-body .box-layout {
    margin-top: -40px;
  }

  .home-slider.fullpage .slick-dots {
    display: flex;
    bottom: 70px;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  .home-slider.fullpage .slick-dots li button:before {
    color: #0a0100;
  }

  .footer-contant {
    text-align: left;
  }
  .footer-contant .footer-logo {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .footer-contant li {
    text-align: left;
  }
  .footer-contant .footer-social ul {
    margin: unset;
  }
  .footer-contant .footer-social ul li {
    padding-right: 25px;
    padding-left: 0;
  }

  .social-white ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-white li:first-child {
    padding-left: 0;
  }

  .footer-theme {
    text-align: center;
  }
  .footer-theme .footer-mobile-title {
    display: block !important;
  }
  .footer-theme .col {
    max-width: 100%;
    flex-basis: unset;
  }
  .footer-theme .footer-social {
    margin-bottom: 15px;
  }
  .footer-theme .sub-title li {
    padding-top: 7px;
  }
  .footer-theme .sub-title .contact-list i {
    position: relative;
    top: 0;
    margin-right: 10px;
  }
  .footer-theme .sub-title .contact-list li {
    padding-left: 0;
  }

  .footer-social ul {
    margin: 0 auto;
  }
  .footer-social li {
    padding: 0 15px;
  }

  .footer-end {
    text-align: center;
  }

  .sub-footer .payment-card-bottom {
    justify-content: center;
    padding-bottom: 25px;
  }

  .subscribe-wrapper {
    margin: 10px 0 0 0;
    order: 0;
  }

  .social-white {
    margin-bottom: 10px;
  }

  .category-border div .category-banner .category-box h2 {
    font-size: 25px;
  }

  .box-layout .small-section {
    padding-top: 0;
  }

  .white-layout .footer-theme2 > div:nth-child(2) {
    order: -1;
  }
  .white-layout .footer-theme2.footer-border {
    padding: 0;
    border: none;
  }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 540px;
    width: 100%;
  }
  .white-layout .footer-theme2 .footer-block .footer-container .footer-mobile-title {
    margin-top: 20px;
  }
  .white-layout .footer-theme2 .footer-block .subscribe-white h2 {
    display: none;
  }

  .product-box .cart-detail,
.product-wrap .cart-detail {
    top: 5px;
    right: 10px;
  }

  .product-box .img-block {
    min-height: 350px;
  }
  .product-box .cart-info,
.product-box .cart-detail {
    opacity: 1;
  }
  .product-box .img-wrapper .cart-box {
    opacity: 1;
  }
  .product-box:hover .img-wrapper .cart-box {
    animation: none;
  }
  .product-box:hover .cart-info button,
.product-box:hover .cart-detail button {
    animation: none;
  }
  .product-box:hover .cart-info a i,
.product-box:hover .cart-detail a i {
    animation: none;
  }
  .product-box:hover .cart-info a:nth-child(2) i,
.product-box:hover .cart-detail a:nth-child(2) i {
    animation: none;
  }
  .product-box:hover .cart-info a:nth-child(3) i,
.product-box:hover .cart-detail a:nth-child(3) i {
    animation: none;
  }
  .product-box:hover .cart-info a:nth-child(4) i,
.product-box:hover .cart-detail a:nth-child(4) i {
    animation: none;
  }

  .theme-tab .tab-title2 {
    font-size: 20px;
  }
  .theme-tab .tab-title2 li {
    padding-left: 15px;
    padding-right: 15px;
  }
  .theme-tab .tab-title2:after {
    top: 15px;
  }

  .multiple-slider > div:nth-child(-n+3) {
    margin-bottom: 30px;
  }

  .full-banner {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .full-banner.parallax-layout {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .full-banner .banner-contain h2 {
    font-size: 75px;
  }

  .collection-banner .contain-banner {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collection-banner .contain-banner.banner-3 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 20px;
  }
  .collection-banner .contain-banner.banner-3 h4 {
    font-size: 18px;
  }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 18px;
  }
  .collection-banner .contain-banner h2 {
    font-size: 35px;
  }

  .home-slider .home {
    height: 60vh;
  }
  .home-slider .slider-contain {
    height: 60vh;
  }
  .home-slider .slider-contain h1 {
    font-size: 35px;
    margin-top: 5px;
  }
  .home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline {
    margin-top: 10px;
    padding: 10px 25px;
  }
  .home-slider:hover .owl-next {
    right: 80px;
  }
  .home-slider:hover .owl-prev {
    left: 80px;
  }

  .background .col {
    flex-basis: unset;
  }
  .background .contain-bg {
    margin: 10px 0;
  }

  .full-box .center-slider .offer-slider {
    text-align: center;
  }
  .full-box .center-slider .offer-slider .product-box {
    border: none;
    display: inline-block;
  }
  .full-box .center-slider .offer-slider .product-box .img-wrapper {
    text-align: center;
  }
  .full-box .center-slider .offer-slider .product-box .img-wrapper img {
    height: 200px;
    width: auto;
  }
  .full-box .center-slider .offer-slider .product-box:hover {
    box-shadow: none;
  }
  .full-box .theme-card .offer-slider .sec-1 {
    display: flex;
  }
  .full-box .theme-card .offer-slider .sec-1 .product-box2 .media .media-body .color-variant li {
    height: 18px;
    width: 18px;
    padding-right: 3px;
  }
  .full-box .theme-card .offer-slider .sec-1 .product-box2 + .product-box2 {
    border-top: none;
  }

  .theme-tab .tab-title li,
.theme-tab .tab-title2 li {
    padding-left: 5px;
    padding-right: 5px;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 150px;
    padding: 5px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
    margin-bottom: 20px;
    font-size: 15px;
  }
  .theme-modal.cart-modal .product-section {
    display: none;
  }
  .filter-main-btn span {
    font-size: 16px;
  }

  .tap-top {
    bottom: 70px;
    right: 20px;
  }

  .blog-detail-page .comment-section li {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .blog-page .blog-sidebar .theme-card {
    padding: 20px;
  }
  .blog-page .blog-sidebar .theme-card .popular-blog li p {
    margin-top: 5px;
  }

  .product-pagination .pagination {
    justify-content: center;
    border-left: 1px solid #dddddd;
  }

  .testimonial {
    background-color: #f7f7f7;
  }
  .testimonial .testimonial-slider .media .media-body {
    padding: 30px 30px 30px 30px;
  }

  .about-page {
    text-align: center;
  }
  .about-page p {
    line-height: 1.5;
  }

  .team h2 {
    font-size: 25px;
  }

  .collection .partition-collection > div:nth-last-child(1), .collection .partition-collection > div:nth-last-child(2), .collection .partition-collection > div:nth-last-child(3) {
    margin-top: 30px;
  }

  .collection-product-wrapper .product-pagination .pagination .page-item a {
    padding: 15px 18px;
  }
  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    justify-content: center;
    padding-right: 0;
  }
  .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
    padding: 10px 0;
  }
  .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important;
  }
  .faq-section .theme-accordion .card .card-header button {
    font-size: 14px !important;
    white-space: normal;
    width: 100%;
    padding: 16px 30px 16px 18px !important;
  }

  .register-page .theme-card {
    padding: 25px;
  }
  .register-page .theme-card .theme-form input {
    margin-bottom: 25px;
    padding: 13px 18px;
  }

  .search-product > div:nth-last-child(4) {
    margin-top: 30px;
  }

  .cart-section tbody tr td a,
.wishlist-section tbody tr td a {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .cart-section .cart-table thead th:nth-last-child(-n+4) {
    display: none;
  }
  .cart-section tbody tr td:nth-last-child(-n+4) {
    display: none;
  }
  .cart-section tbody tr td .mobile-cart-content {
    display: flex;
  }

  .wishlist-section .cart-table thead th:nth-last-child(-n+3) {
    display: none;
  }
  .wishlist-section tbody tr td:nth-last-child(-n+3) {
    display: none;
  }
  .wishlist-section tbody tr td .mobile-cart-content {
    display: flex;
  }

  .blog-page .order-sec {
    order: -1;
  }
  .blog-page .blog-media:last-child {
    margin-bottom: 30px;
  }
  .blog-page .blog-sidebar ul li {
    display: block;
  }

  .tab-pane iframe {
    width: 100%;
  }

  .product-order .product-order-detail img {
    height: auto;
  }

  .rtl .main-nav-center .toggle-nav {
    left: 75px;
  }
  .rtl .tab-left .theme-tab .tab-content-cls .product-box .cart-info .mobile-quick-view {
    margin-right: 5px;
    margin-left: 0;
  }
  .rtl .service-block svg,
.rtl .service-block img {
    margin-right: 0;
  }
  .rtl .service-block + .service-block {
    border-right: 0;
  }
  .rtl .service-block .media .media-body h4,
.rtl .service-block .media .media-body p {
    text-align: center;
  }
  .rtl .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-right: none;
    border-left: 1px solid #dddddd;
  }
  .rtl .collection-product-wrapper .product-pagination .product-search-count-bottom {
    padding-left: 5px;
    padding-right: 5px;
  }
  .rtl .footer-title.active .according-menu:before {
    right: unset;
    left: 2px;
  }
  .rtl .footer-title .according-menu:before {
    right: unset;
    left: 2px;
  }
  .rtl .footer-end p {
    text-align: center;
  }
  .rtl .sub-footer .payment-card-bottom li {
    padding-left: 7px;
    padding-right: 7px;
  }
  .rtl .footer-theme2.section-light .footer-block h4 {
    text-align: right;
  }
  .rtl .footer-theme2.section-light .footer-block .footer-logo {
    text-align: right;
  }
  .rtl .footer-theme2 .footer-title h4 {
    text-align: right;
  }
  .rtl .footer-contant li {
    text-align: right;
  }
  .rtl .footer-contant .footer-social ul li {
    padding-right: 0;
    padding-left: 25px;
  }
  .rtl .dark-layout .footer-contant .footer-logo {
    text-align: right;
  }
  .rtl .dark-layout .footer-contant p {
    text-align: right;
  }
  .rtl .footer-theme .sub-title .contact-list i {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl .footer-theme .sub-title .contact-list li {
    padding-right: 0;
  }
  .rtl .layout2-logo {
    padding-left: 70px;
    padding-right: 0;
  }
  .rtl .full-box .theme-card .offer-slider .sec-1 .product-box2 + .product-box2 {
    border-left: none;
    border-right: 1px solid #f1f5f4;
  }
  .rtl .tools_product .tools-grey .product-box .cart-info .mobile-quick-view {
    margin-left: 0;
    margin-right: 5px;
  }
  .rtl .tools-parallax-product.tools-grey .product-box .cart-info .mobile-quick-view {
    margin-left: 0;
    margin-right: 5px;
  }
  .rtl .product-pagination .pagination {
    border-right: 1px solid #dddddd;
    border-left: none;
  }

  .dark .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-color: #404040;
  }
  .dark .product-pagination .pagination {
    border-color: #404040;
  }
}
@media (max-width: 577px) {
  .header-6 .main-menu .brand-logo {
    position: relative;
    left: 0;
    transform: none;
  }

  .layout3-menu .main-menu {
    height: 75px;
  }
  .layout3-menu .brand-logo {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .tab-left .theme-tab .left-side .left-tab-title {
    margin-bottom: 15px;
  }

  .detail-cannabis .detail_section > div {
    padding: 30px 25px;
  }

  .green-gradient .top-header .header-dropdown li {
    padding: 0;
  }

  .service_slide .home-slider .slider-contain {
    height: 58vh;
  }

  .header-tools .top-header .header-dropdown li {
    padding: 0;
  }

  .header-gym {
    position: relative;
    background-color: #000000;
  }
  .header-gym .mobile-fix-option {
    z-index: 1;
  }

  .tools-parallax-product.full-banner {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .tools-service.absolute-banner .absolute-bg {
    padding-bottom: 20px;
    box-shadow: 0 0 8px 0 #ddd;
  }
  .tools-service .service-block + .service-block {
    margin-top: 20px;
  }
  .tools-service .service-block svg,
.tools-service .service-block img,
.tools-service .service-block1 svg,
.tools-service .service-block1 img {
    margin-bottom: 7px;
  }

  .tools-parallax-product.full-banner {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .gym-product .part-cls > div:nth-last-child(-n+3) .product-box {
    margin-top: 30px;
  }
  .gym-product .partition-cls > div:nth-child(2) {
    margin-top: 30px;
  }
  .gym-product .product-box:hover {
    transform: unset;
  }

  .left-header .top-header {
    height: 70px;
  }
  .left-header .top-header .header-dropdown li {
    padding: 0;
  }
  .left-header .top-header .header-dropdown .mobile-wishlist img,
.left-header .top-header .header-dropdown .mobile-account img {
    display: none;
  }
  .left-header .top-header .header-dropdown .mobile-wishlist i,
.left-header .top-header .header-dropdown .mobile-account i {
    display: block;
  }
  .left-header .main-menu .menu-left .mobile-logo {
    position: absolute;
    padding: 0 !important;
    transform: unset;
    right: unset;
    left: 15px;
    margin-right: 0;
    top: 16px;
  }
  .left-header .main-menu .menu-left .navbar {
    position: absolute;
    right: 15px;
    padding: 0 !important;
    top: 22px;
  }

  .pet-parallax .full-banner {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .portfolio-section.metro-section .product-box .cart-wrap a i {
    font-size: 18px;
    padding: 7px;
  }
  .portfolio-section.metro-section .product-box .cart-wrap button i {
    font-size: 18px;
    padding: 7px;
  }

  .box-layout-body .box-layout .full-banner {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .layout3-menu .main-menu .menu-left {
    width: calc(100vw - 32px);
  }
  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: 15px;
  }
  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
    display: none;
  }
  .layout3-menu .main-menu > div:nth-child(2) {
    position: absolute;
    left: 0;
    right: 50%;
    transform: none;
    top: 0;
  }
  .layout3-menu .main-menu > div:nth-child(3) {
    height: 0;
  }
  .layout3-menu .brand-logo {
    left: 0;
    transform: none;
  }

  .layout2-logo {
    padding-right: 0;
  }

  .dark-layout .footer-theme2 .col {
    padding: 0 15px;
  }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block {
    padding: 10px 20px 20px 10px;
  }

  .footer-theme2 .col {
    padding: 0 15px;
  }
  .footer-theme2 .form-p {
    padding: 0;
  }
  .footer-theme2.footer-border {
    padding: 15px 0;
  }
  .footer-theme2 .subscribe-block {
    padding: 0 75px;
  }
  .footer-theme2 .footer-link h4 {
    padding-bottom: 10px;
  }
  .footer-theme2 .footer-link li {
    padding-right: 20px;
  }
  .footer-theme2 .footer-link-b h4 {
    padding-bottom: 15px;
  }
  .footer-theme2 .footer-link-b li {
    padding-right: 20px;
  }

  .full-scroll-menu .container-fluid, .full-scroll-menu .container-sm, .full-scroll-menu .container-md, .full-scroll-menu .container-lg, .full-scroll-menu .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }

  .layout-8 {
    padding: 0 15px;
  }

  .layout-20 {
    padding: 0;
  }

  .addcart_btm_popup {
    display: none !important;
  }

  .tab-product .tab-content.nav-material p,
.product-full-tab .tab-content.nav-material p {
    padding: 15px 0;
  }

  .layout7-product .product-box .details-product {
    padding: 5px;
  }
  .layout7-product .product-box:hover .details-product {
    animation: none;
  }

  .beauty-about .service .service-block1 {
    margin-bottom: 20px;
  }
  .beauty-about .service .service-block1:last-child {
    margin-bottom: 0;
  }

  #fb-root {
    display: none;
  }

  .section-b-space {
    padding-bottom: 30px;
  }

  section,
.section-t-space {
    padding-top: 30px;
  }

  h2 {
    font-size: 24px;
  }

  .rtl-btn {
    width: 30px;
    height: 32px;
  }

  .tab-product .tab-content.nav-material p,
.product-full-tab .tab-content.nav-material p {
    padding: 15px 0;
  }

  .sticky.fixed .top-header {
    display: block;
  }

  .top-header .header-dropdown .compare {
    display: none;
  }

  .search-overlay > div .closebtn {
    top: 15px;
    right: 30px;
  }

  .btn-solid {
    padding: 7px 15px;
  }

  .product-box .product-detail .color-variant,
.product-box .product-info .color-variant,
.product-wrap .product-detail .color-variant,
.product-wrap .product-info .color-variant {
    padding-top: 10px;
  }

  footer {
    margin-bottom: 60px;
  }
  footer .btn-solid {
    padding: 13px 29px;
  }
  footer p {
    line-height: 20px;
  }

  .partition1 {
    margin-bottom: -15px;
  }
  .partition1 > div {
    margin-bottom: 15px;
  }

  .theme-modal .modal-dialog .quick-view-modal .product-right {
    margin: 15px 0 10px 0;
  }
  .theme-modal .modal-dialog .quick-view-modal .product-right h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .theme-modal .modal-dialog .quick-view-modal .product-right h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .theme-modal .modal-dialog .quick-view-modal .product-right .color-variant li {
    height: 25px;
    width: 25px;
  }
  .theme-modal .modal-dialog .quick-view-modal .product-right .border-product {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .theme-modal .modal-dialog .quick-view-modal .product-right .size-box {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .theme-modal .modal-dialog .quick-view-modal .product-right .product-description .qty-box {
    margin-top: 5px;
  }
  .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media a img {
    max-width: 105px;
    margin-bottom: 10px;
  }
  .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons a {
    padding: 4px 5px;
  }
  .theme-modal.exit-modal .media {
    align-items: center;
  }
  .theme-modal.exit-modal .media img {
    width: 25%;
  }
  .theme-modal.exit-modal .media .media-body h4 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .theme-modal.exit-modal .media .media-body h2 {
    margin-bottom: 2px;
  }
  .theme-modal.exit-modal .media .media-body h5 {
    font-size: 16px;
    line-height: 18px;
  }
  .theme-modal.exit-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 12px;
  }

  .white-layout .small-section .footer-theme2 .col {
    padding: 0 15px;
  }

  .social-white {
    margin-top: 25px;
  }

  .category-border div .category-banner .category-box h2 {
    font-size: 30px;
    padding: 10px 20px;
  }

  .filter-main-btn span {
    font-size: 14px;
  }

  .category-bg .image-block:after {
    display: none;
  }
  .category-bg .contain-block:after {
    display: none;
  }
  .category-bg .order-section div {
    order: 1;
  }
  .category-bg .order-section div:nth-child(2) {
    order: 4;
    margin-bottom: 30px;
  }
  .category-bg .order-section div:nth-child(3) {
    order: 3;
  }
  .category-bg .order-section div:nth-child(4) {
    order: 2;
    margin-bottom: 30px;
  }
  .category-bg .order-section div:nth-child(5) {
    order: 5;
  }
  .category-bg .order-section div:nth-child(6) {
    order: 6;
  }
  .category-bg .contain-block {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .theme-tab .tab-title {
    margin-bottom: 25px;
  }

  .full-banner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .full-banner.parallax-layout {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .full-banner .banner-contain {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 25px;
  }
  .full-banner .banner-contain .btn-solid,
.full-banner .banner-contain .btn-outline {
    padding: 10px 20px;
  }
  .full-banner.p-left .banner-contain, .full-banner.p-right .banner-contain {
    float: none;
  }
  .full-banner .banner-contain h2 {
    font-size: 65px;
  }
  .full-banner .banner-contain h3 {
    font-size: 40px;
  }
  .full-banner .banner-contain h4 {
    font-size: 18px;
    letter-spacing: 0.3em;
  }

  .home-slider .home {
    height: 58vh;
  }
  .home-slider .slider-contain {
    height: 58vh;
  }
  .home-slider .slider-contain h1 {
    font-size: 30px;
  }
  .home-slider:hover .owl-next {
    right: 70px;
  }
  .home-slider:hover .owl-prev {
    left: 70px;
  }
  .home-slider:hover .owl-prev,
.home-slider:hover .owl-next {
    transform: scale(1.5);
  }
  .home-slider.fullpage .slick-dots {
    display: flex;
    bottom: 60px;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  .home-slider.fullpage .home .slider-contain {
    text-align: center;
    justify-content: center;
    padding-left: 0;
  }
  .home-slider.fullpage .home .slider-contain > div {
    background-color: rgba(0, 0, 0, 0.15);
    padding: 30px 50px;
  }
  .home-slider.fullpage .home .slider-contain h1,
.home-slider.fullpage .home .slider-contain p {
    color: #ffffff;
  }
  .home-slider.fullpage .home .slider-contain h1 {
    font-size: 22px;
  }
  .home-slider.fullpage .home .slider-contain p {
    max-width: 330px;
    font-size: 12px;
  }
  .home-slider.fullpage .home .slider-contain .btn-solid {
    padding: 10px 15px;
  }

  .background .contain-bg {
    background-color: unset;
    padding-top: 0;
    padding-bottom: 0;
    margin: 10px 0;
  }
  .background .contain-bg:hover {
    background-color: unset;
  }

  .theme-tab .tab-content .product-tab .tab-box {
    flex: 0 0 100%;
    max-width: calc(100% - 10px);
  }
  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+2) {
    margin: 0 5px 10px;
  }
  .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n+1) {
    margin: 0 5px 0;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 150px;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
    margin-right: 10px;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    margin-top: 7px;
    height: 18px;
    width: 18px;
  }

  .alert {
    right: 0 !important;
    left: 0;
    max-width: 90% !important;
  }

  .tap-top {
    width: 35px;
    height: 35px;
    padding: 2px;
    font-size: 19px;
  }

  .absolute-banner {
    margin-top: 0;
  }
  .absolute-banner .absolute-bg {
    box-shadow: none;
    padding-bottom: 0;
  }
  .absolute-banner.banner-padding {
    padding-top: 0;
  }

  .box-layout-body .box-layout {
    margin-top: 0;
  }

  .color-picker a.handle {
    width: 30px;
    height: 35px;
  }
  .color-picker a.handle i {
    margin-left: 7px;
    font-size: 17px;
  }

  .full-scroll-footer {
    display: none;
  }

  .cart_qty_cls {
    top: -10px;
  }

  .setting-sidebar {
    width: 35px;
    height: 35px;
  }
  .setting-sidebar i {
    font-size: 19px;
  }

  .dark-light {
    width: 35px;
    height: 35px;
  }

  .dashboard-right .dashboard {
    padding: 20px;
  }

  .error-section {
    padding: 100px 0;
  }
  .error-section h1 {
    font-size: 100px;
  }
  .error-section h2 {
    margin: 20px 0;
    font-size: 18px;
  }

  .about-page .about-head {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .about-page .about-head .theme-breadcrumb .breadcrumb {
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 0;
  }

  .breadcrumb-section .page-title {
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    display: unset;
  }
  .breadcrumb-section .breadcrumb {
    justify-content: center;
    padding: 0;
    line-height: 30px;
  }

  .blog-detail-page .blog-detail .post-social li {
    display: block;
  }
  .blog-detail-page .blog-detail .post-social li + li {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }

  .collection .collection-block .collection-content p {
    margin-bottom: 10px;
  }
  .collection .collection-block .collection-content h4,
.collection .collection-block .collection-content h3 {
    margin-bottom: 5px;
  }

  .collection-product-wrapper .product-wrapper-grid .product-box {
    margin-top: 30px;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    padding: 10px 20px 10px 20px;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
    font-size: 14px;
    line-height: 20px;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 100%;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    padding: 10px 20px 10px 20px;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
    top: 11px;
  }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    border-right: 1px solid #dddddd !important;
    border-bottom: 1px solid #dddddd;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-bottom: 1px solid #dddddd;
    width: 100%;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    border-right: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
    top: 14px;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 100%;
    border-top: none;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    left: unset;
    right: 35px !important;
    top: 14px;
  }

  .search-product > div:nth-last-child(5) {
    margin-top: 30px;
  }

  .cart-section .cart-buttons > div:last-child {
    padding-right: 15px;
  }
  .cart-section .cart-buttons .btn-solid {
    padding: 7px 8px;
  }

  .wishlist-section .btn-solid {
    padding: 7px 8px;
  }

  .product-box .img-wrapper .lable-block .lable3 {
    font-size: 12px;
    padding: 13px 8px;
  }
  .product-box .img-block .lable-wrapper .lable1,
.product-box .img-block .lable-wrapper .lable2 {
    padding: 6px 9px 8px 15px;
    font-size: 12px;
  }

  .tab-product .tab-content.nav-material p {
    line-height: 1.3;
  }

  .slider-right-nav .slick-slide:first-child > div {
    margin-top: 15px;
  }

  .collection-wrapper .order-up {
    order: -1;
  }

  .lookbook .lookbook-block .lookbook-dot .dot-showbox {
    width: 90px;
    top: -70px;
  }
  .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
    line-height: 16px;
  }

  .checkout-page .checkout-form .checkout-details {
    padding: 15px;
  }

  .padding-cls {
    padding-left: 15px;
    padding-right: 15px;
  }

  .success-text i {
    font-size: 40px;
  }
  .success-text p {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.2;
  }
  .success-text h2 {
    margin-bottom: 10px;
  }

  .order-success-sec > div {
    margin-top: 15px;
  }

  .delivery-sec {
    padding: 15px;
    margin-top: 15px;
  }
  .delivery-sec h3,
.delivery-sec h2 {
    font-size: 20px;
  }

  .product-order h3 {
    font-size: 20px;
  }
  .product-order .product-order-detail .order_detail h4,
.product-order .product-order-detail .order_detail h5 {
    font-size: 14px;
    line-height: 1.2;
  }
  .product-order .total-sec ul li {
    font-size: 16px;
  }
  .product-order .final-total h3 {
    font-size: 16px;
  }

  .main-menu .menu-left .navbar i {
    font-size: 24px;
  }

  .template-password #container #login {
    margin-bottom: 0;
  }

  .bundle .bundle_img .img-box img {
    max-width: 70px;
  }

  .timer p {
    font-size: 14px;
  }

  .rtl .header-6 .main-menu .brand-logo {
    right: 0;
    left: unset;
    margin-right: 0;
  }
  .rtl .main-nav-center .toggle-nav {
    left: 15px;
  }
  .rtl .breadcrumb-section .page-title h2 {
    text-align: center;
  }
  .rtl .blog-detail-page .blog-detail .post-social li + li {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-right: 1px solid #dddddd;
  }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    padding: 10px 34px 10px 34px;
  }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-left: none;
  }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
    left: 25px;
  }
  .rtl .home-slider .owl-next,
.rtl .center-slider .owl-next {
    right: unset;
    left: 1px;
  }
  .rtl .home-slider .owl-prev,
.rtl .center-slider .owl-prev {
    left: unset;
    right: 1px;
  }
  .rtl .home-slider:hover .owl-next,
.rtl .center-slider:hover .owl-next {
    right: unset;
    left: 30px;
  }
  .rtl .home-slider:hover .owl-prev,
.rtl .center-slider:hover .owl-prev {
    left: unset;
    right: 30px;
  }
  .rtl .layout2-logo {
    padding-left: 0;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart {
    left: unset !important;
    right: -118px !important;
  }
  .rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
    right: unset;
    left: 0 !important;
  }
  .rtl .layout3-menu .main-menu > div:nth-child(2) {
    left: unset;
    right: 0;
  }
  .rtl .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    left: 15px;
  }
  .rtl .layout3-menu .brand-logo {
    transform: translateX(-15%);
  }
  .rtl .top-header .header-dropdown li i {
    padding-left: 0;
  }
  .rtl .top-header .header-dropdown .mobile-account .onhover-show-div {
    right: unset;
    left: 0;
  }

  .dark header.header-gym {
    background-color: #020202;
  }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    border-color: #404040 !important;
  }
  .dark .tools-service.absolute-banner .absolute-bg {
    box-shadow: none;
  }
  .dark .full-banner .banner-contain {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .dark .absolute-banner .absolute-bg {
    box-shadow: none;
  }
}
@media (min-width: 577px) {
  .rtl .onhover-dropdown:before {
    right: unset;
    left: 3px;
  }
  .rtl .onhover-dropdown .onhover-show-div {
    right: unset;
    left: 0;
  }
  .rtl .main-menu .category-nav-right .navbar {
    padding: 40px 45px 40px 0;
  }
  .rtl .main-menu .menu-right .icon-nav li {
    padding-left: 0;
    padding-right: 20px;
  }
  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart, .rtl .main-menu .menu-right .onhover-div .show-div.setting {
    left: 0;
    right: unset;
  }
  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart li .close-circle {
    right: unset;
    left: 0;
  }
  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart li .total h5 span {
    float: left;
  }
}
@media (max-width: 480px) {
  .service_slide .home-slider .slider-contain {
    height: 55vh;
  }

  .left-header .main-menu .menu-left .mobile-logo {
    top: 22px;
  }

  .title1 .title-inner1 {
    margin-bottom: 20px;
  }

  .title2 .title-inner2 {
    margin-bottom: 20px;
  }

  .title3 .line {
    margin-bottom: 20px;
  }

  .title4 .line {
    margin-bottom: 20px;
  }

  .pet-parallax .full-banner {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-theme2 p {
    line-height: 25px;
  }

  .footer-light .subscribe-form .form-control {
    width: 280px;
  }

  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 388px;
  }
  .white-layout .footer-theme2 .footer-block .subscribe-white .btn-solid,
.white-layout .footer-theme2 .footer-block .subscribe-white .btn-outline {
    padding: 13px 8px;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .btn-solid,
.dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .btn-outline {
    padding: 13px 8px;
  }

  .footer-theme2 .subscribe-block .form-control {
    letter-spacing: 3px;
  }

  .category-border div .category-banner .category-box h2 {
    font-size: 25px;
  }

  .product-box .img-wrapper .cart-box i,
.product-wrap .img-wrapper .cart-box i {
    font-size: 16px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .title-borderless {
    margin-bottom: 20px;
  }

  .subscribe-form.classic-form .form-control {
    margin-left: 0;
  }

  .product-box .img-block {
    min-height: 290px;
  }

  .theme-tab .tab-title li {
    padding-left: 5px;
    padding-right: 5px;
  }
  .theme-tab .tab-title2 {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
  }
  .theme-tab .tab-title2 li {
    padding-left: 15px;
    padding-right: 15px;
  }
  .theme-tab .tab-title2:after {
    display: none;
  }

  .blog-details {
    padding-left: 0;
    padding-right: 0;
  }

  .full-banner {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .full-banner.parallax-layout {
    padding-top: 4px;
    padding-bottom: 40px;
  }
  .full-banner .banner-contain h2 {
    font-size: 60px;
  }
  .full-banner .banner-contain h4 {
    font-size: 18px;
  }

  .collection-banner .contain-banner {
    padding-left: 30px;
    padding-right: 30px;
  }
  .collection-banner .contain-banner h2 {
    font-size: 24px;
  }

  .home-slider .home {
    height: 55vh;
  }
  .home-slider .slider-contain {
    height: 55vh;
  }
  .home-slider .slider-contain h1 {
    font-size: 28px;
  }
  .home-slider .slider-contain h4 {
    letter-spacing: 0.1em;
  }
  .home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline {
    margin-top: 5px;
  }
  .home-slider:hover .owl-prev,
.home-slider:hover .owl-next {
    transform: scale(1.2);
  }
  .home-slider:hover .owl-next {
    right: 60px;
  }
  .home-slider:hover .owl-prev {
    left: 60px;
  }

  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 448px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 30px 30px;
  }
  .footer-theme2.section-light .footer-block .subscribe-white .form-control {
    letter-spacing: 3px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 35px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
    margin-bottom: 10px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    margin-bottom: 15px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
    height: 40px;
    padding: 0;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .btn-solid,
.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .btn-outline {
    padding: 8px 15px;
  }
  .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media {
    display: block;
    text-align: center;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
    font-size: 15px;
  }

  .tab-bg,
.bg-block {
    padding: 35px 25px 30px 25px;
  }

  .setting-box {
    width: 280px;
  }

  .setting-sidebar.open-icon {
    right: 278px;
  }

  .setting-box .setting-contant .setting_buttons {
    display: flex;
  }
  .setting-box .setting-contant .setting_buttons li {
    width: 40% !important;
  }

  .about-page p {
    line-height: 1.5;
  }

  .testimonial .testimonial-slider .media {
    display: inline-block;
  }
  .testimonial .testimonial-slider .media img {
    margin: 0 auto;
    height: 100px;
    width: 100px;
  }
  .testimonial .testimonial-slider .media .media-body {
    padding: 0;
    text-align: center;
  }

  .blog-detail-page .blog-contact h2 {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .blog-detail-page .blog-contact .theme-form input,
.blog-detail-page .blog-contact .theme-form textarea {
    padding: 12px 20px;
    margin-bottom: 20px;
  }
  .blog-detail-page .blog-detail h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .blog-detail-page .blog-detail img {
    margin-bottom: 25px;
  }
  .blog-detail-page .blog-advance ul {
    line-height: 1.5;
  }
  .blog-detail-page .blog-advance img {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .blog-detail-page .blog-advance p {
    line-height: 1.5;
  }
  .blog-detail-page .comment-section li {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .blog-detail-page .comment-section li p {
    line-height: 1.5;
  }
  .blog-detail-page .comment-section li h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blog-detail-page .comment-section li h6 span {
    display: inherit;
    margin-left: 0;
  }
  .blog-detail-page .comment-section .media {
    display: inline-block;
    text-align: center;
  }

  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important;
  }
  .faq-section .theme-accordion .card .card-header button {
    line-height: 1.3;
    padding: 12px 35px 12px 12px !important;
  }
  .faq-section .accordion.theme-accordion .card .card-header button:before {
    top: 20px;
  }

  .pwd-page {
    padding: 70px 0;
  }
  .pwd-page h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .login-page .authentication-right h6 {
    margin-bottom: 10px;
  }
  .login-page .theme-card {
    padding: 25px;
  }

  .register-page .theme-card {
    padding: 18px;
  }
  .register-page .theme-card .theme-form input {
    margin-bottom: 20px;
    padding: 12px 15px;
  }

  .search-block .btn-solid {
    padding: 10px 15px;
  }

  .contact-page .map iframe {
    height: 275px;
  }
  .contact-page .theme-form input {
    padding: 12px 15px;
    margin-bottom: 18px;
  }

  .blog-page .blog-media .blog-right h6 {
    margin-bottom: 0;
  }
  .blog-page .blog-media .blog-right p {
    line-height: 1.3;
  }
  .blog-page .blog-media .blog-right h4 {
    margin-bottom: 5px;
  }
  .blog-page .blog-media .blog-right ul {
    margin-bottom: 5px;
  }
  .blog-page .blog-media .blog-right ul li {
    display: block;
  }
  .blog-page .blog-media .blog-right ul li + li {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }

  .account-sidebar {
    width: 45%;
  }

  .success-text h2 {
    font-size: 20px;
  }
  .success-text p {
    font-size: 14px;
  }

  .delivery-sec h3,
.delivery-sec h2 {
    font-size: 18px;
  }

  .rtl .theme-tab .tab-title2 {
    -webkit-padding-start: 0;
  }
  .rtl .blog-page .blog-media .blog-right ul li + li {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }
  .rtl .blog-detail-page .comment-section .comnt-sec {
    text-align: center;
  }
  .rtl .blog-detail-page .comment-section li p,
.rtl .blog-detail-page .comment-section li h6 {
    text-align: center;
  }
  .rtl .product-right .product-icon .product-social li {
    padding-left: 16px;
  }
  .rtl .tab-product .nav-material .nav-tabs .nav-item .nav-link {
    text-align: right;
  }
  .rtl .tab-product .nav-material .nav-tabs .nav-item .nav-link,
.rtl .product-full-tab .nav-material .nav-tabs .nav-item .nav-link {
    padding: 15px;
  }

  .rtl .theme-tab .tab-title2 {
    -webkit-padding-start: 0;
  }
  .rtl .blog-page .blog-media .blog-right ul li + li {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }
  .rtl .blog-detail-page .comment-section li p,
.rtl .blog-detail-page .comment-section li h6 {
    text-align: center;
  }
  .rtl .product-right .product-icon .product-social li {
    padding-left: 16px;
  }
  .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link {
    text-align: right;
  }
  .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link,
.rtl .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
    padding: 15px;
  }
  .rtl .testimonial .testimonial-slider .media .media-body p {
    text-align: center;
  }
  .rtl .full-banner .santa-img {
    right: 10px;
  }
}
@media (max-width: 420px) {
  .br[_ngcontent-c29] {
    margin: -8px 0 15px 0 !important;
  }

  .about-text p {
    line-height: 22px;
  }

  .service_slide .service-home .service-block1 svg {
    width: 40px;
    height: 40px;
  }

  .vertical-tab.tab-product .nav-material .nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material .nav-tabs .nav-item .nav-link {
    padding: 10px 20px;
  }

  .center-slider .product-box {
    padding: 10px 0;
  }
  .center-slider .product-box > app-product-box {
    display: flex;
    width: 100%;
  }
  .center-slider .product-box .product-detail {
    margin-top: 10px;
  }
  .center-slider .product-box .product-detail h6 {
    font-size: 14px;
    margin-top: -10px;
  }
  .center-slider .product-box .product-detail .rating .pro-rating .br-units {
    justify-content: left;
  }

  h2 {
    font-size: 22px;
  }

  .subscribe h4 {
    display: block;
  }

  footer .btn-solid {
    padding: 7px 8px;
  }
  footer p {
    line-height: 25px;
  }

  .blog-details p {
    font-size: 14px;
    line-height: 1.3;
  }

  .about-text p {
    line-height: 22px;
  }

  .footer-theme .sub-title h4 {
    margin-bottom: 5px;
  }
  .footer-theme .sub-title .contact-list li {
    line-height: 25px;
  }

  .breadcrumb-section {
    padding: 15px 0;
  }

  .footer-light .subscribe-form .form-control {
    width: 320px;
  }

  .subscribe-form {
    display: inline-block;
  }
  .subscribe-form .form-group {
    margin-bottom: 10px;
  }

  .no-slider .product-box {
    flex: 0 0 100%;
    max-width: calc(100% - 30px);
    margin: 0 15px 30px !important;
  }
  .no-slider .product-box:nth-last-child(1) {
    margin: 0 15px 0 !important;
  }
  .no-slider .product-box:nth-last-child(2) {
    margin: 0 15px 30px !important;
  }

  .full-banner .banner-contain h2 {
    font-size: 55px;
  }
  .full-banner .banner-contain h3 {
    font-size: 36px;
  }

  .home-slider .slider-contain h1 {
    font-size: 25px;
    margin-top: 10px;
  }
  .home-slider .slider-contain h4 {
    letter-spacing: 0.01em;
  }
  .home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline {
    margin-top: 10px;
    padding: 5px 18px;
  }
  .home-slider:hover .owl-prev,
.home-slider:hover .owl-next {
    transform: scale(1.5);
  }
  .home-slider:hover .owl-next {
    right: 50px;
  }
  .home-slider:hover .owl-prev {
    left: 50px;
  }

  .theme-card.center-align {
    display: unset;
    height: unset;
    align-items: unset;
  }
  .theme-card.card-border .offer-slider img {
    padding: 0 15px 0 15px;
  }

  .full-box .center-slider .offer-slider .product-box {
    display: flex;
    border-bottom: 1px solid #f1f5f4;
    border-top: 1px solid #f1f5f4;
  }
  .full-box .center-slider .offer-slider .product-box app-product-box-two {
    display: flex;
    align-items: center;
  }
  .full-box .center-slider .offer-slider .product-box .img-wrapper {
    margin-right: 10px;
  }
  .full-box .center-slider .offer-slider .product-box .img-wrapper img {
    height: 120px;
    width: unset;
  }
  .full-box .center-slider .offer-slider .product-box .product-info {
    text-align: left;
  }
  .full-box .center-slider .offer-slider .product-box .product-info h6 {
    padding-top: 5px;
  }
  .full-box .center-slider .offer-slider .product-box .product-info .color-variant {
    padding-top: 2px;
  }
  .full-box .center-slider .offer-slider .product-box .product-info .color-variant li {
    height: 15px;
    width: 15px;
    padding-right: 3px;
  }
  .full-box .center-slider .offer-slider .product-box .product-detail {
    padding-left: 0;
  }
  .full-box .center-slider .offer-slider .product-box .product-detail h4 {
    font-size: 20px;
  }
  .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
    padding-top: 0;
  }
  .full-box .center-slider .offer-slider .product-box:hover .product-info .btn-outline {
    display: none;
  }
  .full-box .center-slider .offer-slider .product-box:hover .product-info .color-variant {
    opacity: 1;
  }
  .full-box .theme-card .offer-slider .product-box2 .media img {
    margin-right: 10px;
  }
  .full-box .theme-card .offer-slider .sec-1 {
    display: inline;
  }
  .full-box .theme-card .offer-slider .sec-1 .product-box2 {
    border-bottom: 1px solid #f1f5f4;
  }
  .full-box .theme-card .offer-slider .sec-1 .product-box2:last-child {
    border-bottom: none;
  }
  .full-box .theme-card .offer-slider .sec-1 .product-box2 + .product-box2 {
    border-left: none;
  }

  .center-slider .offer-slider .product-box .product-detail {
    text-align: left !important;
  }
  .center-slider .offer-slider .product-box .product-detail .rating i {
    padding-right: 3px;
  }
  .center-slider .offer-slider .product-box .product-detail h4 {
    padding-bottom: 0.5rem;
  }
  .center-slider .offer-slider .product-box .product-detail .color-variant li {
    height: 18px;
    width: 18px;
    padding-right: 3px;
  }
  .center-slider .offer-slider .product-box .img-wrapper .cart-box {
    display: none;
  }

  .product-box .img-wrapper .lable-block .lable3 {
    font-size: 13px;
    padding: 15px 10px;
  }
  .product-box .img-block .lable-wrapper .lable1,
.product-box .img-block .lable-wrapper .lable2 {
    padding: 10px 14px 10px 20px;
    font-size: 12px;
  }

  .team h6 {
    line-height: 15px;
  }

  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 30px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    font-size: 30px;
  }

  .login-page .authentication-right p {
    line-height: 1.5;
  }
  .login-page .theme-card .theme-form input {
    padding: 15px 20px;
    margin-bottom: 25px;
  }

  .tab-product .nav-material .nav-tabs {
    display: block;
  }
  .tab-product .nav-material .nav-tabs .nav-item {
    width: 100%;
  }
  .tab-product .nav-material .nav-tabs .nav-item .nav-link {
    padding: 10px 20px;
  }

  .product-right .product-buttons .btn-solid,
.product-right .product-buttons .btn-outline {
    padding: 5px 8px;
  }
  .product-right.product-form-box .timer {
    padding-left: 29px;
  }
  .product-right.product-form-box .timer span {
    width: 45px;
  }
  .product-right .timer {
    padding-left: 35px;
    width: 100%;
  }
  .product-right .timer span {
    width: 55px;
  }
  .product-right .timer span.padding-l {
    padding-right: 18px;
  }
  .product-right .product-icon .product-social {
    margin-top: 0;
  }
  .product-right .product-icon .product-social li {
    padding-right: 5px;
  }
  .product-right .product-icon .product-social li a i {
    font-size: 14px;
  }
  .product-right .product-icon .wishlist-btn i {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 5px;
  }
  .product-right .product-icon .wishlist-btn span {
    font-size: 14px;
  }

  .tab-product .theme-form input,
.tab-product .theme-form textarea {
    font-size: 12px;
    padding: 8px 15px;
  }

  .order-box .sub-total .shipping {
    width: unset;
    float: unset;
    display: flex;
  }
  .order-box .sub-total .shipping .shopping-option:last-child {
    padding-left: 20px;
  }

  .checkout-page .checkout-form .form-group {
    margin-bottom: 15px;
  }
  .checkout-page .checkout-form input[type=text], .checkout-page .checkout-form input[type=email], .checkout-page .checkout-form input[type=password], .checkout-page .checkout-form input[type=tel], .checkout-page .checkout-form input[type=number], .checkout-page .checkout-form input[type=url] {
    height: 40px;
  }
  .checkout-page .checkout-form select,
.checkout-page .checkout-form textarea {
    height: 40px;
  }

  .center-slider .product-box .img-wrapper img {
    padding: 0;
  }

  .rtl .full-box .center-slider .offer-slider .product-box .product-detail .rating,
.rtl .full-box .center-slider .offer-slider .product-box .product-detail h4,
.rtl .full-box .center-slider .offer-slider .product-box .product-detail h6,
.rtl .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
    text-align: right !important;
  }
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail .br.br-default {
    justify-content: end !important;
  }
  .rtl .order-box .sub-total .shopping-option label {
    padding-right: 0;
  }
  .rtl .faq-section .accordion.theme-accordion .card .card-header button:before {
    left: 10px;
  }

  .dark .full-box .theme-card .offer-slider .sec-1 .product-box2 {
    border-bottom: none;
  }
  .dark .full-box .center-slider .offer-slider .product-box {
    border-bottom: none;
    border-top: none;
  }
}
@media (max-width: 360px) {
  .tools-parallax-product .tools-description .tools-form .search-box {
    width: 92%;
  }

  .service_slide .home-slider .slider-contain {
    height: 50vh;
  }

  .btn-solid,
.btn-outline {
    padding: 10px 15px;
  }

  h2 {
    font-size: 25px;
  }

  .pet-parallax .full-banner {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .category-button .col {
    flex-basis: unset;
  }

  .footer-theme2 .subscribe-block {
    padding: 0 30px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 20px 20px;
  }

  .layout-7 .home .slider-contain {
    height: 50vh;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .form-control {
    border: none;
  }

  .footer-light .subscribe-form .form-control {
    width: 250px;
  }

  .white-layout .small-section .footer-theme2 .col {
    padding: 0 15px;
  }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 330px;
  }

  .footer-end p {
    font-size: 13px;
  }

  .category-border div .category-banner .category-box h2 {
    padding: 7px 15px;
  }

  .full-banner {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .full-banner.parallax-layout {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .full-banner .banner-contain h2 {
    font-size: 55px;
  }
  .full-banner .banner-contain h3 {
    font-size: 30px;
  }

  .home-slider .home {
    height: 50vh;
  }
  .home-slider .slider-contain {
    height: 50vh;
  }
  .home-slider .slider-contain h1 {
    font-size: 25px;
  }
  .home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline {
    margin-top: 8px;
    padding: 5px 15px;
  }
  .home-slider:hover .owl-prev,
.home-slider:hover .owl-next {
    transform: scale(1.1);
  }
  .home-slider:hover .owl-next {
    right: 40px;
  }
  .home-slider:hover .owl-prev {
    left: 40px;
  }

  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
    font-size: 13px;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    height: 12px;
    width: 12px;
  }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 115px;
  }

  .blog-detail-page .blog-detail p {
    line-height: 1.5;
  }
  .blog-detail-page .blog-contact h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .about-page p {
    line-height: 1.2;
  }

  .theme-modal .modal-dialog .modal-content .modal-body {
    padding: 10px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 20px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
    right: 10px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .collection-product-wrapper .product-pagination .pagination .page-item a {
    padding: 15px 13px;
  }
  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    padding-left: 10px;
  }
  .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
    line-height: 15px;
  }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 80%;
  }

  .login-page .theme-card {
    padding: 18px;
  }

  .search-block .btn-solid {
    padding: 10px 5px;
  }
  .search-block .form-header .input-group input {
    padding: 10px 12px;
  }

  .contact-page .map iframe {
    height: 200px;
  }

  .cart-section tbody tr td,
.wishlist-section tbody tr td {
    min-width: 115px;
  }
  .cart-section tbody tr td .mobile-cart-content .col-xs-3,
.wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .cart-section tbody tr td a img,
.wishlist-section tbody tr td a img {
    height: 80px;
  }
  .cart-section tfoot tr td,
.wishlist-section tfoot tr td {
    padding-right: 27px;
  }
  .cart-section tfoot tr td h2,
.wishlist-section tfoot tr td h2 {
    font-size: 21px;
  }

  .wishlist-section .wishlist-buttons:last-child a {
    margin-left: 2px;
  }

  .product-buttons .btn-solid,
.product-buttons .btn-outline {
    padding: 7px 16px;
  }

  .product-description .qty-box .input-group {
    width: 100%;
  }

  .single-product-tables.detail-section table {
    width: 70%;
  }

  .product-right .timer {
    padding-left: 25px;
  }
  .product-right .timer span .padding-l {
    padding-left: 10px;
  }

  .product-form-box .timer span {
    width: 47px;
  }
  .product-form-box .timer span .padding-l {
    padding-left: 9px;
  }
  .product-form-box .product-buttons .btn-solid,
.product-form-box .product-buttons .btn-outline {
    padding: 5px 5px;
  }

  .product-form-box .timer span,
.border-product .timer span {
    width: 45px;
  }
  .product-form-box .timer span .padding-l,
.border-product .timer span .padding-l {
    padding-right: 5px;
  }

  .rtl .product-right .product-icon .product-social li {
    padding-left: 7px;
  }
  .rtl .banner-timer .timer {
    padding-left: 0;
    padding-right: 20px;
  }
  .rtl .banner-timer .timer span {
    width: 50px;
  }
  .rtl .product-form-box .timer span,
.rtl .border-product .timer span {
    width: 55px;
  }
}
@media (max-width: 320px) {
  .footer-theme2 .col {
    padding: 10px 5px;
  }

  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .form-control {
    letter-spacing: 1px;
  }

  .footer-theme2.section-light .footer-block .subscribe-white .form-control {
    letter-spacing: 1px;
  }

  .footer-theme .footer-logo {
    margin-bottom: 20px;
  }

  .white-layout .footer-theme2 .col {
    padding: 0 15px;
  }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 290px;
  }

  .collection-banner .contain-banner.banner-3 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .collection-banner .contain-banner.banner-3 h2 {
    font-size: 15px;
  }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .collection-banner .contain-banner.banner-4 h2 {
    font-size: 15px;
  }
}
/*=====================
Fashion-2 Demo Responsive CSS
==========================*/
@media (max-width: 767px) {
  .box-layout-body .box-layout {
    margin-top: -40px;
  }
}
@media (max-width: 577px) {
  .box-layout-body .box-layout {
    margin-top: 0;
  }
}
.br-default .br-units {
  align-items: center;
}
.br-default .br-unit {
  margin-right: 5px;
  font-size: 23px;
  height: 18px;
  width: 18px;
}
.br-default .br-unit:after {
  content: "★";
  color: #d2d2d2;
}
.br-default .br-selected:after {
  color: #EDB867;
}
.br-default .br-active:after {
  color: #EDB867;
}